<template>
    <div class="top-bar">
        <ul>
            <li class="text-primary-hover active">
                <router-link to="/toate-finantarile">{{data}}</router-link>
            </li>
            <!--<li>-->
                <!--<router-link :to="'/program/' + program.id">{{program.acronym}}</router-link>-->
            <!--</li>-->
            <li
                    v-for="(item, x) in itemsReverse"
                    :key="x"
                    class="text-primary-hover"
            >
                <!--                <router-link :to="item.ruta" >
                                    <span>{{ item.denumire }}</span>
                                </router-link>-->
                <router-link :to="'/program/' + item.id">{{item.name}}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            breadcrumb: {
                type: Array,
                default: null,
            },
            data: {
                type: String,
                default: null,
            },
            program: {
                default: null,
            },
        },
        data() {
            return {}
        },
        computed: {
            itemsReverse() {
                if (this.breadcrumb) {
                    return [...this.breadcrumb].sort((a, b) => b.level - a.level)
                } else {
                    return null
                }
            }
        }
    }
</script>

<style scoped>
    .top-bar {
        display: flex;
        align-items: center;
        min-height: 50px;
        box-sizing: border-box;
        padding: 0 20px;
        background: linear-gradient(to bottom, #6786aa, #7da8ac);
        color: #fff;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .active {
        font-weight: 700;
        background: transparent;
    }

    ul {
        display: flex;
        margin: 0;
        flex-wrap: wrap;
        justify-content: center;

    }

    ul > li:not(:last-child):after {
        content: "|";
        padding-left: 20px;
        margin-right: 20px;
    }

    .text-primary-hover {
        font-size: 15px;
    }

    a {
        display: inherit;
    }

    a:not(:last-child):after {
        content: "|";
        padding-left: 20px;
        margin-right: 20px;
    }

    .text {
        font-weight: bold;
    }

    a {
        color: #fff;
        display: initial;
    }

    @media only screen and (max-width: 900px) {
        .top-bar > div {
            margin: 5px auto;
        }

        .top-bar {
            text-align: center;
        }
    }
</style>
