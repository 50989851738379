
<template>
  <div class="top-line">
    <form action="#" class="">
      <div class="checkbox-container">
         <span  v-if="data.status === 'opened'">
              <div class="circle"></div>
              <label class="activ">Finanțare activă - apel deschis </label>
         </span>
        <span  v-else-if="data.status === 'pending'">
          <div class="circle in-pregatire"></div>
              <label class="in-pregatire">Finanțare in pregătire / consultare</label>
          </span>
        <span  v-else-if="data.status === 'closed'">
            <div class="circle inactiv"></div>
            <label class="inactiv">Finanțare inactivă - apel închis </label>
        </span>
        <p class="data-text">
          <span class="line">|</span> Dată deschidere apel: <span class="bold-text">{{formatUtcDate(data.start_date, 'DD.MM.YYYY (HH:mm)')}}</span>
        </p>
        <p class="data-text">
          <span class="line">|</span> Dată limită depunere: <span class="bold-text">{{formatUtcDate(data.end_date, 'DD.MM.YYYY (HH:mm)')}}</span>
        </p>
      </div>
    </form>
    <div class="heart-favorite-icon">
      <HeartFavorite :isFavorite="data.wishlisted" :id="data.id"></HeartFavorite>
    </div>
  </div>
</template>

<script>
import HeartFavorite from '@/common/components/HeartFavorite'
import {formatUtcDate} from '@/helpers/helpers'
  export default{
    components: {HeartFavorite},
    props: {
      data: null,
    },
      mounted () {
      },
    data() {
      return{
        isFavorite: this.data.wishlisted,
      }
    },
    methods: {
      formatUtcDate,
      favorite() {
        if (this.isFavorite === true) {
          this.isFavorite = false;
        } else {
          this.isFavorite = true;
        }
      }
    },
  }
</script>

<style scoped>
.top-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bold-text {
  font-weight: bold;
}
.checkbox-container {
  display: flex;
  align-items: center;
}
label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #00c9a1;
  margin: 0;
}
label.activ{
  color: #0cc2aa;
}
label.in-pregatire {
  color: #6f6f6f;
}
label.inactiv {
  color: #ff5a5a;
}
.data-text {
  color: #868686;
  margin-bottom: 0px;
  font-size: 14px;
}
.circle {
  background-color: #00c9a1;
  border: 2px solid #fdfdfd;
  width: 18px;
  height: 18px;
  min-width: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.circle.in-pregatire {
  background-color: #6f6f6f;
}
.circle.inactiv {
  background-color: #ff5a5a;
}
.checkbox-container>span{
  display: flex;
}
.checkbox-container p {
  text-align: left;
}
.heart-favorite-icon {
  margin-left: 10px;
}
.line{
  margin: 0 30px;
}
.heart-favorite-icon {
  cursor: pointer;
  margin-right: 5px;
}
@media only screen and (max-width: 710px) {
  .checkbox-container{
    display: flex;
    flex-direction:column;
  }
  .data-text{
    margin: 10px 0;
  }
  .top-line{
    flex-direction: column;
  }
  .heart-favorite-icon{
    margin: 10px
  }
}
@media only screen and (max-width: 1100px) {
  .data-text{
    margin-left: 15px;
  }
  .line{
    display: none;
  }
}
</style>