<template>
    <div>
        <div>
            <div class="flexed">
                <div>
                    <apexchart type="pie" width="90" :options="chartOptions" :series="series"></apexchart>
                </div>
                <div>
                    <p class="finantari-active"><span class="procent-finantari">{{ parseInt(this.data.active_fundings*100/this.data.total_fundings) }}%</span> finanțări active</p>
                    <p>Vezi toate apelurile deschise</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import VueApexCharts from 'vue-apexcharts'
    Vue.use(VueApexCharts)

    Vue.component('apexchart', VueApexCharts)
    export default {
        components: {},
        props:{
            data: null
        },
        data() {
            return {
                series: [100-parseInt(this.data.active_fundings*100/this.data.total_fundings), parseInt(this.data.active_fundings*100/this.data.total_fundings)],

                chartOptions: {
                    colors: ['#f1f2f3', '#0cc2aa'],
                    plotOptions: {
                        pie: {
                            expandOnClick: false
                        },
                    },
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false
                    },
                    states: {
                        normal: {
                            filter: {
                                type: 'none',
                            }
                        },
                        hover: {
                            filter: {
                                type: 'none',
                            }
                        },
                        active: {
                            filter: {
                                type: 'none',
                            }
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                }
            }
        },
    }
</script>

<style scoped>
    .flexed {
        display: flex;
        align-items: center;
    }

    p {
        text-align: left;
    }

    .procent-finantari {
        font-size: 18px;
        font-weight: 700;
    }

    .finantari-active {
        font-weight: 500;
    }

    @media only screen and (max-width: 1600px) {
        .flexed {
            display: block;
        }
    }
</style>