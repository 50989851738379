<template>
    <div >
        <div>
            <UserTopBar :user-name="this.infoCont.name"
                        :role="this.infoCont.rol"
                        :email="this.infoCont.email"
                        :user-image="this.infoCont.avatar"
                        :usepackage="this.infoCont.account_type_name"
                        :phone="this.infoCont.phone"
                        :company="this.infoCont.company"
            ></UserTopBar>
            <CompaniileMeleTabs></CompaniileMeleTabs>
        </div>
        <div class="viewPagePadding">
            <div>
                <CompaniiContulTau></CompaniiContulTau>
            </div>
        </div>
    </div>
</template>

<script>
    import CompaniiContulTau from "./components/CompaniiContulTau";
    import CompaniileMeleTabs from "./components/CompaniileMeleTabs";
    import UserTopBar from '../ContulMeu/components/UserTopBar'
    export default {
        name: "Test",
        components: {
            CompaniiContulTau,
            CompaniileMeleTabs,
            UserTopBar,
        },
        props: {},
        data() {
            return {
                infoCont: {
                    name: '',
                    rol: '',
                    email: '',
                    phone: '',
                    avatar: '',
                    account_type_name: '',
                    account_type_logo: '',
                    company: '',
                },
            };
        },
        mounted() {
            this.getInfoCont()
        },
        methods: {
            getInfoCont() {
                this.infoCont.name = this.$store.state.auth.user.name
                this.infoCont.rol = this.$store.state.auth.user.role
                this.infoCont.email = this.$store.state.auth.user.email
                this.infoCont.phone = this.$store.state.auth.user.phone
                this.infoCont.avatar = this.$store.state.auth.user.logo
                if(this.$store.state.auth.user.account_type){
                    this.infoCont.account_type_name = this.$store.state.auth.user.account_type.name
                    this.infoCont.account_type_logo = this.$store.state.auth.user.account_type.logo
                }
                this.infoCont.company = this.$store.state.auth.user.company
            }
        },
    };
</script>

<style scoped>
p {
    font-size: 14px;
    text-align: left;
    color: #bababa;
}
.title-text {
    font-weight: bold;
    color: #929292;
}
.text-width {
    width: 75%;
}
.adaugare-companie {
    display: flex;
    justify-content: space-between;
}
.adaugare-companie .bttn {
    margin: 0 0 0 10px;
}
@media only screen and (max-width: 700px) {
.adaugare-companie {
    display: flex;
    flex-direction: column;
    justify-content: initial;
}
.text-width {
    width: 100%;
}
.adaugare-companie .bttn {
    margin: 20px 0 0 0;
}
}
</style>
