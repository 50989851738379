<template>
    <div>
        <div class="background-container" :style="{ background: 'linear-gradient(rgba(148, 182, 186, 0.8), rgba(148, 182, 186, 0.8)), url(' + data.banner.path ? data.banner.path : '' + ')' }">
            <div class="head">
                <h1 class="text-left">{{data.acronym}}</h1>
                <div class="headerText">
                    <h2 class="text-left">
                        <span class="text-bold">Programul Operațional:</span> {{data.name}}<br>
                      {{ data.main }}
                    </h2>                    
                </div>
            </div>
            <div class="d-flex detalii">
                <div class="finantare">
                    <p class="margin-text-b">Tip Finanțare</p>
                    <img v-if="data.type.logo && data.type.logo.path" :src="data.type.logo.path" :alt="data.type.title" >
                    <p class="text-bold text-uppercase" v-if="data.type.title">{{ data.type.title }}</p>
                </div>
                <div class="buget">					
                    <p>
                        <strong>Total buget alocat:</strong>
                    </p>
                    <p class="bigText" v-if="data.budget">{{data.budget}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
          data: {
              name: null,
              type: null,
              banner: {path: null},
              acronym: null,
              budget: null,
              main: null,
          }
        },
        data() {
            return {

            };
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
.headerText{
	margin-right: 10px;
}
.bigText{
	font-size:26px;
	font-weight: 600;
	line-height: 17px;
}

	.background-container {
		display: flex;
		justify-content: space-between;
		background: url("../../../assets/images/prezentare-background.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 30% center;
		padding: 20px;
		align-items: center;
	}
	p, h1, h2{
		color: #fff;
	}
	p {
		text-align: center;
		font-size: 14px;
	}
	h1, h2{
		margin: 0;
	}
	h1{
		font-size: 26px;
		line-height: 23px;
	}
	h2{
		font-size: 14px;
	}
	h3 {
		font-size: 18px;
	}
	.buget {
		width: 225px;
		margin-right: 75px;
		padding-top: 40px;
	}
	
.buget p{
	text-align: right;
}

.finantare {
	border-right: 3px dotted #f4f6f6;
	border-left: 3px dotted #f4f6f6;
	text-align: center;
	width: 210px;
	padding: 0 20px;
}
.text-left {
	text-align: left;
	padding-left: 10px;
}
.text-bold{
	font-size: 14px;
	margin-top: 5px;
}
.margin-text-b{
	margin-bottom: 5px;
}
.text-bold,
.margin-text-b {
font-weight: bold;
}
@media only screen and (max-width: 850px) {
	.bigText{
		font-size: 20px;
	}
	.buget{
		width: 190px;
		margin-right: auto;
	}
}
@media only screen and (max-width: 700px) {
	.bigText{
		font-size: 20px;
	}
	.buget{
		width: 190px;
		margin-right: auto;
	}
	.finantare{		
		width: auto;		
	}
}

@media only screen and (max-width: 480px) {
	.finantare img{
		width: 16%;
		margin: 0 10px;
		height: auto;
	}
	h1{
		text-align: center !important;
		display: block;
	}
	h2 *{
		text-align: center;
		line-height: 35px;
	}
	.bigText{
		font-size: 16px;
	}
	.buget {
		width: auto;
		margin: 0 10px;
		display: flex;
		justify-content: space-around;
	}
	.background-container{
		flex-direction: column;
		padding: 10px 0px;
	}
	.detalii{
		margin-top: 15px;
		display: flex;
		flex-direction: column;
	}
	.finantare{
		border: none;
		display: flex;
	}
	.finantare p{
		font-size: 11px;
	}
	h1.text-left{
		text-align: center;
	}
	.headerText .text-bold{
		display: block;
	}
	br{
		display: none;
	}


}

</style>
