import moment from 'moment'
import Vue from 'vue';

/**
 * Format UTC Date
 * Default format: 'DD.MM.YYYY'
 */
export function formatUtcDate (utcDate, format = 'DD.MM.YYYY') {
    moment.locale("ro")
    return moment.utc(utcDate).format(format);
}

/**
 * Format URL - Remove http, https
 * Default format: 'DD.MM.YYYY'
 */
export function formatUrl (url, addWww) {
    let urlFormated = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
    if(addWww) {
        return 'www.' + urlFormated
    } else {
        return urlFormated
    }
}

/**
 * Limit Words from string
 * @parameters: string, words number
 */
export function limitWords (string, words) {
    var totalWords = string.split(' ').length;
    return string.split(" ").splice(0, words).join(" ") + (words < totalWords ? '...' : '')
}

export function notify(message, options = {}) {
    Vue.$toast.open({
        message,
        type: 'default',
        duration: 3000,
        position: (screen.width < 992) ? 'bottom' : 'bottom-right',
        ...options,
    });
}
