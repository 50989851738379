<template>
    <div>
        <div class="centered">
            <b-icon-question-circle font-scale="4"></b-icon-question-circle>
            <span class="you-sure mt-4">Ești sigur?</span>
            <p>Această companie va fi ștearsă permanent din contul tău.</p>
        </div>
    </div>
</template>

<script>
import {BIconQuestionCircle} from 'bootstrap-vue';
export default{
    components:{
        BIconQuestionCircle,
    },
    props: {
    },
    data() {
        return{
        }
    },
    methods: {
    },
}
</script>

<style scoped>
.centered{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.button-modal{
    font-size: 13px;
}
.flexed{
    display: flex;
    margin-top: 15px;
    width: 100%;
    justify-content: space-evenly;
}
.you-sure{
    font-size: 18px;
    font-weight: 600;
}
span, p, svg{
    color: #fff;
}
</style>