<template>
    <div >
        <transition name="v" appear>
            <div v-if="title.length > 0" class="dropdown-menu-finantare" >
                <div class="dropdown-menu-finantare-inner">
                    <div
                        v-for="(item, i) in title"
                        :key="i"
                        class="dropdown-menu-finantare-item"
                    >
                        <div class="title">
                            <a><span>{{ item.name }} </span>
                                <span

                                    class="notification"
                                >test</span></a>
                        </div>
                        <div class="col-sub">
                            <ul
                                v-for="(childItem, x) in item.items"
                                :key="x"
                                class="text-primary-hover"
                            >
                                <router-link class="menu-item" :to="`/program/${childItem.id}`" @click.native="$emit('closeDropdown')" >
                                    <span>{{ childItem.name.length > 50 ? childItem.name.substring(0,50)+'...' : childItem.name }}</span>
                                </router-link>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: Array,
                default: null,
            },
        },
        data() {
            return {};
        },
        created() {},
        methods: {
        },
    };
</script>

<style scoped>
    .dropdown-menu-finantare {
        position: absolute;
        z-index: 999;
        float: left;
        min-width: 160px;
        margin-left: 251px;
        width: calc(100% - 251px);
        margin-top: 1px;
        background-color: #fff;
        background-clip: padding-box;
        font-size: 0.875rem;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border-left: 1px solid #e5e5e5;
        border-right: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        transform-origin: top left;
        padding: 1rem;
        color: rgba(0, 0, 0, 0.87);
        top: 100%;
        left: 0;
        box-sizing: border-box;
        display: block;
    }

    .dropdown-menu-finantare-inner {
        display: flex;
    }
    .dropdown-menu-finantare-item {
        width: 25%;
    }
    .title {
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
        color: #212121;
        padding-right: 2px;
        font-weight: bold;
    }
    .title a{
        display: flex;
        align-items: center;
    }
    .title .notification{
        margin-left: 10px;
    }
    .notification {
        background-color: #6cc788;
        padding: 0.15em 0.35em;
        font-size: 75%;
        font-weight: 700;
        color: #fff;
        border-radius: 0.2rem;
    }
    .col-sub {
        padding-right: 15px;
        font-size: 12px;
    }
    ul{
        margin-bottom: 0;
    }
    .col-sub a {
        font-weight: normal !important;
    }
    .text-primary-hover a:hover {
        color: #0cc2aa !important;
        text-decoration: none;
    }
    .menu-item {
        text-decoration: none;
        line-height: 2em;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .v-enter-active,
    .v-leave-active {
        transition: all 0.7s ease-in-out;
        opacity: 0.8;
    }
    .v-enter,
    .v-leave-to {
        transform: translateY(-310px);
        opacity: 0;
    }
    @media only screen and (max-width: 1510px) {
        .dropdown-menu-finantare{
            margin-left: 234px !important;
            width: calc(100% - 234px);
        }
    }
</style>