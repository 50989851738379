<template>
    <div class="modul-finantare-title">
        <div class="module-header">
            <span class="bold-text gradient-title">BUSINESS CONSULTING SERVICES</span>
            <div class="social-media">
                <a :href="(data.facebook ? data.facebook : '#')">
                    <span class="background-circle facebook">
                        <font-awesome-icon :icon="['fab', 'facebook-f']"></font-awesome-icon>
                    </span>
                </a>
                <a :href="(data.instagram ? data.instagram : '#')">
                    <div class="background-circle gray" :class="{ 'instagram': data.instagram }">
                        <font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon>
                    </div>
                </a>
                <a :href="(data.youtube ? data.youtube : '#')">
                    <div class="background-circle gray" :class="{ 'youtube': data.youtube }">
                        <font-awesome-icon :icon="['fab', 'youtube']"></font-awesome-icon>
                    </div>
                </a>
                <a :href="(data.twitter ? data.twitter : '#')">
                    <div class="background-circle twitter" :class="{ 'twitter': data.twitter }">
                        <font-awesome-icon :icon="['fab', 'twitter']"></font-awesome-icon>
                    </div>
                </a>
                <a :href="(data.linkedin ? data.linkedin : '#')">
                    <div class="background-circle linkedin" :class="{ 'linkedin': data.linkedin }">
                        <font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon>
                    </div>
                </a>
            </div>
        </div>

        <div class="despre-companie" v-html="data.description">

        </div>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faFacebookF} from '@fortawesome/free-brands-svg-icons'
import {faInstagram} from '@fortawesome/free-brands-svg-icons'
import {faYoutube} from '@fortawesome/free-brands-svg-icons'
import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons'


library.add(faFacebookF, faInstagram, faYoutube, faTwitter, faLinkedinIn)
export default {
    name: "InformatiiCompanie",
    components: {
        FontAwesomeIcon
    },
    props: {
        data: null,
    }
}
</script>

<style scoped>
a {
    color: inherit;
    margin-right: 5px;
}

.bold-text {
    font-weight: bold;
}

.social-media {
    display: flex;
    color: #fff;
}

.module-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-media svg {
    font-size: 20px;
}

.background-circle {
    width: 30px;
    height: 30px;
    background: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-circle:not(:last-child) {
    margin-right: 5px;
}

.gray {
    background: #c7c7c7;
}

.facebook {
    background: #4b69b0;
}

.instagram {
    background: #8a3ab9;
}

.youtube {
    background: #c4302b;
}

.twitter {
    background: #37b1e1;
}

.linkedin {
    background: #0678b6;
}
.despre-companie >>> h1, .despre-companie >>> h2, .despre-companie >>> h3, .despre-companie >>> h4{
    font-size: 18px;
}
.dark-gray {
    color: #727272;
}

.gradient-title {
    font-size: 14px;
    color: #1c1c1c;
}

@media only screen and (max-width: 900px) {
    .module-header {
        display: block;
    }
}
</style>