<template>
    <div>
        <b-sidebar
            id="sidebar-task-handler"
            sidebar-class="sidebar-lg"
            :visible="active"
            bg-variant="white"
            shadow
            backdrop
            no-header
            right
            @change="(val) => this.$emit('closeSidebar', val)"
        >
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1" v-if="todoInfo != null">
                <b-button type="button" class="btn btn-outline btn-sm">Finalizare task</b-button>
                <div class="right-icons">
                    <b-icon-trash font-scale="1" @click="deleteTask(todoInfo.id)"></b-icon-trash>
                    <b-icon-star font-scale="1"  @click="favoriteTask(todoInfo.id, todoInfo.is_important)" v-if="todoInfo.is_important === 0"></b-icon-star>
                    <b-icon-star-fill font-scale="1" style="fill: var(--warningColor)" @click="favoriteTask(todoInfo.id, todoInfo.is_important)" v-else></b-icon-star-fill>
                    <b-icon-x-circle font-scale="1" @click="closeSidebar"></b-icon-x-circle>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1" v-else>
                <div class="right-icons">
                    <b-icon-x-circle font-scale="1" @click="closeSidebar"></b-icon-x-circle>
                </div>
            </div>
            <div class="padding-box">
                <b-form v-if="todoInfo != null">
                    <b-form-group
                        label="Titlu"
                        label-for="titlu"
                        class="w-100"
                    >
                        <b-form-input
                            id="title"
                            type="text"
                            class="w-100"
                            v-model="todoInfo.title"
                        ></b-form-input>
                        <span
                            class="error-message"
                            v-if="errors.has('title')"
                            v-text="errors.get('title')"
                        ></span>
                    </b-form-group>
                    <b-form-group
                        label="Termen limită"
                        label-for="termen-limita"
                        class="w-100"
                    >
                        <b-form-datepicker
                            id="deadline"
                            class="w-100"
                            v-model="todoInfo.deadline"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="ro"
                            placeholder="Selectează o dată"
                        ></b-form-datepicker>
                        <span
                            class="error-message"
                            v-if="errors.has('deadline')"
                            v-text="errors.get('deadline')"
                        ></span>
                    </b-form-group>
                    <b-form-group
                        label="Etichete"
                        label-for="etichete"
                        class="w-100"
                        id="tags"
                    >
                        <v-select
                            v-model="todoInfo.tags"
                            dir="ltr"
                            multiple
                            :close-on-select="false"
                            :options="tags"
                            label="name"
                            :reduce="(option) => option.id"
                            input-id="tags"
                        ></v-select>
                        <span
                            class="error-message"
                            v-if="errors.has('tags')"
                            v-text="errors.get('tags')"
                        ></span>
                    </b-form-group>
                    <b-form-group
                        label="Descriere"
                        label-for="descriere"
                        class="w-100"
                        id="description"
                    >
                        <b-form-textarea
                            class="mb-2 mr-sm-2 mb-sm-0"
                            v-model="todoInfo.description"
                            :value="null"
                            placeholder="Descrierea taskului"
                        ></b-form-textarea>
                        <span
                            class="error-message"
                            v-if="errors.has('description')"
                            v-text="errors.get('description')"
                        ></span>
                    </b-form-group>
                    <div class="flex">
                        <b-button class="add-label-button" @click="changeTask">Modifică task</b-button>
                        <b-button class="remove-label-button" @click="emptyForm">Resetează</b-button>
                    </div>
                </b-form>
                <b-form v-else>
                    <b-form-group
                        label="Titlu"
                        label-for="titlu"
                        class="w-100"
                    >
                        <b-form-input
                            id="titlu"
                            type="text"
                            class="w-100"
                            v-model="form.title"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="Termen limită"
                        label-for="termen-limita"
                        class="w-100"
                    >
                        <b-form-datepicker
                            id="termen-limita"
                            class="w-100"
                            v-model="form.deadline"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            locale="ro"
                            placeholder="Selectează o dată"
                        ></b-form-datepicker>
                    </b-form-group>
                    <b-form-group
                        label="Etichete"
                        label-for="etichete"
                        class="w-100"
                    >
                        <v-select
                            v-model="form.tags"
                            dir="ltr"
                            multiple
                            :close-on-select="false"
                            :options="tags"
                            label="name"
                            :reduce="(option) => option.id"
                            input-id="tags"
                        ></v-select>
                    </b-form-group>
                    <b-form-group
                        label="Descriere"
                        label-for="descriere"
                        class="w-100"
                    >
                        <b-form-textarea
                            class="mb-2 mr-sm-2 mb-sm-0"
                            v-model="form.description"
                            :value="null"
                            placeholder="Descrierea taskului"
                        ></b-form-textarea>
                    </b-form-group>
                    <div class="flex" v-if="tipSidebar === 'modificare'">
                        <b-button class="add-label-button">Modifică task</b-button>
                        <b-button class="remove-label-button">Șterge</b-button>
                    </div>
                    <div class="flex" v-else>
                        <b-button class="add-label-button" @click="addTask">Adaugă task</b-button>
                        <b-button class="remove-label-button" @click="emptyForm">Resetează</b-button>
                    </div>
                </b-form>
            </div>
        </b-sidebar>
    </div>
</template>


<script>
import {
    BSidebar,
    BButton,
    BIconTrash,
    BIconStar,
    BIconStarFill,
    BIconXCircle,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormDatepicker,
    BFormTextarea
} from 'bootstrap-vue'
import api from '@/helpers/api'
import axios from '@/helpers/axios'
import Errors from '@/helpers/Errors';
import vSelect from 'vue-select'

export default {
    components: {
        BButton,
        BSidebar,
        BIconTrash,
        BIconStar,
        BIconStarFill,
        BIconXCircle,
        BForm,
        BFormInput,
        BFormGroup,
        BFormSelect,
        BFormDatepicker,
        BFormTextarea,
        vSelect
    },
    directives: {},
    model: {},
    props: {
        active: null,
        tipSidebar: null,
        todoInfo: null,
        tags: [],
    },
    data() {
        return {
            form: {
                id: null,
                title: null,
                deadline: null,
                description: null,
                tags: null,
                is_important: null,
            },
            deleteTaskId: null,
            errors: new Errors(),
        }
    },
    methods: {
        closeSidebar() {
            this.$emit('closeSidebar', false);
        },
        emptyForm() {
            this.form.title = null
            this.form.deadline = null
            this.form.description = null
            this.form.tags = null
        },
        addTask() {
            axios.post(api.API_ENDPOINTS.todos, this.form)
                .then((response) => {
                    this.$emit('newTodo', response.data.todo);
                    this.$notify(response.data.message);
                }).catch((xhr) => {
                if (xhr.status === 422) {
                    this.errors.record(xhr.responseJSON.errors);
                }

                this.$notify(xhr.responseJSON.message);
            });
            this.closeSidebar()
            this.$emit('closeSidebar', false);
        },
        changeTask() {
            this.form = this.todoInfo
            if(typeof this.todoInfo.tags[0] !== 'number') {
                this.form.tags = this.todoInfo.tags.map(item => item.id)
            }
            axios.put(api.API_ENDPOINTS.todos + '/' + this.todoInfo.id, this.form)
                .then((response) => {
                    this.$notify(response.data.message);
                }).catch(xhr => {
                if (xhr.response.status === 422) {
                    this.errors.record(xhr.response.data.errors);
                }

                this.$notify(xhr.response.data.message);
                });
            this.closeSidebar()
            this.$emit('closeSidebar', false);
            this.$emit('loadTodos');
        },
        deleteTask(id){
            axios.delete(api.API_ENDPOINTS.todos + '/' + id)
                .then((response) => {
                    this.$emit('removeTodo', id);
                    this.$notify(response.data.message);
                }).catch(xhr => {
                if (xhr.response.status === 422) {
                    this.errors.record(xhr.response.data.errors);
                }

                this.$notify(xhr.response.data.message);
            });
            this.closeSidebar()
            this.$emit('closeSidebar', false);
        },
        favoriteTask(id, important){
            if(important === 0){
                important = 1
            } else if(important === 1){
                important = 0
            }
            this.form.id = id
            this.form.is_important = important
            this.todoInfo.is_important = important
            axios.post(api.API_ENDPOINTS.todos + '/important', this.form)
                .then((response) => {
                    this.$notify(response.data.message);
                }).catch(xhr => {
                if (xhr.response.status === 422) {
                    this.errors.record(xhr.response.data.errors);
                }

                this.$notify(xhr.response.data.message);
                });
            this.closeSidebar()
            this.$emit('closeSidebar', false);
        }
    },
}
</script>

<style scoped>
.right-icons svg{
    cursor: pointer;
}
.btn-outline {
    border: 1px solid #82868b !important;
    background-color: transparent;
    color: #82868b;
    font-size: 12px;
}

.btn-outline:hover {
    background-color: rgba(130, 134, 139, 0.1);
}

.content-sidebar-header {
    padding: 15px !important;
    background-color: #f6f6f6;
    align-items: center;
}

.right-icons {
    display: flex;
    align-items: center;
}

.right-icons svg:not(:last-child) {
    margin-right: 15px;
}

.padding-box {
    padding: 20px;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-label-button {
    color: #fff;
    background: -webkit-linear-gradient(#6404cd, #4593d2);
    border: none;
    padding: 10px 15px;
    font-size: var(--paragraphSize);
    font-weight: var(--bold);
}

.remove-label-button {
    color: #82868b;
    background-color: #ffffff;
    border: 1px solid #82868b !important;
    padding: 10px 15px;
    font-size: var(--paragraphSize);
    font-weight: var(--bold);
}

form >>> label {
    font-size: 14px !important;
}

input, select, textarea {
    height: 2.0rem !important;
    padding: .438rem 1rem;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 1.45 !important;
    color: #6e6b7b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: .357rem;
}
.v-select >>> .vs__dropdown-toggle{
    height: 2.0rem !important;
    padding: 0 10px;
}
.v-select >>> .vs__selected{
    margin: 0 !important;
    font-size: 12px;
    background: -webkit-linear-gradient(#6404cd, #4593d2);
    color: #fff;
    border: none;
    line-height: 20px;
}
.v-select >>> .vs__deselect{
    fill: #fff;
}
.v-select >>> .vs__selected-options{
    align-items: center;
}
textarea {
    height: 8.0rem !important;
}

.b-form-datepicker >>> button, .b-form-datepicker >>> label {
    font-size: 12px !important;
}

.b-form-datepicker >>> label {
    padding: 0.430rem 0.75rem;
}

.b-form-datepicker >>> .b-calendar-header, .b-form-datepicker >>> .b-calendar-grid-help {
    display: none;
}

.b-form-datepicker >>> .b-calendar-grid {
    font-size: 12px !important;
}

.b-form-datepicker >>> .b-calendar .b-calendar-grid-body .btn {
    font-size: 12px !important;
}

input:focus {
    box-shadow: none;
    border: 1px solid #00dfc2;
}
</style>