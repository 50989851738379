<template>
    <div>
        <div>
            <p class="title">Regiuni eligibile pentru finanțare</p>
        </div>
        <div class="harta">
            <div class="hartaInner">
                <img src="@/assets/images/finantareSingle/harta/blank-map.png" alt="Regiuni eligibile pentru finanțare">
                <div :class="regiune" v-for="(regiune, index) in data" :key="index">
                    <img class="overlayMap" :src="require(`@/assets/images/finantareSingle/harta/${regiune}.png`)">
                </div>
            </div>
            <!--<div v-else class="hartaInner">-->
                <!--<img src="@/assets/images/finantareSingle/harta/blank-map.png" alt="Regiuni eligibile pentru finanțare">-->
                <!--<img class="overlayMap" :src="require(`@/assets/images/finantareSingle/harta/ALL.png`)">-->
            <!--</div>-->
        </div>
        <div class="harta-icon">
            <img src="@/assets/images/HFLogo.png" alt="" >
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            data: []
        },
        data() {
            return {};
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
    .title{
      font-size: var(--moduleTitleSize);
    }
	p {
		color: #9f9f9f;
		text-align: center;
		font-weight: bold;
	}
	.harta {
		text-align: center;
		margin: 10px 0;
	}
	.harta img {
		max-width: -webkit-fill-available;
		height: auto;
	}
	.harta-icon {
		text-align: end;
	}
    .hartaInner{
        position: relative;
    }
    .overlayMap{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
</style>
