<template>
    <div v-if="!loading">
        <DarkBlueTitleBar :data="title"></DarkBlueTitleBar>
        <GradientTopSection :count="resultCount" :trainer="trainerCount"></GradientTopSection>
        <Filters @filterConsultanti="filterConsultanti" @noFilter="noFilters"></Filters>

        <div class="box-padding">
            <div class="parinte-companii">
                <p>Companii de consultanță verificate și recomandate de SMIS.ro</p>
                <div v-if="consultatsCondition(consultantVerificatFiltrat, consultantVerificat)" class="list-companii">
                    <CompanieLista v-for="(consultantSingle, key) in filtrari ? consultantVerificatFiltrat : consultantVerificat " :key="key"
                                   :consultant="consultantSingle"
                    ></CompanieLista>
                </div>
                <FaraCompanii v-else></FaraCompanii>
            </div>
            <div class="parinte-companii mt-5">
                <p>Companii de consultanță</p>
                <div v-if="consultatsCondition(consultantFiltrat, consultant)" class="list-companii">
                    <CompanieLista v-for="(consultantSingle, key) in filtrari ? consultantFiltrat : consultant" :key="key"
                                   :consultant="consultantSingle"
                    ></CompanieLista>
                </div>
                <FaraCompanii v-else></FaraCompanii>
            </div>
            <div class="parinte-companii mt-5">
                <p>Consultanți independenți specializați</p>
                <div v-if="consultatsCondition(consultantIndependentFiltrat, consultantIndependent)" class="list-companii" >
                    <CompanieLista v-for="(consultantSingle, key) in filtrari ? consultantIndependentFiltrat : consultantIndependent" :key="key"
                                   :consultant="consultantSingle"
                    ></CompanieLista>
                </div>
                <FaraCompanii v-else></FaraCompanii>
            </div>
            <div class="parinte-companii mt-5">
                <p>Centre de formare profesională</p>
                <div v-if="consultatsCondition(consultantCentruFiltrat, consultantCentru)" class="list-companii">
                    <CompanieLista v-for="(consultantSingle, key) in filtrari ? consultantCentruFiltrat : consultantCentru" :key="key"
                                   :consultant="consultantSingle"
                    ></CompanieLista>
                </div>
                <FaraCompanii v-else></FaraCompanii>
            </div>

        </div>
    </div>
    <LoadingSpinner v-else></LoadingSpinner>
</template>

<script>
    import LoadingSpinner from '@/common/components/LoadingSpinner'
    import DarkBlueTitleBar from '@/common/components/DarkBlueTitleBar';
    import GradientTopSection from './components/GradientTopSection';
    import Filters from './components/Filters';
    import CompanieLista from './components/CompanieLista';
    import FaraCompanii from './components/FaraCompanii';
    import api from '@/helpers/api';
    import axios from '@/helpers/axios';
    import _ from 'lodash'

    export default {
        components: {
            LoadingSpinner,
            DarkBlueTitleBar,
            GradientTopSection,
            Filters,
            CompanieLista,
            FaraCompanii
        },
        data() {
            return {
                loading: true,
                title: 'Caută un consultant',
                consultant: null,
                consultantVerificat: null,
                consultantCentru: null,
                resultCount: null,
                trainerCount: null,
                consultantIndependent: null,
                filtrari:false,
                consultantFiltrat: null,
                consultantVerificatFiltrat: null,
                consultantCentruFiltrat: null,
                trainerCountFiltrat: null,
                consultantIndependentFiltrat: null,
            }
        },
        mounted() {
            this.getCompanies()
        },
        methods: {
            consultatsCondition(filtredConsultants,consultants){
                return (filtredConsultants && filtredConsultants.length > 0 && this.filtrari) || (consultants.length > 0 && !this.filtrari)
            },
            getCompanies() {
                return axios.get(api.API_ENDPOINTS.consultants)
                    .then(response => {
                        this.consultant = _.filter(response.data, v => !v.is_verified && !v.is_trainer && !v.is_basic);
                        this.consultantVerificat = _.filter(response.data, v => v.is_verified && !v.is_basic);
                        this.consultantCentru = _.filter(response.data, v => v.is_trainer && !v.is_basic);
                        this.consultantIndependent = _.filter(response.data, v => !v.is_company && v.is_basic);
                        this.resultCount = response.data.length;
                        this.trainerCount = response.data.filter((consultant) => {
                            return consultant.is_trainer === true;
                        }).length
                    }).finally(() => this.loading = false)
            },
            filterConsultanti(filters){
                this.filtrari = true;
                this.consultantFiltrat = this.consultant;
                this.consultantVerificatFiltrat = this.consultantVerificat;
                this.consultantCentruFiltrat = this.consultantCentru;
                this.consultantIndependentFiltrat = this.consultantIndependent;
                let urlQuery = {};

                if(filters){
                    if(filters.arataCompanii){
                        this.consultantFiltrat = this.filterConsultantsByCompany( this.consultantFiltrat);
                        this.consultantVerificatFiltrat = this.filterConsultantsByCompany(this.consultantVerificatFiltrat);
                        this.consultantCentruFiltrat = this.filterConsultantsByCompany(this.consultantCentruFiltrat);
                        this.consultantIndependentFiltrat = this.filterConsultantsByCompany(this.consultantIndependentFiltrat);
                        urlQuery['doar-companii'] ="da"; 
                    }
                    if(filters.companiiVerificate){
                        this.consultantFiltrat = this.filterConsultantsByVerified( this.consultantFiltrat);
                        this.consultantVerificatFiltrat = this.filterConsultantsByVerified(this.consultantVerificatFiltrat);
                        this.consultantCentruFiltrat = this.filterConsultantsByVerified(this.consultantCentruFiltrat);
                        this.consultantIndependentFiltrat = this.filterConsultantsByVerified(this.consultantIndependentFiltrat);
                        urlQuery['companii-verificate'] ="da";
                    }
                    if(filters.judet != 'all'){
                        this.consultantFiltrat = this.filterConsultantsByJudet( this.consultantFiltrat,filters.judet);
                        this.consultantVerificatFiltrat = this.filterConsultantsByJudet(this.consultantVerificatFiltrat,filters.judet);
                        this.consultantCentruFiltrat = this.filterConsultantsByJudet(this.consultantCentruFiltrat,filters.judet);
                        this.consultantIndependentFiltrat = this.filterConsultantsByJudet(this.consultantIndependentFiltrat,filters.judet);
                        urlQuery.judet =filters.judet;
                    }
                    if(filters.servicii != 'all'){
                        this.consultantFiltrat = this.filterConsultantsByServicii( this.consultantFiltrat,filters.servicii);
                        this.consultantVerificatFiltrat = this.filterConsultantsByServicii(this.consultantVerificatFiltrat,filters.servicii);
                        this.consultantCentruFiltrat = this.filterConsultantsByServicii(this.consultantCentruFiltrat,filters.servicii);
                        this.consultantIndependentFiltrat = this.filterConsultantsByServicii(this.consultantIndependentFiltrat,filters.servicii);
                        urlQuery.serviciu = filters.servicii.replace(/\s+/g, '-');
                    }
                    this.$router.push({  query: {
                        ...urlQuery
                    } }).catch(() => {});
                }

            },
            filterConsultantsByCompany(consultants){
                return  _.filter(consultants, consultant => consultant.is_company);
            },
            filterConsultantsByVerified(consultants){
                return  _.filter(consultants, consultant => consultant.is_verified);
            },
            filterConsultantsByJudet(consultants,judet){
                return  _.filter(consultants, consultant => consultant.counties.includes(judet));
            },
            filterConsultantsByServicii(consultants,serviciu){
                return  _.filter(consultants, consultant => consultant.services.includes(serviciu));
            },
            noFilters(){
                this.filtrari = false;
            }
        }
    }
</script>

<style scoped>
.box-padding {
    padding: 20px 20px 0 20px;
    margin-bottom: 130px;
}

.list-companii {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-template-rows: auto;
    grid-gap: 20px;
    margin: 20px 0 0 0;
}

.list-companii p {
    font-size: 12px;
    color: #919394;
}

@media only screen and (max-width: 900px) {
    .list-companii {
        grid-template-columns: auto;
    }

    .box-padding {
        padding: 20px 20px 0px 20px;
    }
}
</style>