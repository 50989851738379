<template>
    <div>
        <div class="box">
            <div>
                <p class="first-text">
                    Bine ai venit pe <span style="font-weight: 900"> Smis</span>®
                </p>
                <p class="second-text">Conectează-te la contul tău.</p>
            </div>
            <div>
                <p v-if="$route.name ==='EmailVerify'">Contul tau a fost verificat</p>
                <validation-observer #default="{ invalid }">
                    <b-form @submit.prevent="login">

                        <b-form-group
                            id="email-group"
                            label="Email"
                            label-for="email"
                            class="w-100"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="email"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="email"
                                    type="email"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="andrei@domeniu.ro"
                                    class="w-100"
                                ></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <span
                                v-if="errors.has('email')"
                                class="error-message"
                                v-text="errors.get('email')"
                            ></span>
                        </b-form-group>
                        <b-form-group
                            id="password-group"
                            label="Parolă"
                            label-for="password"
                            class="w-100 position-relative"
                        >
                            <div class="pass-box">
                                <router-link class="text-blue" to="/forgot-password"><small>Ai uitat parola?</small></router-link>
                            </div>
                            <b-form-group>
                                <b-form-input
                                    id="password"
                                    v-model="password"
                                    :type="passwordFieldType"
                                    placeholder="*******"
                                    class="w-100 "
                                ></b-form-input>
                                <span
                                    v-if="errors.has('password')"
                                    class="error-message"
                                    v-text="errors.get('password')"
                                ></span>
                            </b-form-group>
                            <span
                                toggle="#password-field"
                                class="fa fa-fw fa-eye field-icon toggle-password"
                                @click="switchVisibility"
                            ></span>
                        </b-form-group>
                        <div class="checkbox-field">
                            <input
                                id="checkbox"
                                class="checkbox"
                                type="checkbox"
                                style="margin-right: 5px"
                            >
                            <label for="checkbox">Tine-mă minte</label>
                        </div>
                        <div>
                            <b-button
                                type="submit"
                                :disabled="invalid"
                                variant="primary"
                                block
                            >Logare</b-button>
                        </div>
                    </b-form>
                </validation-observer>

                <div class="logare">
                    <p class="logare">
                        Nu ai cont?
                        <router-link class="text-blue" to="/register"> Crează cont </router-link>
                    </p>
                </div>
            </div>
        </div>
        <div class="logo-mic">
            <img alt="LogoSmisMicLogin" src="@/assets/images/LogoSmisMicLogin.png" >
        </div>
    </div>
</template>

<script>
    import {BForm,BFormInput,BButton,BFormGroup} from 'bootstrap-vue'
    import api from '@/helpers/api'
    import { startSession } from '@/helpers/auth'
    import axios from '@/helpers/axios'
    import Errors from '@/helpers/Errors';
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    require('@/helpers/validations/validations')

    export default {
        components:{
            BForm,
            BFormInput,
            BButton,
            BFormGroup,
            ValidationProvider,
            ValidationObserver
        },
        props: {},
        data() {
            return {
                email: "",
                userEmail: '',
                status: '',
                password: "",
                passwordFieldType: "password",
                errors: new Errors(),
            };
        },
        created() {
            console.log('param', this.$route.params.id);
        },
        methods: {
            switchVisibility() {
                this.passwordFieldType =
                    this.passwordFieldType === "password" ? "text" : "password";
            },
            login() {
                startSession().then(() => {
                    axios
                        .post(api.API_ENDPOINTS.login, {
                            email: this.email,
                            password: this.password,
                            remember: this.status,
                        })
                        .then(response => {
                            // logIn()
                            this.$notify(response.data.message);
                            console.log('data message', response.data.message);
                            this.$store.dispatch('auth/getUserData').then(() => {
                                this.$router
                                    .replace(
                                        this.$store.state.auth.user.member
                                            ? api.HOME_ROUTE
                                            : api.MY_ACCOUNT_ROUTE
                                    )
                                    .then(() => {

                                    })
                            })
                        })
                        .catch(xhr => {
                            if (xhr.response.status === 422) {
                                this.errors.record(xhr.response.data.errors);
                            }
                            this.$notify(xhr.response.data.message);
                        })
                })
                
            },
        },
    };
</script>

<style scoped>
	.box {
		display: flex;
		flex-direction: column;
		padding: 0 40px;
	}
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	label {
		margin-bottom: 2px;
		font-size: 14px;
		color: #818181;
	}
	input:not([type="checkbox"]) {
		margin-bottom: 10px;
		width: initial;
		line-height: 30px;
		border-radius: 5px;
		border: 1px solid #e7e1e7;
		padding-left: 10px;
		color: #818181;
	}
	input:focus {
		outline: none !important;
		border: 1px solid #6793ce;
	}
	input::placeholder {
		color: #cac5d4;
	}
	.first-text {
		font-size: 22px;
		font-weight: 600;
		background: -webkit-linear-gradient(#262728, #4e5359);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		text-align: left;
	}
	.second-text {
		font-size: 18px;
		margin: 30px 0 20px 0;
		color: #818181;
		text-align: left;
	}
	.form-field {
		display: flex;
		position: relative;
		flex-direction: column;
		width: 100%;
	}
	.checkbox-field {
		display: flex;
		flex-direction: row;
		margin-right: auto;
		align-items: center;
	}
	.field-icon {
		position: absolute;
		z-index: 2;
		cursor: pointer;
		right: 15px;
		bottom: 18px;
	}
	.buton-register {
		margin-top: 35px;
	}
	.logo-mic {
		position: absolute;
		bottom: 40px;
		right: 50px;
	}
	.logare {
		text-align: center;
		margin-top: 20px;
	}
	.pass-box {
		display: flex;
		justify-content: space-between;
	}
	.text-blue {
		color: #4d87f1;
	}
    .form-group{
        margin-bottom: 0;
    }
    @media (max-width:800px){
        .second-text, form, .logare{
            color: #fff;
        }
        .first-text{
            background: -webkit-linear-gradient(#ffffff, #efefef);
            -webkit-background-clip: text;
        }
        .second-text{
            margin: 0;
            margin-bottom: 10px;
        }
    }
    @media (max-width:350px){
        .first-text{
            font-size: 17px;
        }
        .second-text{
            font-size: 15px;
        }
        .form-group{
            font-size: 15px;
            margin: 0;
        }
    }

</style>
