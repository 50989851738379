<template>
    <div>
        <p>Nu exista companii disponibile pentru aceasta categorie</p>
    </div>
</template>
<script>
    export default {
    
    }
</script>
<style scoped>

</style>