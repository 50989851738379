<template>
    <div class="contul-meu-background">
        <div class="formular-contul-meu">
            <validation-observer ref="accountForm" #default="{ invalid }">
                <b-form @submit.prevent="changeAccountDetails()">
                    <b-img
                        v-if="imageData.length > 0"
                        v-show="imageData"
                        ref="previewEl"
                        class="preview"
                        :src="imageData"
                    ></b-img>
                    <b-img
                        v-else
                        :src="require('@/assets/images/' + imagineCurenta + '.png')"
                        class="curent-pic"
                    ></b-img>
                    <b-form-group id="input-group-1" label="Imagine de profil" class="bold-label">
                        <b-card-text class="text-tip-imagine">Acceptă JPG, GIF sau PNG. Maximum 800kB</b-card-text>
                        <b-form-file
                            name="image"
                            accept=".jpg, .png, .gif"
                            @change="previewImage"
                        ></b-form-file>
                    </b-form-group>
                    <b-form-group id="input-group-2" label="Nume complet" class="bold-label">
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="mesaj"
                            min="50"
                        >
                            <b-form-input
                                id="name"
                                v-model="form.name"
                                type="text"
                                name="message"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Nume complet"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group id="input-group-3" label="E-mail login" class="bold-label">
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="mesaj"
                            min="50"
                        >
                            <b-form-input
                                id="email"
                                v-model="form.email"
                                type="email"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Email"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group id="input-group-4" label="Telefon" class="bold-label">
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="mesaj"
                            min="50"
                        >
                            <b-form-input
                                id="phone"
                                v-model="form.phone"
                                type="text"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Telefon"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-button
                        type="submit"
                        class="buton-submit-setari-cont"
                        variant="primary"
                        :disabled="invalid"
                    >Salvează modificările</b-button>
                </b-form>
            </validation-observer>
        </div>
        <div class="formular-contul-meu">
            <div class="top-securitate-cont">
                <div>
                    <p class="text-gradient">Securitatea contului</p>
                    <p class="text-mic">schimbă parola</p>
                </div>
                <img src="@/assets/images/ContulMeuShield.png" alt="" >
            </div>
            <validation-observer ref="passwordForm" #default="{ invalid }">
                <b-form @submit.prevent="changePassword()">
                    <b-form-group id="input-group-ps1" label="Vechea parolă" class="bold-label">
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="mesaj"
                            min="50"
                        >
                            <b-form-input
                                id="currentPassword"
                                v-model="passwordForm.currentPassword"
                                :type="passwordFieldTypeOld"
                                :state="errors.length > 0 ? false : null"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span
                            toggle="#password-field"
                            class="fa fa-fw fa-eye field-icon toggle-password"
                            @click="togglePasswordOld"
                        ></span>
                    </b-form-group>
                    <b-form-group id="input-group-ps2" label="Noua parolă" class="bold-label">
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="mesaj"
                            min="50"
                        >
                            <b-form-input
                                id="newPassword"
                                v-model="passwordForm.newPassword"
                                :type="passwordFieldTypeNew"
                                :state="errors.length > 0 ? false : null"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span
                            toggle="#password-field"
                            class="fa fa-fw fa-eye field-icon toggle-password"
                            @click="togglePasswordNew"
                        ></span>
                    </b-form-group>
                    <b-form-group
                        id="input-group-ps3"
                        label="Reintroduceți noua parolă"
                        class="bold-label"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="mesaj"
                            min="50"
                        >
                            <b-form-input
                                id="confirmPassword"
                                v-model="passwordForm.confirmPassword"
                                :type="passwordFieldTypeRetype"
                                :state="errors.length > 0 ? false : null"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span
                            toggle="#password-field"
                            class="fa fa-fw fa-eye field-icon toggle-password"
                            @click="togglePasswordRetype"
                        ></span>
                    </b-form-group>
                    <div class="butoane-securitatea-contului">
                        <b-button type="submit" :disabled="invalid" class="buton-submit-setari-cont" variant="primary">Salvează modificările</b-button>
                        <b-button class="buton-submit-sterge-cont" variant="danger" @click="modalShow = !modalShow">Șterge definitiv contul</b-button>
                    </div>
                </b-form>
            </validation-observer>
        </div>
        <b-modal
            id="blackModal"
            v-model="modalShow"
            button-size="sm"
            ok-variant="success"
            ok-title="Confirmă"
            cancel-variant="danger"
            cancel-title="Anulează"
            header-bg-variant="dark"
            body-bg-variant="dark"
            hide-header-close
            @ok="deleteAccount"
        >
            <StergeUtilizator></StergeUtilizator>
        </b-modal>
    </div>
</template>
<script>
    import {BImg,BButton,BForm,BFormGroup,BFormInput,BFormFile,BCardText,BModal} from 'bootstrap-vue'
    import api from '@/helpers/api'
    import axios from '@/helpers/axios'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import StergeUtilizator from './StergeUtiliztor.vue'
    require('@/helpers/validations/validations')

    export default {
        components:{
            BImg,
            BButton,
            BForm,
            BFormGroup,
            BFormInput,
            BFormFile,
            BCardText,
            BModal,
            StergeUtilizator,
            ValidationProvider,
            ValidationObserver,
        },
        props: {
            imagineCurenta: {
                type: String,
                default: "userimage",
            },
            data: null,
        },
        data() {
            return {
                name: '',
                rol: '',
                email: '',
                phone: '',
                avatar: '',
                account_type_name: '',
                account_type_logo: '',
                company: '',
                modalShow:false,
                form: {
                    file: null,
                    name: null,
                    email: null,
                    phone: null,
                },
                passwordForm:{
                    currentPassword:'',
                    newPassword:'',
                    confirmPassword:''
                },
                password: "",
                passwordFieldTypeOld: 'password',
                passwordFieldTypeNew: 'password',
                passwordFieldTypeRetype: 'password',
                imageData: "",
            };
        },
        mounted() {
            this.getInfoCont()
        },
        update () {
            this.$mount();
        },
        methods: {
            getInfoCont() {
                this.form.name = this.$store.state.auth.user.name
                this.rol = this.$store.state.auth.user.role
                this.form.email = this.$store.state.auth.user.email
                this.form.phone = this.$store.state.auth.user.phone
                this.avatar = this.$store.state.auth.user.logo
                if(this.$store.state.auth.user.account_type){
                    this.account_type_name = this.$store.state.auth.user.account_type.name
                    this.account_type_logo = this.$store.state.auth.user.account_type.logo
                }
                this.company = this.$store.state.auth.user.company
            },
            changeAccountDetails() {
                let formData =  new FormData();
                formData.append('name',this.form.name)
                formData.append('email',this.form.email)
                formData.append('phone',this.form.phone)
                if(this.form.file){
                    formData.append('image',this.form.file,this.form.file.name)
                }
                
                axios.post(api.API_ENDPOINTS.updateUserInfo,formData)
                    .then(response=>{ 
                        this.$store.dispatch('auth/getUserData').then(()=>{
                            this.$root.$emit('userDataChanged');
                            this.$notify(response.data.message);       
                            if(response.data.emailChanged)        {
                                this.$notify('Adresa de email a fost schimbata,un email de confirmare a fost trimis catre noua adresa')
                            }        
                        })
                    }).catch((err) => {
                        console.log(err);
                    })
            },
            changePassword(){
                if(this.passwordForm.newPassword === this.passwordForm.confirmPassword){

                    axios.post(api.API_ENDPOINTS.updatePassword,this.passwordForm)
                        .then(response=>{
                            this.$notify(response.data.message);
                            this.passwordForm.currentPassword = '';
                            this.passwordForm.newPassword = '';
                            this.passwordForm.confirmPassword = '';
                        }).catch(err=>{
                            if(err.response.status===422){
                                this.$notify(err.response.data.message)
                            }
                        })
                }else{
                    this.$notify('Noua parolă nu corespunde cu Reintroduceți noua parolă')
                }
               
            },
            togglePasswordOld() {
                this.passwordFieldTypeOld =
                    this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
            },
            togglePasswordNew() {
                this.passwordFieldTypeNew =
                    this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
            },
            togglePasswordRetype() {
                this.passwordFieldTypeRetype =
                    this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
            },
            previewImage: function (event) {
                var input = event.target;
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.imageData = e.target.result;
                    };
                    reader.readAsDataURL(input.files[0]);
                    this.form.file=input.files[0];

                }
            },
            deleteAccount(){
                axios.delete(api.API_ENDPOINTS.deleteUser + "/" + this.$store.state.auth.user.id)
                    .then(response =>{
                        this.$store
                            .dispatch('auth/logout')
                            .then(() => this.$router.push({ path: api.LOGIN_ROUTE }))
                        this.notify(response.data.message);     
                    })
              
            
            }
        },
    };
</script>

<style scoped>
	.contul-meu-background {
		background-color: #f0f0f0;
		width: 100%;
		padding: 20px;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
		gap: 10px 20px;
	}
	.formular-contul-meu {
		background-color: #f8f8f8;
		box-sizing: border-box;
		padding: 20px;
		border: 1px solid #e7e7e7;
		height: fit-content;
	}
	.butoane-securitatea-contului {
		display: flex;
		justify-content: space-between;
	}
	.buton-submit-setari-cont {
		color: #fff;
		background-color: #00dfc2;
		border-color: #00dfc2;
        margin-top: 10px;
        font-size: var(--paragraphSize);
        font-weight: var(--bold);

	}
    .buton-submit-sterge-cont {
		color: #fff;
		background-color: #ff4747;
		border-color: #ff4747;
        margin-top: 10px;
      font-size: var(--paragraphSize);
      font-weight: var(--bold);
	}
	.btn-primary:not(:disabled):not(.disabled):active {
		color: #fff;
		background-color: #00dfc2;
		border-color: #00dfc2;
	}
	.bold-label {
		font-weight: 500;
		color: #575757;
		position: relative;
    font-size: var(--moduleTitleSize);
	}
	.field-icon {
		position: absolute;
		z-index: 2;
		cursor: pointer;
		right: 15px;
		bottom: 10px;
	}
	.text-gradient {
		font-size: 16px;
		font-weight: bold;
		background: -webkit-linear-gradient(#5847d2, #3cbdd4);
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.text-mic {
		font-size: 12px;
		margin-top: -10px;
	}
	.top-securitate-cont {
		display: flex;
		justify-content: space-between;
		margin: 31px 0 90px 0;
	}
	img.preview, img.curent-pic {
		padding: 5px;
		border-radius: 50%;
		width: 60px;
		height: 60px;
	}
	img.curent-pic {
		opacity: 0.3;
	}
	.text-tip-imagine {
		font-weight: initial;
		font-size: 12px;
		color: #a9a9a9;
	}
    @media (max-width:450px) {
        .contul-meu-background{
            grid-template-columns: repeat(auto-fit, minmax(56%, 1fr));
        }
    }
</style>
