<template>
    <div class="top-bar">
        <ul>
          <li class="text-primary-hover active"><router-link to="/toate-finantarile">{{data}}</router-link></li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            data:{
              type: String,
              default: null,
            },
        },
        data() {
            return {

            }
        },
    }
</script>

<style scoped>
	.top-bar {
		display: flex;
		align-items: center;
		min-height: 50px;
		box-sizing: border-box;
		padding: 0px 20px;
		background: linear-gradient(to bottom, #6786aa, #7da8ac);
		color: #fff;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}
  .active{
    font-weight: 700;
  }
    ul{
        display: flex;
        margin: 0;
        flex-wrap: wrap;
        justify-content: center;
        
    }
    ul > li:not(:last-child):after{
        content: "|";
        padding-left: 20px;
        margin-right: 20px;
    }
    .text-primary-hover{
      font-size: 15px;
    }
    a{
      display: inherit;
    }
    a:not(:last-child):after{
        content: "|";
        padding-left: 20px;
        margin-right: 20px;
    }
	.text {
		font-weight: bold;
	}
    a{
        color:#fff;
        display: initial;
    }
@media only screen and (max-width: 900px) {
    .top-bar>div{
        margin: 5px auto;
    }  
    .top-bar {
        text-align: center;
    } 
}
</style>
