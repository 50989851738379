<template>
    <div class="modul-finantare-title">
        <span class="bold-text gradient-title">Feedback utilizatori</span>
        <div class="review">
            <b-carousel
                class="carousel-center"
                id="feedbackSlider"
                :interval="4000"
                indicators
                v-if="data"
            >
                <b-carousel-slide v-for="(recenzie, key) in data" :key="key">
                    <div class="slide">
                        <div>
                            <div class="top-slide">
                                <div class="left">
                                    <p><strong>{{ recenzie.name }}</strong> #63B2A</p>
                                </div>
                                <div class="right">
                                    <b-form-rating id="rating-lg-no-border " :value="recenzie.rating" color="#ffbe00"
                                                   no-border size="sm" readonly></b-form-rating>
                                </div>
                            </div>
                            <p>{{ recenzie.comment }}</p>
                        </div>
                    </div>
                </b-carousel-slide>

            </b-carousel>
        </div>
    </div>
</template>

<script>
import {BCarouselSlide, BCarousel, BFormRating} from 'bootstrap-vue'

export default {
    name: "FeedbackCompanie",
    components: {
        BCarousel,
        BCarouselSlide,
        BFormRating
    },
    props: {
        data: [],
    },
    data() {
        return {
            review: {
                stars: 4.5,
            },
        };
    },
}
</script>

<style scoped>
.bold-text {
    font-weight: bold;
}

.carousel-item {
    height: 160px;
    background: transparent;
}

#feedbackSlider >>> li {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: 1px solid #666666;
}

#feedbackSlider >>> .carousel-indicators .active {
    background: #666666;
}

#feedbackSlider >>> .carousel-caption {
    position: relative;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

.slide .form-control {
    background-color: transparent !important;
    max-width: 115px;
}

.slide p {
    font-style: italic;
    font-size: 12px;
}

.top-slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gradient-title {
    font-size: 14px;
    color: #1c1c1c;
}

@media only screen and (max-width: 900px) {
    .carousel-item {
        height: 100%;
    }
}
</style>