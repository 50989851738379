<template>
    <div>
        <div v-if="data.companies && data.companies.length > 0" class="flexItems">
            <div v-for="(value, key) in data.companies" :key="key">
                <Companie :nume="value.name" :rules="value.rules" :eligibility="value.eligibility" image="LogoFormare"></Companie>
            </div>
        </div>
        <p class="text-center padding-bottom" v-else>Nicio companie eligibila pentru aceasta finantare.</p>
    </div>
</template>

<script>
    import Companie from './Companie'
    export default {
        props: {
          data: null,
        },
        components: { Companie },
        data() {
            return {

            };
        },
        created() {},
        methods: {},
    };


</script>

<style scoped>
    .padding-bottom{
        padding-bottom: 50px;
    }
	.flexItems {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
		grid-template-rows: auto;
		grid-gap: 20px;
		padding: 10px;
        margin: 0 0 50px 30px ;
	}
    @media only screen and (max-width: 710px) {
        .flexItems {
            margin: 0;
            padding: 10px;
            display: block;
        }
    }

</style>