<template>
    <div class="app-sidebar">
        <ul>
            <li v-for="app in apps" :key="app.id" class="position-relative">
                <img :src="app.logo" :class="{'app-item': true, 'selected': app.id == selected}" @click="changePlan(app.id)" :alt="app.name">
                <img src="@/assets/images/checkMark.png" class="check-badge">
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    props: ['apps', 'selected'],

    methods: {
        changePlan(id) {
            this.$emit('planChanged', id);
        }
    },
}

</script>

<style scoped>
.app-sidebar{
    background: #fff;
    padding: 20px;
}
.app-item{
    width: 100%;
    margin-bottom: 30px;
    filter: grayscale(100%);
    transition: 0.1s;
    object-fit: cover;
}
.app-item:hover{
    filter: inherit;
}
.selected{
    filter: inherit;
}
.check-badge{
    width: 21px;
    height: 15px;
    position: absolute;
    right: -5%;
    top: -10%;
}
li{
    cursor: pointer;
}
</style>