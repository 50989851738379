import store from '@/helpers/store'

export default (to, from, next) => {
  if (
    store.getters['auth/authenticated'] &&
    store.getters['auth/user'].plans.some(
      plan => plan.id === 8
    )
  ) {
    next()
  } else {
    next({ name: 'pages-account-setting' })
  }
}
