<template>
    <div>
        <div class="background-container justify-content-between align-items-center">
            <div class="user-info">
                <div class="uProf" v-if="userImage">
                    <b-img
                        class="image"
                        :src="userImage"
                    ></b-img>
                    <!--<span class="dot"></span>-->
                </div>
                <div class="text-user">
                    <p class="nume">
                        {{ userName }}
                    </p>
                    <p class="rol" v-if="usepackage && role">
                        <span class="pachet">{{ usepackage }}</span> | {{ role }}
                    </p>
                </div>
            </div>
            <div class="top-bar-right-side mr-4">
                <img src="@/assets/images/personalSmis.png">
            </div>
        </div>
    </div>
</template>

<script>
    import {BImg} from 'bootstrap-vue'
    export default {
        components:{
            BImg
        },
        props: {
            userName: {
                type: String,
                default: "Nume",
            },
            userImage: {
                type: String,
                default: '',
            },
            role: {
                type: String,
                default: "Rol",
            },
            usepackage: {
                type: String,
                default: "Basic",
            },
        },
        data() {
            return {};
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
.background-container {
  display: flex;
  background-image: linear-gradient(to right, #5f58a8, #5eb9b2);
  width: 100%;
  padding: 40px 30px;
}
.user-info {
  display: flex;
  align-items: center;
}
.image {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: relative;
  margin-right: 17px;
  background-color: #5f58a8;
}
.dot {
  position: absolute;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  background-color: #50e7f3;
  border: 2px solid white;
  top: 5px;
  left: 20px;
}
.text-user {
  line-height: 1;
}
.nume {
  color: #fff;
  font-size: 22px;
}
.rol {
  color: #58ceb9;
  font-size: 15px;
}
.pachet {
  font-weight: bold;
}

@media only screen and (max-width: 1550px) {
  .top-bar-right-side {
    margin-left: 10px;
  }
  @media (max-width:500px){
    .background-container{
      flex-direction: column;
    }
  }
}
</style>