<template>
    <div class="Proiecte-finantate">
        <div class="Proiecte">
            <div class="modul-finantare-title flexed">
                <span class="bold-text gradient-title">Proiecte Finanțate</span>
                <span class="gradient-text">{{ data.projects }}</span>
            </div>
        </div>
        <div class="Experienta">
            <div class="modul-finantare-title flexed">
                <span class="bold-text gradient-title">Experiență</span>
                <div><span class="gradient-text">{{ data.experience }}</span><span class="gradient-info ml-2">ani</span>
                </div>
            </div>
        </div>
        <div class="Proiecte-implementate">
            <div class="modul-finantare-title flexed">
                <span class="bold-text gradient-title">Proiecte implementate</span>
                <span class="gradient-text">{{ data.implemented }}</span>
            </div>
        </div>
        <div class="Rata-de-sucecs">
            <div class="modul-finantare-title flexed">
                <span class="bold-text gradient-title">Rată de succes</span>
                <span class="gradient-text">99%</span>
            </div>
            <p class="info-text">*informații furnizate de către companie</p>
        </div>

        <div class="Contact">
            <div class="modul-finantare-title flexed">
                <span class="bold-text gradient-title">Contact rapid</span>
                <div><a :href="'https://wa.me/' + contact" target="_blank" class="flexed"><span
                    class="whatsapp-text">{{ contact }}</span><img src="@/assets/images/logo-wapp.png" class="ml-2"></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "StatisticiCompanie",
    props: {
        data: [],
        contact: [],
    },
    data() {
        return {
            review: {
                stars: 4.5,
            },
        };
    },
}
</script>

<style scoped>
.bold-text {
    font-weight: bold;
}

.Proiecte-finantate {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px 20px;
    grid-auto-flow: row;
    grid-template-areas:
    "Proiecte Proiecte Proiecte"
    "Experienta Experienta Experienta"
    "Proiecte-implementate Proiecte-implementate Proiecte-implementate"
    "Rata-de-sucecs Rata-de-sucecs Rata-de-sucecs"
    "Contact Contact Contact";
    grid-area: Proiecte-finantate;
    margin-bottom: 0 !important;
}

.info-text{
    color: #868686;
    font-size: 11px;
    font-style: italic;
}
.modul-finantare-title {
    background-color: #fff;
    color: #434343;
    box-sizing: border-box;
    padding: 4px 10px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 2px 6px 1px #dddddd;
    height: 100%;
}

.flexed {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gradient-text {
    font-size: 47px;
    font-weight: 700;
    background: -webkit-linear-gradient(#6211cd, #52a1d4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 47px;
}

.gradient-info {
    font-size: 36px;
    font-weight: 900;
    background: -webkit-linear-gradient(#6211cd, #52a1d4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.whatsapp-text {
    font-size: 21px;
    font-weight: 600;
    color: #40c351;
}

.Proiecte {
    grid-area: Proiecte;
}

.Experienta {
    grid-area: Experienta;
}

.Proiecte-implementate {
    grid-area: Proiecte-implementate;
}

.Rata-de-sucecs {
    grid-area: Rata-de-sucecs;
}

.Contact {
    grid-area: Contact;
}

.gradient-title {
    font-size: 14px;
    color: #1c1c1c;
}
</style>