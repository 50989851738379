<template>
    <div v-if="load" id="expert" >              
        <div class="mainMenu flexClass modExpert">
            <div v-for="(item, ind) in data"
                 :key="ind"
                 class="modExpertItem"
            >
                <h2>{{ item.name }}</h2>
                <ul>
                    <li v-for="(tab, index) in item.items"
                        :key="index"
                        @click.prevent="getFirstChild(tab);getProgram(tab.acronym)"
                    ><a href="#" :class="getActive(tab.id,currentProgram)">
                        {{ tab.name.length > 50 ? tab.name.substring(0,50)+'...' : tab.name }}
                    </a> 
                                      
                    </li> 
                </ul>
            </div>            
        </div>
        <div v-if="childFirstMenu.length>0" class="secondMenu">
            <ul class="flexClass alignLeft">
                <li v-for="(tab, index) in childFirstMenu"
                    :key="index"
                ><a
                    href="#"
                    :class="getActive(tab.id,currentMasura)"
                    @click.prevent="getSecondChild(tab.items,tab.id);getMasura(tab)"  
                >
                    {{ tab.acronym ? tab.acronym : tab.name }}
                </a>
                </li>
            </ul>
        </div>
        <div v-if="childSecondMenu.length>0" class="thirdMenu">
            <ul class="flexClass alignLeft">
                <li v-for="(tab, index) in childSecondMenu"
                    :key="index"
                ><a href="#"
                    :class="getActive(tab.id,currentSubMasura)"
                    @click.prevent="getThirdChild(tab.items,tab.id);getMasura(tab)"  
                >
                    {{ tab.acronym }}
                </a>
                </li>
            </ul>
        </div>
        <div v-if="childThirdMenu.length>0" class="thirdMenu">
            <ul class="flexClass alignLeft">
                <li v-for="(tab, index) in childThirdMenu"
                    :key="index"
                ><a href="#"
                    :class="getActive(tab.id,currentSubMasuraChild)"  
                    @click.prevent="getLastChild(tab.items,tab.id);getMasura(tab)"
                >
                    {{ tab.acronym }}
                </a>
                </li>
            </ul>
        </div>
        <div v-if="childLastMenu.length>0" class="thirdMenu">
            <ul class="flexClass alignLeft">
                <li v-for="(tab, index) in childLastMenu"
                    :key="index"
                ><a href="#">
                    {{ tab.name }}
                </a>
                </li>
            </ul>
        </div>
    </div>
    
</template>

<script>
    import LoadingSpinner from '@/common/components/LoadingSpinner'

    export default {          
        components: {
            LoadingSpinner
        },
        props: {
            data: null,
            load:{
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                childFirstMenu: [],
                childSecondMenu: [],
                childThirdMenu: [],
                childLastMenu: [],
                currentProgram:-1,
                currentMasura:null,
                currentSubMasura:null,
                currentSubMasuraChild:null,
            }
        },
        computed:{},
        mounted() {
        },
        methods: {
            getFirstChild(child) {
                this.currentProgram = child.id;
                this.currentMasura = null;
                this.childFirstMenu = [];                
                this.childFirstMenu = child.items;
                this.childSecondMenu = [];
                this.childThirdMenu = [];
                this.childLastMenu = [];
            },
            getSecondChild(child,id) {

                this.currentMasura = id;
                this.currentSubMasura = null;
                this.childSecondMenu = [];
                this.childThirdMenu = [];
                this.childLastMenu = [];
                this.childSecondMenu = child;
            },
            getThirdChild(child,id) {

                this.currentSubMasura = id;
                this.currentSubMasuraChild = null;
                this.childThirdMenu = [];
                this.childLastMenu = [];
                this.childThirdMenu = child;
            },
            getLastChild(child,id) {

                this.currentSubMasuraChild = id;
                this.childLastMenu = [];
                this.childLastMenu = child;
            },
            getProgram(programAcronym){
                
                this.$emit('filterByProgram', programAcronym)
            },
            getMasura(masura){
                
                this.$emit('filterByMasura', masura)
            },
            getActive(id,currentProgram){
                return{
                    'active': currentProgram === id
                }
            }
     
            
        }
    };
</script>

<style scoped>
.heigth150px{
    height: 150px;
}
#expert{
    border: 1px solid #e2e2e2
}
#expert a{
    color:#555555;
    text-decoration: none;
    padding: 5px;
    border-radius: 5px;
}
#expert .mainMenu a:hover, 
#expert .secondMenu a:hover
{
    background: #02c8a1;
    color: #fff;
}

.active{
    background: #02c8a1!important;
    color: #fff!important;
    border:1px solid #02c8a1 !important;

}
#expert .mainMenu, 
#expert .secondMenu{
    padding: 20px;
}
#expert .thirdMenu{
    padding: 10px 20px;
}
#expert .secondMenu a,
#expert .thirdMenu a{
    border:1px solid #737373;
    margin-right: 15px;
}
#expert .secondMenu a:hover{
    border:1px solid #d9e8e5;
}
#expert .thirdMenu a:hover{
    border:1px solid #7fdec3;
    background: #02c8a1;
    color:#fff;
}
#expert ul{
    margin-bottom: 0;
}
#expert .flexClass{
	display: flex;
}
#expert .flexClass div{
	flex-direction: row;
}
#expert .spaceBetween{
	justify-content: space-between;
}
#expert .alignLeft{
    justify-content: flex-start;
}
.mainMenu{
    background: #f5f5f5;
}
.secondMenu{
    background: #d9e8e5;
}
.thirdMenu{
    background: #b2bcbe;
}

#expert h2{
    font-weight: bold;
    color: #18274b;
    margin-left: 5px;
}

#expert h2,
#expert a{
    font-size: 12px;
}
.modExpertItem{
    width: 25%;
}
@media (max-width:768px){
    .modExpert{
        display: block !important;
    }
}
</style>
