<template>
    <div>
        <BlueTitleBar title="COMPANIA MEA" subtitle="Termeni și condiții"></BlueTitleBar>       
        <ContentBox></ContentBox>
    </div>
</template>
<script>
    import ContentBox from '../Aplicatii/CompaniaMea/components/ContentBox'
    import BlueTitleBar from '@/common/components/BlueTitleBar'
    export default {
        components: {
            ContentBox,            
            BlueTitleBar,
        }
    }
</script>
<style scoped>
    
</style>
