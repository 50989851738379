var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"padding-box"},[_c('div',{staticClass:"card-contact"},[_c('span',{staticClass:"title"},[_vm._v("Această secțiune vă permite să ne trimiteți mesaje. Vă vom răspunde în cel mai scurt timp.")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"row pt-4",on:{"submit":_vm.submit}},[_c('div',{staticClass:"col-md-4"},[_c('b-form-group',{attrs:{"label":"Nume și prenume","label-for":"fullName"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fullName","state":errors.length > 0 ? false : null,"name":"fullName","type":"text","placeholder":"Nume și prenume"},model:{value:(_vm.form.fullName),callback:function ($$v) {_vm.$set(_vm.form, "fullName", $$v)},expression:"form.fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"email","state":errors.length > 0 ? false : null,"type":"email","placeholder":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('b-form-group',{attrs:{"label":"Telefon","label-for":"phone"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","type":"tel","name":"phone","state":errors.length > 0 ? false : null,"placeholder":"Telefon"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Mesaj","label-for":"message"}},[_c('validation-provider',{attrs:{"rules":"required|min:50","name":"mesaj","min":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"message","type":"text","name":"message","state":errors.length > 0 ? false : null,"placeholder":"Mesaj","rows":"5"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('b-button',{staticClass:"background",attrs:{"type":"submit","url-buton":"dadsdsss","text":"Trimite mesajul","classname":"background","disabled":invalid}},[_vm._v("Trimite")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }