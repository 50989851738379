<template>
    <div class="box-padding">
        <div class="Sediu-central">
            <SediuCentral class="mb-4" :data="sediu"></SediuCentral>
        </div>
        <div class="Companie-verificata-de-smis">
            <CompanieVerificata class="mb-4"></CompanieVerificata>
        </div>
        <div class="Despre-noi">
            <DespreNoi class="mb-4" :data="about"></DespreNoi>
        </div>
        <div>
            <StatisticiCompanie class="mb-4" :data="statistici" :contact="contactRapid"></StatisticiCompanie>
        </div>
        <div class="Feedback" v-if="recenzii.length > 0">
            <FeedbackCompanie class="mb-4" :data="recenzii"></FeedbackCompanie>
        </div>
        <div class="BUSINESS-CONSULTING">
            <InformatiiCompanie class="mb-4" :data="description"></InformatiiCompanie>
        </div>
        <div class="Oferta-speciala">
            <OfertaSpeciala class="mb-4" :data="offer" :logo="logo"></OfertaSpeciala>
        </div>
        <div class="Contact-rapid">
            <ContactRapidCompanie class="mb-4"></ContactRapidCompanie>
        </div>
        <div class="Echipa">
            <EchipaCompanie class="mb-4" :data="team"></EchipaCompanie>
        </div>
        <div class="Utile">
            <UtileCompanie class="mb-4"></UtileCompanie>
        </div>
    </div>
</template>

<script>
import SediuCentral from '../components/SediuCentral';
import StatisticiCompanie from '../components/StatisticiCompanie';
import DespreNoi from '../components/DespreNoi';
import CompanieVerificata from '../components/CompanieVerificata';
import FeedbackCompanie from '../components/FeedbackCompanie';
import InformatiiCompanie from '../components/InformatiiCompanie';
import OfertaSpeciala from '../components/OfertaSpeciala';
import ContactRapidCompanie from '../components/ContactRapidCompanie';
import EchipaCompanie from '../components/EchipaCompanie';
import UtileCompanie from '../components/UtileCompanie';

export default {
    name: "PrezentareCompanie",
    components: {
        SediuCentral,
        StatisticiCompanie,
        DespreNoi,
        CompanieVerificata,
        FeedbackCompanie,
        InformatiiCompanie,
        OfertaSpeciala,
        ContactRapidCompanie,
        EchipaCompanie,
        UtileCompanie,
    },
    props: {
        data: [],
        recenzii: [],
    },
    data() {
        return {
            title: 'Profil companie',
            tipCompanie: 'independent',
            about: {},
            sediu: {},
            statistici: {},
            description: {},
            offer: {},
            team: {},
            contactRapid: {},
        }
    },
    mounted() {
        this.sendChild()
    },
    methods: {
        sendChild() {
            this.about = {
                about: this.data.about,
                services: this.data.services,
            }
            this.sediu = {
                offices: this.data.offices,
                city: this.data.city,
                phone: this.data.phone,
                adress: this.data.adress,
                contactPerson: this.data.contactPerson,
                email: this.data.email,
            }
            this.statistici = {
                projects: this.data.projects,
                implemented: this.data.implemented,
                experience: this.data.experience,
            }
            this.description = {
                description: this.data.description,
                facebook: this.data.facebook,
                instagram: this.data.instagram,
                youtube: this.data.youtube,
                twitter: this.data.twitter,
                linkedin: this.data.linkedin,
            }
            this.offer = this.data.offer
            this.logo = this.data.logo
            this.team = this.data.team
            this.contactRapid = this.data.phone
        },
    },
}
</script>

<style scoped>
.box-padding {
    padding: 20px 20px 70px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px 20px;
    grid-auto-flow: row;
    grid-template-areas:
    "Companie-verificata-de-smis Companie-verificata-de-smis Companie-verificata-de-smis Companie-verificata-de-smis Sediu-central"
    "Despre-noi Despre-noi Despre-noi Proiecte-finantate Sediu-central"
    "Feedback Feedback Feedback Oferta-speciala Echipa"
    "BUSINESS-CONSULTING BUSINESS-CONSULTING BUSINESS-CONSULTING Contact-rapid Utile";
    margin-bottom: 20px;
}

.Sediu-central {
    grid-area: Sediu-central;
}

.Companie-verificata-de-smis {
    grid-area: Companie-verificata-de-smis;
}

.Despre-noi {
    grid-area: Despre-noi;
}

.modul-finantare-title {
    background-color: #fff;
    color: #434343;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 2px 6px 1px #dddddd;
    height: 100%;
}

.Feedback {
    grid-area: Feedback;
}

.BUSINESS-CONSULTING {
    grid-area: BUSINESS-CONSULTING;
}

.Oferta-speciala {
    grid-area: Oferta-speciala;
}

.Contact-rapid {
    grid-area: Contact-rapid;
}

.Echipa {
    grid-area: Echipa;
}

.Utile {
    grid-area: Utile;
}

@media only screen and (max-width: 900px) {
    .box-padding {
        padding: 20px 20px 0px 20px;
        display: block;
    }
}
</style>