<template>
    <div>
        <div class="sidebar-container">
            <p class="btn btn-red" @click="$emit('filter', {'type': 'toate', 'id': null})">Afișează toate știrile</p>
            <div class="label-list">
                <p class="cat-title">Surse finanțare</p>
                <ul class="one-column">
                    <li v-for="(funder, key) in data.funders" :key="key" @click="$emit('filter', {'type': 'funder', 'id': funder.id})"><span v-if="funder" class="label" :style="{ 'background': funder.color }">{{ funder.acronym }}</span></li>
                </ul>
            </div>
            <div class="label-list">
                <p class="cat-title">Programe</p>
                <ul class="two-column">
                    <li v-for="(programs, key) in data.programs" :key="key" @click="$emit('filter', {'type': 'program', 'id': programs.id})"><span class="label" :style="{ 'background': programs.color }">{{ programs.acronym }}</span></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        data: null,
    },
    data() {
        return {
        };
    },
};
</script>

<style scoped>
.btn-red{
    background: linear-gradient(to bottom, #ed6b26, #ef3642);
    color: #fff;
    padding: 3px 15px;
    width: 100%;
    font-size: 13px;
}
.cat-title {
    font-size: 13px;
    color: #807e89;
    font-weight: 600;
}
.label {
    padding: 0px 8px;
    height: fit-content;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    white-space: pre;
}
.label-list:not(:first-child) {
    margin-top: 20px;
}
.one-column {
    column-count: 1;
}
.two-column {
    column-count: 2;
}
ul {
    column-count: 2;
}
</style>