<template>
    <div id="chenarGradient">
        <div class="flexClass spaceBetween bigMargin info-platforma">
            <div>
                <p><strong>Reprezinți o companie de consultanță sau un centru de formare profesională? Ești consultant independent?</strong></p>
                <p>Listează-ți compania în platforma <strong>Smis</strong></p>
            </div>
        </div>
        <div class="flexClass spaceBetween bigMargin poza-finantarii ">
            <div class="flexClass count-finantari">
                <div class="flexClass middleAlign numar-finantari">
                    <div class="flexClass">
                        <p class="bigText">2457</p>
                    </div>
                    <div>
                        <p>utilizatori înscriși în<br> platforma <strong>Smis</strong></p>
                    </div>
                </div>
                <div class="flexClass middleAlign numar-finantari">
                    <div class="flexClass">
                        <p class="bigText">30</p>
                    </div>
                    <div>
                        <p>de zile cont de încercare <br><strong>GRATUIT</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        props: {
            count: {
                type: Number,
                default: 0
            },
        }
    }
</script>

<style scoped>
h1,
p{
  color:#fff;
}
h1,
p{
  margin-bottom: 0;
}
h1{
  font-size: 20px;
  margin-bottom: 0;
}
#chenarGradient{
  width:100%;
  background: linear-gradient(180deg, #3456a7, #2c92c4, #30c7b2);
  padding: 15px 30px;
  box-sizing: border-box;
}
.bigText{
  font-size: 40px;
  font-weight: 600;
  margin-right: 5px;
}
.flexClass{
  display: flex;
}

.flexClass div{
  flex-direction: row;
}
.spaceBetween{
  justify-content: space-between;
}
.textRight{
  text-align: right;
}
.middleAlign{
  align-items: center;
}
.middleAlign p{
  line-height: 13px;
}
.bigMargin{
  margin-bottom: 25px;
}
.count-finantari{
  width: 50%;
  justify-content: space-between;
}
.poza-finantari-container svg {
  font-size: 35px;
  color: #fff;
}
.poza-finantarii img{
  object-fit: contain;
}
@media (max-width:768px){
  .poza-finantarii{
    display: block;

  }
  .count-finantari{
    justify-content: space-evenly;
    width:100% ;
    margin-bottom: 2rem;
  }
}
@media(max-width:500px){
  .poza-finantari-container img {
    display: block;
    width:280px;

  }
  .info-platforma{
    display: block;
  }
  .textRight{
    text-align: start;
  }
  .count-finantari{
    display: block;
  }
  .numar-finantari{
    display: flex;
    margin: 1.5rem 0;
  }

}
</style>