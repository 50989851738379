<template>
    <div>
        <b-carousel
            class="carousel-center"
            id="feedbackSlider"
            :interval="4000"
            indicators
        >
            <b-carousel-slide v-for="(review, index) in reviews" :key="index">
                <div class="slide">
                    <div>
                        <div class="top-slide">
                            <div class="left">
                                <p><strong>{{ review.name }}</strong> #63B2A</p>
                            </div>
                            <div class="right">
                                <b-form-rating id="rating-lg-no-border " :value="review.rating" color="#ffbe00"
                                               no-border size="sm" readonly></b-form-rating>
                            </div>
                        </div>
                        <p>{{ review.comment }}</p>
                    </div>
                </div>
            </b-carousel-slide>
        </b-carousel>
    </div>
</template>

<script>

import {BCarouselSlide, BCarousel, BFormRating} from 'bootstrap-vue'
import axios from '@/helpers/axios';
import api from '@/helpers/api';

export default {
    components: {
        BCarousel,
        BCarouselSlide,
        BFormRating
    },

    props: ['selected'],

    data() {
        return {
            reviews: [],
        };
    },

    methods: {
        fetchReviews() {
            return axios.get(api.API_ENDPOINTS.fetchApps + '/' + this.selected + '/reviews')
                .then((response) => {
                    this.reviews = response.data;
                }).catch((xhr) => {
                    this.$notify(xhr.responseJSON.message);
                });
        }
    },

    watch: {
        'selected': function () {
            this.fetchReviews();
        },
    }
}
</script>

<style scoped>

.carousel-item {
    height: 130px;
    background: transparent;
}

#feedbackSlider >>> li {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: 1px solid #666666;
}

#feedbackSlider >>> .carousel-indicators .active {
    background: #666666;
}

#feedbackSlider >>> .carousel-caption {
    position: relative;
    width: 100%;
    bottom: 0;
    padding-top: 0;
    top: 0;
    left: 0;
    right: 0;
}

.slide .form-control {
    background-color: transparent !important;
    max-width: 115px;
}

.slide p {
    font-style: italic;
    font-size: 12px;
}

.top-slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gradient-title {
    font-size: 14px;
    color: #1c1c1c;
}
</style>