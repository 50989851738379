<template>
    <div>
        <b-form>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group
                                label="Logo"
                            >
                                <b-form-file
                                    name="logo"
                                    accept=".jpg, .png, .gif"
                                    @change="handleLogoImage($event)"
                                ></b-form-file>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group
                                label="Banner"
                            >
                                <b-form-file
                                    name="banner"
                                    accept=".jpg, .png, .gif"
                                    @change="handleBannerImage($event)"
                                ></b-form-file>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
            <b-button class="buton-submit-setari-cont" variant="primary" @click="onSubmit()">Salvează modificările</b-button>
        </b-form>
    </div>
</template>

<script>
    import {BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormFile} from 'bootstrap-vue'
    import api from '@/helpers/api';
    import axios from '@/helpers/axios';
    export default {
        components: {
            BForm,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BButton,
            BFormFile,
        },
        props: {
        },
        data() {
            return {
                imagesForm:new FormData
            }
        },
        mounted() {
        },
        methods: {
           
            handleLogoImage(event){
                this.imagesForm.append('files[consultant_logo]',event.target.files[0],event.target.files[0].name) 
            },
            handleBannerImage(event){
                this.imagesForm.append('files[consultant_banner]',event.target.files[0],event.target.files[0].name)         
            },
            onSubmit(){
                this.imagesForm.append('_method','PUT');  
                axios.post(api.API_ENDPOINTS.consulantsUpdateImages + '/' +this.$store.state.auth.user.consultant_id,this.imagesForm)
                    .then(response=>{
                        this.$notify(response.data.message)
                    })
            }
        }
    }
</script>

<style scoped>
.padding-box{
    padding: 20px;
}
input, textarea{
    border-radius: 0;
    border: 1px solid #e8e8e8;
}
input:focus, textarea:focus{
    box-shadow: none;
}
.buton-submit-setari-cont {
    color: #fff;
    background-image: linear-gradient(180deg, #5b3cd0, #3bc6d4);
    border: none;
    font-size: var(--paragraphSize);
    font-weight: var(--bold);
}
</style>