// Considering adding the user to the vuex store

import { startSession } from '@/helpers/auth'

import api from '@/helpers/api'
import axios from '@/helpers/axios'

export default {
  namespaced: true,
  state: {
    user: localStorage.user ? JSON.parse(localStorage.getItem('user')) : null,
    errors: '',
  },
  getters: {
    user: state => state.user,
    errors: state => state.errors,
    authenticated: state => state.user !== null,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
      state.errors = ''
    },

    SET_ERRORS(state, { errors }) {
      state.errors = errors
    },

    LOGOUT(state) {
      state.user = null
    },

    // SET_USER_PLANS(state, plans) {
    //   state.plans = plans
    //   state.errors = ''
    // },
  },
  actions: {
    async getUserData({ commit }) {
      await startSession()

      try {
        const { data } = await axios.get(api.API_ENDPOINTS.userData)
        commit('SET_USER', data.data)
        localStorage.user = JSON.stringify(data.data)
      } catch (e) {
        commit('SET_ERRORS', { errors: e.response.data.message })
      }
    },

    async logout({ commit }) {
      await axios.post(api.API_ENDPOINTS.logout).then(() => {
        localStorage.removeItem('user')
        commit('SET_USER', null)
      })
    },

    async logoutForVerification({ commit }) {
      await axios.post(api.API_ENDPOINTS.logout).then(() => {
        localStorage.removeItem('user')
        commit('SET_USER', null)
        commit('SET_ERRORS', {
          errors:
            'Adresa de email trebuie verificată! Te rugăm să o verifici accesând email-ul în secțiunea inbox sau spam.',
        })
      })
    },

    // async getUserPlans({ commit }) {
    //   await startSession()

    //   try {
    //     const { data } = await axios.get(api.API_ENDPOINTS.userPlans)
    //     commit('SET_USER_PLANS', data)
    //   } catch (e) {
    //     commit('SET_ERRORS', { errors: e.response.data.message })
    //   }
    // },
  },
}
