<template>
    <div class="modul-finantare-title item-l">
        <span class="bold-text">Documentele finanțării</span>
        <div class="modul-finantare-content">
            <ul class="lista-documente-finantari">

                <li v-for="(value, key, index) in data.downloads" :key="index">
                    <a style="color: inherit" :href="value" download>
                        <span><b-icon-download
                            font-scale="1"
                            style="margin-right: 5px"
                        ></b-icon-download></span><span class="document">{{ key }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <p class="modul-finantare-content bottom-text">
            Echipa smis.ro face eforturi în actualizarea perioadică a documentelor și
            informațiilor. Vă rugăm să vizitați și pagina oficială a finanțării.
        </p>
    </div>
</template>

<script>
    import { BIconDownload } from 'bootstrap-vue';
    export default{
        components:{
            BIconDownload
        },
        props: {
            data: null,
        },
        data() {
            return{
            }
        },
        methods: {
        },
    }
</script>

<style scoped>
.bold-text {
  font-weight: bold;
  font-size: var(--moduleTitleSize);
}
.item-l {
  grid-area: documenteFinantare;
}
.modul-finantare-content {
  margin-top: 10px;
  color: #868686;
  font-size: 14px;
  display: flex;
}
.lista-documente-finantari {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
  width: 100%;
  margin: 0;
  padding: 0;
}
.document{
  font-size: var(--paragraphSize);
  margin-left: 3px;
}
.modul-finantare-title{
  position: relative;
}
.bottom-text{
  position: absolute;
  bottom: 10%;
  left: 20px;
  right: 20px;
  font-size: var(--paragraphSize);
}
@media only screen and (max-width: 1250px) {
  .item-l {
    grid-area: documenteFinantare;
  }
}
@media only screen and (max-width: 1100px) {
  .lista-documente-finantari{
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}
@media only screen and (max-width: 710px) {
  .lista-documente-finantari{
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
}
</style>