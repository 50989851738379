import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/pages/Home/Home.vue'
import CompaniaMeaPas1 from '@/pages/Aplicatii/CompaniaMea/CompaniaMeaPas1.vue'
import CompaniaMeaPas2 from '@/pages/Aplicatii/CompaniaMea/CompaniaMeaPas2.vue'
import CompaniaMeaPas3 from '@/pages/Aplicatii/CompaniaMea/CompaniaMeaPas3.vue'
import IdentitateVizuala from '@/pages/Aplicatii/AplicatiileMele/M2Manager/IdentitateVizuala/IdentitateVizuala.vue'
import Register from '@/pages/UserManager/Register.vue'
import Login from '@/pages/UserManager/Login.vue'
import ForgotPassword from '@/pages/UserManager/ForgotPassword.vue'
import HartaConsultanti from '@/pages/Consultanti/HartaConsultanti/HartaConsultantilor.vue';
import ToateFinantarile from '@/pages/Finantari/ToateFinantarile/ToateFinantarile.vue'
import ContulMeu from '@/pages/Home/ContulMeu/ContulMeu.vue'
import Notificari from '@/pages/Home/ContulMeu/components/Notificari.vue'
import Financiar from '@/pages/Home/ContulMeu/components/Financiar.vue'
import Stiri from '@/pages/Stiri/Stiri.vue'
import StireSingle from '@/pages/Stiri/StireSingle.vue'
import NotificarileMele from '@/pages/NotificarileMele/NotificarileMele.vue'
import SmisFlowchart from '@/pages/SmisFlowchart/SmisFlowchart.vue'
import PersonalSmis from '@/pages/Home/PersonalSmis/PersonalSmis.vue'
import CompaniileMele from '@/pages/Home/CompaniileMele/CompaniileMele.vue'
import AdaugaCompanie from '@/pages/Home/CompaniileMele/AdaugaCompanie.vue'
import ServiciiActive from '@/pages/Home/ServiciiActive/ServiciiActive.vue'
import CalendarFinantari from '@/pages/Finantari/CalendarFinantari/CalendarFinantari.vue'
import FinantariFavorite from '@/pages/Finantari/FinantariFavorite/FinantariFavorite.vue'
import EligibilitateFinantari from '@/pages/Finantari/EligibilitateFinantari/EligibilitateFinantari.vue'
import AplicatiiSmis from '@/pages/Aplicatii/AplicatiiSmis/AplicatiiSmis.vue'
import ToDo from '@/pages/Home/ToDo/ToDo.vue'
import CumFunctioneaza from '@/pages/Aplicatii/CumFunctioneaza/CumFunctioneaza.vue'
import CompaniaMea from '@/pages/Aplicatii/CompaniaMea/CompaniaMea.vue'
import PrezentareGenerala from '@/pages/Aplicatii/AplicatiileMele/M2Manager/PrezentareGenerala/PrezentareGenerala.vue'
import CatalogCheltuieli from '@/pages/Aplicatii/CatalogCheltuieli/CatalogCheltuieli.vue'
import IntrebariM2 from '@/pages/Aplicatii/AplicatiileMele/M2Manager/IntrebariM2/IntrebariM2.vue'
import FormulareClarificari from '@/pages/Aplicatii/FormulareClarificari/FormulareClarificari.vue'
import ContacteFinantator from '@/pages/Aplicatii/AplicatiileMele/M2Manager/ContacteFinantator/ContacteFinantator.vue'
import CalculatorValutar from '@/pages/Aplicatii/AplicatiileMele/M2Manager/CalculatorValutar/CalculatorValutar.vue'
import ModeleDocumente from '@/pages/Aplicatii/AplicatiileMele/M2Manager/ModeleDocumente/ModeleDocumente.vue'
import NeplatitorTva from '@/pages/Aplicatii/AplicatiileMele/M2Manager/NeplatitorTva/NeplatitorTva.vue'
import TutorialeVideo from '@/pages/Aplicatii/AplicatiileMele/M2Manager/TutorialeVideo/TutorialeVideo.vue'
import MonografiaContabila from '@/pages/Aplicatii/AplicatiileMele/M2Manager/MonografiaContabila/MonografiaContabila.vue'
import GrantNecheltuit from '@/pages/Aplicatii/AplicatiileMele/M2Manager/GrantNecheltuit/GrantNecheltuit.vue'
import ComunicatFinal from '@/pages/Aplicatii/AplicatiileMele/M2Manager/ComunicatFinal/ComunicatFinal.vue'
import CautaConsultant from '@/pages/Consultanti/CautaConsultant/CautaConsultant.vue'
import OferteSpeciale from '@/pages/Consultanti/OferteSpeciale/OferteSpeciale.vue'
import SolicitaOferta from '@/pages/Consultanti/SolicitaOferta/SolicitaOferta.vue'
import EvalueazaCompanie from '@/pages/Consultanti/EvalueazaCompanie/EvalueazaCompanie.vue'
import SmisConsultant from '@/pages/Consultanti/SmisConsultant/SmisConsultant.vue'
import PrezentareCompanie from '@/pages/Consultanti/PrezentareCompanie/PrezentareCompanie.vue'
import PrezentareCompaniePremium from '@/pages/Consultanti/PrezentareCompanie/PrezentareCompaniePremium.vue'
import Finantari from '@/pages/Finantari/Finantari/Finantari.vue'
import HartaFinantarilor from '@/pages/Finantari/HartaFinantarilor/HartaFinantarilor.vue'
import AplicatiileMele from '@/pages/Aplicatii/AplicatiileMele/AplicatiileMele.vue'
import M2Manager from '@/pages/Aplicatii/AplicatiileMele/M2Manager/M2Manager.vue'
import PocuManager from '@/pages/Aplicatii/AplicatiileMele/PocuManager/PocuManager.vue'
import Innotech from '@/pages/Aplicatii/AplicatiileMele/Innotech/Innotech.vue'
import Consultanti from '@/pages/Consultanti/Consultanti/Consultanti.vue'
import Aplicatii from '@/pages/Aplicatii/Aplicatii.vue'
import FinantareSingle from '@/pages/Finantari/FinantareSingle/FinantareSingle.vue'
import PrezentareProgram from '@/pages/PrezentareProgram/PrezentareProgram.vue'
import EditeazaInformatii from '@/pages/Aplicatii/AplicatiileMele/SmisConsultant/EditeazaInformatii.vue'
import RecenziileMele from '@/pages/Aplicatii/AplicatiileMele/SmisConsultant/RecenziileMele.vue'
import CompanieVerificata from '@/pages/Aplicatii/AplicatiileMele/SmisConsultant/CompanieVerificata.vue'
import CereriDeOferta from '@/pages/Aplicatii/AplicatiileMele/SmisConsultant/CereriDeOferta.vue'
import OfertaSpeciala from '@/pages/Aplicatii/AplicatiileMele/SmisConsultant/OfertaSpeciala.vue'
import SuportTehnic from '@/pages/SuportTehnic/SuportTehnic.vue'
import ErrorPage from '@/pages/404/ErrorPage.vue'
import PageClosed from '@/pages/PageClosed/PageClosed.vue'

import middleware from '@/helpers/middleware'


Vue.use(VueRouter)
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

const routes = [
    {
        path: "/",
        name: "PersonalSmis",
        component: PersonalSmis,
        beforeEnter: middleware.auth,
    },
    {
        path: "/stiri",
        name: "Stiri",
        component: Stiri,
    },
    {
        path: "/stire/:slug",
        name: "StireSingle",
        component: StireSingle,
    },
    {
        path: "/compania-mea-pas1",
        name: "CompaniaMeaPas1",
        component: CompaniaMeaPas1,
        beforeEnter: middleware.auth,
    },
    {
        path: "/compania-mea-pas2",
        name: "CompaniaMeaPas2",
        component: CompaniaMeaPas2,
        beforeEnter: middleware.auth,
    },
    {
        path: "/compania-mea-pas3",
        name: "CompaniaMeaPas3",
        component: CompaniaMeaPas3,
        beforeEnter: middleware.auth,
    },
    {
        path: "/toate-finantarile",
        name: "ToateFinantarile",
        component: ToateFinantarile,
        beforeEnter: middleware.auth,
    },
    {
        path: "/finantare/:id",
        name: "FinantareSingle",
        component: FinantareSingle,
        beforeEnter: middleware.auth,
    },
    {
        path: "/program/:id",
        name: "PrezentareProgram",
        component: PrezentareProgram,
        beforeEnter: middleware.auth,
    },
    {
        path: "/harta-consultantilor",
        name: "HartaConsultanti",
        component: HartaConsultanti,
        beforeEnter: middleware.auth,
    },
    {
        path: "/todo",
        name: "ToDo",
        component: ToDo,
        beforeEnter: middleware.auth,
    },
    {
        path: "/notificarile-mele",
        name: "NotificarileMele",
        component: NotificarileMele,
        beforeEnter: middleware.auth,
    },
    {
        path: "/smis-flowchart",
        name: "SmisFlowchart",
        component: SmisFlowchart,
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        beforeEnter: middleware.guest,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        beforeEnter: middleware.guest,
    },
      {
        path: "/email/verify/*",
        name: "EmailVerify",
        component: Login,
        beforeEnter: middleware.guest,
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
        beforeEnter: middleware.guest,
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
        beforeEnter: middleware.auth,
    },
    {
        path: "/contul-meu",
        name: "ContulMeu",
        component: ContulMeu,
        beforeEnter: middleware.auth,
    },
    {
        path: "/contul-meu/notificari",
        name: "Notificari",
        component: Notificari,
        beforeEnter: middleware.auth,
    },
    {
        path: "/contul-meu/financiar",
        name: "Financiar",
        component: Financiar,
        beforeEnter: middleware.auth,
    },
    {
        path: "/companiile-mele",
        name: "CompaniileMele",
        component: CompaniileMele,
        beforeEnter: middleware.auth,
    },
    {
        path: "/companiile-mele/adauga-companie",
        name: "AdaugaCompanie",
        component: AdaugaCompanie,
        beforeEnter: middleware.auth,
    },
    {
        path: "/servicii-active",
        name: "ServiciiActive",
        component: ServiciiActive,
        beforeEnter: middleware.auth,
    },
    {
        path: "/finantari",
        name: "Finantari",
        component: Finantari,
        beforeEnter: middleware.auth,
    },
    {
        path: "/calendar-finantari",
        name: "CalendarFinantari",
        component: CalendarFinantari,
        beforeEnter: middleware.auth,
    },
    {
        path: "/harta-finantarilor",
        name: "HartaFinantarilor",
        component: HartaFinantarilor,
        beforeEnter: middleware.auth,
    },
    {
        path: "/finantari-favorite",
        name: "FinantariFavorite",
        component: FinantariFavorite,
        beforeEnter: middleware.auth,
    },
    {
        path: "/eligibilitate-finantari",
        name: "EligibilitateFinantari",
        component: EligibilitateFinantari,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatii-smis",
        name: "AplicatiiSmis",
        component: AplicatiiSmis,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele",
        name: "AplicatiileMele",
        component: AplicatiileMele,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager",
        name: "M2Manager",
        component: M2Manager,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/cum-functioneaza",
        name: "CumFunctioneaza",
        component: CumFunctioneaza,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/compania-mea",
        name: "CompaniaMea",
        component: CompaniaMea,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/prezentare-generala",
        name: "PrezentareGenerala",
        component: PrezentareGenerala,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/identitate-vizuala",
        name: "IdentitateVizuala",
        component: IdentitateVizuala,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/catalog-cheltuieli",
        name: "CatalogCheltuieli",
        component: CatalogCheltuieli,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/intrebari-m2",
        name: "IntrebariM2",
        component: IntrebariM2,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/formulare-clarificari",
        name: "FormulareClarificari",
        component: FormulareClarificari,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/contacte-finantator",
        name: "ContacteFinantator",
        component: ContacteFinantator,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/calculator-valutar",
        name: "CalculatorValutar",
        component: CalculatorValutar,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/modele-de-documente",
        name: "ModeleDocumente",
        component: ModeleDocumente,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/neplatitor-tva",
        name: "NeplatitorTva",
        component: NeplatitorTva,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/tutoriale-video",
        name: "TutorialeVideo",
        component: TutorialeVideo,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/monografia-contabila",
        name: "MonografiaContabila",
        component: MonografiaContabila,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/grant-necheltuit",
        name: "GrantNecheltuit",
        component: GrantNecheltuit,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/m2-manager/comunicat-final",
        name: "ComunicatFinal",
        component: ComunicatFinal,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/pocu-manager",
        name: "PocuManager",
        component: PocuManager,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/innotech",
        name: "Innotech",
        component: Innotech,
        beforeEnter: middleware.auth,
    },
    {
        path: "/consultanti",
        name: "Consultanti",
        component: Consultanti,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatii",
        name: "Aplicatii",
        component: Aplicatii,
        beforeEnter: middleware.auth,
    },
    {
        path: "/cauta-consultant",
        name: "CautaConsultant",
        component: CautaConsultant,
        beforeEnter: middleware.auth,
    },
    {
        path: "/oferte-speciale",
        name: "OferteSpeciale",
        component: OferteSpeciale,
        beforeEnter: middleware.auth,
    },
    {
        path: "/solicita-oferta",
        name: "SolicitaOferta",
        component: SolicitaOferta,
        beforeEnter: middleware.auth,
    },
    {
        path: "/evalueaza-companie",
        name: "EvalueazaCompanie",
        component: EvalueazaCompanie,
        beforeEnter: middleware.auth,
    },
    {
        path: "/smis-consultant",
        name: "SmisConsultant",
        component: SmisConsultant,
        beforeEnter: middleware.auth,
    },
    {
        path: "/prezentare-program",
        name: "Prezentare program",
        component: PrezentareProgram,
        beforeEnter: middleware.auth,
    },
    {
        path: "/prezentare-companie",
        name: "Prezentare Companie",
        component: PrezentareCompanie,
        beforeEnter: middleware.auth,
    },
    {
        path: "/consultant/:id",
        name: "Prezentare Companie Premium",
        component: PrezentareCompaniePremium,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/consultant/editeaza-informatii",
        name: "Editeaza Informatii",
        component: EditeazaInformatii,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/consultant/recenziile-mele",
        name: "Recenziile Mele",
        component: RecenziileMele,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/consultant/companie-verificata",
        name: "Companie Verificata",
        component: CompanieVerificata,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/consultant/cereri-de-oferta",
        name: "Cereri de oferta",
        component: CereriDeOferta,
        beforeEnter: middleware.auth,
    },
    {
        path: "/aplicatiile-mele/consultant/oferta-speciala",
        name: "Oferta mea speciala",
        component: OfertaSpeciala,
        beforeEnter: middleware.auth,
    },
    {
        path: "/suport-tehnic",
        name: "Suport tehnic",
        component: SuportTehnic,
        beforeEnter: middleware.auth,
    },
    {
        path: "/*/in-constructie",
        name: "In curand",
        component: PageClosed,
    },
    {
        path: "*",
        component: ErrorPage,
    }
];

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    routes
})

export default router
