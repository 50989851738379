<template>
    <div>
        <b-form @submit.prevent="onSubmit()">
            <div v-for="(member, key) in team" :key="key" class="row align-items-center">
                <b-form-group
                    label="Nume"
                    class="group"
                >
                    <b-form-input
                        v-model="member.name"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Funcție"
                    class="group"
                >
                    <b-form-input
                        v-model="member.role"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Avatar"
                    class="group"
                >
                    <b-form-file
                        name="image"
                        accept=".jpg, .png, .gif"
                        :placeholder="member.profile.filename!='null' && member.profile.filename ? member.profile.filename : 'No file chosen'"
                        @change="handleTeamAvatar($event,key)"
                    ></b-form-file>
                </b-form-group>
                <span class="delete-icon" @click="removeItems(key)"><b-icon-trash variant="danger" font-scale="1.1"></b-icon-trash></span>
            </div>
            <b-button class="buton-submit-setari-cont mr-3 button-add-office" variant="primary" @click="addItems">Adaugă membru</b-button>
            <b-button class="buton-submit-setari-cont" variant="primary" type="submit" >Salvează modificările</b-button>
        </b-form>
    </div>
</template>

<script>
    import {BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BIconTrash, BFormFile} from 'bootstrap-vue'
    import api from '@/helpers/api';
    import axios from '@/helpers/axios';

    export default {
        components: {
            BForm,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BButton,
            BIconTrash,
            BFormFile,
        },
        props: {
            data:{
                type:Array,
                default:() => []
            },
        },
        data() {
            return {
                team:[],
            }
        },
        watch:{
            data:function () {
                this.team = [...this.data];
                if(this.team.length === 0){
                    this.addItems();
                }
            }
        },
        mounted() {
           
        },
        methods: {
            addItems() {
                this.team.push({
                    name: '',
                    role: '',
                    logo: null,
                    profile:{
                        id:null,
                        filename:null,
                    }
                })
            },
            removeItems(key) {
                this.team.splice(key,1)
            },
            onSubmit() {
                let formData = new FormData;
                this.team.forEach( (member,index)=>{
                    formData.append(`team[${index}][name]`,member.name)
                    formData.append(`team[${index}][role]`,member.role)
                    formData.append(`team[${index}][profile][filename]`,member.profile.filename)
                    formData.append(`team[${index}][profile][id]`,member.profile.id);
                    formData.append(`images[team][${index}]`,member.logo)
                });
                formData.append('location','member-profile');
                formData.append('_method','PUT');
                axios.post(api.API_ENDPOINTS.consultantUpdateTeam + '/'+ this.$store.state.auth.user.consultant_id,formData)
                    .then(response=>{
                        if(response.data.team && response.data.team.length > 0) {
                            this.team = response.data.team;
                        } else {
                            this.addItems();
                        }
                        this.$notify(response.data.message); 
                    }).catch((err) =>{
                        this.$notify(err.response.data.message);    
                    })
            },
            handleTeamAvatar(event,index){
                this.team[index].logo = event.target.files[0];
                this.team[index].profile.filename = event.target.files[0].name;
            }
        }
    }
</script>

<style scoped>
.padding-box {
    padding: 20px;
}
input, textarea {
    border-radius: 0;
    border: 1px solid #e8e8e8;
}
input:focus, textarea:focus {
    box-shadow: none;
}
.buton-submit-setari-cont {
    color: #fff;
    background-image: linear-gradient(180deg, #5b3cd0, #3bc6d4);
    border: none;
    font-size: var(--paragraphSize);
    font-weight: var(--bold);
}
.button-add-office{
    background: #3bc6d4;
}
.group:not(:last-child) {
    margin-right: 10px;
}
.delete-icon{
    cursor: pointer;
}
.row{
    margin-right: 0;
    margin-left: 0;
}
</style>