<template>
    <div>
        <UserTopBar :user-name="this.infoCont.name"
                    :role="this.infoCont.rol"
                    :email="this.infoCont.email"
                    :user-image="this.infoCont.avatar"
                    :usepackage="this.infoCont.account_type_name"
                    :phone="this.infoCont.phone"
                    :company="this.infoCont.company"
        ></UserTopBar>
        <div class="viewPagePadding" v-if="!loading">
            <ModulPachetSmis class="mb-3" :total="subscriptionsTotal" :trial="subscriptionsTrial" :licenta="subscriptionsLicense"></ModulPachetSmis>
            <div class="grid-pachete">
                <div v-for="(subscriptie, i) in subscriptions" :key="i">
                    <ServiciiActiveContentBox
                        :subscriptie="subscriptie"
                    ></ServiciiActiveContentBox>
                </div>
            </div>
        </div>
        <div class="loading" v-else>
            <LoadingSpinner></LoadingSpinner>
        </div>
    </div>
</template>

<script>
    import LoadingSpinner from '@/common/components/LoadingSpinner'
    import ModulPachetSmis from "./components/ModulPachetSmis";
    import ServiciiActiveContentBox from "./components/ServiciiActiveContentBox";
    import UserTopBar from '../ContulMeu/components/UserTopBar'
    import api from '@/helpers/api';
    import axios from '@/helpers/axios';
    import _ from 'lodash'

    export default {
        name: "Test",
        components: {
            LoadingSpinner,
            ModulPachetSmis,
            ServiciiActiveContentBox,
            UserTopBar,
        },
        props: {},
        data() {
            return {
                loading: true,
                infoCont: {
                  name: '',
                  rol: '',
                  email: '',
                  phone: '',
                  avatar: '',
                  account_type_name: '',
                  account_type_logo: '',
                  company: '',
                },
                pachet: [
                    {
                        poza: "LogoSmisDesktopAccess",
                        tippachet: "abonament",
                        lunar: 4,
                        anual: 3,
                        economisire: 144,
                        serviciu: "licenta",
                    },
                    {
                        poza: "LogoM2Manager",
                        tippachet: "licenta",
                        serviciu: "licenta",
                        pret: 59,
                    },
                    {
                        poza: "LogoSmisConsultantPROF",
                        tippachet: "abonament",
                        lunar: 132,
                        anual: 78,
                        economisire: 648,
                        serviciu: "none",
                    },
                ],
                subscriptions: [],
                subscriptionsTotal: [],
                subscriptionsTrial: [],
                subscriptionsLicense: [],
            };
        },
        created() {},
        mounted() {
          this.getInfoCont()
          this.getUserSubscriptions()
        },
        methods: {
          getInfoCont() {
            this.infoCont.name = this.$store.state.auth.user.name
            this.infoCont.rol = this.$store.state.auth.user.role
            this.infoCont.email = this.$store.state.auth.user.email
            this.infoCont.phone = this.$store.state.auth.user.phone
            this.infoCont.avatar = this.$store.state.auth.user.logo
            this.infoCont.account_type_name = this.$store.state.auth.user.account_type.name
            this.infoCont.account_type_logo = this.$store.state.auth.user.account_type.logo
            this.infoCont.company = this.$store.state.auth.user.company
          },
          getUserSubscriptions() {
            return axios.get(api.API_ENDPOINTS.userSubscriptions)
                .then(response => {
                  this.subscriptions = response.data;
                  this.subscriptionsTotal = response.data.length;
                  this.subscriptionsTrial = _.filter(response.data, v => v.on_trial).length;
                  this.subscriptionsLicense = _.filter(response.data, v => v.license).length;

                }).finally(() => {
                    this.loading = false
                })
          },
        },
    };
</script>

<style scoped>
.loading{
    height: 150px;
    width: 100%;
}
.grid-pachete {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    grid-template-rows: auto;
    grid-gap: 20px;
    margin: 20px 0 0 0;
}
@media only screen and (max-width: 600px) {
    .grid-pachete {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
}
</style>
