<template>
    <div>
        <div class="background-container">
            <div class="hdr">
                <h3 class="text-left text-bold">{{data.name}}</h3>
                <div>
                    <p class="text-left" style="margin:0">
                        <span class="text-bold"> Programul Operațional:</span> {{  data.program.name }}
                    </p>
                    <p class="text-left">
                        <!--<span class="text-bold">Axa prioritară:</span>-->
                        <span v-for="(item, x) in breadcrumb.slice(0,-1).reverse()" :key="x" class="list-axa">
                            {{ item.name }}
                        </span>
                    </p>
                </div>
            </div>
            <div class="details">
                <div class="finantare">
                    <p class="margin-text-b">Tip Finanțare</p>
                    <img :src="data.program.type.logo.path" alt="" >
                    <p class="text-bold text-uppercase">{{data.program.type.title}}</p>
                </div>
                <div class="buget">
                    <span>
                        <p class="text-bold">Total buget alocat:</p>
                        <p class="margin-text-b buget-text">{{data.total_budget.toLocaleString('en-EN')}}</p>
                    </span>
                    <span>
                        <p class="text-bold">Bugetul unui proiect:</p>
                        <p class="text-bold buget-text">{{data.project_budget}}</p>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
  props: {
    data: null,
    breadcrumb:{
      type: Array
    },
  },
  data() {
    return{

    }
  },
  methods: {
  }

}
</script>

<style scoped>
	.background-container {
		display: flex;
		justify-content: space-between;
		background: linear-gradient(rgba(148, 182, 186, 0.8), rgba(148, 182, 186, 0.8)),
			url("../../../../assets/images/HartaFinantarilorPoza3.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 30% center;
		padding: 20px;
		align-items: center;
	}
	.background-container .details{
		display: flex;
	}
	p {
		text-align: center;
		font-size: 14px;
		color: #fff;
	}
	h3 {
		font-size: var(--titleSize);
		color: #fff;
	}
	.buget {
		width: 300px;
	}
  .buget-text{
    font-size: var(--titleSize);
  }
	.finantare {
		border-right: 3px dotted #f4f6f6;
		border-left: 3px dotted #f4f6f6;
		text-align: center;
		width: 210px;
		padding: 0 20px;
	}
	.text-left {
		text-align: left;
		padding-left: 10px;
	}
	.text-bold,
	.margin-text-b {
		font-weight: var(--bold);
	}
	.margin-text-b {
		margin-bottom: 10px;
	}
  .list-axa{
    position: relative;
  }
  .list-axa:not(:last-child){
    margin-right: 10px;
  }
  .list-axa:not(:last-child):before{
    content: "";
    position: absolute;
    width: 1px;
    height: 80%;
    background: #fff;
    right: -3%;
    bottom: 0;
  }

	@media only screen and (max-width: 1030px) {
        .background-container{
			flex-direction: column;
		}
		.finantare{
			border: none;
		}
		.buget {
			display: flex;
		}
		.buget>span{
			margin: 10px;
		}
		.details{
			margin-top: 20px;
			flex-direction: row-reverse;
			flex-direction: column;
		}
		.finantare, .buget{
			width: auto;
		}
		.hdr * {
			text-align: center !important;
		}
    }
		
	@media only screen and (max-width: 450px) {
		.buget{
			flex-direction: column;
		}
	}
</style>
