<template>
    <div>
        <div>
            <BlueTitleBar
                title="IDENTITATE VIZUALĂ"
            ></BlueTitleBar>
        </div>
        <div class="col-md-12 margin-top">
            <div class="flexed">
                <div class="first-col">
                    <div class="white-box">
                        <TimpRamas></TimpRamas>
                    </div>
                </div>
                <div class="two-col">
                    <div class="white-box gov">
                        <img src="@/assets/images/logoguvern-wide.png">
                        <p>Vizitează platforma</p>
                        <a href="https://granturi.imm.gov.ro" class="gov-link">granturi.imm.gov.ro</a>
                    </div>
                </div>
                <div class="three-col">
                    <div class="white-box height">
                        <ContulM2Manager></ContulM2Manager>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 margin-top">
            <div class="flexed">
                <div class="four-col">
                    <FAQM2></FAQM2>
                </div>
                <div class="five-col">
                    <DocumenteImplementareM2></DocumenteImplementareM2>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="six-col margin-top">
                <StiriM2></StiriM2>
            </div>
        </div>

    </div>
</template>

<script>
import BlueTitleBar from "@/common/components/BlueTitleBar";
import TimpRamas from "./components/TimpRamas";
import ContulM2Manager from "./components/ContulM2Manager";
import FAQM2 from "./components/FAQM2";
import DocumenteImplementareM2 from "./components/DocumenteImplementareM2";
import StiriM2 from "./components/StiriM2";
export default {
    components: {
        BlueTitleBar,
        TimpRamas,
        ContulM2Manager,
        FAQM2,
        DocumenteImplementareM2,
        StiriM2,
    },
    props: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.white-box{
    background: #fff;
    border: #dddddd;
    border-radius: 2px;
    padding: 10px;
}
.flexed{
    display: flex;
    justify-content: space-between;
}
.margin-top{
    margin-top: 20px;
}
.height{
    min-height: 100%;
}
.first-col{
    width: 48%;
}
.two-col{
    width: 10%;
    min-height: 100%;
}
.three-col{
    width: 40%;
}
.four-col, .six-col{
    width: 59%;
}
.five-col{
    width: 40%;
}
.gov{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.gov img{
    max-width: 65px;
    object-fit: cover;
}
.gov p, .gov a{
    font-size: 14px;
}
.gov a{
    color: #314081;
    font-weight: 600;
}
@media (max-width:1100px){
    .flexed{
        display: block;
    }
    .first-col, .two-col, .three-col, .four-col, .five-col, .six-col{
        width: 100%;
        margin-bottom: 20px;
    }
}
</style>
