<template>
    <div v-if="nume">
        <div>
            <p class="info-text">Bine ai venit! Compania ta este</p>
        </div>
        <div>
            <strong class="nume-companie">{{nume}}</strong>
        </div>
    </div>
</template>

<script>
export default{
    props: {
        nume: {
            type: String,
            default: null,
        },
    }
}
</script>

<style scoped>
.info-text{
    color: #878588;
    text-align: left;
    font-size: 13px;
}
.nume-companie{
    color: #878588;
    font-size: 15px;
}

</style>