<template>
    <div v-click-outside="closeMenu" class="cursor-pointer" @click="toggleMenu()">
        <div class="menu-item">
            <div href="#" class="spriteBg grid"></div>
            <transition name="fade" appear>
                <div v-if="isOpen" class="sub-menu">
                    <div class="menu-item">
                        <div class="col">
                            <a class="p-a block">
                                <b-icon-question-circle-fill scale="2"></b-icon-question-circle-fill>
                                <span class="block">SMIS Help</span>
                            </a>
                        </div>
                    </div>
                    <div class="menu-item">
                        <router-link to="/finantari-favorite">
                            <div class="col">
                                <a class="p-a block">
                                    <b-icon-heart-fill scale="2"></b-icon-heart-fill>
                                    <span class="block">Favorite</span>
                                </a>
                            </div>
                        </router-link>
                    </div>
                    <div class="menu-item">
                        <router-link to="/notificarile-mele">
                            <div class="col">
                                <a class="p-a block" >
                                    <b-icon-bell-fill scale="2"></b-icon-bell-fill>
                                    <span class="block">Notificări</span>
                                </a>
                            </div>
                        </router-link>
                    </div>
                    <div class="menu-item">
                        <div class="col">
                            <a href="tel:0040314025010" class="p-a block">
                                <b-icon-telephone-fill scale="2"></b-icon-telephone-fill>
                                <span class="block">Sună-ne</span>
                            </a>
                        </div>
                    </div>
                    <div class="menu-item">
                        <div class="col">
                            <router-link to="suport-tehnic" class="p-a block">
                                <b-icon-chat-left-text-fill scale="2"></b-icon-chat-left-text-fill>
                                <span class="block">Suport tehnic</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="menu-item">
                        <div class="col">
                            <a href="mailto:contact@smis.ro" class="p-a block">
                                <b-icon-envelope-fill scale="2"></b-icon-envelope-fill>
                                <span class="block">Trimite mail</span>
                            </a>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import {
        
        BIconHeartFill,
        BIconQuestionCircleFill,
        BIconBellFill,
        BIconEnvelopeFill,
        BIconTelephoneFill,
        BIconChatLeftTextFill  
    } from 'bootstrap-vue';
    export default {
        name: "ShortcutDropdown",
        components:{
            
            BIconHeartFill,
            BIconQuestionCircleFill,
            BIconBellFill,
            BIconEnvelopeFill,
            BIconTelephoneFill,
            BIconChatLeftTextFill  
        },
        directives: {
            'click-outside': {
                bind: function(el, binding, vNode) {
                    // Provided expression must evaluate to a function.
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) { warn += `Found in component '${compName}'` }
          
                        console.warn(warn)
                    }
                    // Define Handler and cache it on the element
                    const bubble = binding.modifiers.bubble
                
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    }
                    
                    el.__vueClickOutside__ = handler

                    // add Event Listeners
                    document.addEventListener('click', handler)
                },
      
                unbind: function(el) {
                    // Remove Event Listeners
                    
                    document.removeEventListener('click', el.__vueClickOutside__)
                    el.__vueClickOutside__ = null

                }
            }
        },
        props: {notificationsDropdownOpen:{
            type:Boolean,
            default:false
        }},

        data() {
            return {
                isOpen: false,
                services: [
                    {
                        title: "SMIS Help",
                        link: "#",
                        icon:'question-circle-fill'
                    },
                    {
                        title: "Favorite",
                        link: "#",
                    },
                    {
                        title: "Notificări",
                        link: "#",
                    },
                    {
                        title: "Sună-ne",
                        link: "#",
                    },
                    {
                        title: "Suport tehnic",
                        link: "#",
                    },
                    {
                        title: "Trimite mail",
                        link: "#",
                    },
                ],
            };
        },
        watch:{
            notificationsDropdownOpen(){
                if(this.notificationsDropdownOpen){this.closeMenu();}
            } 
        },
        created() {},
        methods: {
            toggleMenu(){
                this.isOpen=!this.isOpen
                if(this.isOpen)
                    this.$emit('shortcutsDropdownOpen',this.isOpen)
            },
            closeMenu(){
                this.isOpen=false;
                
            }
        },
    }
</script>

<style scoped>
    .sub-menu {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        width: 286px;
        border-radius: 10px;
        top: 110%;
        right:310px;
        z-index: 4
    }
    .sub-menu .menu-item{
        background-color: white;
        flex-basis: 33.33333%;
        border: 1px solid #eeeff0 !important;
    }
    .cursor-pointer .menu-item{
      border: 0px;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.5s ease-out;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    .col {
        background: white;
        float: left;
        position: relative;
        min-height: 1px;
       
        
        padding: 20px 0px 20px;
        font-weight: bold;
    }
    .col svg{
        margin-bottom: 7px;
    }
    .block {
        display: block;
        text-align: center;
        font-size: 12px;
        color:#7a7a7a;
        font-weight: 400;
        padding-top: 3px;
    }
    .spriteBg {
        background: url("../../../assets/images/leftNavIcons.png") no-repeat;
        margin: 23px 10px;
    }
    .grid {
        background-position: -0px -209px;
        width: 17px;
        height: 15px;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    @media (max-width:540px) {
        .sub-menu{
            right:auto;
            left: 12px;
        }
    }
</style>
