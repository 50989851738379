<template>
    <div>
        <div>
            <p class="info-text">Introduci aici CUI-ul firmei și noi îți generăm toate datele</p>
        </div>
        <b-form class="mt-4 cui-form" v-on:submit.prevent="onSubmit()" :class="cuiError ? 'cuiInputError' : ''">
            <b-form-group>
                <b-form-input
                    id="cui"
                    type="text"
                    class="cui-input w-100"
                    :maxlength="max"
                    v-model="form.cui"
                    v-on:keyup.native.enter="onSubmit()"
                ></b-form-input>
                <span class="error-message" v-if="cuiError">CUI-ul introdus nu este valid</span>
            </b-form-group>
            <font-awesome-icon icon="chevron-right" :style="{ color: '#fff' }" @click.prevent="onSubmit()"></font-awesome-icon>
            <p class="text-right mt-1 text-bold click-submit" @click.prevent="onSubmit()">Generează datele</p>
        </b-form>
    </div>
</template>

<script>
import { BForm, BFormInput, BFormGroup } from 'bootstrap-vue';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faChevronRight);
export default{
    components:{
        BForm,
        BFormInput,
        BFormGroup,
        FontAwesomeIcon,
    },
    props: {
        data: null,
        cuiError: null,
        cui: null,
    },
    data() {
        return{
            max: 10,
            form: {
                cui: '',
            },
            errors: '',
        }
    },
    mounted() {

    },
    created() {
        if(this.$route.params.cui){
            this.form.cui = this.cui = this.$route.params.cui
            console.log('cuiafacere')
        }
    },
    methods: {
        onSubmit() {
                this.$emit('cuiCompletat', this.form.cui);
        },
    },
}
</script>

<style scoped>
.text-bold{
    font-weight: 600;
    color: #fff;
}
.click-submit{
    cursor: pointer;
}
.info-text{
    color: #fff;
    text-align: left;
    font-size: 13px;
}
.cui-input{
    background: transparent;
    border-radius: 2px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 12px;
}
.cui-form{
    position: relative;
}
.fa-chevron-right{
    position: absolute;
    top: 13px;
    right: 2%;
    cursor: pointer;
    width: 35px;
}
.cui-input:focus{
    outline: none;
    border: 1px solid #fff;
    box-shadow: none;
}
.cui-input::-webkit-outer-spin-button,
.cui-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.cuiInputError >>> input {
    border-color: red;
}
.cuiInputError >>> input:focus {
    border-color: red;
}
.cuiInputError >>> svg{
    color: red !important;
}
</style>