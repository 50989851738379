<template>
    <div v-if="data.description">
        <span class="bold-text">Unde depun proiectul?</span>
        <div v-if="data.description" class="modul-finantare-content" v-html="data.description"></div>
        <div class="top-line">
            <a target="_blank" :href="data.link ? data.link : ''">
                <img :src="data.logo.path" :alt="data.title">
            </a>

        </div>
    </div>
</template>

<script>
    export default{
        props: {
            data: null,
        },
        data() {
            return{
            }
        },
        methods: {
        },
    }
</script>

<style scoped>
 p{
     font-size: var(--paragraphSize);
     color: #868686;
}
 .bold-text {
     font-weight: bold;
    font-size: var(--moduleTitleSize);
     color: #434343;
}
 img {
   width: auto;
   height: auto;
   max-height: 60px;
 }
</style>