<template>
    <div>
        <div>
            <UserTopBar :user-name="infoCont.name"
                        :role="infoCont.rol"
                        :email="infoCont.email"
                        :user-image="infoCont.avatar"
                        :usepackage="infoCont.account_type_name"
                        :phone="infoCont.phone"
                        :company="infoCont.company"
            ></UserTopBar>
        </div>
        <ContulMeuTabs></ContulMeuTabs>
        <Setari></Setari>
    </div>
</template>

<script>
    import ContulMeuTabs from './components/ContulMeuTabs'
    import UserTopBar from './components/UserTopBar'
    import Setari from './components/Setari'

    export default {
        components: {
            ContulMeuTabs, UserTopBar, Setari
        },
        props: {},
        data() {
            return {
                infoCont: {
                    name: '',
                    rol: '',
                    email: '',
                    phone: '',
                    avatar: '',
                    account_type_name: '',
                    account_type_logo: '',
                    company: '',
                },
            };
        },
        mounted() {
            this.getInfoCont()
            this.$root.$on('userDataChanged', () => {
                this.getInfoCont()
            });
        },
        methods: {
            getInfoCont() {
                this.infoCont.name = this.$store.state.auth.user.name
                this.infoCont.rol = this.$store.state.auth.user.role
                this.infoCont.email = this.$store.state.auth.user.email
                this.infoCont.phone = this.$store.state.auth.user.phone
                this.infoCont.avatar = this.$store.state.auth.user.logo
                if(this.$store.state.auth.user.account_type){
                    this.infoCont.account_type_name = this.$store.state.auth.user.account_type.name
                    this.infoCont.account_type_logo = this.$store.state.auth.user.account_type.logo
                }
                this.infoCont.company = this.$store.state.auth.user.company
            }
        },
    };
</script>

<style scoped></style>