<template>
    <div class="popup">
        <div :class="[
            finantare.status === 'opened'?'gradinetBorder':'',
            finantare.status === 'pending'?'grayBorder':'',
            finantare.status === 'closed'?'redBorder':'',
            'arrow']"
        ></div>
        <h5 
            :class="[finantare.status === 'opened'?'gradient-text':'',
                     finantare.status === 'pending'?'grayColor':'',
                     finantare.status === 'closed'?'redColor':'']"
        >{{ title }}
        </h5>
        <p><span class="bold">Valoare proiect:</span>&nbsp;{{ finantare.min_budget.formatted +' - ' + finantare.max_budget.formatted }}</p>
        <p v-if="finantare.contribution[0] "><span class="bold">Contribuție proprie:</span>&nbsp;{{ finantare.contribution[0].min }}%</p>
        <p v-if="finantare.status === 'opened'" ><span class="bold">Depunere proiecte:</span>&nbsp;{{ getData }}</p>
        <p v-if="finantare.status === 'pending'" ><span class="bold">Depunere proiecte:</span>&nbsp;apel in lansare / consultare</p>
        <p v-if="finantare.status === 'closed'" class="redColor" ><span class="bold">Depunere proiecte:</span>&nbsp;apel inchis</p>
    </div>
</template>
<script>
    export default {
        props:{
            finantare: {type:Object,
                        required:true   },
            
        },
        data() {
            return {
                currentColor:'',
                colors:{
                    blue:'#5d3fcf',
                    gray:'#a6a6a6',
                    red:'#ff7272'
                },
            }
        },
        computed:{
            getData(){
                let startDate=new Date(this.finantare.start_date);
                let endDate=new Date(this.finantare.end_date);
                


                return  `${startDate.getDate()}.${startDate.getMonth()}.${startDate.getFullYear()} - ${endDate.getDate()}.${endDate.getMonth()}.${endDate.getFullYear()}`;
            },
            title(){
                
               
                
                return this.finantare.name;
            }
        },
        mounted() {
            this.dataColor()
            

        },
        methods:{
            dataColor()
            {   if(this.status === 'pending') this.currentColor =this.colors.gray
                if(this.status === 'started') this.currentColor =this.colors.blue
                if(this.status === 'ended') this.currentColor =this.colors.red
            }
        }
    }
</script>
<style scoped>
    
    .bold{
      font-weight: 800;
    }
    .arrow{
        border-left: 5px solid ;
        border-top: 5px solid ;
        position: absolute;
        width: 4vh;
        height: 4vh;
        top:-1.2vh;
        left: -1.2vh;
        border-radius: 5px;
        box-sizing: content-box;
    }
    .redColor{
        color:#ff7272;
    }
    .redBorder{
        border-color:#ff7272;
    }
    .grayColor{
        color: #a6a6a6;
    }
    .grayBorder{
        border-color:#a6a6a6;
    }
    .gradient-text{
    background: -webkit-linear-gradient(#6404cd, #4593d2);
	background-clip: text;
    -webkit-text-fill-color: transparent;
    }
    .gradinetBorder{
         border-color:#5d3fcf ;

        
    }
    .popup{
        width: 100%;
   
        padding: 1.9rem 0.6rem;
        color:#a6a6a6;
    }
    .popup p{
        margin: 0;
        font-weight: 500;
        text-align: start;
        font-size: var(--paragraphSize);
        font-family: "Montserrat";
    }
    .popup strong{
        color:black !important;
        font-weight: bold;
    }
    .popup h5{
        font-size: var(--moduleTitleSize);
        margin-top: -2vh;
        margin-bottom: 2vh;
        font-weight: bold;
    }
   @media(max-width:768px){
       
   }

</style>