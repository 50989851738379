<template>
  <div>
    <div class="flexed">
      <div class="video-top">
        <div class="videoWrapper">
          <iframe width="380" height="200" :src="data.link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <div class="video-info">
        <p class="video-title">{{ data.title }}</p>
        <p v-html="data.description"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YoutubeEmbed',
  props: {
      data: null
  },
  data() {
    return {
  }
  },
}
</script>

<style scoped>
.flexed{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.contact-rapid p{
  color: #fff;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 1px);
}
.video-info{
  background: #fff;
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.video-title{
  font-weight: 500;
  font-size: 14px;
}
@media only screen and (max-width: 1600px) {
  .flexed {
    display: block;
  }
}
</style>