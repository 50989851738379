<template>
    <div class="item-a">
        <div class="modul-finantare-title">
            <span class="bold-text">Descrierea finanțării</span>
            <div class="modul-finantare-text fixedHeight" v-html="data.description"></div>
        </div>
    </div>
</template>

<script>
    export default{
        props: {
            data: null,
        },
        data() {
            return {
                items: []
            }
        },
        methods: {},
    }
</script>

<style scoped>
    .fixedHeight {
        height: 230px;
        overflow-y: auto;
        padding-right: 10px;
    }
    .item-a {
        grid-area: descriere;
    }

    .modul-finantare-text >>> p,
    .modul-finantare-text >>> li {
        font-size: var(--paragraphSize);
        color: #868686;
    }
    .modul-finantare-text >>> ul {
        list-style: disc;
        padding-left: 15px;
    }
    .modul-finantare-text >>> h1, .modul-finantare-text >>> h2, .modul-finantare-text >>> h3, .modul-finantare-text >>> h4, .modul-finantare-text >>> h5 {
        font-size: var(--moduleTitleSize);
        margin-top: 10px;
        color: #868686;
    }

    .modul-finantare-title {
        background-color: #f8f8f8;
        color: #434343;
        box-sizing: border-box;
        padding: 20px;
        border: 1px solid #e7e7e7;
        box-shadow: 0px 2px 6px 1px #dddddd;
        height: 100%;
    }

    .bold-text {
        font-weight: bold;
        font-size: var(--moduleTitleSize);
    }

    .modul-finantare-content-no-background {
        background-color: #f0f0f0;
        font-size: 14px;
        color: #868686;
    }

    .modul-finantare-text {
        text-align: justify;
    }

    @media only screen and (max-width: 1250px) {
        .item-a {
            grid-area: descriere;
        }
    }
</style>