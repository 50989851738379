<template>
    <div>
        <div class="newsCategory">
            <div class="news-cat-logo">
                <img v-if="logo" :src="require('@/assets/images/' + logo + '.png')">

            </div>
            <h3>{{ title }}</h3>
            <div class="icon" @click="CategoryVisible"><b-icon-list-nested></b-icon-list-nested></div>
        </div>
    </div>
</template>

<script>
import {BIconListNested, BImg} from 'bootstrap-vue'
export default {
    components: {
        BIconListNested,
        BImg
    },
    props: {
        title: null,
        logo: {
            type: String,
            default: "userimage",
        },
    },
    data() {
        return {
            isVisible: true,
        };
    },
    created() {
    },
    mounted() {
    },
    methods: {
        CategoryVisible() {
            if(this.isVisible === true){
                this.isVisible = false
            } else if(this.isVisible === false){
                this.isVisible = true
            }
            this.$emit('isVisible', this.isVisible)
        }
    },
};
</script>

<style scoped>
.newsCategory{
    background: #fff;
    padding: 15px;
    display: flex;
    justify-content: space-between;
}
.newsCategory h3{
    color: #807c87;
    font-size: 14px;
    font-weight: 700;
    align-self: center;
}
.icon{
    color: #7a7a7a;
}
.news-cat-logo{
    min-height: 57px;
    display: flex;
    align-items: center;
}
</style>