<template>
    <transition name="modal-fade">
        <div class="modal-backdrop-info-popup" @click="$emit('close')">
            <div
                class="modal-info-popup"
                role="dialog"
                aria-labelledby="modalTitle"
                aria-describedby="modalDescription"
                @click.stop=""
            >
                <header id="modalTitle" class="modal-header">
                    <slot name="header"></slot>
                    <button
                        type="button"
                        class="btn-close"
                        aria-label="Close modal"
                        @click="$emit('close')"
                    >
                        x
                    </button>
                </header>

                <section id="modalDescription" class="modal-body">
                    <slot name="body"> Body </slot>
                </section>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "Modal",
    };
</script>

<style scoped>
	.modal-backdrop-info-popup {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.3);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 7;
	}

	.modal-info-popup {
		background: #ffffff;
		box-shadow: 2px 2px 20px 1px;
		overflow-x: auto;
		display: flex;
		flex-direction: column;
	}

	.modal-header,
	.modal-footer {
		padding: 15px;
		display: flex;
	}
    header{
        box-shadow: none;
    }

	.modal-header {
		position: relative;
		border-bottom: 0px;
		color: #4aae9b;
		justify-content: space-between;
	}

	.modal-footer {
		border-top: 1px solid #eeeeee;
		flex-direction: column;
		justify-content: flex-end;
	}

	.modal-body {
		position: relative;
		padding: 20px 10px;
    min-height: 300px;
    min-width: 300px;
        height: 100%;
	}


	.btn-close {
		position: absolute;
		top: 0;
		right: 0;
		border: none;
		font-size: 20px;
		padding: 10px;
		cursor: pointer;
		font-weight: bold;
		color: #4aae9b;
		background: transparent;
	}
	.modal-fade-enter,
	.modal-fade-leave-to {
		opacity: 0;
	}

	.modal-fade-enter-active,
	.modal-fade-leave-active {
		transition: opacity 0.5s ease;
	}
</style>