<template>
    <div>
        <div class="box-title">
            <p>Cardurile tale</p>
        </div>
        <div class="card">
            <div class="card-body grid-credit-card">
                
                <div class="box-credit-card">
                    <div class="d-flex justify-content-between">
                        <b-icon-check-circle-fill
                            font-scale="1.5"
                            class="border border-primary rounded-circle bg-danger"
                            style="fill: var(--succesColor);"
                        ></b-icon-check-circle-fill>
                        <p class="font-weight-bolder">Visa</p>
                    </div>
                    <p class="card-number mt-3">**** &nbsp; **** &nbsp; **** &nbsp; &nbsp; 2864</p>
                    <div class="card-data mt-3 d-flex justify-content-between">
                        <div class="d-flex">
                            <div class="mr-4">
                                <p class="text-data">EXPIRES</p>
                                <p>08/22</p>
                            </div>
                            <div>
                                <p class="text-data">CVV</p>
                                <p>186</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box-credit-card-white">
                    <div class="d-flex justify-content-between">
                        <b-icon-check-circle-fill font-scale="1.5" style="fill: var(--succesColor);"></b-icon-check-circle-fill>
                        <p class="font-weight-bolder text-blue">Visa</p>
                    </div>
                    <p class="card-number mt-3 text-blue">
                        **** &nbsp; **** &nbsp; **** &nbsp; &nbsp; 6835
                    </p>
                    <div class="card-data mt-3 d-flex justify-content-between">
                        <div class="d-flex">
                            <div class="mr-4">
                                <p class="text-grey">EXPIRES</p>
                                <p>06/21</p>
                            </div>
                            <div>
                                <p class="text-grey">CVV</p>
                                <p>123</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box-credit-card-white">
                    <div class="d-flex justify-content-between">
                        <b-icon-check-circle-fill font-scale="1.5" style="fill: var(--succesColor);"></b-icon-check-circle-fill>
                        <p class="font-weight-bolder text-blue">Visa</p>
                    </div>
                    <p class="card-number mt-3 text-blue">
                        **** &nbsp; **** &nbsp; **** &nbsp; &nbsp; 6835
                    </p>
                    <div class="card-data mt-3 d-flex justify-content-between">
                        <div class="d-flex">
                            <div class="mr-4">
                                <p class="text-grey">EXPIRES</p>
                                <p>06/21</p>
                            </div>
                            <div>
                                <p class="text-grey">CVV</p>
                                <p>123</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box-add-credit-card d-flex align-items-center justify-content-center">
                    <b-icon-plus-circle font-scale="4.5"></b-icon-plus-circle>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {BIconPlusCircle,BIconCheckCircleFill} from 'bootstrap-vue'
    
    
    export default {
        name: "Test",
        components:{
            BIconPlusCircle,
            BIconCheckCircleFill,
        },
        props: {},
        data() {
            return {};
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
	.box-title {
		background-image: linear-gradient(to right, #b9dbd9, #c3c2cd);
		height: 60px;
		border-top: 1px solid #d7d8de;
		display: flex;
		align-items: center;
	}
	.box-title p {
		font-weight: bold;
		font-size: 16px;
		color: #fff;
		padding-left: 30px;
	}
    .grid-credit-card{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(290px, auto));
		grid-gap: 20px;
		padding: 20px;
        justify-content: space-between;
	}
	.card {
		box-shadow: 0px 15px 19px -13px #dbdbdb;
		border-radius: 0px;
	}
	.bg-danger {
		background-color: #ffffff !important;
	}

	.box-credit-card {
		width: 290px;
		height: 155px;
		background: linear-gradient(290deg, #1a31c0 38%, #172b9f 1%);
		border-radius: 3px;
		padding: 10px 25px;
	}
	.box-credit-card p {
		color: #fff;
	}
	.card-number {
		font-size: 16px;
		font-weight: bold;
	}
	.card-data p {
		font-size: 12px;
	}
	.text-data {
		color: #a0a8d8 !important;
	}

	.box-credit-card-white {
		width: 290px;
		height: 155px;
		border: 1px solid #e1e1e1;
		background: #fff;
		border-radius: 3px;
		padding: 10px 25px;


	}
	.box-credit-card-white .text-blue {
		color: #0013a0;
	}
	.box-credit-card-white p {
		color: #3d3d3d;
	}
	.box-credit-card-white .text-grey {
		color: #dee0e5 !important;
	}

    .box-add-credit-card {
		width: 290px;
		height: 155px;
		border: 1px solid #e1e1e1;
		background: #fff;
		border-radius: 3px;
		padding: 10px 25px;
        cursor: pointer;
        color: #d7d6d7;
	}
    @media only screen and (max-width: 560px) {
        .grid-credit-card{
            grid-template-columns: repeat(auto-fit, minmax(100%, auto));
            justify-content: center;
        }

        .box-credit-card,
        .box-credit-card-white,
        .box-add-credit-card{
            height: auto;
            width: auto;
        }
        }
         @media only screen and (max-width: 900px) {
              .grid-credit-card{
            justify-content: center;
        }
         }

</style>
