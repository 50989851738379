<template>
    <div>
        <p class="info-text pb-2">CAEN secundare</p>
        <div>
            <div class="list-eligibilitati">
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp; Alte activitati</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
                <div class="list-item">
                    <span class="caen-secundar">0891</span><span class="caen-name"> &nbsp;-&nbsp;  Extracția turbei</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    data() {
        return {

        }
    },
}
</script>

<style scoped>
.info-text{
    color: #acacac;
    text-align: left;
    font-size: 13px;
}
.caen-secundar{
    font-size: 18px;
    color: #acacac;
    font-weight: 600;
}
.caen-name{
    font-size: 13px;
    color: #acacac;
    font-weight: 500;
}
.list-item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.list-eligibilitati{
    overflow-y: scroll;
    max-height: 485px;
}
::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(208, 208, 208, 0.38);
}
::-webkit-scrollbar
{
    width: 3px;
}
::-webkit-scrollbar-thumb
{
    background-color: rgba(208, 208, 208, 0.38);
    border: 2px solid #95a3cb;
}
</style>