<template>
    <div>
        <div class="news-header">
            <span>Întrebări și răspunsuri oficiale Ministerul Economiei</span>
        </div>
        <div class="news-body">
            <div class="col-md-12">
                <div class="row news-list">
                    <div class="col-md-3 col-sm-6 col-xs-12 news-info">
                        <img src="@/assets/images/stirem2.png">
                        <p>Situația privind înscrierile
                            şi plățile pentru Măsura 2
                            Granturi pentru Capital .</p>
                        <p class="date">14.05.2021</p>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-12 news-info">
                        <img src="@/assets/images/stirem2.png">
                        <p>Situația privind înscrierile
                            şi plățile pentru Măsura 2
                            Granturi pentru Capital .</p>
                        <p class="date">14.05.2021</p>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-12 news-info">
                        <img src="@/assets/images/stirem2.png">
                        <p>Situația privind înscrierile
                            şi plățile pentru Măsura 2
                            Granturi pentru Capital .</p>
                        <p class="date">14.05.2021</p>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-12 news-info">
                        <img src="@/assets/images/stirem2.png">
                        <p>Situația privind înscrierile
                            şi plățile pentru Măsura 2
                            Granturi pentru Capital .</p>
                        <p class="date">14.05.2021</p>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-12 news-info">
                        <img src="@/assets/images/stirem2.png">
                        <p>Situația privind înscrierile
                            şi plățile pentru Măsura 2
                            Granturi pentru Capital .</p>
                        <p class="date">14.05.2021</p>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-12 news-info">
                        <img src="@/assets/images/stirem2.png">
                        <p>Situația privind înscrierile
                            şi plățile pentru Măsura 2
                            Granturi pentru Capital .</p>
                        <p class="date">14.05.2021</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import AppCollapse from '@/common/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/common/components/app-collapse/AppCollapseItem.vue'
export default {
    components:{
        AppCollapse,
        AppCollapseItem
    },
    props: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.news-header{
    padding: 10px;
    border-bottom: 0;
    background: #f8f8f8;
}
.news-header span{
    font-size: 14px;
    font-weight: 800;
    background: -webkit-linear-gradient(#583fcf, #408cd3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.news-body{
    padding: 20px;
    background: #fff;
    max-height: 225px;
    overflow-y: scroll;
}
.news-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;
}
.news-info p{
    max-width: 90%;
}
.date{
    color: #5395d7;
    font-weight: 600;
    align-self: flex-start;
    padding-left: 10px;
}
@media (max-width:1100px){

}
</style>
