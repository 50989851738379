<template>
    <div>
        <div>
            <UserTopBar :user-name="this.infoCont.name"
                        :role="this.infoCont.rol"
                        :email="this.infoCont.email"
                        :user-image="this.infoCont.avatar"
                        :usepackage="this.infoCont.account_type_name"
                        :phone="this.infoCont.phone"
                        :company="this.infoCont.company"
            ></UserTopBar>
        </div>
        <ContulMeuTabs></ContulMeuTabs>
        <div v-if="!loading" class="contul-meu-background">
            <div class="formular-contul-meu">
                <div class="contulmeu-grid">
                    <div v-for="(setting, index) in settings" :key="index">
                        <h3>{{ setting.name }}</h3>
                        <div :class="setting.class">
                            <div v-for="(setting, idx) in setting.values" :key="idx" class="checkbox-container">
                                <div class="check_box">
                                    <input :id="setting.key" v-model="data[setting.key]" type="checkbox" true-value="1" false-value="0">
                                    <label :for="setting.key"></label>
                                </div>
                                <label class="nume-checkbox" :for="setting.key">{{ setting.name }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <b-button type="submit" class="buton-submit-setari-cont" variant="primary" @click="setSettings">Salvează
                    modificările
                </b-button>
            </div>
        </div>
        <div v-else class="loading">
            <LoadingSpinner></LoadingSpinner>
        </div>

    </div>
</template>

<script>
    import LoadingSpinner from '@/common/components/LoadingSpinner'
    import {BButton} from 'bootstrap-vue'
    import axios from '@/helpers/axios'
    import api from '@/helpers/api'
    import _ from 'lodash'
    import ContulMeuTabs from './ContulMeuTabs'
    import UserTopBar from './UserTopBar'

    export default {
        components: {
            LoadingSpinner, BButton, ContulMeuTabs, UserTopBar
        },
        data() {
            return {
                loading: true,
                data: {},
                settings: {},
                infoCont: {
                    name: '',
                    rol: '',
                    email: '',
                    phone: '',
                    avatar: '',
                    account_type_name: '',
                    account_type_logo: '',
                    company: '',
                },
            };
        },
        watch: {
            settings: {
                immediate: true,
                handler(settings) {
                    _.map(_.map(settings, 'values'), (values) => {
                        _.map(values, (setting) => {
                            this.data[setting.key] = setting.value
                        });
                    });
                }
            },
        },
        mounted() {
            this.getAccountSettings();
            this.getInfoCont()
        },
        methods: {
            getAccountSettings() {
                return axios.get(api.API_ENDPOINTS.userNotificationsSettings)
                    .then(response => {
                        this.settings = response.data;
                    }).finally(() => { this.loading = false })
            },
            setSettings() {
                axios.put(api.API_ENDPOINTS.userNotificationsSettings, {'settings': this.data})
                    .then((response) => {
                        this.$notify(response.data.message);
                    }).catch((xhr) => {
                        if (xhr.status === 422) {
                            this.errors.record(xhr.responseJSON.errors);
                        }

                        this.$notify(xhr.responseJSON.message);
                    });
            },
            getInfoCont() {
                this.infoCont.name = this.$store.state.auth.user.name
                this.infoCont.rol = this.$store.state.auth.user.role
                this.infoCont.email = this.$store.state.auth.user.email
                this.infoCont.phone = this.$store.state.auth.user.phone
                this.infoCont.avatar = this.$store.state.auth.user.logo
                if(this.$store.state.auth.user.account_type){
                    this.infoCont.account_type_name = this.$store.state.auth.user.account_type.name
                    this.infoCont.account_type_logo = this.$store.state.auth.user.account_type.logo
                }
                this.infoCont.company = this.$store.state.auth.user.company
            }
        }
    };
</script>

<style scoped>
.contul-meu-background {
    background-color: #f0f0f0;
    width: 100%;
    padding: 20px;
}
.formular-contul-meu {
    background-color: #f8f8f8;
    box-sizing: border-box;
    border: 1px solid #e7e7e7;
}
.loading{
    height: 150px;
    width: 100%;
}
.contulmeu-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
    padding: 25px;
    margin-bottom: 25px;
}
.setari-generale {
    display: flex;
}
.setari-notificari {
    display: flex;
    flex-direction: column;
}
h3 {
    font-weight: bold;
    font-size: 14px;
    color: #575757;
}
.buton-submit-setari-cont {
    color: #fff;
    background-color: #00dfc2;
    border-color: #00dfc2;
    font-size: var(--paragraphSize);
    font-weight: var(--bold);
    margin: 25px;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #00dfc2;
    border-color: #00dfc2;
}

/* checkbox */
.checkbox-container {
    display: flex;
}
.nume-checkbox {
    margin-left: 10px;
    color: #848484;
    cursor: pointer;
    font-size: 12px;
}
.check_box {
    display: flex;
    position: relative;
}
.check_box input[type="checkbox"] {
    display: none;
}
.check_box label {
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    border: 0px solid #f2f1f2;
    width: 20px;
    height: 20px;
    transition: all 0.1s ease;
    z-index: 2;
    box-shadow: 0px 0px 0px 2px #f2f1f2;
    display: block;
    background: white;
}
.check_box input[type="checkbox"]:checked ~ label {
    border: 5px solid white;
    background: #0c5ec2;
}
/* checkbox */
</style>
