<template>
    <div>
        <GreenTitleBar :data="title" ></GreenTitleBar>
        <div class="box-padding">
            <div class="evenimente-header pt-3">
                <div class="left-header">
                    <p>21.05.2021 - 27.05.2021</p>
                </div>
                <div class="right-header">
                    <div class="count-item">
                        <h1 class="greenNumber">2</h1>
                        <p>Finanțări ce vor fi lansate</p>
                    </div>
                    <div class="count-item">
                        <h1 class="redNumber">4</h1>
                        <p>Finanțări cu termen limită</p>
                    </div>
                    <div class="count-item">
                        <h1 class="grayNumber">8</h1>
                        <p>Finanțări cu termen valabil</p>
                    </div>
                    <div class="count-item">
                        <h1 class="yellowNumber">/</h1>
                        <p>Evenimente SMIS.ro</p>
                    </div>
                </div>
            </div>
            <p class="thisWeek">Săptămâna aceasta</p>

            <div class="evenimente-saptamana pt-4">
                <div class="evenimente-box mr-4">
                    <div class="eveniment">
                        <p class="zi-saptamana greenNumber">MIERCURI</p>
                        <p>22.05.2021</p>
                        <p class="descriere-saptamana">Lansare apel de proiecte “Granturi pentru vapital de lucru - POC 2014-2021 Axa 3.1 - A...</p>
                        <div class="icon-inima"><HeartFavorite></HeartFavorite></div>
                    </div>
                    <div class="eveniment">
                        <p class="zi-saptamana reenNumber">JOI</p>
                        <p>22.05.2021</p>
                        <p class="descriere-saptamana">Lansare apel de proiecte “Granturi pentru vapital de lucru - POC 2014-2021 Axa 3.1 - A...</p>
                        <div class="icon-inima"><HeartFavorite></HeartFavorite></div>
                    </div>
                    <div class="eveniment">
                        <p class="zi-saptamana redNumber">VINERI</p>
                        <p>22.05.2021</p>
                        <p class="descriere-saptamana">Depunere continua  proiecte “Granturi pentru vapital de lucru - POCU 4.15 ...</p>
                        <div class="icon-inima"><HeartFavorite></HeartFavorite></div>
                    </div>
                    <div class="eveniment">
                        <p class="zi-saptamana grayNumber">LU-DU</p>
                        <p>22.05.2021</p>
                        <p class="descriere-saptamana">Depunere continua  proiecte “Granturi pentru vapital de lucru - POCU 4.15 ...</p>
                        <div class="icon-inima"><HeartFavorite></HeartFavorite></div>
                    </div>
                    <div class="eveniment">
                        <p class="zi-saptamana yellowNumber">SAMBATA</p>
                        <p>22.05.2021</p>
                        <p class="descriere-saptamana">Depunere continua  proiecte “Granturi pentru vapital de lucru - POCU 4.15 ...</p>
                        <div class="icon-inima"><HeartFavorite></HeartFavorite></div>
                    </div>
                    <div class="eveniment">
                        <p class="zi-saptamana grayNumber">LU-DU</p>
                        <p>22.05.2021</p>
                        <p class="descriere-saptamana">Depunere continua  proiecte “Granturi pentru vapital de lucru - POCU 4.15 ...</p>
                        <div class="icon-inima"><HeartFavorite></HeartFavorite></div>
                    </div>
                </div>
                <div class="evenimente-box">
                    <div class="eveniment">
                        <p class="zi-saptamana grayNumber">LU-DU</p>
                        <p>-</p>
                        <p class="descriere-saptamana">Depunere continua  proiecte “Granturi pentru vapital de lucru - POCU 4.15 ...</p>
                        <div class="icon-inima"><HeartFavorite></HeartFavorite></div>
                    </div>
                    <div class="eveniment">
                        <p class="zi-saptamana grayNumber">LU-DU</p>
                        <p>-</p>
                        <p class="descriere-saptamana">Depunere continua  proiecte “Granturi pentru vapital de lucru - POCU 4.15 ...</p>
                        <div class="icon-inima"><HeartFavorite></HeartFavorite></div>

                    </div>
                </div>
            </div>

            <div class="calendar-evenimente pt-4">
                <div class="calendar-header">
                    <div class="vezi-lista">
                        <i class="fas fa-bars"></i>
                        <p>Listă</p>
                    </div>
                    <div class="legenda">
                        <div class="legenda-item">
                            <div class="bullet green"></div>
                            <p>Deschidere apel de proiecte</p>
                        </div>
                        <div class="legenda-item">
                            <div class="bullet red"></div>
                            <p>Termen limită depunere proiecte</p>
                        </div>
                        <div class="legenda-item">
                            <div class="bullet yellow"></div>
                            <p>Evenimente</p>
                        </div>
                    </div>
                </div>

                <div class="calendar">
                  <FullCalendar :options="calendarOptions"></FullCalendar>
<!--                  <full-calendar :config="config" :events="events"></full-calendar>-->
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import GreenTitleBar from "./components/GreenTitleBar";
    import HeartFavorite from '@/common/components/HeartFavorite';

    import '@fullcalendar/core/vdom' // solves problem with Vite
    import roLocale from '@fullcalendar/core/locales/ro'
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import interactionPlugin from '@fullcalendar/interaction'

    export default {
        components: {
            GreenTitleBar,
            HeartFavorite,
            FullCalendar
        },
        props: {},
        data() {
            return {
              title: 'Calendar evenimente',
              calendarOptions: {
                plugins: [ dayGridPlugin, interactionPlugin ],
                initialView: 'dayGridMonth',
                height: 700,
                locale: roLocale,
                eventClick: this.handleDateClick,
                events: [
                  {
                    title: 'event 1',
                    // date: '2021-08-01',
                    start: '2021-08-01',
                    end: '2021-08-03',
                    color: '#0cc2aa',
                    // url: '/toate-finantarile',
                  },
                  {
                    title: 'event 2',
                    id: '58752',
                    // date: '2021-08-01',
                    start: '2021-08-04',
                    end: '2021-08-08',
                    color: '#0cc2aa',
                    // url: '/toate-finantarile',
                  }
                ]
              }
            }
        },
      methods: {
        handleDateClick: function(arg) {
          console.log('date click! ', arg)
        }
      }
    }
</script>

<style>
.calendar .fc-toolbar.fc-header-toolbar{
  background: #679cbd;
  color: #fff;
  margin-top: 30px;
  position: relative;
  margin-bottom: 0 !important;
}
.calendar button.fc-today-button.fc-button.fc-state-default.fc-corner-left.fc-corner-right{
  position: absolute;
  left: 10%;
  top: 19%;
}
.calendar button.fc-next-button.fc-button.fc-state-default.fc-corner-right{
  position: absolute;
  right: 1%;
  top: 12%;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
}
.calendar button.fc-prev-button.fc-button.fc-state-default.fc-corner-left{
  position: absolute;
  left: 1%;
  top: 12%;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
}
.calendar button.fc-today-button.fc-button.fc-button-primary {
  color: #fff;
  background-color: #0cc2aa;
  border-color: #0cc2aa;
  font-size: var(--paragraphSize);
  font-weight: var(--bold);
  background-image: none;
}
.calendar button.fc-next-button.fc-button.fc-button-primary, button.fc-prev-button.fc-button.fc-button-primary{
  outline: none;
  box-shadow: none;
  background: none;
  border: none;
}
.calendar .fc-scroller.fc-day-grid-container{
  height: auto !important;
}
.calendar .fc-right{
  display: none;
}
.calendar h2.fc-toolbar-title{
  position: absolute;
  top: 16%;
  left: 45%;
  font-size: 20px !important;
  text-transform: capitalize;
}
.calendar button.fc-prev-button.fc-button.fc-button-primary{
  position: absolute;
  background: none;
  outline: none;
  border: none;
  left: 0%;
}
.calendar .fc .fc-button-group{
  position: inherit;
}
.calendar thead tr th{
  border: 0 !important;
  background: #e7e7e7;
  color: #6d6d6d;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0px !important;
}
.calendar button.fc-prev-button.fc-button.fc-button-primary:focus, button.fc-prev-button.fc-button.fc-button-primary:active{
  border: 0;
  outline: 0;
  box-shadow: none !important;
  background: none;
}
.calendar button.fc-next-button.fc-button.fc-button-primary:focus, button.fc-next-button.fc-button.fc-button-primary:active{
  border: 0;
  outline: 0;
  box-shadow: none !important;
  background: none;
}

@media only screen and (max-width: 1200px) {
  .calendar button.fc-today-button.fc-button.fc-state-default.fc-corner-left.fc-corner-right {
    display: none;
  }
}
</style>

<style scoped>
.box-padding {
  padding: 20px 70px 0px 20px;
}
.calendar{
  padding-bottom: 100px;
}
.evenimente-header, .right-header, .calendar-header, .legenda, .legenda-item, .vezi-lista{
  display: flex;
}
.calendar-header{
  align-items: center;
}
.vezi-lista{
  align-items: center;
  padding: 5px 10px;
  border-radius: 3px;
}
.count-item{
  display: flex;
  align-items: flex-end;
}
.count-item:not(:last-child){
  margin-right: 40px;
}
.count-item h1{
  margin: 0;
  font-size: 44px;
  font-weight: 900;
}
.icon-inima{
  text-align: left;
}
.icon-inima >>> svg{
  color: #727272 !important;
  font-size: 20px;
  margin-left: 0;
  margin-right: 10px;
}
.count-item p{
  font-size: 12px;
  color: #727272;
  max-width: 100px;
  text-align: left;
  margin-left: 15px;
}
.greenNumber{
  color: #0cc2aa;
}
.redNumber{
  color: #ff5c5c;
}
.grayNumber{
  color: #c4c4c4;
}
.yellowNumber{
  color: #ffc71d;
}
.green{
  background: #0cc2aa;
}


.red{
  background: #ff5c5c;
}
.yellow{
  background: #ffc71d;
}
.left-header p{
  font-size: 30px;
  color: #d7d7d7;
  font-weight: 900;
  margin-right: 100px;
}
.thisWeek{
  color: #747474;
  font-size: 15px;
  font-weight: 700;
}
.evenimente-saptamana{
  display: flex;
}
.eveniment{
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
  align-items: center;
}
.eveniment:not(:last-child){
  border-bottom: 1px solid #e7e7e7;
}
.eveniment p{
  font-size: 13px;
}
.evenimente-box{
  background-color: #ffff;
  color: #434343;
  padding: 10px;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 2px 6px 1px #dddddd;
  width: 45%;
}
.zi-saptamana{
  font-weight: 600;
  min-width: 65px;
  text-align: center;
}
.descriere-saptamana{
  width: 75%;
  text-align: center;
}
.vezi-lista, .legenda-item{
  margin-right: 50px;
}
.vezi-lista{
  background: #fff;
}
.vezi-lista p{
  color: #212121;
  font-weight: 600;
  margin-left: 15px;
}
.bullet{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 20px;
}
@media only screen and (max-width: 1200px) {
  .evenimente-header{
    display: block;
  }
  .right-header{
    display: block;
  }
  .left-header p{
    font-size: 19px;
    margin-right: 0;
    text-align: left;
    margin-bottom: 10px;
  }
  .count-item h1{
    font-size: 40px;
    min-width: 30px;
  }
  .thisWeek{
    margin-top: 10px;
  }
  .evenimente-saptamana, .eveniment{
    display: block;
  }
  .evenimente-box, .icon-inima, .eveniment p{
    width: 100%;
    text-align: center;
  }
  .icon-inima{
    margin-top: 10px;
  }
  .zi-saptamana{
    margin-bottom: 10px;
  }
  .box-padding {
  padding: 20px 20px 0px 20px;
  }
  .evenimente-box:not(:last-child){
    margin-bottom: 15px;
  }
  .vezi-lista{
    width: 100%;
    margin-bottom: 10px;
  }
  .legenda, .calendar-header{
    display: block;
  }
  .legenda-item{
    margin-bottom: 10px;
  }
}
</style>