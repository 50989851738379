<template>
    <div>
        <div>
            <div class="flexed">
                <div>
                    <img alt="Vue logo" src="@/assets/images/finantari-prezente.png">
                </div>
                <div class="finantari-prezente">
                    <p><span class="procent-finantari">{{ data }}</span> finanțări</p>
                    <p>prezentate detaliat în platforma Smis</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        data: null,
    },
    data() {
        return {}
    },
}
</script>

<style scoped>
.flexed {
    display: flex;
    align-items: center;
}

p {
    text-align: left;
}

.procent-finantari {
    font-size: 18px;
    font-weight: 700;
}

.finantari-prezente p {
    color: #fff;
    margin-left: 15px;
}

@media only screen and (max-width: 1600px) {
    .flexed {
        display: block;
    }
}
</style>