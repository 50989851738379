<template>
    <div>
        <div class="pasi">
            <div class="pas">
                <div class="bullet">1</div><span>Confirmarea serviciilor</span>
            </div>
            <div class="pas">
                <div class="bullet">2</div><span>Date de facturare</span>
            </div>
            <div class="pas">
                <div class="bullet">3</div><span>Modalitatea de plată</span>
            </div>
            <div class="pas">
                <div class="bullet bullet-active">4</div><span>Confirmarea plății</span>
            </div>
        </div>
        <div class="flex justify-content-between">
            <div class="total-plata">
                <div class="d-flex flex-column">
                    <span class="span-title flex justify-content-start yellow-text border-bottom-white">Total de plată</span>
                    <span class="sold-total price flex align-self-end pt-3">{{ Math.floor(getTotal()) }}<span class="price-small">,{{ parseInt(getTotal() % 1 * 100) }} lei</span></span>
                </div>
                <div class="d-flex flex-column">
                    <span class="span-title flex justify-content-start yellow-text border-bottom-white">Modalitatea de plată</span>
                    <b-form-radio-group 
                        :options="gateways"
                        :reduce="(option => option.id)"
                        text="label" 
                        v-model="form.payment_method"
                        class="modalitate-plata mt-3"
                        :clearable="false">
                    </b-form-radio-group>
                </div>
                <div class="w-100 text-center">
                    <img :src="gifRandom" class="random-gif">
                </div>

            </div>
            <div class="mesaj-centru">
                <div class="plata-op" v-if="form.payment_method === 'bank_transfer'">
                    <span class="multumire">{{mesajRandom}}</span>
                    <div class="download flex align-items-center justify-content-center">
                        <img src="@/assets/images/download-invoice.png" class="mr-2"><span>Descarcă factura</span>
                    </div>
                    <span>Îți mulțumim! Regăsești factura mai jos. Te rugăm să faci plata în contul menționat.</span>
                    <span class="yellow-text"><strong><br>Dacă achiți acum, te rugăm să atașezi OP mai jos. </strong></span>
                </div>
                <div class="plata-card" v-if="form.payment_method === 'mobilpay' && ! isRedirect">
                    <span v-if="payment.status && payment.status.type == 'success'" class="multumire">{{mesajRandom}}</span>
                    <span v-if="payment.status" class="multumire">{{payment.status.message}}</span>
                    <div v-if="payment.status && payment.status.type == 'success'" class="download flex align-items-center justify-content-center">
                        <img src="@/assets/images/download-invoice.png" class="mr-2"><span>Descarcă factura</span>
                    </div>
                </div>
                <div class="plata-card">
                    <div v-if="isRedirect" class="flex flex-column justify-content-center redirect-message">
                        <form ref="formPaymentRedirect" method="post" :action="redirect.url">
                            <input type="hidden" name="env_key" :value="redirect.env_key">
                            <input type="hidden" name="data" :value="redirect.data">
                        </form>
                        <span v-if="countDown > 0">Îți mulțumim! Vei fi redirecționat pe pagina procesatorului de plăți în</span>
                        <span v-if="countDown > 0" class="yellow-text" style="font-size: 28px;">
                            <strong v-html="countDown"></strong>
                            <span v-if="typeof countDown === 'number'" class="yellow-text"> secunde</span>
                        </span>
                        <span v-if="countDown == 0">Nu ai fost redirectionat?</span>
                        <div class="fake-input" v-if="countDown == 0">
                            <span @click.prevent="submitForm"><strong>Apasa aici</strong></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mesaje-random">

            </div>
        </div>
        <div class="flex justify-content-center">
            <b-button class="next-step" @click="$emit('closeModal')"><strong>Continuă navigarea</strong></b-button>
        </div>
    </div>
</template>

<script>

import {BFormRadioGroup, BFormRadio, BButton} from "bootstrap-vue";
import gifuri from "./gifuri.json";
import multumiri from "./multumiri.json";

export default {
    components: {
        BFormRadioGroup,
        BFormRadio,
        BButton,
    },

    props: ['data', 'invoiceType', 'checkout', 'redirect', 'payment'],

    data() {
        return {
            form: {
                business_invoice: '',
                invoice_company_id: '',
                new_company: false,
                company_id: '',
                plan_id: '',
                plan_type: '',
                payment_method: '',
                customer_email: '',
                customer_phone: '',
                billing: {
                    first_name: '',
                    last_name: '',
                    address: '',
                    state: '',
                    city: '',
                },
                business_id: '',
                company_name: '',
                reg_no: '',
                bank_name: '',
                bank_account: '',
                contact_person: '',
                contact_person_job: '',
                coupons: [],
                customer_sold: 0,
            },
            gifuri: gifuri,
            multumiri: multumiri,
            mesajRandom: null,
            gifRandom: null,
            countDown: 3
        }
    },

    mounted() {
        this.form = this.data.form
        this.randomGif()
        this.randomMultumire()
        this.countdown()
    },

    methods: {
        randomGif() {
            const random = Math.floor(Math.random() * this.gifuri.length);
            this.gifRandom = this.gifuri[random]
        },

        randomMultumire() {
            const random = Math.floor(Math.random() * this.multumiri.length);
            this.mesajRandom = this.multumiri[random]
        },

        countdown() {
            if(this.isRedirect && this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countdown()
                    if(this.countDown === 0) {
                        this.submitForm()
                    }
                }, 1000)
            }
        },

        submitForm() {
            this.$refs.formPaymentRedirect.submit()
        },

        getTotal() {
            if(this.payment.total) {
                return this.payment.total;
            }

            return (this.checkout.subtotal - this.form.customer_sold - this.sumOfCoupons).toFixed(2);
        }
    },

    computed: {
        sumOfCoupons() {
            return this.form.coupons.reduce((sum, coupon) => {
                return sum += coupon.discount;
            }, 0);
        },

        gateways() {
            if(this.payment) {
                return this.payment.gateways;
            }

            return this.checkout.gateways;
        }, 

        isRedirect() {
            return Object.keys(this.redirect).length != 0;
        },

        isCompleted() {
            return Object.keys(this.payment).length != 0;
        },
    },
}
</script>

<style scoped>
p, span, h1, h2, h3{
    color: #fff;
}
.pasi{
    display: flex;
    justify-content: space-between;
    padding-bottom: 70px;
}
.pas{
    display: flex;
    font-size: 14px;
}
.bullet{
    height: 20px;
    width: 20px;
    background: #fff;
    font-size: 14px;
    color: #343434;
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
}
.fake-input{
    display: flex;
    border: 1px solid #ffcc00;
    border-radius: 3px;
    align-items: center;
    justify-content: space-between;
    padding: 0.375rem 0.75rem;
    cursor: pointer;
}
.fake-input span{
    color: #fff;
    margin: 0;
    font-size: 15px;
}
.bullet-active{
    background: #ffcc00;
}
.yellow-text{
    color: #ffcc00;
}
.span-title{
    font-size: 24px;
}
.total-plata, .mesaje-random{
    width: 23%;
}
.mesaj-centru{
    text-align: center;
}
.multumire{
    font-size: 18px;
}
.flex{
    display: flex;
}
.mesaj-centru{
    width: 50%;
}
.price-small{
    font-size: 20px;
    line-height: 40px;
}
.border-bottom-white{
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
}
.price{
    font-size: 28px;
    font-weight: 700;
    line-height: 30px;
}
.sold-total{
    font-size: 35px;
}
.modalitate-plata >>> .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #ffcc00 !important;
    border-color: #ffcc00 !important;
}
.modalitate-plata >>> label{
    color: #fff;
    font-weight: 400;
    font-size: 14px;
}
.random-gif{
    max-height: 141px;
    max-width: 265px;
    width: 100%;
    object-fit: cover;
    margin-top: 10px;
    border: 1px solid #fff;
}
.download{
    padding: 90px 0;
}
.next-step{
    background: #ffcc00;
    color: #272727;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid #ffcc00;
    margin: 40px 0 0;
}
.plata-card{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.mesaj-card{
    height: 50%;
    align-items: center;
}
.redirect-message{
    text-align: center;
    align-items: center;
    font-size: 18px;
}
@media (max-width: 1000px){
    .flex{
        display: block;
    }
    .pasi{
        display: block;
    }
    .pas{
        justify-content: center;
        margin-bottom: 10px;
    }
    .total-plata, .mesaj-centru{
        width: 100%;
    }
    .mesaj-centru{
        padding-top: 30px;
    }
}
</style>