<template>
    <div id="chenarGradient">
        <div>
            <h1>Toate finanțările</h1>
        </div>
        <div class="flexClass spaceBetween bigMargin info-platforma">
            <div>
                <p><strong>Analizăm zilnic pentru tine peste 56 de surse de informare publice și guvernamentale.</strong></p>
                <p>Ai acces la filtre avansate și funcționalități extinse. Noi suntem SMIS - și suntem alături de tine!</p>
            </div>
            <div>
                <p v-if="fundingsSum" class="textRight">
                    Total finanțări prezentate în platformă:<br>
                    <span class="totalMoney">
                        {{ fundingsSum.toLocaleString('ro-RO') }} €
                    </span>
                </p>
            </div>	
        </div> 
        <div class="flexClass spaceBetween poza-finantarii ">
            <div class="flexClass middleAlign numar-finantari">
                <div class="flexClass">
                    <p class="bigText">{{ resultCount }}</p>
                </div>
                <div>
                    <p>Finanțări<br>prezentate</p>
                </div>
            </div>
            <div class="flexClass middleAlign numar-finantari">
                <div>
                    <p class="bigText">{{ resultCountOpened }}</p>
                </div>
                <div>
                    <p>Finanțări<br>cu apel de proiecte deschis</p>
                </div>
            </div>
            <div class="flexClass middleAlign numar-finantari">
                <div>
                    <p class="bigText">8</p>
                </div>
                <div>
                    <p>Finanțări<br>start-ups</p>
                </div>
            </div>
            <div class="poza-finantari-container">
                <img src="@/assets/images/ToateFinantarileTopLogos.png">
            </div>
        </div>          
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            resultCount: null,
            resultCountOpened: null,
            fundingsSum: null,
        },
        data() {
            return {

            };
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
h1,
p{
	color:#fff;
  font-size: 14px;
}
h1,
p{
    margin-bottom: 0;
}
h1{
	font-size: 20px;
	margin-bottom: 0;
}
#chenarGradient{
	width:100%;
	background: linear-gradient(0deg,#01c9a1, #7ba6ac,#6887aa);
	padding: 15px 30px;
	box-sizing: border-box;  
}
.bigText{
	font-size: 40px;
	font-weight: 600;
	margin-right: 5px;
}
.flexClass{
	display: flex;
}
.totalMoney{
  font-weight: 600;
  font-size: 18px;
}
.flexClass div{
	flex-direction: row;
}
.spaceBetween{
	justify-content: space-between;
}
.textRight{
	text-align: right;
}
.middleAlign{
	align-items: center;
}
.middleAlign p{
	line-height: 13px;
}
.bigMargin{
	margin:25px 0;
}
.poza-finantarii img{
    object-fit: contain;
}
@media (max-width:768px){
    .poza-finantarii{
        display: block;
            
    }
   .count-finantari{
       justify-content: space-evenly;
       width:100% ;
       margin-bottom: 2rem;
   }
}
@media(max-width:900px){
    .poza-finantari-container img {
       display: block;
       width:280px;
       
    }
    .info-platforma{
        display: block;
    }
    .textRight{
        text-align: start;
    }
    .count-finantari{
        display: block;
    }
    .numar-finantari{
        display: flex;
        margin: 1.5rem 0;
    }
    
}
</style>
