<template>
    <div id="filters" class="flexClass spaceBetween">             
        <div>
            <b-form-checkbox
                v-model="selected.arataCompanii"
                switch                    
                size="sm"
                @change="$emit('filterConsultanti',selected)"
            >Arată doar companii</b-form-checkbox>
        </div>  
        <div>
            <b-form-checkbox
                v-model="selected.companiiVerificate"
                switch                    
                size="sm"
                @change="$emit('filterConsultanti',selected)"
            >Arată doar companii verificate</b-form-checkbox>
        </div>           
        <div>
            <div>
                <b-form-select v-model="selected.judet" :options="judete" @change="$emit('filterConsultanti',selected)"></b-form-select>
            </div>
        </div> 
        <div>
            <div>
                <b-form-select v-model="selected.servicii" :options="servicii" @change="$emit('filterConsultanti',selected)"></b-form-select>
            </div>
        </div> 
        <div>
            <div>
                <p>Ce reprezintă o &nbsp;<img src="@/assets/images/CompanieVerificata.png" alt="">&nbsp; de către echipa SMIS?</p>
            </div> 
        </div>
        <div>
            <div>&nbsp;</div>
        </div>
    </div>
</template>

<script>

    import { BFormCheckbox, BFormSelect} from 'bootstrap-vue'
    import api from '@/helpers/api';
    import axios from '@/helpers/axios';

    export default {          
        components: {
            BFormCheckbox,
            BFormSelect,
        },   
        props: {
            ststusView: Boolean
        },
             
        data() {
            return {
                selected: {
                    judet: 'all',
                    servicii: 'all',
                    companiiVerificate: false,
                    arataCompanii:false,
                },
                defaultSelect: {
                    judet: 'all',
                    servicii: 'all',
                    companiiVerificate: false,
                    arataCompanii:false,
                },
                judete: [],
                servicii: [],
            };
        },
        watch:{
            'selected.judet':function () {
                if(JSON.stringify(this.defaultSelect) === JSON.stringify(this.selected)){
                    this.$emit('noFilter');
                }
            },
            'selected.servicii':function () {
                if(JSON.stringify(this.defaultSelect) === JSON.stringify(this.selected)){
                    this.$emit('noFilter');
                }
            },
            'selected.companiiVerificate':function () {
           
                if(JSON.stringify(this.defaultSelect) === JSON.stringify(this.selected)){
                    this.$emit('noFilter');
                }
            },
            'selected.arataCompanii':function () {
           
                if(JSON.stringify(this.defaultSelect) === JSON.stringify(this.selected)){
                    this.$emit('noFilter');
                }
            },
        },
        created() {
            this.getServices();
            
        },
        methods: {
            getServices(){
                axios.get(api.API_ENDPOINTS.conultantsServices)
                    .then(response => {
                        this.servicii = response.data.services;
                        this.selected.servicii = this.servicii[0].value
                        this.judete = response.data.counties;
                        this.selected.judet = this.judete[0].value
                        this.handleUrlParameters();
                    }).catch(err => {
                        console.log(err);
                    }) 
            },
            handleUrlParameters(){
                if(!(this.$route.query && Object.keys(this.$route.query).length===0 &&  this.$route.query.constructor === Object)) {
                    let urlQuery = this.$route.query;
                    
                    if(urlQuery['doar-companii'] ==="da" && urlQuery['doar-companii']) {
                        this.selected.arataCompanii = true;
                    }else{
                        this.selected.arataCompanii = false;
                    }
                    
                    if(urlQuery['companii-verificate'] ==="da" && urlQuery['companii-verificate']) {
                        this.selected.companiiVerificate = true;
                    }else{
                        this.selected.companiiVerificate = false;
                    }
                    
                    if(urlQuery.judet) {
                        this.selected.judet = urlQuery.judet;
                    }else{
                        this.selected.judet = this.judete[0].value;
                    } 
                    
                    if(urlQuery.serviciu) {
                        this.selected.servicii = urlQuery.serviciu.replace(/-/g, ' ');
                    } else {
                        this.selected.servicii = this.servicii[0].value
                    }

                    this.$emit('filterConsultanti',this.selected)

                }
            }
        },
    };
</script>

<style>

#filters select{
    color: #a1a1a1;
    margin-bottom: 5px;
}
#filters label, #filters p{
    color: #696969;
    line-height: 20px;
}
#filters label{
    margin: 5px 0;
}
#filters .flexClass{
	display: flex;
}
#filters .flexClass div{
	flex-direction: row;
}
#filters.spaceBetween{
	justify-content: space-between;
}
#filters{
    padding: 10px 30px 5px 30px;
    box-sizing: border-box; 
    background: #fff;
    display: flex;
    align-items: center;
}
#filters .flexClass div
{
    line-height: 45px;
}
#filters label, #filters *{
    font-size: 12px !important;
}
#filters .custom-select{
    width: 370px !important;
} 
@media only screen and (max-width: 1440px) {
    #filters .custom-select{
        width: 100% !important;
    } 
}
@media(max-width:500px){
    #filters{
        display: block;
    }
}
</style>
