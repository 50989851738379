<template>
    <div class="news-wrapper flex p-0">
        <div class="content-news">
            <div class="news-content" v-html="data.description"></div>
            <StireComments></StireComments>
        </div>
        <div class="sidebar-single">
            <div class="label-list">
                <p class="cat-title">Surse finanțare</p>
                <ul class="one-column">
                    <li v-for="(funder, key) in labels.funders" :key="key"><span class="label" :style="{ 'background': funder.color }">{{funder.acronym }}</span></li>
                </ul>
            </div>
            <div class="label-list">
                <p class="cat-title">Programe</p>
                <ul>
                    <li v-for="(programs, key) in labels.programs" :key="key"><span class="label" :style="{ 'background': programs.color }">{{programs.acronym }}</span></li>
                </ul>
            </div>
            <div class="recent-posts">
                <p class="cat-title">Știri recente</p>
                <ul class="one-column">
                    <li class="recent-news">
                        <img src="@/assets/images/stire-recenta.png">
                        <div class="recent-info">
                            <p class="title">Recenta 1</p>
                            <p class="date">38 Februarie / Admin</p>
                        </div>
                    </li>
                    <li class="recent-news">
                        <img src="@/assets/images/stire-recenta.png">
                        <div class="recent-info">
                            <p class="title">Recenta 1</p>
                            <p class="date">38 Februarie / Admin</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import StireComments from '../components/StireComments';
export default {
    components: {
        StireComments,
    },
    props: {
        data: null,
        labels: null,
    },
    data() {
        return {};
    },
    methods: {},
};
</script>

<style scoped>
.title{
    color: #4b4e53;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
.date{
    font-size: 12px;
}
.flex{
    display: flex;
    justify-content: space-between;
}
.content-news{
    width: 70%;
}
.sidebar-single{
    width: 25%;
}
.recent-news{
    display: flex;
    margin-top: 10px;
}
.recent-info{
    margin-left: 15px;
    text-align: left;
    width: 100%;
}
.cat-title {
    font-size: 16px;
    color: #4b4e53;
    text-transform: uppercase;
    font-weight: 600;
}
.label {
    padding: 0px 8px;
    height: fit-content;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    white-space: pre;
}
.label-list li:not(:last-child){
    margin-right: 10px;
}
.label-list:not(:first-child) {
    margin-top: 20px;
}
ul {
    display: flex;
    flex-wrap: wrap;
}
.news-wrapper {
    width: 100%;
    max-width: 1300px;
    margin: auto;
    padding-top: 30px !important;
    display: flex;
    height: 100%;
}
.news-content >>> p {
    font-size: 14px;
    color: #807c87;
}
.news-content >>> a {
    font-size: 12px;
    color: #0cc2aa;
}
.news-content >>> h1 {
    font-size: 22px;
    color: #807c87;
    padding: 10px 0;
}
.news-content >>> h2 {
    font-size: 20px;
    color: #807c87;
    padding: 10px 0;
}
.news-content >>> h3 {
    font-size: 18px;
    color: #807c87;
    padding: 10px 0;
}
.news-content >>> h4 {
    font-size: 16px;
    color: #807c87;
    padding: 10px 0;
}
@media (max-width:1000px){
    .news-wrapper{
        display: block;
    }
    .content-news, .sidebar-single{
        width: 100%;
    }
    .content-news{
        padding: 0 20px;
    }
    .sidebar-single{
        padding: 0 20px;
        margin-top: 30px;
    }

}
</style>