<template>
    <div>
        <div>
            <div class="flexed">
                <div>
                    <p class="finantari-active"><span class="procent-finantari">{{ data }}</span> utilizatori Smis</p>
                    <p>Tu ești unul dintre ei. Îți super mulțumim!</p>
                </div>
                <div>
                    <div class="icon">
                        <font-awesome-icon :icon="['fas', 'user-friends']"></font-awesome-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faUserFriends} from '@fortawesome/free-solid-svg-icons'

library.add(faUserFriends)
export default {
    components: {
        FontAwesomeIcon
    },
    props: {
        data: null
    },
    data() {
        return {}
    },
}
</script>

<style scoped>
.flexed {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

p {
    text-align: left;
}

.procent-finantari {
    font-size: 18px;
    font-weight: 700;
}

.finantari-active {
    font-weight: 500;
}

.icon {
    background: #a88add;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fa-user-friends {
    color: #fff;
    font-size: 13px;
}

@media only screen and (max-width: 1600px) {
    .flexed {
        display: block;
    }
}
</style>