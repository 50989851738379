<template>
    <div>
        <div v-if="isOpen === false" class="companie" @click="bigSmall">
            <span class="LogoName">
                <img class="companie-imagine-mica" src="@/assets/images/LogoFormare.png" alt="" >
                <p class="nume-companie-mica">{{ nume }}</p>
            </span>
            <b-icon-check-circle-fill
                v-if="eligibility === true"
                class="icon-companie-mica"
                style="fill: var(--succesColor);"
                font-scale="1.5"
            ></b-icon-check-circle-fill>
            <b-icon-question-circle-fill
                v-else-if="eligibility === null"
                class="icon-companie-mica"
                variant="secondary"
                font-scale="1.5"
            ></b-icon-question-circle-fill>
            <b-icon-x-circle-fill
                v-else-if="eligibility === false"
                class="icon-companie-mica"
                style="fill: var(--dangerColor);"
                font-scale="1.5"
            ></b-icon-x-circle-fill>
        </div>
        <div v-else class="companie-mare" @click="bigSmall">
            <div class="companie-mare-margin">
                <img class="companie-imagine-mare" src="@/assets/images/LogoFormare.png" alt="" >
                <div class="companie-eligibilitate">
                    <p class="nume-companie-mare">{{ nume }}</p>
                    <b-icon-check-circle-fill
                        v-if="eligibility === true"
                        class="icon-companie-mica"
                        style="fill: var(--succesColor);"
                        font-scale="1.5"
                    ></b-icon-check-circle-fill>
                    <b-icon-question-circle-fill
                        v-else-if="eligibility === null"
                        class="icon-companie-mica"
                        variant="secondary"
                        font-scale="1.5"
                    ></b-icon-question-circle-fill>
                    <b-icon-x-circle-fill
                        v-else-if="eligibility === false"
                        class="icon-companie-mica"
                        style="fill: var(--dangerColor);"
                        font-scale="1.5"
                    ></b-icon-x-circle-fill>
                </div>
                <div v-for="(tab, index) in rules" :key="index">
                    <p>
                        <b-icon-check-circle-fill
                            v-if="tab.value === true"
                            style="margin-right: 10px; fill: var(--succesColor);"
                            font-scale="1"
                        ></b-icon-check-circle-fill>
                        <b-icon-question-circle-fill
                            v-else-if="tab.value === null"
                            style="margin-right: 10px"
                            variant="secondary"
                            font-scale="1"
                        ></b-icon-question-circle-fill>
                        <b-icon-x-circle-fill
                            v-else-if="tab.value === false"
                            style="margin-right: 10px; fill: var(--dangerColor);"
                            font-scale="1"
                        ></b-icon-x-circle-fill>
                        <!--                        <b-icon-circle-fill
                            v-else-if="tab.value === 4"
                            style="margin-right: 10px"
                            variant="warning"
                            font-scale="1"
                        ></b-icon-circle-fill>-->
                        <span>{{ tab.name }}</span>
                    </p>
                </div>
                <div class="icon">
                    <b-icon-exclamation-triangle-fill
                        v-if="eligibility === true"
                        style="fill: var(--succesColor);"
                        font-scale="1.5"
                    ></b-icon-exclamation-triangle-fill>
                    <b-icon-exclamation-triangle-fill
                        v-if="eligibility === null"
                        style="fill: var(--warningColor);"
                        font-scale="1.5"
                    ></b-icon-exclamation-triangle-fill>
                    <b-icon-exclamation-triangle-fill
                        v-if="eligibility === false"
                        style="fill: var(--dangerColor);"
                        font-scale="1.5"
                    ></b-icon-exclamation-triangle-fill>
                </div>
                <div>
                    <p class="text-final">
                        Platforma SMIS realizează automat profilul companiei utilizând date
                        disponibile prin Ministerul de Finante. Analiza este una orientativă, de aceea
                        vă recomandăm să analizați personal ficare situație în parte.
                        <span>Vă mulțumim pentru înțelegere!</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        BIconCheckCircleFill,
        BIconExclamationTriangleFill,
        BIconXCircleFill,
        BIconQuestionCircleFill
    } from 'bootstrap-vue'
    export default {
        components: {
            BIconCheckCircleFill,
            BIconExclamationTriangleFill,
            BIconXCircleFill,
            BIconQuestionCircleFill

        },
        props: {
            nume: {
                type: String,
                default: null,
            },
            eligibility: {
                type: Boolean,
                default: null,
            },
            rules: {
                type: Array,
                default: null,
            }
        },
        data() {
            return {
                isOpen: false,

            };
        },
        created() {},
        methods: {
            bigSmall() {
                if (this.isOpen === false) {
                    this.isOpen = true;
                } else {
                    this.isOpen = false;
                }
            },
        },
    };
</script>

<style scoped>
	.companie {
		display: flex;
		background-color: #fff;
		align-items: center;
		min-height: 55px;
		border-radius: 5px;
		cursor: pointer;
        overflow-wrap: anywhere;
	}
	.companie-mare {
		display: flex;
		background-color: #fff;
		align-items: center;
		border-radius: 5px;
		cursor: pointer;
        overflow-wrap: anywhere;
	}
	.companie-mare-margin {
		margin: 20px;
	}
	.nume-companie-mica {
		border-left: 1px solid #eeeff0;
		margin-left: 30px;
		padding: 10px;
		font-size: var(--paragraphSize);
		text-align: initial;
		color: #929292;
	}
	.nume-companie-mare {
		padding: 10px 0;
		font-size: var(--paragraphSize);
		text-align: initial;
		color: #929292;
	}
	.companie-eligibilitate {
		display: flex;
		align-items: center;
	}
	.companie-imagine-mica {
		max-width: 100px;
		margin-left: 10px;
	}
	.icon-companie-mica {
		margin: 0px 10px 0px auto;
	}
	.companie-imagine-mare {
		max-width: 160px;
	}
	p {
		margin: 0;
	}
	.text-final {
		font-size: 11px;
		color: #929292;
	}
	.icon {
		margin: 30px 0 10px 0;
	}
    .LogoName{
            display: flex;
            align-items: center;
        }

@media only screen and (max-width: 700px) {
    .companie{
        margin-bottom: 10px;
    }
}

    @media only screen and (max-width: 530px) {
        .LogoName{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
        .nume-companie-mica{
            margin-left: 0;
            border-left: none;
        }
        .companie-imagine-mica{
            margin-top: 20px;
        }
    }
</style>
