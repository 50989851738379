<template>
    <div>
        <div v-if="isOpen === false" class="companie" @click="bigSmall">
            <div class="companie-head">
                <div class="info">
                    <p v-if="consultant.name" class="head-title">{{ consultant.name }}</p>
                    <b-form-rating id="rating-lg-no-border " :value="consultant.rating" color="#ffbe00" no-border
                                   size="sm" readonly
                    ></b-form-rating>
                    <p class="head-adress">{{ consultant.city }}</p>
                </div>
                <div class="image">
                    <img class="companie-imagine-mica" :src="consultant.logo" alt="">
                </div>
            </div>
        </div>
        <div v-else class="companie-mare">
            <div class="companie-mare-margin">
                <div class="companie-eligibilitate" @click="bigSmall">
                    <div class="companie-head">
                        <div class="info">
                            <p v-if="consultant.name" class="head-title">{{ consultant.name }}</p>
                            <b-form-rating id="rating-lg-no-border " :value="consultant.rating" color="#ffbe00"
                                           no-border size="sm" readonly
                            ></b-form-rating>
                        </div>
                        <div class="image">
                            <img class="companie-imagine-mica" :src="consultant.logo" alt="">
                        </div>
                    </div>
                    <img v-if="consultant.is_verified && !consultant.is_basic" src="@/assets/images/CompanieVerificata.png" alt="" class="mt-3">
                    <div v-if="consultant.is_center" class="flexed mt-3"><p>Centru de formare acreditat &nbsp;</p><img
                        src="@/assets/images/authorized-icon.png" alt=""
                    ></div>
                </div>
                <div class="companie-body">
                    <div class="mt-4">
                        <p>Adresa: {{ consultant.city }}</p>
                        <p>{{ consultant.address }}</p>
                    </div>
                    <div class="mt-2">
                        <p>Email: {{ consultant.email }}</p>
                        <p>{{ consultant.phone }}</p>
                    </div>
                    <router-link :to="'/consultant/'+consultant.id" class="vezi-detalii"><p>Vezi detalii</p></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {BFormRating} from 'bootstrap-vue'

    export default {
        name: "Test",
        components: {
            BFormRating
        },
        props: {
            nume: {
                type: String,
                default: null,
            },
            tip: {
                type: Number,
                default: 1,
            },
            consultant: null,
        },
        data() {
            return {
                isOpen: false,
                review: {
                    stars: 4.5,
                },
            };
        },
        created() {
        },
        methods: {
            bigSmall() {
                this.isOpen = !this.isOpen;
            },
        },
    };
</script>

<style>
.b-rating .b-rating-star{
    max-width: 22px;
}
</style>
<style scoped>
.vezi-detalii p{
    background: -webkit-linear-gradient(#6404cd, #4593d2);
    -webkit-background-clip: text;
    margin-top: 5px;
}
.companie {
    background-color: #fff;
    align-items: center;
    min-height: 55px;
    padding: 20px;
    cursor: pointer;
    overflow-wrap: anywhere;
    border-radius: 3px;
    border: 1px solid #c9c9c9;
}

.companie-head {
    display: flex;
    justify-content: space-between;
}
.image img{
    max-height: 60px;
}
.image{
    display: flex;
    align-items: center;
    justify-content: center;
}
.companie-mare {
    background-color: #fff;
    align-items: center;
    cursor: pointer;
    overflow-wrap: anywhere;
    border-radius: 3px;
    border: 1px solid #c9c9c9;
}

.companie-mare-margin {
    margin: 20px;
    width: -webkit-fill-available;

}

.nume-companie-mare {
    padding: 10px 0;
    font-size: 14px;
    text-align: initial;
    color: #929292;
}

.companie-imagine-mica {
}

.companie-imagine-mare {
    max-width: 160px;
}

p {
    margin: 0;
}

.form-control-sm {
    padding: 0;
    height: inherit;
}

.head-adress {
    font-size: 12px;
    margin-top: 10px;
    text-transform: uppercase;
}

.head-title {
    font-weight: 700;
    color: #373737;
    min-height: 36px;
}

.companie-body p {
    font-size: 12px;
    color: #606060;
}

.flexed {
    display: flex;
    align-items: center;
}

.flexed img {
    margin: 0;
}

.flexed p {
    color: #12b58c;
    font-size: 12px;
}

@media only screen and (max-width: 500px) {
    .companie-head {
        flex-direction: column-reverse;
    }

    .d-flex {
        display: inline-block !important;
        max-width: 150px;
    }
}
</style>
