<!-- eslint-disable -->
<template>
  <!-- eslint-disable -->
  <div class="hartaConsultantilor">
    <HeaderHarta></HeaderHarta>
    <div id="harta">
      <l-map
        style="height: 100%; width: 100%"
        :zoom="zoom"
        :minZoom="7"
        :center="center"
        :bounds="bounds"
        :max-bounds="maxBounds"
        :options="{ zoomControl: false }"
        v-if="!loading"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        @update:bounds="boundsUpdated"
      >
        <l-tile-layer :url="url"></l-tile-layer>
        <l-polygon :lat-lngs="rectacgle.coordinates" :weight="rectacgle.weight" color="gray" fill-color="#f0f0f0" :fill-opacity="1"></l-polygon>

        <l-control position="topright">
          <button @click="openFullscreen" class="btn-map">
            <i :class="fullscreen ? 'fas fa-compress' : 'fas fa-expand'"></i>
          </button>
        </l-control>
        <l-control-zoom position="topright"></l-control-zoom>
        <l-marker
          v-if="consultantiFormatati.length>0 "
          v-for="(consultant,index) in consultantiFormatati"
          :key="consultant.index"
          :lat-lng="consultant.coordinates[0] && consultant.coordinates[0] ? consultant.coordinates : [20,20]"
          :icon="DefaultIcon"
        > 
           <l-icon
           v-if="!consultant.is_basic"
            :icon-size="dynamicSize"
            :icon-anchor="dynamicAnchor"
            :icon-url="chooseConsultantIcon(consultant)"
            :popup-anchor="[230,10]"

          ></l-icon>
          <l-icon
            v-else
            :icon-size="dynamicSize"
            :icon-anchor="dynamicAnchor"
            :popup-anchor="[230,10]"

          >
          <i v-if="consultant.is_basic" :style="{color:consultant.services[0] ? consultant.services[0].color : '#3D3F40' }" class="fas fa-circle"></i>
          </l-icon>
          <l-popup>
            <PopupHarta :consultant="consultant"></PopupHarta>
          </l-popup>
        </l-marker>
      </l-map>
        <LoadingSpinner v-else></LoadingSpinner>
    </div>
    <div class="trademark">
      <img src="@/assets/images/gray_smis_icon.png" alt="smis" />
      <span>Toate drepturile rezervate STRUCTURAL MANAGEMENT SYSTEM 2021</span>
    </div>
    <div class="footer">
      <ul class="legend1">
        <li class="companiiDeConultantaLegend">
          <div>
            <img
              src="@/assets/images/big_buiding_icon.png"
              alt=""
              style="height:30px; width:20px;"
            />
            <img
              src="@/assets/images/small_building_icon.png"
              alt=""
              style="height:20px; width:20px; margin-left:-10px;"
            />
          </div>

          <span>
            Companii consultanta specializata in implementarea proiectelor
            siservicii conexe,cu birouri la nivel multi-regional.<br />Servicii
            variate:financiar,juridic,contabil,etc.
          </span>
        </li>
        <li>
          <span>
            <img
              src="@/assets/images/companie_consultanta_icon.png"
              alt=""
              style="height:20px; width:25px;"
            />
            Companii specializate in consultanta-proiecte cu finantare
            nerambursabila
          </span>
        </li>
        <li>
          <img
            src="@/assets/images/centre_formare_icon.png"
            alt=""
            style="height:20px; width:30px;"
          />
          <span>
            Centre de formare profesionala in management de proiect si finantari
            europene
          </span>
        </li>
      </ul>
      <ul class="legend2">
        <li>
          <i class="fas fa-circle" style="color:#626d92;"></i>Consultanti
          proiecte cu finantare nerambursabila
        </li>
        <li>
          <i class="fas fa-circle" style="color:#44cacf;"></i>Consultanti in
          domeniul juridic/avocati
        </li>
        <li>
          <i class="fas fa-circle" style="color:#c70039;"></i>Auditori Proiecte
        </li>
        <li>
          <i class="fas fa-circle" style="color:#ff5733;"></i> Evaluatori
          proiecte
        </li>
        <li>
          <i class="fas fa-circle" style="color:#ffab33;"></i>Consultant
          infintari firme
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
    import HeaderHarta from "@/pages/Consultanti/HartaConsultanti/componets/HeaderHarta.vue";
    import PopupHarta from "@/pages/Consultanti/HartaConsultanti/componets/PopupHarta.vue";
    import "leaflet/dist/leaflet.css";
    import L from "leaflet";
    import centreDeFormare from "@/assets/images/centre_formare_icon.png";
    import CompanieConultanta from '@/assets/images/big_buiding_icon.png'
    import companieConultantaNerambursabil from "@/assets/images/companie_consultanta_icon.png"
    import api from '@/helpers/api'
    import axios from '@/helpers/axios'
    import icon from '@/assets/images/SmisLogo.png';
    import iconShadow from '@/assets/images/SmisLogo.png';
    import regiuni from '@/pages/Finantari/HartaFinantarilor/components/Regiuni.json';
    import LoadingSpinner from '@/common/components/LoadingSpinner'



    import {
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LPopup,
        LControlZoom,
        LControl,
        LPolygon,
    } from "vue2-leaflet";

    export default {
        name: "HartaConsultanti",
        components: {
            LoadingSpinner,
            HeaderHarta,
            LMap,
            LTileLayer,
            LMarker,
            LIcon,
            LPopup,
            PopupHarta,
            LControlZoom,
            LControl,
            LPolygon
        },
        props: {},
        data() {
            return {
                fullscreen: false,
                url:
                    "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
                zoom: 7,
                center: [45.920587, 27.756958],
                bounds: null,
                maxBounds: L.latLngBounds([
                    [43.529549935614595, 15.072509765625004],
                    [48.59538740833338, 35.36425781250001],
                ]),
                DefaultIcon : L.icon({
                    iconUrl: icon,
                    shadowUrl: iconShadow
                }),
                consultanti:[],
                consultantiFormatati:[],
                centreDeFormare:centreDeFormare,
                companieConultanta:CompanieConultanta,
                companieConultantaNerambursabil:companieConultantaNerambursabil,
                smisIcon:icon,
                rectacgle:{
                    coordinates:[[[50.474896, 42.876606],[0.801497, 42.288689],[0.543529, 11.293907],[50.763539, 11.253209]],regiuni[10].contur],
                    weight:0,
                    color:'gray'
                },
                loading: true,

            };
        },
        computed: {
            dynamicSize() {
                return [this.iconSize, this.iconSize * 1.15];
            },
            dynamicAnchor() {
                return [this.iconSize / 2, this.iconSize * 1.15];
            },
        },
        mounted(){
            this.getConsultants();
        },
        methods: {
            getConsultants() {
                return  axios.get(api.API_ENDPOINTS.consultantiHarta)
                    .then(response => {
                        this.consultanti = response.data
                        this.formatConsultants()
                    }).finally(() => this.loading = false)
            },
            formatConsultants(){
                this.consultanti.forEach(consultant => {
                    this.consultantiFormatati.push({
                        name:consultant.name,
                        city:consultant.city,
                        address:consultant.address,
                        email:consultant.email,
                        phone:consultant.phone,
                        is_basic:consultant.is_basic,
                        rating:consultant.rating,
                        logo:consultant.logo,
                        is_verified:consultant.is_verified,
                        is_trainer:consultant.is_trainer,
                        coordinates:[Number(consultant.coordinates[0]),Number(consultant.coordinates[1])],
                        services:consultant.services
                    });
                    
                    if(consultant.offices){
                        consultant.offices.forEach(office =>{
                            this.consultantiFormatati.push({
                                name:consultant.name,
                                city:office.city,
                                address:office.address,
                                email:office.email,
                                phone:office.phone,
                                coordinates:[Number(office.long),Number(office.lat)],
                                is_basic:consultant.is_basic,
                                rating:consultant.rating,
                                logo:consultant.logo,
                                is_verified:consultant.is_verified,
                                is_trainer:consultant.is_trainer,
                                services:consultant.services
                            });
                        })
                    }
                });
            },
            chooseConsultantIcon(consultant){    
                if(consultant.is_trainer){
                    return this.centreDeFormare
                }
                
                if(consultant.is_verified && consultant.logo){
                    return consultant.logo;
                }
               
                if(consultant.services.length>0){
  
                    if(consultant.services.length>1){
                        return this.companieConultanta
                    }
                    let serviceNerabursabil =  consultant.services.filter(service =>{
                        return service.id === 0
                    })
                   
                    if(serviceNerabursabil){
                        return this.companieConultantaNerambursabil
                    }
                }
                return this.smisIcon; 
            },
            openFullscreen() {
                const elem = document.getElementById("harta");
                if (!this.fullscreen) {
                    if (elem.requestFullscreen) {
                        elem.requestFullscreen();
                    } else if (elem.webkitRequestFullscreen) {
                        /* Safari */
                        elem.webkitRequestFullscreen();
                    } else if (elem.msRequestFullscreen) {
                        /* IE11 */
                        elem.msRequestFullscreen();
                    }
                    this.fullscreen = true;
                } else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitExitFullscreen) {
                        /* Safari */
                        document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) {
                        /* IE11 */
                        document.msExitFullscreen();
                    }
                    this.fullscreen = false;
                }
            },
            zoomUpdated(zoom) {
                this.zoom = zoom;
            },
            centerUpdated(center) {
                this.center = center;
            },
            boundsUpdated(bounds) {
                this.bounds = bounds;
            },
        },
    };
</script>
<style scoped>
.hartaConsultantilor {
  background-color: #f0f0f0;
  width: 100%;

}
#harta{
    height: 56%;
}

.footer {
  display: flex;
  background-color: #f6f6f6;
  width: 100%;
  padding-top: 1rem;
  padding-left: 1rem;
}
.footer ul {
  border: 1px #d8d8d8 solid;
  border-radius: 5px;
  background-color: white;
  margin-right: 2vw;
}

.legend1 {
  font-size: 10px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 1rem 1.5rem 1rem 0.5rem;
}
.legend1 div {
  display: inline-flex;
  align-items: flex-end;

  padding-left: -10px;
}
.companiiDeConultantaLegend span{
  display: inline-block;
}
.legend1 li {
  padding: 8px;
}

.legend1 li img {
  margin-right: 10px;
}
.legend2 {
  display: flex;
  flex-flow: column wrap;
  font-size: 10px;
  justify-content: center;
  padding-right: 20px;
  padding-left: 10px;
}
.legend2 li i {
  padding-right: 15px;
}
.trademark {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 1rem;
}
.trademark img {
  height: 25px;
  width: 65px;
}
.trademark span {
  font-size: 0.5rem;
  color: #b2b2b2;
}
.btn-map {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  display: block;
  background-color: #e0e0e0;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: none;
}
@media(max-width:768px){
  #harta{
    height: 50%;
  }
}
@media(max-width:500px){
  
    .footer{
        display: block;
        padding-left:0.4rem;
        padding-right: 0.4rem;
    }
    .legend1{
        margin: 0.5rem 0;
       
    }
}
@media(min-height:1000px){
    .footer{
        position: fixed;
        bottom: 0;
    }
    
    
    
    #harta{
        height:63% ;
    }
}

@media(min-height:1500px){
    #harta{
        height: 69%;
    }
}
@media(min-height:1950px){
    #harta{
        height: 69%;
    }
}

</style>
<style  >
.hartaConsultantilor .leaflet-popup-content-wrapper {
  height: 200px;
  width: 400px;
  border-radius: 0;
}

.hartaConsultantilor .leaflet-popup-content p {
  margin: 0;
  font-size: 12x;
  font-weight: 300;
}
.fullscreenButton {
  height: 30px;
  width: 30px;
  border: 1px solid rgb(204, 204, 204);
  background-color: #f0f0f0;
}
.hartaConsultantilor .leaflet-control-zoom-in,
.hartaConsultantilor .leaflet-control-zoom-out {
  margin-top: 0.5vh !important;
  margin-bottom: 1vh !important;
  display: block !important;
  background-color: #e0e0e0 !important;
  height: 35px !important;
  width: 35px !important;
  border-radius: 50% !important;
  border: none !important;
}
.hartaConsultantilor .leaflet-control-zoom-out {

  text-align: center;
  line-height: 40px !important;
}
.hartaConsultantilor .leaflet-touch .leaflet-control-layers,
.hartaConsultantilor .leaflet-touch .leaflet-bar {
  border: none !important;
}
.hartaConsultantilor .leaflet-top {
  margin-right: 10vw;
  margin-top: 5vh;
}

.hartaConsultantilor .leaflet-marker-pane img {
  max-height: 20px !important;
}

.hartaConsultantilor .leaflet-marker-icon {
  width: auto !important;
  height: auto !important;
  max-height: 20px;
}
.hartaConsultantilor .leaflet-container .leaflet-marker-pane img,
.hartaConsultantilor .leaflet-container .leaflet-shadow-pane img {
  max-width: none !important;
  max-height: 20px !important;
}

</style>
