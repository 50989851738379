<template>
    <div class="flexed" v-if="statut_vechime">
        <div class="flexed-profit">
            <div class="statut">
                <p class="info-text">Statut</p>
                <p class="section-title">{{statut_vechime.statut.toLowerCase()}} TVA</p>
            </div>
            <div>
                <p class="info-text">Vechime firma</p>
                <p class="section-title">{{statut_vechime.vechime.toLowerCase()}}</p>
            </div>
            <div>
                <img src="@/assets/images/vechime-firma.png">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        statut_vechime: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
        }
    },
}
</script>

<style scoped>
.info-text{
    color: #878588;
    text-align: left;
    font-size: 13px;
}
.section-title {
    font-size: 14px;
    color: #878588;
    font-weight: 600;
}
.statut .section-title{
    text-transform: capitalize;
}
.flexed {
    display: flex;
    align-items: center;
}
.flexed-profit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
p {
    text-align: left;
}

@media only screen and (max-width: 1600px) {
    .flexed {
        display: block;
    }
}
</style>