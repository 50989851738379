<template>
    <div>
        <b-form>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4">
                            <b-form-group
                                label="Website"
                            >
                                <b-form-input
                                    v-model="data.website"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group
                                label="Facebook"
                            >
                                <b-form-input
                                    v-model="data.facebook_link"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group
                                label="Instagram"
                            >
                                <b-form-input
                                    v-model="data.instagram_link"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <b-form-group
                                label="Youtube"
                            >
                                <b-form-input
                                    v-model="data.youtube_link"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group
                                label="Twitter"
                            >
                                <b-form-input
                                    v-model="data.twitter_link"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group
                                label="Linkedin"
                            >
                                <b-form-input
                                    v-model="data.linkedin_link"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
            <b-button @click="onSubmit()" class="buton-submit-setari-cont" variant="primary">Salvează modificările</b-button>
        </b-form>
    </div>
</template>

<script>
import {BForm, BFormGroup, BFormInput, BFormTextarea, BButton} from 'bootstrap-vue'
export default {
    components: {
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
    },
    props: {
        data: [],
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        onSubmit() {
            this.$emit('submitForm');
        }
    }
}
</script>

<style scoped>
.padding-box{
    padding: 20px;
}
input, textarea{
    border-radius: 0;
    border: 1px solid #e8e8e8;
}
input:focus, textarea:focus{
    box-shadow: none;
}
.buton-submit-setari-cont {
    color: #fff;
    background-image: linear-gradient(180deg, #5b3cd0, #3bc6d4);
    border: none;
    font-size: var(--paragraphSize);
    font-weight: var(--bold);
}
</style>