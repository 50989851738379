<template>
  <div class="modul-finantare-title">
    <span class="bold-text gradient-title">Utile</span>
    <p class="info-text">BUSINESS CONSULTANTS SYSTEM</p>
    <b-carousel
        class="carousel-center"
        id="feedbackSlider"
        :interval="4000"
        :speed="2000"
        indicators
    >

      <b-carousel-slide>
        <div class="slide">
          <div>
            <div class="angajat">
              <img src="@/assets/images/imagineUtile.png" alt="">
            </div>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide>
        <div class="slide">
          <div>
            <div class="angajat">
              <img src="@/assets/images/imagineUtile.png" alt="">
            </div>
          </div>
        </div>
      </b-carousel-slide>

    </b-carousel>
  </div>
</template>

<script>
import {BCarouselSlide, BCarousel} from 'bootstrap-vue'
export default {
  components: {
    BCarousel,
    BCarouselSlide,
  },
}
</script>

<style scoped>
.bold-text {
  font-weight: bold;
}
.gradient-title{
    font-size: 14px;
  color: #1c1c1c;
}

.carousel-item{
  height: 200px;
  background: transparent;
}
#feedbackSlider >>> li {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid #666666;
}
#feedbackSlider >>> .carousel-indicators .active {
  background: #666666;
}
#feedbackSlider >>> .carousel-indicators {
  left: inherit;
  transform: rotate(90deg);
  margin: 0;
  bottom: inherit;
  top: 40%;
}
#feedbackSlider >>> .carousel-caption {
  position: relative;
  width: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#feedbackSlider >>> .carousel-item-next:not(.carousel-item-left), .active.carousel-item-right {
  transform: translateY(100%);
}
#feedbackSlider >>> .carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left{
  transform: translateY(-100%);
}
.slide .form-control {
  background-color: transparent !important;
  max-width: 115px;
}

.slide p{
  font-style: italic;
  font-size: 12px;
}
.angajat{
  display: flex;
  justify-content: flex-start;
}
.angajat img{
  max-width: 80%;
  height: 100%;
}
.info-text{
  text-transform: uppercase;
  font-size: 12px;
}
</style>