<template>
    <div>
        <div class="box">
            <div>
                <p class="first-text">
                    Bine ai venit pe <span style="font-weight: 900"> Smis</span>®
                </p>
                <p class="second-text">Înregistrare cont nou</p>
            </div>
            <div>
                <b-form @submit.prevent="registerMethod">
                    <b-form-group
                        id="name-group"
                        label="Nume Complet"
                        label-for="name"
                        class="w-100"
                    >
                        <b-form-input id="name" v-model="register.name" type="text" placeholder="Nume prenume"
                                      class="w-100"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        id="phone-group"
                        label="Telefon"
                        label-for="phone"
                        class="w-100"
                    >
                        <b-form-input id="phone" v-model="register.phone" type="tel" placeholder="0722222222"
                                      class="w-100"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        id="email-group"
                        label="Email"
                        label-for="email"
                        class="w-100"
                    >
                        <b-form-input id="email" v-model="register.email" type="email" placeholder="john@email.com"
                                      class="w-100"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        id="password-group"
                        label="Parolă"
                        label-for="password"
                        class="w-100 position-relative"
                    >
                        <b-form-input
                            id="password"
                            v-model="register.password"
                            :type="passwordFieldType"
                            placeholder="*******"
                            class="w-100"
                        ></b-form-input>
                        <span
                            toggle="#password-field"
                            class="fa fa-fw fa-eye field-icon toggle-password"
                            @click="switchVisibility"
                        ></span>
                    </b-form-group>
                    <b-form-group
                        id="confirm-password-group"
                        label="Confirma Parolă"
                        label-for="confirm-password"
                        class="w-100"
                    >
                        <b-form-input id="confirm-password" v-model="register.password_confirmation" type="password"
                                      placeholder="*********" class="w-100"
                        ></b-form-input>
                    </b-form-group>
                    <div class="checkbox-field">
                        <input
                            id="checkbox"
                            class="checkbox"
                            type="checkbox"
                            style="margin-right: 5px"
                        >
                        <label for="checkbox">Sunt de acord cu
                            <span class="text-blue">termenii și condițiile</span></label>
                    </div>
                    <div>
                        <b-button
                            type="submit"
                            variant="primary"
                            block
                        >Înregistrare cont
                        </b-button>
                        <!--<ButtonGradient-->
                        <!--type="submit"-->
                        <!--class="buton-register"-->
                        <!--url-buton="dadsdsss"-->
                        <!--text="Înregistrare cont"-->
                        <!--classname="idenviz"-->
                        <!--&gt;</ButtonGradient>-->
                    </div>
                </b-form>

                <div class="logare">
                    <p class="logare">
                        Ai deja un cont?
                        <router-link class="text-blue" to="/login"> Logare </router-link>
                    </p>
                </div>
            </div>
        </div>
        <div class="logo-mic">
            <img alt="LogoSmisMicLogin" src="@/assets/images/LogoSmisMicLogin.png">
        </div>
    </div>
</template>

<script>
    import {BForm, BFormGroup, BFormInput, BButton, BSpinner} from 'bootstrap-vue';
    import ButtonGradient from "../../../common/components/ButtonGradient";
    import api from '@/helpers/api'
    import {startSession} from '@/helpers/auth'
    import axios from '@/helpers/axios'

    export default {
        components: {
            ButtonGradient,
            BForm,
            BFormGroup,
            BFormInput,
            BButton,
            BSpinner
        },
        props: {},
        data() {
            return {
                loading: false,
                confirmMessage: 'Adresa de email trebuie verificată! Te rugăm să o verifici accesând email-ul în secțiunea inbox sau spam.',
                register: {
                    name: '',
                    email: '',
                    phone: '',
                    password: '',
                    password_confirmation: ''
                },
                passwordFieldType: "password",
            };
        },
        created() {
        },
        methods: {
            switchVisibility() {
                this.passwordFieldType =
                    this.passwordFieldType === "password" ? "text" : "password";
            },
            registerMethod() {
                this.loading = true
                startSession().then(() => {
                    axios
                        .post(api.API_ENDPOINTS.register, this.register)
                        .then(response => {
                            this.$notify(response.data.message);
                            this.loading = false
                            this.$store.dispatch('auth/logoutForVerification').then(() => {

                            })
                            this.$router.push({
                                params: {id: this.confirmMessage},
                                path: api.LOGIN_ROUTE
                            })
                        })
                        .catch(xhr => {
                            if (xhr.response.status === 422) {
                                this.errors.record(xhr.response.message);
                            }
                            this.$notify(xhr.response.data.message);
                        })
                });    
            },
        },
    };
</script>

<style scoped>
    .box {
        display: flex;
        flex-direction: column;
        padding: 0 40px;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    label {
        margin-bottom: 2px;
        font-size: 14px;
        color: #818181;
    }

    input:not([type="checkbox"]) {
        margin-bottom: 10px;
        width: initial;
        line-height: 30px;
        border-radius: 5px;
        border: 1px solid #e7e1e7;
        padding-left: 10px;
        color: #818181;
    }

    input:focus {
        outline: none !important;
        border: 1px solid #6793ce;
    }

    input::placeholder {
        color: #cac5d4;
    }

    .first-text {
        font-size: 22px;
        font-weight: 600;
        background: -webkit-linear-gradient(#262728, #4e5359);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: left;
    }

    .second-text {
        font-size: 18px;
        margin: 30px 0 20px 0;
        color: #818181;
        text-align: left;
    }

    .form-field {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
    }

    .checkbox-field {
        display: flex;
        flex-direction: row;
        margin-right: auto;
        align-items: center;
    }

    .field-icon {
        position: absolute;
        z-index: 2;
        cursor: pointer;
        right: 15px;
        bottom: 18px;
    }

    .buton-register {
        margin-top: 35px;
    }

    .logo-mic {
        position: absolute;
        bottom: 40px;
        right: 50px;
    }

    .logare {
        text-align: center;
        margin-top: 20px;
    }

    .text-blue {
        color: #4d87f1;
    }

    @media (max-width: 1200px) {
        .second-text, form, .logare {
            color: #fff;
        }

        .first-text {
            background: -webkit-linear-gradient(#ffffff, #efefef);
            -webkit-background-clip: text;
        }
    }

    @media (max-width: 800px) {
        .second-text, form, .logare {
            font-size: 14px;
            margin: 0;
        }

        .first-text {
            background: -webkit-linear-gradient(#ffffff, #efefef);
            -webkit-background-clip: text;
            font-size: 17px;
        }

        .second-text {
            margin-bottom: 5px;
        }

        .form-group {
            margin: 0;
        }

        .form-control {
            height: 33px;
        }

        .logo-mic img {
            max-width: 18px;
        }

        .buton-register {
            margin-top: 15px;
        }
    }

    @media (max-width: 330px) {
        .second-text, form, .logare {
            font-size: 12px;
        }

        .first-text {
            font-size: 15px;
        }

        .form-control {
            height: 28px;
        }

        label {
            margin: 0 !important;
        }
    }
</style>

<style>
    @media (max-width: 330px) {
        .date-user label {
            margin: 0;
        }
    }
</style>
