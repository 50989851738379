<template>
    <div>
        <b-modal
                v-model="openModalShow"
                size="xl"
                ok-variant="success"
                ok-title="Confirmă"
                cancel-variant="danger"
                cancel-title="Anulează"
                hide-header-close
                header-bg-variant="dark"
                body-bg-variant="dark"
                hide-footer
                hide-header
                @hide="closeModal"
                v-bind:id="closedTemp ? 'opacityNone' : 'checkoutModal'"
        >
            <div class="modal-body">
                <img src="@/assets/images/smisPay.png" class="pay-logo">
                <div class="modal-header">
                    <span>Plată servicii</span>
                </div>
                <div class="modal-pas">
                    <Pas1Checkout @tempCloseModal="tempCloseModal($event)" @invoiceTypeChanged="invoiceTypeChanged()" @stepActive="nextStep($event)"
                                  v-if="stepActive === 1" :invoiceType="invoiceType" :checkout="checkout" :newCompanyAdded="newCompanyAdded" :planId="planId"></Pas1Checkout>
                    <Pas2Checkout @stepActive="nextStep($event)" v-if="stepActive === 2" :data="pasData" :invoiceType="invoiceType" :checkout="checkout"></Pas2Checkout>
                    <Pas3Checkout @tempCloseModal="tempCloseModal($event)" @stepActive="nextStep($event)"
                                  v-if="stepActive === 3" :data="pasData" :invoiceType="invoiceType" :checkout="checkout"></Pas3Checkout>
                    <Pas4Checkout v-if="stepActive === 4" :data="pasData" @closeModal="closeModal" :invoiceType="invoiceType" :checkout="checkout" :redirect="redirect" :payment="payment"></Pas4Checkout>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {BModal} from 'bootstrap-vue';
    import Pas1Checkout from './components/Pas1Checkout';
    import Pas2Checkout from './components/Pas2Checkout';
    import Pas3Checkout from './components/Pas3Checkout';
    import Pas4Checkout from './components/Pas4Checkout';
    import Errors from '@/helpers/Errors';
    import axios from '@/helpers/axios';
    import api from '@/helpers/api';

    export default {
        components: {
            BModal,
            Pas1Checkout,
            Pas2Checkout,
            Pas3Checkout,
            Pas4Checkout,
        },

        props: ['modalShow', 'invoiceType', 'planId', 'newCompanyAdded'],

        data() {
            return {
                reversed: false,
                closedTemp: false,
                creatingPayment: false,
                stepActive: 1,
                pasData: [],
                checkout: {},
                errors: new Errors(),
                redirect: [],
                payment: {}
            };
        },

        mounted() {
            this.getPayment()
        },

        computed: {
            openModalShow: {
                get() {
                    return this.modalShow
                },
                
                set(modalShow) {
                    return modalShow
                }
            }
        },

        methods: {
            closeModal() {
                this.$emit('closeModal')
            },

            tempCloseModal(event) {
                this.closedTemp = event
                this.getCheckout()
            },

            invoiceTypeChanged() {
                this.$emit('invoiceTypeChanged')
                console.log('invoiceTypeChanged')
            },

            nextStep(event) {
                if(event.submit) {
                    this.postCheckout(event)
                } else {
                    this.stepActive = event.pageNumber
                    if (event.form !== null) {
                        this.pasData = {
                            'form': event.form
                        }
                    }
                }
            },

            getCheckout() {
                if (this.$route.query.orderId || Object.keys(this.payment).length != 0) {
                    return;
                }

                return axios.get(api.API_ENDPOINTS.checkout + '/' + this.planId + '/' + this.invoiceType)
                    .then((response) => {
                        this.checkout = response.data;
                        this.stepActive = 1;
                    }).catch((xhr) => {
                        this.$notify(xhr.response.data.message);
                    });
            },

            postCheckout(event) {
                if (this.creatingPayment) {
                    return;
                }

                this.creatingPayment = true;
                let form = event.form;
                form.plan_id = this.planId;
                form.plan_type = this.invoiceType;
                form.return = this.$route.path.replace('/', '');

                return axios.post(api.API_ENDPOINTS.payments, form)
                    .then((response) => {
                        if (response.data.url) {
                            this.redirect = response.data;
                            this.stepActive = 4;
                        } else {
                            this.confirmPayment(response.data.paymentId, form.payment_method);
                        }
                    }).catch((xhr) => {
                        if (xhr.response.status === 422) {
                            this.errors.record(xhr.response.data.errors);
                        }

                        this.$notify(xhr.response.data.message);

                        this.creatingPayment = false;
                    }).then(() => {
                        if (this.errors.any() == false && event.form !== null) {
                            this.stepActive = event.pageNumber
                            this.pasData = {
                                'form': event.form
                            }
                        }
                    });
            },

            confirmPayment(paymentId, paymentMethod) {
                return axios.get(api.API_ENDPOINTS.payments + '/' + paymentId + '/complete/' + paymentMethod)
                    .then((response) => {
                        this.stepActive = 4;
                        this.$notify(response.data.message);
                    }).catch((xhr) => {
                        this.creatingPayment = false;

                        this.deletePayment(paymentId);
                        this.$notify(xhr.response.data.message);
                    });
            },

            getPayment() {
                if (! this.$route.query.orderId) {
                    return;
                }

                return axios.get(api.API_ENDPOINTS.payments + '/complete', {
                    params: {
                        orderId: this.$route.query.orderId
                    }
                }).then((response) => {
                    this.payment = response.data
                    this.stepActive = 4;
                    this.pasData = {
                        'form': {
                            payment_method: response.data.payment_method
                        }
                    }
                    this.$emit('openModal')
                });
            },

            deletePayment(paymentId) {
                if (! paymentId) {
                    return;
                }

                return axios.get(api.API_ENDPOINTS.payments + '/' + paymentId + '/payment-canceled');
            },
        },

        watch: {
            'planId': function () {
                this.getCheckout();
            },

            'invoiceType': function () {
                this.getCheckout();
            },
        }
    };
</script>

<style>
    #checkoutModal .modal-content {
        background-color: transparent;
    }

    #checkoutModal .modal-xl {
        max-width: 1640px;
    }

    #checkoutModal___BV_modal_backdrop_ {
        background-color: transparent !important;
    }

    #opacityNone .modal-dialog {
        opacity: 0 !important;
    }

    #checkoutModal .modal-dialog .bg-dark {
        background-color: rgba(21, 20, 20, 0.85) !important;
        border: rgba(21, 20, 20, 0.7) !important;
        backdrop-filter: blur(7px);
        position: relative;
        max-height: 830px;
        overflow: auto;
        height: 85vh;
        padding: 30px;
    }

    #checkoutModal .modal-dialog .opacityNone {
        background-color: rgba(21, 20, 20, 0.00) !important;
        border: none !important;
        position: relative;
    }

    #checkoutModal .modal-dialog {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .modal-content {
        border: 2px solid #fff;
    }
</style>

<style scoped>
    .modal-body {
        padding: 0;
    }

    .modal-body > .pay-logo {
        position: absolute;
        top: 0;
        left: 0;
    }

    .modal-header span {
        font-size: 36px;
        font-weight: 700;
        color: #fff;
    }

    .modal-header {
        display: flex;
        justify-content: center;
        padding: 0;
        position: relative;
    }

    .modal-header:before {
        content: ' ';
        width: 50%;
        height: 1px;
        position: absolute;
        bottom: -10%;
        background: #fff;
    }

    .modal-pas {
        padding: 0px 50px;
        margin-top: 20px;
    }

    @media (max-width: 700px) {
        .modal-header {
            padding-top: 70px;
        }
    }
</style>