<template>
    <div class="padding">
        <div class="d-flex flex-column align-right">
            <span class="content-title">Achiziție</span>
            <p>Vă mulțumim! Ați ales varianta <span class="label green">free</span></p>
        </div>
        <div class="functii mt-4">
            <span class="section-title">Ai acces la următoarele funcții gratuite</span>
            <ul class="mt-3">
                <li class="functie">
                    <b-icon-check-circle-fill
                        style="fill: var(--succesColor); margin-right: 15px;"
                        font-scale="1.1"
                    ></b-icon-check-circle-fill>
                    <span>Functie 1</span>
                </li>
                <li class="functie mt-1">
                    <b-icon-check-circle-fill
                        style="fill: var(--succesColor); margin-right: 15px;"
                        font-scale="1.1"
                    ></b-icon-check-circle-fill>
                    <span>Functie 1</span>
                </li>
                <li class="functie mt-1">
                    <b-icon-check-circle-fill
                        style="fill: var(--succesColor); margin-right: 15px;"
                        font-scale="1.1"
                    ></b-icon-check-circle-fill>
                    <span>Functie 1</span>
                </li>
                <li class="functie mt-1">
                    <b-icon-check-circle-fill
                        style="fill: var(--succesColor); margin-right: 15px;"
                        font-scale="1.1"
                    ></b-icon-check-circle-fill>
                    <span>Functie 1</span>
                </li>
                <li class="functie mt-1">
                    <b-icon-check-circle-fill
                        style="fill: var(--succesColor); margin-right: 15px;"
                        font-scale="1.1"
                    ></b-icon-check-circle-fill>
                    <span>Functie 1</span>
                </li>
                <li class="functie mt-1">
                    <b-icon-check-circle-fill
                        style="fill: var(--succesColor); margin-right: 15px;"
                        font-scale="1.1"
                    ></b-icon-check-circle-fill>
                    <span>Functie 1</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {BIconCheckCircleFill} from 'bootstrap-vue'
export default {
    components: {
        BIconCheckCircleFill,
    },
    props: {
    },
    data() {
        return {
        };
    },
    created() {
    },
    methods: {
    },
};
</script>

<style scoped>
.padding{
    padding: 10px;
}
.align-right{
    text-align: right;
    justify-content: end;
    align-items: flex-end;
}
.content-title{
    font-size: 18px;
    color: #686b77;
    font-weight: 600;
}
.label{
    padding: 1px 7px;
    color: #fff;
    font-weight: 600;
    border-radius: 3px;
}
.green{
    background: #0cc2af;
}
.section-title{
     font-weight: 600;
     font-size: 15px;
     color: #686b77;
     margin-bottom: 10px;
 }
.functie{
    font-size: 12px;
}

</style>