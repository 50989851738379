<template>  
    <div>
        <BlueTitleBar title="COMPANIA MEA" subtitle="Termeni și condiții"></BlueTitleBar>
        <div class="viewPagePadding">
            <ContentBox></ContentBox>
        </div>
    </div>  
</template>
<script>
    import ContentBox from './components/ContentBox'
    import BlueTitleBar from '@/common/components/BlueTitleBar'
    export default {
        components: {
            ContentBox,
            BlueTitleBar,
        }
    }
</script>
<style scoped>

</style>
