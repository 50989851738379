
<template>
 
    <div class="hartaFinantarilor">
        <HeaderHarta :finantari="finantari.length" :finantariDeschise="countFinantariDeschise()"></HeaderHarta>
        
        <div id="harta" >

            <l-map
                ref="map"
                style="height: 100%; width: 100%"
                :zoom="zoom"
                :min-zoom="6.9"
                :center="center"
                :bounds="bounds"
                :max-bounds="maxBounds"
                :options="{ zoomControl: false }"
                @update:zoom="zoomUpdated"
                @update:center="centerUpdated"
                @update:bounds="boundsUpdated"
            >
                <l-tile-layer ref="layer" :url="url"></l-tile-layer>
               
                <l-circle
                    :lat-lng="circle.center"
                    :radius="circle.radius"
                    :dash-array="circle.dashArray"
                    :fill-opacity="circle.opacity"
                    :color="circle.color"
                ></l-circle>
                
                <div v-for="regiune in regiuni" :key="regiune.cod" >
                    <l-polygon v-if="regiuniEligibile.includes(regiune.cod)" :lat-lngs="regiune.contur" :weight="lineWieght" :color="conturColor" :fill-color="fillColor" :fill-opacity="fillOpacity"></l-polygon>
                </div>
                <l-polygon :lat-lngs="rectacgle.coordinates" :weight="rectacgle.weight" color="gray" fill-color="#f0f0f0" :fill-opacity="1"></l-polygon>
                 
                <l-control position="topright">
                    <button class="btn-map" @click="openFullscreen">
                        <i :class="fullscreen ? 'fas fa-compress' : 'fas fa-expand'"></i>
                    </button>
                </l-control>
                <l-control-zoom position="topright"></l-control-zoom>
                <l-marker
                    v-for="finantare in finantari"
                    :key="finantare.id"
                    :lat-lng="finantare.coordinates"
                    :icon="DefaultIcon"
                    @click="showRegiuniEligibile(finantare)"
                >
                    <l-icon
                        :icon-size="dynamicSize"
                        :icon-anchor="dynamicAnchor"
                    >
                        <i :class="markerClasses(finantare.status,finantare.national,finantare.supported_regions)"></i>
                    </l-icon>
                    <l-popup >
                        <PopupHarta :finantare="finantare"></PopupHarta>
                    </l-popup>
                </l-marker>
            </l-map>
        </div>
        <div class="trademark">
            <img src="@/assets/images/gray_smis_icon.png" alt="smis" >
            <span>Toate drepturile rezervate STRUCTURAL MANAGEMENT SYSTEM 2021</span>
        </div>
        <div class="footer">
            <div class="legend1">
                <ul>
                    <li>
                        <i class="far fa-circle" style="font-size:25px"></i>
                        <span>Apel de finanțare la nivel național</span>
                    </li>
                    <li>
                        <i class="far fa-circle"></i>
                        <span>Apel de finanțare la nivel multi-regional</span>
                    </li>
                    <li>
                        <i class="fas fa-circle"></i>
                        <span>Linie de finanțare la nivel regional</span>
                    </li>
                </ul>
                <ul >
                    <li>
                        <i class="fas fa-star"></i>
                        <span>Linie de finanțare </span>
                    </li>
                    <li>
                        <i class="fas fa-rocket"></i>
                        <span>Linie de finanțare start-ups</span>
                    </li>
                    <li>
                        <i class="fas fa-map-marker-alt"></i>
                        <span>Finanțare GAL</span>
                    </li>
        
                </ul>
            </div>
            <div class="legend2">
                <ul>
                    <li>
                        <i class="fas fa-circle" style="color:#5d3fcf"></i>
                        Finanțare activă (apel deschis)
                    </li>
                    <li>
                        <i class="fas fa-circle" style="color:#a6a6a6"></i>
                        Finanțare în curs de re/lansare / în consultare
                    </li>
                    <li>
                        <i class="fas fa-circle" style="color:#ff7272"></i>
                        Finanțare inactivă (apel închis)
                    </li>
                    <li>
                        <img src="@/assets/images/regiune_finantare_harta_finantari.png" alt="">
                        Regiunea de finanțare eligibilă
                    </li>
                    
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import HeaderHarta from "@/pages/Finantari/HartaFinantarilor/components/HeaderHarta.vue";
    import PopupHarta from "@/pages/Finantari/HartaFinantarilor/components/PopupHarta.vue";
    import "leaflet/dist/leaflet.css";
    import L from "leaflet";
    import 'leaflet-boundary-canvas';
    import regiuni from '@/pages/Finantari/HartaFinantarilor/components/Regiuni.json';
    import api from '@/helpers/api'
    import axios from '@/helpers/axios'
    import {
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LPopup,
        LControlZoom,
        LControl,
        LPolygon,
        LCircle  
    } from "vue2-leaflet";
    import icon from '@/assets/images/SmisLogo.png';
    import iconShadow from '@/assets/images/SmisLogo.png';

   

    export default {
       
        name: "HartaFinantari",
        components: {
            HeaderHarta,
            LMap,
            LTileLayer,
            LMarker,
            LIcon,
            LPopup,
            PopupHarta,
            LControlZoom,
            LControl,
            LPolygon,
            LCircle
        },
        props: {},
        data() {
            return {
                iconSize:10,
                DefaultIcon : L.icon({
                    iconUrl: icon,
                    shadowUrl: iconShadow
                }),
                fullscreen: false,
                url:
                    "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
                zoom: 7,
                center: [45.920587, 27.756958],
                bounds: null,
                maxBounds: L.latLngBounds([
                    [43.529549935614595, 15.072509765625004],
                    [48.59538740833338, 35.36425781250001],
                ]),
                circle:{
                    center:[45.9442858,25.0094303],  
                    radius:140000,
                    color:'#a6a6a6',
                    opacity:0,
                    dashArray:'1,10'


                },
                rectacgle:{
                    coordinates:[[[50.474896, 42.876606],[0.801497, 42.288689],[0.543529, 11.293907],[50.763539, 11.253209]],regiuni[10].contur],
                    weight:0,
                    color:'gray'
                },
                regiuni:regiuni,
                regiuniEligibile:[],
                conturColor:'#6211cd',
                fillColor:'#4F61D2',
                fillOpacity:0.1,
                lineWieght:1,
                
             
                finantari:[],
                
            };
        },
        computed: {

            dynamicSize() {
                return [this.iconSize, this.iconSize * 1.15];
            },
            dynamicAnchor() {
                return [this.iconSize / 2, this.iconSize * 1.15];
            },
        },
        created() {
           

        },
        mounted() {
            
            this.getFunding();
            this.$refs.map.mapObject.on('popupclose', () => this.regiuniEligibile=[]);
            
        },
        methods: {
            

            
            showRegiuniEligibile(finantare){
                this.regiuniEligibile = [];
                this.regiuniEligibile = Object.keys(finantare.supported_regions);
            },
            countFinantariDeschise(){
                
                let deschise = this.finantari.filter(finantare => finantare.status === 'opened');
                return deschise.length;
            },
            getFunding() {
                return  axios.get(api.API_ENDPOINTS.finantariHarta)
                    .then(response => {
                        this.finantari = response.data
                    }).finally(() => this.loading = false)
            },
            markerClasses(status,national,regiuni){
                let classes = [];
                switch(status){
                case "pending":
                    classes.push('pending');
                    break;    
                case "opened":    
                    classes.push('opened');
                    break
                case"closed":
                    classes.push('closed'); 
                    break       
                }
                if(national){
                    classes.push('far','fa-circle','nivelNationalSize');
                }else if(Object.values(regiuni).length>1){
                    classes.push('far','fa-circle','nivelRegionalSize');
                }else{
                    classes.push('fas','fa-circle','nivelRegionalSize');
                }
                return classes;
            },
            popupAnchor(national,regiuni){
                let anchor=[];
                if(national){
                    anchor = [165,200];
                }else if(Object.values(regiuni).length>1){
                    anchor = [165,195];
                }else{
                    anchor = [165,195];
                }
                return anchor;
            },

               
            openFullscreen() {
                const elem = document.getElementById("harta");
                if (!this.fullscreen) {
                    if (elem.requestFullscreen) {
                        elem.requestFullscreen();
                    } else if (elem.webkitRequestFullscreen) {
                        /* Safari */
                        elem.webkitRequestFullscreen();
                    } else if (elem.msRequestFullscreen) {
                        /* IE11 */
                        elem.msRequestFullscreen();
                    }
                    this.fullscreen = true;
                } else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitExitFullscreen) {
                        /* Safari */
                        document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) {
                        /* IE11 */
                        document.msExitFullscreen();
                    }
                    this.fullscreen = false;
                }
            },
            zoomUpdated(zoom) {
                this.zoom = zoom;
            },
            centerUpdated(center) {
                this.center = center;
            },
            boundsUpdated(bounds) {
                this.bounds = bounds;
            },
        },
    };
</script>
<style scoped>
.hartaFinantarilor {
  background-color: #f0f0f0;
  width: 100%;
}

#harta{
    height: 56%;
}

.footer {
  display: flex;
  background-color: #f6f6f6;
  width: 100%;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
}


.btn-map {
  margin-top: 0.5vh;
  margin-bottom: 1vh;
  display: block;
  background-color: #e0e0e0;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: none;
}
.map {
  display: flex;
  justify-content: space-around;
}

.legend1 {
  display: flex;  
  font-size: var(--moduleTitleSize);
  justify-content: space-around;
  align-items: center;
  border: 1px #d8d8d8 solid;
  border-radius: 5px;
  background-color: white;
  color:#3d3f40;
  margin-right: 2rem;
  padding: 1rem;
}
.legend1 span{
  margin-left: 10px;
}
.legend1 ul{
margin-bottom: 0;
}
.legend1 li  {
    width:100%;
    margin:0.5rem 0.4rem 0 0.5rem;
    }
.legend2 {
    height: 100%;
   border: 1px #d8d8d8 solid;
    border-radius: 5px;
  background-color: white;
  color:#3d3f40;
  display: flex;
  flex-flow: column nowrap;
  justify-content: start;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
  font-size: var(--moduleTitleSize);
}
.legend2 li  {
  padding: 0.4rem 0 0.3rem 0;
  
}
.legend2 img{
    height: 30px;
} 
.legend2 li i {
  padding-right: 15px;
}
.trademark {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 1rem;
}
.trademark img {
  height: 1.3rem;
  width: 4rem;
}
.trademark span {
  font-size: var(--paragraphSize);
  color: #b2b2b2;
}
.nivelNationalSize{
    font-size: 1.25rem;
}
.nivelRegionalSize{
    font-size: 0.75rem;
}
.closed{
    color:#f05542;
}
.opened{
    background: -webkit-linear-gradient(#6404cd, #4593d2);
	background-clip: text;
    -webkit-text-fill-color: transparent;
}
.pending{
    color: #a6a6a6;
}

@media(max-width:800px){
    .contentPage{
        height: auto;
    }
    .footer{
        display: block;
        padding-left:0.4rem;
        padding-right: 0.4rem;
    }
    .legend1{
        margin: 0.5rem 0;
        display: block;
    }
    .legend2{
        align-items: start;
    }
}
@media(min-height:1000px){
    .footer{
        position: fixed;
        bottom: 0;
    }
    
    
    
    #harta{
        height:63% ;
    }
}

@media(min-height:1500px){
    #harta{
        height: 69%;
    }
}
@media(min-height:1950px){
    #harta{
        height: 69%;
    }
}
</style>
<style >
.hartaFinantarilor .leaflet-popup-content-wrapper{
    border-radius: 5px !important;
}
.hartaFinantarilor  .leaflet-popup-content {
  height: 100%;
  /* width: 21vw !important; */
}
.hartaFinantarilor .leaflet-popup{
    bottom: auto !important;
    top: 14px !important;
    left: 21px !important;

}

.leaflet-popup-tip{
    display: none;
}
.fullscreenButton {
  height: 30px;
  width: 30px;
  border: 1px solid rgb(204, 204, 204);
  background-color: #f0f0f0;
}
.hartaFinantarilor .leaflet-control-zoom-in,
.hartaFinantarilor .leaflet-control-zoom-out {
  margin-top: 0.5vh !important;
  margin-bottom: 1vh !important;
  display: block !important;
  background-color: #e0e0e0 !important;
  height: 35px !important;
  width: 35px !important;
  border-radius: 50% !important;
  border: none !important;
}
 .hartaFinantarilor .leaflet-control-zoom-out {
  text-align: center;
  line-height: 40px !important;
}
.hartaFinantarilor .leaflet-touch .leaflet-control-layers,
.hartaFinantarilor .leaflet-touch .leaflet-bar {
  border: none !important;
}
.hartaFinantarilor .leaflet-top {
  margin-right: 10vw;
  margin-top: 5vh;
}

.hartaFinantarilor .leaflet-marker-pane img {
  max-height: 50px !important;
}

.hartaFinantarilor .leaflet-marker-icon {
  width: auto !important;
  height: auto !important;
  max-height: 50px !important;
}
.hartaFinantarilor .leaflet-container .leaflet-marker-pane img,
.hartaFinantarilor .leaflet-container .leaflet-shadow-pane img {
  max-width: none !important;
  max-height: 50px !important;
}

@media (min-width:1800px){
    .hartaFinantarilor .leaflet-popup{
    bottom: auto !important;
    top: 18px !important;
    left: 27px !important;

}

}
</style>
