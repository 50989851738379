<template>
    <div>
        <div class="box">
            <div class="text-box">
                <p class="first-text">Ai uitat parola?</p>
                <p>
                    Introdu adresa de email cu care ti-ai facut cont si iti vom trimite un link de
                    resetare pe email. Te rog sa verifici sectiunea inbox sau spam.
                </p>
            </div>
            <div>
                <b-form @submit.prevent="send()">
                    <b-form-group
                        id="email-group"
                        label="Email"
                        label-for="email"
                        class="w-100"
                    >    
                        <b-form-input id="email" v-model="email" type="email" placeholder="john@email.com" class="w-100"></b-form-input>
                    </b-form-group>
                </b-form>
                <div>
                    <ButtonGradient
                        class="buton-register"
                        url-buton="dadsdsss"
                        text="Trimite linkul de resetare"
                        classname="idenviz"
                    ></ButtonGradient>
                </div>
                <div class="logare">
                    <p class="logare">
                        <router-link class="text-blue" to="/login"> &lt; Logare </router-link>
                    </p>
                </div>
            </div>
        </div>
        <div class="logo-mic">
            <img alt="LogoSmisMicLogin" src="@/assets/images/LogoSmisMicLogin.png" >
        </div>
    </div>
</template>

<script>
    import { BForm,BFormGroup,BFormInput } from 'bootstrap-vue';
    import ButtonGradient from "../../../common/components/ButtonGradient";
    export default {
        name: "Test",
        components: { ButtonGradient,BForm,BFormGroup,BFormInput },
        props: {},
        data() {
            return {
                email: "",
            };
        },
        created() {},
        methods: {
            send(){
                console.log(this.email);
            }
        },
    };
</script>

<style scoped>
	.box {
		display: flex;
		flex-direction: column;
		padding: 0 40px;
	}
	.text-box {
		margin-bottom: 20px;
	}
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	label {
		margin-bottom: 2px;
		font-size: 14px;
		color: #818181;
	}
	input:not([type="checkbox"]) {
		margin-bottom: 10px;
		width: initial;
		line-height: 30px;
		border-radius: 5px;
		border: 1px solid #e7e1e7;
		padding-left: 10px;
		color: #818181;
	}
	input:focus {
		outline: none !important;
		border: 1px solid #6793ce;
	}
	input::placeholder {
		color: #cac5d4;
	}
	.first-text {
		font-size: 22px;
		font-weight: 600;
		background: -webkit-linear-gradient(#262728, #4e5359);
		background-clip: text;
		-webkit-text-fill-color: transparent;
		text-align: left;
		margin: 30px 0 20px 0;
	}
	.form-field {
		display: flex;
		position: relative;
		flex-direction: column;
		width: 100%;
	}
	.checkbox-field {
		display: flex;
		flex-direction: row;
		margin-right: auto;
		align-items: center;
	}
	.buton-register {
		margin-top: 15px;
	}
	.logo-mic {
		position: absolute;
		bottom: 40px;
		right: 50px;
	}
	.logare {
		text-align: center;
		margin-top: 20px;
	}
	.text-blue {
		color: #4d87f1;
	}
</style>
