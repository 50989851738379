<template>
    <div>
        <div class="contact-rapid">
            <h3 class="section-title mb-2 text-right pb-2"><strong>TaskManager</strong> Smis</h3>
            <div class="task-manager">
                <div class="task-item" v-for="(task, key) in data" :key="key">
                        <div class="bullet"
                             v-bind:class="{ 'green': task.is_completed === 1, 'red': task.is_completed === 0 }"
                        ></div>
                        <router-link to="/todo"><span>{{ task.title }}</span></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: null
    },
}
</script>

<style scoped>
.section-title {
    font-size: var(--moduleTitleSize);
    color: #4b4b4f;
}

.task-manager {
    border: 1px solid #dfdfe0;
    padding: 10px;
    border-radius: 2px;
}

.bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.green {
    background: #00c9a1;
}

.gray {
    background: #74748b;
}

.red {
    background: #ff7070;
}

.task-item {
    display: flex;
    align-items: center;
}

.task-item span {
    font-size: var(--paragraphSize);
    color: var(--mainColorText)
}

@media only screen and (max-width: 1600px) {

}
</style>