<template>
    <b-form @submit="onEdit">
        <div class="com-form">
            <div class="flex">
                <b-form-textarea
                    v-model="form.comment"
                    type="text"
                    class="message-field mt-3"
                    required
                ></b-form-textarea>
            </div>
            <b-button type="submit" class="send-button mt-2">Postează mesajul</b-button>
        </div>
    </b-form>
</template>

<script>
import { BForm, BFormTextarea, BButton } from "bootstrap-vue";
import api from '@/helpers/api';
import axios from '@/helpers/axios';
export default {
    components: {
        BForm,
        BFormTextarea,
        BButton,
    },
    props: {
        idComentariu: null,
        comment: null,
    },
    data() {
        return {
            form: {
                comment: this.comment,
                parent_id: this.idComentariu,
            },
            newComment: null,
        };
    },
    methods: {
        onEdit(event) {
            event.preventDefault();
            axios
                .put(api.API_ENDPOINTS.newsComments + '/' + this.idComentariu, this.form)
                .then().catch(err => {
                console.log(err)
            }).then(() => {
                this.editedComment = this.form.comment;
                this.$emit('finishEdit', this.editedComment);

            }).finally(() =>
                    this.form.comment = null,
                    this.form.parent_id = null
            );
        },
    },
};
</script>

<style scoped>
.cat-title {
    font-size: 16px;
    color: #4b4e53;
    text-transform: uppercase;
    font-weight: 600;
}
.add-comment{
    padding-top: 30px;
    width: 100%;
}
.flex{
    display: flex;
}
.send-button{
    background: transparent;
    border: 1px solid #4b4e53;
    color: #4b4e53;
}
.send-button:hover{
    background: #4b4e53;
    color: #fff;
}
.message-field{
    background: transparent;
    border: 1px solid #4b4e53;
    color: #4b4e53;
    font-size: 14px;
    height: 4em;
    transition: 0.6s;
}
.message-field:focus{
    box-shadow: none;
    height: 10em;
}
</style>