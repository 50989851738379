<template>
    <div>
        <div class="pasi">
            <div class="pas" @click="sendDataTo(1)">
                <div class="bullet">1</div><span>Confirmarea serviciilor</span>
            </div>
            <div class="pas" @click="sendDataTo(2)">
                <div class="bullet">2</div><span>Date de facturare</span>
            </div>
            <div class="pas">
                <div class="bullet bullet-active">3</div><span>Modalitatea de plată</span>
            </div>
            <div class="pas">
                <div class="bullet">4</div><span>Confirmarea plății</span>
            </div>
        </div>

        <img :src="checkout.logo" class="mb-2" :class="{'logo-centered': checkout.sold === 0}">

        <div class="flex"
             v-bind:class="{ 'justify-content-evenly': checkout.sold === 0, 'justify-content-between':  checkout.sold > 0}">
            <div class="left-sidebar">
                <div class="main-price" v-if="isSubscription && invoiceType === 'month'">
                    <p class="span-title">Abonament <strong>lunar</strong></p>
                    <p class="price-info"><span class="price"><sup>€</sup>{{ getSubTotal }}</span> <span v-if="checkout.vat_enabled">+ TVA</span></p>
                    <p class="yellow-text price-details"><strong>(vei achita prima lună. apoi {{ getSubTotal }}€/lună - facturare lunară automată)</strong></p>
                    <p class="yellow-text price-details">poți renunța oricând la abonament, din contul tău - secțiunea serviciile mele</p>
                    <p class="yellow-text price-details mt-3 font-italic">Factura se va emite la cursul BNR din ziua plății</p>
                </div>

                <div class="main-price" v-else-if="isSubscription">
                    <p class="span-title">Abonament <strong>anual</strong></p>
                    <p class="price-info"><span class="price"><sup>€</sup>{{ getSubTotal }}</span> <span v-if="checkout.vat_enabled">+ TVA</span></p>
                    <p class="yellow-text price-details">(cu plată unică) Economisești <span class="green-text">€{{ checkout.yearly_discount}}</span> <span v-if="checkout.vat_enabled">+ TVA</span></p>
                    <p class="yellow-text price-details mt-3 font-italic">Factura se va emite la cursul BNR din ziua plății</p>
                </div>

                <div class="main-price" v-else>
                    <p class="span-title">Abonament <strong>lifetime</strong></p>
                    <p class="price-info"><span class="price"><sup>€</sup>{{ getSubTotal }}</span> <span v-if="checkout.vat_enabled">+ TVA</span></p>
                    <p class="yellow-text price-details mt-3 font-italic">Factura se va emite la cursul BNR din ziua plății</p>
                </div>

                <div class="change-price mt-4">
                    <p class="span-title">Vei plăti <strong>astăzi</strong></p>
                    <p class="price-info"><span class="price"><sup style="font-size: 16px;">LEI</sup>{{ Math.floor(checkout.subtotal) }},{{ parseInt(checkout.subtotal % 1 * 100) }}<span v-if="checkout.vat_enabled" class="price-small" style="font-weight: 500; margin-left: -7px;">&nbsp; + TVA</span></span></p>
                </div>
                <div class="tip-facturare mt-3">
                    <span>Ai un voucher?</span>
                    <b-form-group class="mt-2">
                        <b-form-input
                            id="voucher"
                            v-model="voucher"
                            type="text"
                            placeholder="Cod voucher"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <div class="fake-input">
                            <span @click="applyCoupon"><strong>Aplica</strong></span>
                        </div>
                    </b-form-group>
                    <span v-if="voucher_info" class="voucher-info"><strong>Voucher valid -</strong> <span class="green-text">{{ voucher_info }}</span></span>
                </div>
            </div>
            <div class="sold-smis" v-if="checkout.sold > 0">
                <div class="border-bottom-white flex align-items-center justify-content-center">
                    <span class="span-title yellow-text">Sodul tău</span>
                    <img src="@/assets/images/smisPay.png" class="ml-2">
                </div>
                <span class="sold-total"><strong>{{ checkout.sold }} lei</strong></span>
                <p class="yellow-text" @click="smisPayInfo()">Ce este soldul Smispay?</p>
                <ModalSmisPay :smisPayModal="smisPayModal" @hideTempModal="closeSmisPay()"></ModalSmisPay>

                <p class="mt-5 yellow-text">Vreau să achit prin Smispay suma de:</p>
                <b-form-group class="mt-2">
                    <div class="smis-credit-input">
                        <b-form-input
                            id="smispay"
                            type="number"
                            v-model.number="form.customer_sold"
                            placeholder="Introdu suma"
                            min="0"
                            :max="checkout.sold"
                            @input="validateSold($event)"
                        ></b-form-input>
                    </div>
                </b-form-group>
                <b-form-group>
                    <div class="fake-input">
                        <span @click="payAllSmis"><strong>Achit integral prin Smis<span class="yellow-text">pay</span></strong></span>
                    </div>
                </b-form-group>
                <span v-if="form.customer_sold" class="voucher-info"><strong>Sold validat -</strong><span class="green-text"> {{ form.customer_sold }} lei</span></span>
            </div>
            <div class="servicii-alese" :class="{'ml-5': checkout.sold === 0}">
                <div class="border-bottom-white flex align-items-center justify-content-center">
                    <span class="span-title yellow-text">Servicii alese</span>
                </div>
                <span class="sold-total flex justify-content-end border-bottom-white price pt-3">{{ Math.floor(checkout.subtotal) }}<span class="price-small">,{{ parseInt(checkout.subtotal % 1 * 100) }} lei</span></span>
                <div v-if="form.coupons.length > 0">

                    <b-form-radio-group>

                    </b-form-radio-group>
                    <div class="border-bottom-white pt-3" v-for="coupon in form.coupons" :key="coupon.id">
                        <p class="flex justify-content-end"><strong>{{ coupon.name }}</strong></p>
                        <span class="sold-total green-text flex justify-content-end price">- {{ Math.floor(coupon.discount) }}<span class="price-small green-text">,{{ parseInt(coupon.discount % 1 * 100) }} lei</span></span>
                    </div>


                </div>
                <div class="pt-3" v-if="form.customer_sold">
                    <p class="flex justify-content-end"><strong>Reducere Smis<span class="yellow-text">pay</span></strong></p>
                    <span class="sold-total yellow-text flex justify-content-end price">- {{ form.customer_sold }}<span class="price-small yellow-text">,00 lei</span></span>
                </div>
                <div class="mt-4">
                    <span class="span-title flex justify-content-start yellow-text border-bottom-white">Total de plată</span>
                    <span class="sold-total price flex justify-content-end pt-3">{{ Math.floor(getTotal()) }}<span class="price-small">,{{ parseInt(getTotal() % 1 * 100) }} lei</span></span>
                </div>
                <div class="mt-3">
                    <span class="span-title flex justify-content-start yellow-text border-bottom-white">Modalitatea de plată</span>
                    <b-form-radio-group 
                        :options="payment_methods"
                        :reduce="(option => option.id)"
                        text="label" 
                        v-model="form.payment_method"
                        class="modalitate-plata mt-3"
                        :clearable="false">
                    </b-form-radio-group>
                </div>
                <div class="mt-3 payment-instructions" v-if="shouldShowPaymentInstructions">
                    <span class="span-title flex justify-content-start yellow-text border-bottom-white">Instructiuni</span>
                    <p class="pt-3" v-html="paymentInstructions"></p>
                </div>
            </div>
        </div>
        <div class="flex justify-content-center">
            <b-button class="next-step" @click="sendDataTo(4)"><strong>Pasul 4</strong> - Confirmarea plății</b-button>
        </div>
    </div>
</template>

<script>
import {BButton, BFormGroup, BFormInput, BFormRadioGroup, BFormRadio} from "bootstrap-vue";
import ModalSmisPay from './ModalSmisPay'
import axios from '@/helpers/axios';
import api from '@/helpers/api';
import _ from "lodash";

export default {
    components: {
        BButton,
        BFormGroup,
        BFormInput,
        BFormRadioGroup,
        BFormRadio,
        ModalSmisPay,
    },

    props: ['data', 'invoiceType', 'checkout'],

    data() {
        return {
            form: {
                business_invoice: '',
                invoice_company_id: '',
                new_company: false,
                company_id: '',
                plan_id: '',
                plan_type: '',
                payment_method: '',
                customer_email: '',
                customer_phone: '',
                billing: {
                    first_name: '',
                    last_name: '',
                    address: '',
                    state: '',
                    city: '',
                },
                business_id: '',
                company_name: '',
                reg_no: '',
                bank_name: '',
                bank_account: '',
                contact_person: '',
                contact_person_job: '',
                coupons: [],
                customer_sold: 0,
            },
            smisPayModal: false,
            voucher: '',
            voucher_info: '',
            payment_methods: [],
        }
    },

    mounted() {
        this.form = this.data.form;
        this.getGateways()
    },

    methods: {
        closeSmisPay() {
            this.smisPayModal = false;
            this.tempCloseModal = false;
            this.$emit('tempCloseModal', this.tempCloseModal)
        },

        smisPayInfo() {
            this.smisPayModal = true;
            this.tempCloseModal = true;
            this.$emit('tempCloseModal', this.tempCloseModal)
        },

        payAllSmis() {
            this.form.customer_sold = this.checkout.sold
        },

        validateSold($event) {
            if($event < 0) {
                this.form.customer_sold = (-1) * $event;
            }

            if($event > this.checkout.sold) {
                this.form.customer_sold = this.checkout.sold;
            }
        },

        getTotal() {
            return (this.checkout.subtotal - this.form.customer_sold - this.sumOfCoupons).toFixed(2);
        },

        applyCoupon() {
            if(this.voucher) {
                return axios.post(api.API_ENDPOINTS.checkoutCoupon, {plan_id: this.checkout.id, interval: this.invoiceType, coupon: this.voucher})
                    .then((response) => {
                        if(this.form.coupons.includes(response.data)) {
                            this.$notify('Cuponul a fost folosit deja.');
                        } else {
                            this.form.coupons.push(response.data);
                        }
                    }).catch((xhr) => {
                        this.$notify(xhr.response.data.message);
                    });
            }
        },

        getGateways() {
            this.payment_methods = this.checkout.gateways

            if(this.checkout.sold === 0) {
                _.remove(this.payment_methods, function (e) {
                    return e.id === 'smispay';
                });
            }

            if (this.firstPaymentMethod) {
                this.form.payment_method = this.firstPaymentMethod;
            }
        },

        sendDataTo(step) {
            let submit = step == 4 ? true : false;
            this.$emit('stepActive', {'pageNumber': step, 'form': this.form, 'submit': submit})
        }
    },

    computed: {
        isLifetime() {
            return this.checkout.invoice_interval ? this.checkout.invoice_interval.includes('lifetime') : false;
        },

        isSubscription() {
            return ['year', 'month'].every(val => this.checkout.invoice_interval ? this.checkout.invoice_interval.includes(val) : false)
        },

        firstPaymentMethod() {
            let gateways = this.checkout.sold > 0 ? this.checkout.gateways : _.filter(this.checkout.gateways, v => v.id != 'smispay');
            return gateways.length > 0 ? gateways[0].id : '';
        },

        shouldShowPaymentInstructions() {
            return this.form.payment_method == 'bank_transfer';
        },

        paymentInstructions() {
            if (this.shouldShowPaymentInstructions) {
                return this.checkout.gateways.find(gateway => gateway.id == this.form.payment_method).instructions;
            }

            return '';
        },

        sumOfCoupons() {
            return this.form.coupons.reduce((sum, coupon) => {
                return sum += coupon.discount;
            }, 0);
        },

        getSubTotal() {
            return this.invoiceType === 'year' ? this.checkout.total_yearly_price : this.checkout.price;
        }
    },
}
</script>

<style scoped>
p, span, h1, h2, h3{
    color: #fff;
}
.pasi{
    display: flex;
    justify-content: space-between;
    padding-bottom: 70px;
}
.justify-content-evenly{
    justify-content: space-evenly;
}
.pas{
    display: flex;
    font-size: 14px;
    cursor: pointer;
}
.logo-centered{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
}
.bullet{
    height: 20px;
    width: 20px;
    background: #fff;
    font-size: 14px;
    color: #343434;
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
}
.bullet-active{
    background: #ffcc00;
}
.span-title{
    font-size: 24px;
}
.flex{
    display: flex;
}
.yellow-text{
    color: #ffcc00 !important;
}
.left-sidebar, .sold-smis, .servicii-alese{
    width: 26%;
}
.price-small{
    font-size: 20px;
    line-height: 40px;
}
.border-bottom-white{
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
}
.main-price p, .main-price span{
    text-align: left;
    color: #ffcc00;
}
.price{
    font-size: 28px;
    font-weight: 700;
    line-height: 30px;
}
input{
    background: transparent;
    border: 1px solid #ffcc00;
    border-radius: 3px;
    color: #fff;
    font-size: 15px;
}
.form-control[readOnly]{
    background-color: transparent;
    color: #fff;
}
input::placeholder{
    color: #ffcc00;
}
input:focus{
    box-shadow: none;
    background-color: transparent;
    border-color: #ffcc00;
    color: #fff;
}
.green-text{
    color: #00ffc0 !important;
}
.voucher-info{
    font-size: 14px;
}
.sold-total{
    font-size: 35px;
}
.sold-smis p{
    margin-top: -5px;
}
.smis-credit-input{
    position: relative;
}
.smis-credit-input:after{
    content: 'lei';
    color: #fff;
    position: absolute;
    top: 17%;
    right: 5%;
    font-size: 18px;
    font-weight: 600;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.fake-input{
    display: flex;
    border: 1px solid #ffcc00;
    border-radius: 3px;
    align-items: center;
    justify-content: space-between;
    padding: 0.375rem 0.75rem;
    cursor: pointer;
}
.fake-input span{
    color: #fff;
    margin: 0;
    font-size: 15px;
}
.servicii-alese p{
    font-size: 14px;
}
.modalitate-plata >>> .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #ffcc00 !important;
    border-color: #ffcc00 !important;
}
.modalitate-plata >>> label{
    color: #fff;
    font-weight: 400;
    font-size: 14px;
}
.next-step{
    background: #ffcc00;
    color: #272727;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid #ffcc00;
    margin: 40px 0 0;
}
@media (max-width: 1000px){
    .flex{
        display: block;
    }
    .left-sidebar{
        margin-bottom: 10px;
    }
    .pasi{
        display: block;
    }
    .pas{
        justify-content: center;
        margin-bottom: 10px;
    }
    .left-sidebar, .sold-smis, .servicii-alese{
        width: 100%;
    }
}
</style>