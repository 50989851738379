<template>
    <div>
        <div class="formular-contul-meu">
            <div class="tip-user">
                <p class="text-modul">Salut! Ești utilizator</p>
                <b-img
                    v-if="$store.state.auth.user.account_type"
                    class="image"
                    :src="$store.state.auth.user.account_type.logo"
                ></b-img>
            </div>
            <div>
                <p class="text-modul">Facturi emise <span class="text-bold-36">{{ data.length }}</span></p>
            </div>
            <div>
                <p class="text-modul">Total facturat<span class="text-size-36">{{ _.sumBy(_.filter(data, { status: 'In asteptare' }), 'amount').toFixed(2) }}</span>lei</p>
            </div>
            <div>
                <p class="text-modul">Achitat<span class="text-size-36">{{ _.sumBy(_.filter(data, { status: 'Platit' }), 'amount').toFixed(2) }}</span>lei</p>
            </div>
            <div>
                <p class="text-modul">
                    Sold restant <span class="text-color text-bold-36">{{ _.sumBy(_.filter(this.data, { status: 'In asteptare' }), 'amount').toFixed(2) }}</span><span class="text-color">lei</span>
                </p>
            </div>
            <div class="icon-question" @click="showInfoPopup">
                <b-icon-question icon="question" class="text-modul" scale="3"></b-icon-question>
            </div>
        </div>
        <div>
            <InfoPopup v-show="isInfoPopupVisible" @close="closeInfoPopup">
                <template v-slot:header>
                    <div class="mb-3"></div>
                </template>
                <template v-slot:body>
                    <FinanciarModalInfoContent v-if="!loading" :tipCont="tipCont"></FinanciarModalInfoContent>
                    <LoadingSpinner v-else></LoadingSpinner>
                </template>
            </InfoPopup>
        </div>
    </div>
</template>

<script>
    import {BImg,BIconQuestion} from 'bootstrap-vue'
    import LoadingSpinner from '@/common/components/LoadingSpinner'
    import FinanciarModalInfoContent from "./FinanciarModalInfoContent";
    import InfoPopup from "@/common/components/InfoPopup";
    import api from '@/helpers/api';
    import axios from '@/helpers/axios';
    import _ from 'lodash'

    export default {
        components: {
            FinanciarModalInfoContent,
            InfoPopup,
            BImg,
            BIconQuestion,
            LoadingSpinner
        },
        props: {
            data: Array,
        },
        data() {
            return {
                isInfoPopupVisible: false,
                countBilled: 0,
                tipCont: [],
                loading: true,
            };
        },
        computed:{
            _(){
                return _;
            }
        },
        methods: {
            showInfoPopup() {
                this.isInfoPopupVisible = true;
                return  axios.get(api.API_ENDPOINTS.userAccountTypes)
                    .then(response => {
                        this.tipCont = response.data;
                    }).finally(() => this.loading = false)
            },
            closeInfoPopup() {
                this.isInfoPopupVisible = false;
            },
        }
    };
</script>

<style scoped>
	.formular-contul-meu {
		background-color: #f8f8f8;
		padding: 10px 0 10px 20px;
		border: 1px solid #e7e7e7;
		border-radius: 4px;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(165px, auto));
		grid-gap: 30px;
		justify-content: space-between;
	}
	.tip-user {
		display: flex;
	}
	.text-modul {
		font-size: 16px;
		color: #a9a9a9;
		align-self: center;
		text-align: left;
        display: flex;
        align-items: center;
	}
	.text-size-36,
	.text-bold-36 {
		font-size: 36px;
		margin-left: 10px;
	}
	.text-bold-36,
	.text-color {
		font-weight: bold;
	}
	.text-color {
		color: #ff7676;
	}
	.image {
		max-width: 230px;
		height: 45px;
		margin: 0 20px;
	}
	.icon-question {
		border-left: 1px solid #e0e0e0;
		display: flex;
		padding: 0 20px;
		cursor: pointer;
		justify-self: center;
        margin-left: auto;
	}
	@media only screen and (max-width: 1500px) {
        .formular-contul-meu {
            padding: 20px 0 40px 20px;
        }
		.formular-contul-meu div {
			justify-self: center;
		}
		.icon-question {
			border-left: 0;
            margin-left: 0;
		}
	}
</style>
