<template>
    <div class="padding-box">
        <div class="content-box">
            <ToDoSidebar class="sidebar" @openSidebar="openTaskBar($event, null)" :tags="tags" @filter="filterTodos($event)" @filterTags="filterTags" :activeMenu="activeMenu"></ToDoSidebar>
            <div class="content">
                <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                        <b-icon-search
                            icon="SearchIcon"
                            class="text-muted"
                        ></b-icon-search>
                    </b-input-group-prepend>
                    <b-form-input
                        v-model="searchQuery"
                        placeholder="Caută task"
                        @keyup="searchTodo"
                    ></b-form-input>
                </b-input-group>
                <draggable v-model="todos" draggable=".task" v-if="!loading" class="todo-list" @change="onEnd()">
                    <div class="task" v-for="(todo, key) in todosFiltered" :key="key" @click="openTaskBar('modificare', todo)">
                        <div class="task-group" v-bind:class="{ done: todo.is_completed === true }">
                            <div class="check_box" @change="completeTask(todo.id, todo.is_completed)" v-on:click.stop>
                                <input :id="todo.id" v-model="todo.is_completed" type="checkbox" true-value="1" false-value="0">
                                <label :for="todo.id"></label>
                            </div>
                            <div class="flex-space">
                                <p :class="{ 'opacity-title': todo.is_completed === '1' }">{{ todo.title }}</p>
                                <div class="flex align-items-center">
                                    <div v-if="todo.tags"><span v-for="(tag, key) in todo.tags" :key="key" class="badge badge-pill" :style="{ 'background-color': tag.color }">{{ tag.name }}</span></div>
                                    <div v-if="todo.is_important"><b-icon-star-fill font-scale="1" style="fill: var(--warningColor)" class="star-badge ml-2"></b-icon-star-fill></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </draggable>
                <LoadingSpinner v-else></LoadingSpinner>
            </div>
        </div>
        <TaskHandlerSidebar :active="isTaskHandlerSidebarActive" @closeSidebar="isTaskHandlerSidebarActive = $event" :tipSidebar="tipSidebar" @newTodo="addNewTodo($event)" @removeTodo="removeTodo($event)" :todoInfo="todoInfo" :tags="tags" @loadTodos="reloadTodos"></TaskHandlerSidebar>
    </div>

</template>

<script>
import LoadingSpinner from '@/common/components/LoadingSpinner'
import {BInputGroup, BInputGroupPrepend, BFormInput, BIconSearch, BFormCheckbox, BIconStarFill} from 'bootstrap-vue'
import ToDoSidebar from "./components/ToDoSidebar";
import TaskHandlerSidebar from "./components/TaskHandlerSidebar";
import draggable from 'vuedraggable'
import api from '@/helpers/api'
import axios from '@/helpers/axios'
import _ from 'lodash'

export default{
    components:{
        LoadingSpinner,
        ToDoSidebar,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BIconSearch,
        BFormCheckbox,
        TaskHandlerSidebar,
        BIconStarFill,
        draggable,
    },
    props: {
        data: null,
    },
    data() {
        return{
            loading: true,
            todos: [],
            tags: [],
            task: {
                is_completed: false,
            },
            form: {
                id: null,
                is_completed: null,
            },
            isTaskHandlerSidebarActive: false,
            tipSidebar: null,
            todoInfo: null,
            todosSorted: null,
            allTodos: [],
            todosFiltered: [],
            activeMenu: 'toate',
            searchQuery: null,
        }
    },
    mounted() {
        this.getTodos()
        this.getTags()
    },
    methods: {
        getTodos(){
            return  axios.get(api.API_ENDPOINTS.todos)
                .then(response => {
                    this.todos = response.data;
                    this.todosFiltered = this.todos
                }).finally(() => this.loading = false)
        },
        getTags() {
            return axios.get(api.API_ENDPOINTS.tags)
                .then(response => {
                    this.tags = response.data
                })
        },
        openTaskBar(tipTaskbar, todo) {
            this.isTaskHandlerSidebarActive = this.isTaskHandlerSidebarActive === false;
            this.tipSidebar = tipTaskbar;
            if(todo != null){
                this.todoInfo = todo
            } else{
                this.todoInfo = null
            }

        },
        completeTask(id, completed) {
            if(completed === 0){
                completed = 1
            } else if(completed === 1){
                completed = 0
            }
            this.form.id = id
            this.form.is_completed = completed
            axios.post(api.API_ENDPOINTS.completeTodo, this.form)
        },
        addNewTodo(event) {
            this.todos.push(event)
        },
        removeTodo(event) {
            let idToRemove = event;

            this.todos = this.todos.filter(function(item) {
                return item.id != idToRemove;
            });
        },
        onEnd(){
            this.todosSorted = _.map(this.todos, 'id');
            axios.post(api.API_ENDPOINTS.todos + '/sort', {todos: this.todosSorted})
            .then((response) => {
                this.$notify(response.data.message);
            }).catch(xhr => {
            if (xhr.response.status === 422) {
                this.errors.record(xhr.response.data.errors);
            }

            this.$notify(xhr.response.data.message);
            });
        },
        reloadTodos(){
            this.getTodos()
        },
        filterTodos(event){
            this.todosFiltered = this.todos
            if(event === 'de-facut'){
                this.todosFiltered = _.filter(this.todosFiltered, v => !v.is_completed);
                this.activeMenu = 'de-facut'
            } else if(event === 'importante'){
                this.todosFiltered = _.filter(this.todosFiltered, v => v.is_important);
                this.activeMenu = 'importante'
            } else if(event === 'finalizate'){
                this.todosFiltered = _.filter(this.todosFiltered, v => v.is_completed);
                this.activeMenu = 'finalizate'
            } else {
                this.todosFiltered = this.todos
                this.activeMenu = 'toate'
            }
        },
        filterTags(id){
            this.todosFiltered = _.filter(this.todos, { tags: [{ id: id }] } );
        },
        searchTodo(){
            if(this.searchQuery.length > 0){
                //this.todosFiltered = _.filter(this.todos, { title: this.searchQuery });
                this.todosFiltered = this.todos.filter((item) => {
                    let itemLower = item.title.toLowerCase();
                    return itemLower.includes(this.searchQuery);
                })
            } else{
                this.todosFiltered = this.todos
            }
        }
    },
}
</script>

<style scoped>
.flex{
    display: flex;
}
.star-badge{

}
.opacity-title{
    opacity: 0.5;
}
.badge-pill{
    color: #fff;
}
.badge-pill:not(:first-child){
    margin-left: 5px;
}
.flex-space{
    display: flex;
    justify-content: space-between;
    width: 90%;
}
.padding-box{
    padding: 20px;
    height: 100%;
}
.content-box{
    background: #fff;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    min-height: 60%;
}
.sidebar{
    width: 15%;
    height: auto;
    border-right: 1px solid #ebe9f1;
}
.content{
    width: 85%;
    display: block;
}
.task{
    padding: 10px 0;
    border-bottom: 1px solid #ebe9f1;
}
.spinner{
    min-height: 150px;
}
.task:hover{
    transform: translateY(-2px);
    box-shadow: 0 3px 10px 0 #ebe9f1;
    transition: all .3s;
    cursor: move;
}
.task-group{
    padding-left: 0.75rem;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.input-group-text{
    background: transparent;
    border: none;
    border-bottom: 1px solid #ebe9f1;
}
.bi-search{
    font-size: 15px !important;
}
input{
    border: none;
    border-bottom: 1px solid #ebe9f1;
    font-size: 14px;
    padding: 20px 0;
}
input:focus{
    box-shadow: none;
    border-bottom: 1px solid #ebe9f1;
}
.done p{
    opacity: 0.7;
}
.todo-list{
    max-height: 540px;
    overflow-y: auto;
}



.check_box {
    display: flex;
    position: relative;
    margin-right: 10px;
}
.check_box input[type="checkbox"] {
    display: none;
}
.check_box label {
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    border: 0px solid #f2f1f2;
    width: 15px;
    height: 15px;
    transition: all 0.1s ease;
    z-index: 2;
    box-shadow: 0px 0px 0px 1px #ebe9f1;
    display: block;
    background: white;
    margin-bottom: 0;
}
.check_box input[type="checkbox"]:checked ~ label {
    border: 3px solid white;
    background: #00dfc2;
}
@media (max-width: 1300px){
    .content-box{
        flex-direction: column;
    }
    .sidebar, .content{
        width: 100%;
    }
    .content-box{
        justify-content: inherit;
        height: auto;
        min-height: inherit;
    }
}
</style>