<template>
    <div v-if="!loading">

        <div v-if="consultantBasic">
            <DarkBlueTitleBar :data="title2"></DarkBlueTitleBar>
            <PrezentareHead :data="consultantCompany" :consultant="consultantSimple"></PrezentareHead>
            <InfoCompanie :data="consultantCompany" :consultant="consultantSimpleInfo" :recenzii="reviews"></InfoCompanie>
        </div>
        <div v-else>
            <GrayTitleBar :data="title"></GrayTitleBar>
            <CompanieSingleTitleBackground :data="consultantInfo"></CompanieSingleTitleBackground>
            <InfoCompaniePremium :data="consultantContent" :recenzii="reviews"></InfoCompaniePremium>
        </div>
    </div>
    <LoadingSpinner v-else></LoadingSpinner>
</template>

<script>
import LoadingSpinner from '@/common/components/LoadingSpinner'
import GrayTitleBar from "./components/GrayTitleBar";
import CompanieSingleTitleBackground from "./components/CompanieSingleTitleBackground";
import InfoCompaniePremium from "./components/InfoCompaniePremium";
import DarkBlueTitleBar from '@/common/components/DarkBlueTitleBar';
import PrezentareHead from './components/PrezentareHead';
import InfoCompanie from './components/InfoCompanie';
import api from '@/helpers/api'
import axios from '@/helpers/axios'

export default {
    name: "PrezentareCompaniePremium",
    components: {
        LoadingSpinner,
        GrayTitleBar,
        CompanieSingleTitleBackground,
        InfoCompaniePremium,
        DarkBlueTitleBar,
        PrezentareHead,
        InfoCompanie,
    },
    data() {
        return {
            title: 'Profil companie consultanță',
            title2: 'Profil companie',
            loading: true,
            consultantBasic: null,
            consultantInfo: [],
            consultantContent: [],
            consultantCompany: [],
            consultantSimple: [],
            consultantSimpleInfo: [],
            consultantVerificat: [],
            reviews: [],
        }
    },
    mounted() {
        this.getConsultant()
    },
    methods: {
        getReviews(consultantId) {
            return axios.get(api.API_ENDPOINTS.consultantsReviews + '/' + consultantId + '/reviews')
                .then(response => {
                    this.reviews = response.data;

                })
        },
        getConsultant() {
            let id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
            axios.get(api.API_ENDPOINTS.consultants + '/' + id)
                .then(response => {
                    let data = response.data
                    this.getReviews(response.data.customer_id)
                    this.consultant = data
                    this.consultantBasic = data.is_basic
                    this.consultantCompany = data.is_company
                    this.consultantVerificat = data.is_verified && !data.is_basic
                    this.consultantSimple = {
                        name: data.name,
                        adress: data.address,
                        rating: data.rating,
                        phone: data.phone,
                        email: data.email,
                        website: data.website,
                        logo: data.logo,
                        city: data.city,
                    }
                    this.consultantSimpleInfo = {
                        about: data.about,
                        services: data.services,
                    }
                    this.consultantInfo = {
                        name: data.name,
                        adress: data.address,
                        rating: data.rating,
                        phone: data.main_phone,
                        email: data.main_email,
                        website: data.website,
                        logo: data.logo,
                        city: data.city,
                        banner: data.banner,
                    }
                    this.consultantContent = {
                        name: data.name,
                        about: data.about,
                        services: data.services,
                        offices: data.offices,
                        city: data.city,
                        phone: data.phone,
                        adress: data.address,
                        contactPerson: data.contact_person,
                        email: data.email,
                        projects: data.funded_projects,
                        implemented: data.implemented_projects,
                        experience: data.years_of_experience,
                        description: data.description,
                        facebook: data.facebook_link,
                        instagram: data.instagram_link,
                        youtube: data.youtube_link,
                        twitter: data.twitter_link,
                        linkedin: data.linkedin_link,
                        offer: data.special_offer,
                        team: data.team,
                        logo: data.logo,
                        contactRapid: data.main_phone,
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
    }
}
</script>

<style scoped>

</style>