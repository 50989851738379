<template>
    <div>
        <div class="companie">
            <img v-if="data.logo" class="companie-imagine-mica" :src="data.logo" alt="">
            <img v-else class="companie-imagine-mica" src="@/assets/images/fara-logo.png" alt="">
            <div class="flex">
                <p class="nume-companie-mica">{{ data.name }}</p>
                <div class="status-eligibilitate">
                    <b-icon-check-circle-fill
                        style="fill: var(--succesColor);"
                        font-scale="1.2"
                        v-if="data.eligibility === true"
                    ></b-icon-check-circle-fill>
                    <b-icon-x-circle-fill
                        v-else
                        style="fill: var(--dangerColor);"
                        font-scale="1.2"
                    ></b-icon-x-circle-fill>
                </div>
            </div>
        </div>
        <div v-if="isOpen === true && !hasFinantari" class="companie-mare mt-4">
            <div>
                <div class="fara-companii">
                    <img src="@/assets/images/fara-finantari.png">
                    <p class="text-bold mt-4">Momentan nu am identificat finanțări<br>pentru compania ta :(</p>
                    <p class="mt-4">Te rugăm să urmărești această secțiune pentru<br>
                        informații actualizate. Îți mulțumim!</p>
                </div>
            </div>
        </div>
        <div v-else-if="isOpen === true && hasFinantari === true" class="companie-mare mt-4">
            <div class="padding-companie">
                <div class="list-eligibilitati">
                    <div class="list-item" v-for="(eligibilitate, key) in data.fundings" :key="key">
                        <router-link :to="'/finantare/' + eligibilitate.id">
                        <div class="bullet" v-bind:class="{ 'green': eligibilitate.value === true, 'red': eligibilitate.value === false, 'gray': eligibilitate.value === null }"></div><span>{{ eligibilitate.name }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {BIconCheckCircleFill, BIconXCircleFill} from 'bootstrap-vue'
export default {
    name: "Test",
    components: {
        BIconCheckCircleFill,
        BIconXCircleFill,
    },
    props: {
        hasFinantari: null,
        data: null,
    },
    data() {
        return {
            isOpen: true,
        };
    },
    created() {
    },
    methods: {
        /*bigSmall() {
            this.isOpen = !this.isOpen;
        },*/
        appsmisOpen() {
            if (this.appsmis === false) {
                this.appsmis = true;
            } else {
                this.appsmis = false;
            }
        },
        financiarOpen() {
            if (this.financiar === false) {
                this.financiar = true;
            } else {
                this.financiar = false;
            }
        },
    },
};
</script>

<style scoped>
.flex{
    display: flex;
    justify-content: space-between;
    width: 74%;
    align-items: center;
}
.bullet{
    min-width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}
.green{
    background: #00c9a1;
}
.gray{
    background: #74748b;
}
.red{
    background: #ff7070;
}
.list-item a{
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}
.list-item span{
    font-size: var(--paragraphSize);
    color: var(--mainColorText)
}
.text-bold{
    font-weight: bold;
    color: #74748b;
    font-size: 14px;
}
.companie {
    display: flex;
    background-color: #fff;
    align-items: center;
    min-height: 55px;
    border-radius: 5px;
    overflow-wrap: anywhere;
}
.companie-mare {
    display: flex;
    background-color: #fff;
    align-items: center;
    border-radius: 5px;
    overflow-wrap: anywhere;
    justify-content: center;
    height: 100%;
    max-height: 300px;
}
.companie-mare-margin {
    margin: 20px;
    width: -webkit-fill-available;
}
.fara-companii{
    text-align: center;
    height: 100%;
}
.fara-companii p{
    text-align: center;
}
.nume-companie-mica {
    border-left: 1px solid #eeeff0;
    margin-left: 30px;
    padding: 5px 0 5px 10px;
    font-size: 14px;
    text-align: initial;
    color: #929292;
    margin: 10px;
}
.companie-imagine-mica {
    max-height: 40px;
    margin-left: 10px;
    width: 100%;
    max-width: 70px;
    object-fit: contain;
}
.list-eligibilitati{
    overflow-y: scroll;
    height: 100%;
}
.padding-companie{
    padding: 20px;
    width: 100%;
    overflow-y: scroll;
    height: 100%;
}
p {
    margin: 0;
}

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(208, 208, 208, 0.38);
}
::-webkit-scrollbar
{
    width: 3px;
}
::-webkit-scrollbar-thumb
{
    background-color: rgba(208, 208, 208, 0.38);
    border: 2px solid #95a3cb;
}
@media only screen and (max-width: 500px) {

}
</style>
