<template>
    <div class="modul-finantare-title item-d">
        <div class="bold-text title">Tipul de aplicant</div>
        <ul v-if="data.applicants.length > 0" style="margin: 10px 0 0 0">
            <li v-for="(aplicant, index) in data.applicants" :key="index" class="tipuri-aplicant">
                <b-icon-check2 v-if="aplicant.supported === true" font-scale="1"></b-icon-check2>
                <b-icon-x v-else font-scale="1" class="opacity-text"></b-icon-x>
                <span v-if="aplicant.supported === false" class="opacity-text">{{ aplicant.value }}</span>
                <span v-else>{{ aplicant.value }}</span>
            </li>
        </ul>
        <ul v-else style="margin: 10px 0 0 0">
            <li class="tipuri-aplicant">
                <b-icon-check2 font-scale="1"></b-icon-check2>
                <span>Aplicare independentă</span>
            </li>
            <li class="tipuri-aplicant">
                <b-icon-check2 font-scale="1"></b-icon-check2>
                <span>Parteneriat de mai multe companii</span>
            </li>
            <li class="tipuri-aplicant"><b-icon-x font-scale="1" class="opacity-text"></b-icon-x>
                <span class="opacity-text">Parteneriat public-privat</span>
            </li>
            <li class="tipuri-aplicant">
                <b-icon-x font-scale="1" class="opacity-text"></b-icon-x>
                <span class="opacity-text">Parteneriat autoritati publice locale</span>
            </li>
        </ul>
    </div>
</template>

<script>
    import {BIconCheck2, BIconX} from 'bootstrap-vue';

    export default {
        components: {
            BIconCheck2,
            BIconX,
        },
        props: {
            data: null,
        },
        data() {
            return {}
        },
        methods: {},
    }
</script>

<style scoped>
    .title {
        font-size: var(--moduleTitleSize);
    }

    .item-d {
        grid-area: tipAplicant;
        margin-bottom: 20px;
    }

    .bold-text {
        font-weight: bold;
    }

    .tipuri-aplicant {
        font-size: var(--paragraphSize);
    }

    li span {
        font-weight: 500;
        margin-left: 5px;
    }

    li span.opacity-text {
        font-weight: 400;
        margin-left: 5px;
    }

    .opacity-text {
        opacity: 0.3;
    }

    @media only screen and (max-width: 1250px) {
        .item-d {
            grid-area: tipAplicant;
        }
    }
</style>