<template>
    <div id="ViewTab">
        <div class="row">
            <div v-for="field in data" :key="field.id" class="detaliu col-sm-12 col-md-6 col-xl-4">
                <div class="header displayFlex flexStart spaceBetween">
                    <div class="displayFlex middleAlign">
                        <router-link :to="'/finantare/'+field.id">
                            <p>{{ limitWords(field.name, 20) }}</p>
                        </router-link>
                    </div>
                    <div class="displayFlex columnDirection text-center">
                        <div>                            
                            <div :class="clasaFirmeEligibilitate(field.count_eligibilities)">
                                <span v-if="field.count_eligibilities === null">-</span>
                                <span v-else-if="field.count_eligibilities === false">N/A</span>
                                <span v-else>{{ field.count_eligibilities }}</span>
                            </div>
                        </div>
                        <div>
                            <HeartFavorite :id="field.id" :isFavorite="field.wishlisted" class="heartFavorite" @getId="getId(data.item.id)"></HeartFavorite>
                        </div>
                    </div>
                </div>
                <div class="contentSquare">
                    <div class="programNameContainer">
                        <div class="imagesContainer">
                            <img src="@/assets/images/toateFondurileIcon1.png" class="headerLogo">
                            <img v-if="mobile" :src="field.program.logo.path" class="rightLogoMobile">
                        </div>
                        <router-link :to="'/program/'+field.program.id">
                            <h1>{{ field.program.name }}</h1>
                        </router-link>
                        
                    </div>
                    <div class="displayFlex spaceBetween">
                        <div>
                            <p><strong>Valoare proiect: </strong>{{ field.min_budget.toLocaleString('ro-RO') }} <span v-if="field.max_budget > 0">- {{ field.max_budget.toLocaleString('ro-RO') }}</span>
                                <strong>
                                    <span v-if="field.status === 'pending'" class="backgroundGry clasaApel">Apel în consultare</span>
                                    <span v-else-if="field.status === 'closed'" class="backgroundRed clasaApel">Apel inactiv</span>
                                    <span v-else class="backgroundGreen clasaApel">Apel deschis!</span>
                                </strong>
                            </p>
                            <p><strong>Alocare apel: </strong>{{ field.total_budget.toLocaleString('ro-RO') }}</p>
                            <p><strong>Număr de proiecte finanțate: </strong>aproximativ {{ field.projects }}</p>
                            <p>
                                <strong>Disponibilitate: </strong>
                                <span v-if="Object.keys(field.supported_regions).length === 9">
                                    Toate regiunile
                                </span>
                                <span v-for="(regiune, key, index) in field.supported_regions" v-else :key="key">
                                    <span>{{ regiune }}</span>
                                    <span v-if="index != Object.keys(field.supported_regions).length - 1">, </span>
                                </span>
                            </p>
                        </div>
                        <router-link :to="'/program/'+field.program.id">
                            <img v-if="!mobile" :src="field.program.logo.path" class="rightLogo">
                        </router-link>
                    </div>
                    <div class="displayFlex spaceBetween footerZone">
                        <div id="divDataDeschidereInchidere" class="displayFlex">    
                            <div>Dată deschidere apel:  <span class="textGreen">{{ formatUtcDate(field.start_date, 'DD.MM.YYYY') }}</span></div>
                            <div class="ml-3">Dată limită depunere: <span class="textRed">{{ formatUtcDate(field.end_date, 'DD.MM.YYYY') }}</span></div>
                        </div>
                        <div class="ml-4">
                            <router-link :to="'/finantare/'+field.id"><span class="iconLink"><svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="box arrow up right" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-box-arrow-up-right b-icon bi"><g><path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/><path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/></g></svg></span></router-link>
                        </div>
                    </div>
                </div>                
            </div>     
        </div>
    </div>   
</template>

<script>
    import HeartFavorite from '@/common/components/HeartFavorite'
    import {formatUtcDate, limitWords} from '@/helpers/helpers'
    export default {
        components: {HeartFavorite},
        props: {
            data: null,
        },
        data() {
            return {
                clasaFirmaEligibilitate:'',
                mobile:false,
            };
        },
        created() {
            this.isMobile()
        },
        mounted() {
        },
        methods: {
            formatUtcDate,
            limitWords,
            isMobile() {
                if (screen.width <= 760) {
                    this.mobile = true;
                } else {
                    this.mobile = false;
                }
            },
            clasaFirmeEligibilitate(value) { 
                
                if (value === "false"){
                    return 'companiiEligibileMinus'
                } else if (value > 0){
                    return 'companiiEligibile' 
                } else {
                    return 'companiiEligibileNa'
                }
                            
            },
            getId(id){
                this.$emit('getId', { id, done : () => {
                    this.$forceUpdate();
                } });
            },
        }
    };
</script>

<style scope>
#ViewTab{
    background: #f0f0f0;
    padding: 20px 20px 0;
}
#ViewTab .contentSquare{
    background: #fff;
    margin-bottom: 30px;
}
#ViewTab h1{
    margin-bottom: 5px;
}
#ViewTab h1, 
#ViewTab p{
    font-size: 12px;
    text-align: left;
}
.contentSquare h1{
  padding-left: 60px;
  line-height: 34px;
}
#ViewTab .displayFlex{
    display: flex;
}
#ViewTab .flexStart{
    justify-content: flex-start;
}
#ViewTab .middleAlign{
    align-items: center;
}
#ViewTab .columnDirection{
    flex-direction: column;
}
#ViewTab .header{
    background: linear-gradient(0deg,#9ba2a8, #7c8285);
    color: #9ba2a8;
    padding: 10px 20px;
    box-sizing: content-box;
}
#ViewTab .header p{
    color: #fff;
    font-weight: bold;
}
.contentSquare strong{
  color: #383838;
}
#ViewTab .contentSquare{
    padding: 0px 20px 20px 20px;
    position: relative;
}
#ViewTab .headerLogo{
    position: absolute;
    top: -13px;
    width: 50px;
    height: auto;
}
#ViewTab .rightLogo{
    position: relative;
    bottom: 25px;
    width: 100px;
    right: 20px;
    height: auto;
}
#ViewTab .backgroundGry,
#ViewTab .backgroundGreen,
#ViewTab .backgroundRed{
    padding: 3px 10px;
    color:#fff;
    margin: 8px 10px;
}
#ViewTab .backgroundGry{    
    background: #969696;
}
#ViewTab .backgroundGreen{
    background: #08c7a2;
}
#ViewTab .backgroundRed{
    background: #ff6666;
}
#ViewTab .textGreen,
#ViewTab .textRed{
    font-weight: bold;
    padding-top: 30px;
}
#ViewTab .textGreen{
    color: #08c7a2;
}
#ViewTab .textRed{
    color: #ff6666;
}
#ViewTab .iconLink{
    font-size: 27px;
    cursor: pointer;
    color: #b4b4b4;
}
#ViewTab .spaceBetween{
    justify-content: space-between;
}
#ViewTab .footerZone div{
    align-self: flex-end;
    font-size: 12px;
}
#ViewTab .companiiEligibile{
    background: #08c7a2;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    color: #fff;
    padding: 5px;
    line-height: 1;
    width: 26px;
    height: 26px;
    margin: 0 0 10px 20px;
    border: 1px solid #fff;
}
#ViewTab .companiiEligibileMinus{
    background: #676767; 
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    color: #fff;
    padding: 5px;
    line-height: 1;
    width: 26px;
    height: 26px;
    margin: 0 0 10px 20px;
    border: 1px solid #fff;
}
#ViewTab .companiiEligibileNa{
    background: #b5b5b5;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    color: #fff;
    padding: 3px;
    line-height: 1.5;
    width: 26px;
    height: 26px;
    margin: 0 0 10px 20px;
    border: 1px solid #fff;
    font-size: 11px;
}
.heartFavorite{
  margin-left: 20px;
}
.rightLogoMobile{
    width:50px;
    height: 33px;
 
   
}

@media(max-width:750px){
    .programNameContainer{
        position:relative;
        padding-top: 20px;
    }
     .programNameContainer h1{
         padding: 0.75rem 0;
         line-height: 1.25rem;
     }
     .clasaApel{
         display: block;
         width: fit-content;
     }
     #ViewTab .backgroundGry, #ViewTab .backgroundGreen, #ViewTab .backgroundRed{
         margin: 8px 0;
     }
     #divDataDeschidereInchidere{
         flex-direction: column;
}
     #ViewTab .footerZone div{
         align-self: flex-start;
         margin: 0.2rem 0rem;
     }
     .imagesContainer{
         display: flex;
         justify-content: space-between;
     }
     #ViewTab .headerLogo{
         position: relative;
         top: auto;
     }

     #divDataDeschidereInchidere ml-3{
         margin-left: 0 !important;
     }

     .footerZone{
         margin-top: 15px;
     }
}
@media only screen and (max-width: 1600px) {
  .col-xl-4{
      max-width: 50% !important;
      flex: none;
  }
}

@media only screen and (max-width: 1050px) {
  .col-xl-4{
      max-width: 100% !important;
      flex: none;
  }
}
</style>