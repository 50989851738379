<template>
    <div>
        <div>
            <BlueTitleBar
                title="COMPANIA MEA"
                subtitle="Informații companie"
            ></BlueTitleBar>
        </div>
        <div class="viewPagePadding">
            <ContentBox2></ContentBox2>
        </div>
    </div>
</template>

<script>
    import ContentBox2 from "./components/ContentBox2";
    import BlueTitleBar from "@/common/components/BlueTitleBar";
    export default {
        name: "Test",
        components: {
            ContentBox2,
            BlueTitleBar,
        },
        props: {},
        data() {
            return {};
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
	
</style>