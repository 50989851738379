<template>
    <div>
        <div class="contact-rapid">
            <h3 class="section-title mb-2 text-right pb-2"><strong>Aplicațiile</strong> Smis</h3>
            <div class="apps">
                <div v-for="(app, key) in data" :key="key">
                    <a :href="'https://'+app.app_link" v-if="app.app_link">
                        <img :src="app.logo" class="app-logo">
                    </a>
                    <a href="#" v-else>
                        <img :src="app.logo" class="app-logo">
                    </a>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: null,
    },
}
</script>

<style scoped>
.section-title {
    font-size: var(--moduleTitleSize);
    color: #4b4b4f;
    border-bottom: 1px solid #dfdfe0;
}

.contact-rapid p {
    color: #fff;
}

.apps {
    display: flex;
    max-width: 410px;
    flex-flow: wrap;
}

.apps div {
    margin-bottom: 20px;
    width: 33.33%;
    padding: 5px 10px;
    background: border-box;
}

.apps div {
    border-bottom: 1px solid #dfdfe0;
}

.app-logo {
    max-width: 110px;
    max-height: 25px;
    object-fit: cover;
}

@media only screen and (max-width: 1600px) {
    .apps {
        display: block;
        max-width: 410px;
        flex-flow: wrap;
    }
}
</style>