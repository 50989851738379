<template>
    <div v-if="!loading">
        <GradientTopSection :count="finantari.length"></GradientTopSection>
        <ViewList :data="finantari" @getId="getId($event)"></ViewList>
    </div>
    <LoadingSpinner v-else></LoadingSpinner>
</template>

<script>
    import LoadingSpinner from '@/common/components/LoadingSpinner'
    import GradientTopSection from './components/GradientTopSection';
    import ViewList from '../ToateFinantarile/components/ViewList';
    import api from '@/helpers/api';
    import axios from '@/helpers/axios';

    export default{
        components: {
            GradientTopSection,
            ViewList,
            LoadingSpinner,
        },
        data() {
            return{
                loading: true,
                finantari: {},
            }
        },
        mounted () {
            this.getFunding();
        },
        update () {
            this.$mount();
        },
        methods: {
            getFunding() {
                return  axios.get(api.API_ENDPOINTS.wishlistFunding)
                    .then(response => {
                        this.finantari = response.data
                    }).finally(() => this.loading = false)
            },
            getId(id) {
                let item = this.finantari.findIndex(finantare => finantare.id == id)
                this.finantari.splice(item, 1)
                this.$store.dispatch('auth/getUserData');
            }
        }

    }
</script>

