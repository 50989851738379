<template>
    <div>
        <div class="newsCategory">
            <div class="news-head">
                <div class="flex">
                    <div class="news-left">
                            <img v-if="stire.funder" :src="stire.funder.logo">
                            <span v-if="stire.funder" class="label" :style="{ 'background': stire.funder.color }">{{stire.funder.acronym}}</span>
                            <span v-if="stire.program" class="label" :style="{ 'background': stire.program.color }">{{stire.program.acronym}}</span>
                            <div v-if="stire.tags.length > 0">
                                <span class="label" v-for="(tags, key) in stire.tags" :key="key" :style="{ 'background': tags.color }">{{tags.name}}</span>
                            </div>
                    </div>
                    <div class="news-icons">
                        <HeartFavorite
                                class="favorite"
                                :postTo="wishlistMethod"
                                :isFavorite="stire.wishlisted"
                                :id="stire.id"

                        ></HeartFavorite>
                        <span class="favorite-count">{{ stire.wishlist_count }}</span>
                        <div class="icon" @click="ShowList"><b-icon-list-nested></b-icon-list-nested></div>
                    </div>
                </div>
            </div>
            <div class="news-title mt-3">
                <router-link :to="'/stire/'+stire.id">
                    <p class="title">{{stire.title}}</p>
                </router-link>

            </div>
            <div class="news-body" v-bind:class="{ hidden: listaVisible === false }">
                <div class="description-news" v-html="stire.description"></div>
                <img v-if="stire.banner" :src="stire.banner" class="news-image">
            </div>
            <div class="news-footer flex">
                <div class="icons">
                    <div class="icon-big">
                        <HeartFavorite
                                :postTo="wishlistMethod"
                                :isFavorite="stire.wishlisted"
                                :id="stire.id"
                                class="favorite"
                                @getId="getId(stire.id)"
                        ></HeartFavorite>
                    </div>
                    <div class="icon-big"><b-icon-chat></b-icon-chat></div>
                    <div class="icon-big"><b-icon-cursor @click="openShare()"></b-icon-cursor></div>
                    <div class="share-box" v-if="shareShow">
                        <a :href="'https://www.facebook.com/sharer/sharer.php?u='+api.APP_URL+'stire/'+stire.id"><font-awesome-icon :icon="['fab', 'facebook-f']"></font-awesome-icon></a>
                        <a :href="'http://twitter.com/share?url='+api.APP_URL+'stire/'+stire.id" target="_blank"><font-awesome-icon :icon="['fab', 'twitter']"></font-awesome-icon></a>
                        <a :href="'https://pinterest.com/pin/create/button/?url='+api.APP_URL+'stire/'+stire.id" target="_blank"><font-awesome-icon :icon="['fab', 'pinterest-p']"></font-awesome-icon></a>
                        <a :href="'https://www.linkedin.com/cws/share?url='+api.APP_URL+'stire/'+stire.id" target="_blank"><font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon></a>
                    </div>
                </div>
                <div class="date">
                    <span>{{formatUtcDate(stire.created, 'DD MMMM YYYY')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeartFavorite from '@/common/components/HeartFavorite';
import {BIconListNested, BIconChat, BIconCursor, BModal, BCarousel, BCarouselSlide} from 'bootstrap-vue'
import {formatUtcDate} from '@/helpers/helpers'
import api from '@/helpers/api'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faFacebookF} from '@fortawesome/free-brands-svg-icons'
import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import {faPinterestP} from '@fortawesome/free-brands-svg-icons'
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons'


library.add(faFacebookF, faPinterestP, faTwitter, faLinkedinIn)
export default {
    components: {
        BIconListNested,
        HeartFavorite,
        BIconChat,
        BIconCursor,
        BModal,
        BCarousel,
        BCarouselSlide,
        FontAwesomeIcon
    },
    props: {
        title: null,
        stire: null,

    },
    data() {
        return {
            listaVisible: false,
            modalShow: false,
            wishlistMethod: 'wishlistMethod',
            shareShow: false,
            api: api
        };
    },
    created() {
    },
    mounted() {
    },
    methods: {
        formatUtcDate,
        ShowList() {
            if(this.listaVisible === true){
                this.listaVisible = false
            } else if(this.listaVisible === false){
                this.listaVisible = true
            }
        },
        getId(){
            this.$emit('refreshFavNews', 'aicea');
            if(this.stire.wishlisted) {
                this.stire.wishlist_count--
                this.stire.wishlisted = false;
            } else {
                this.stire.wishlist_count++
                this.stire.wishlisted = true;
            }

            console.log('this.stire.wishlisted', this.stire.wishlisted);

        },
        openShare() {
            this.shareShow = this.shareShow !== true;
        }
    },
};
</script>

<style scoped>
.newsCategory{
    background: #fff;
    padding: 15px;
}
.news-body{
    transition: .5s;
    max-height: 600px;
    z-index: -1;
}
.hidden{
    opacity: 0;
    max-height: 0;
    padding: 0 !important;
    transition: max-height 0.6s, opacity 0.4s, top 0.4s ease;
    z-index: -1;
    position: relative;
}
.news-head{
    z-index: 1;
    position: relative;
}
.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.news-left{
    display: flex;
    align-items: center;
}
.news-left img{
    max-height: 45px;
    object-fit: cover;
}
.icon{
    color: #7a7a7a;
}
.label{
    padding: 0px 8px;
    height: fit-content;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    margin-left: 10px;
}
.news-icons{
    display: flex;
    align-items: center;
    align-self: flex-start;
}
.favorite >>> svg{
    font-size: 16px;
    margin-right: 5px;
    color: #7a7a7a;
}
.favorite-count{
    color: #7a7a7a;
    font-size: 12px;
    margin-right: 10px;
}
.title{
    font-size: 14px;
    color: #807c87;
    font-weight: 600;
    text-align: left;
    padding-bottom: 10px;
}
.news-body{
    padding: 15px 0;
}
.description-news{
    font-size: 12px;
    color: #807c87;
}
.icon-big{
    cursor: pointer;
}
.icon-big >>> svg{
    font-size: 24px !important;
    color: #807c87;
}
.icon-big:not(:first-child){
    margin-left: 15px;
}
.news-footer{
    padding-top: 20px;
    border-top: 1px solid #eeeff0;
    background: #fff;
}
.icons{
    display: flex;
}
.news-image{
    width: 100%;
    max-height: 130px;
    object-fit: cover;
    margin: 10px 0;
    -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}
.date{
    color: #807c87;
    font-size: 14px;
}
.share-box{
    margin-left: 20px;
    border: 1px solid #807c87;
    padding: 0 5px;
    border-radius: 3px;
    position: relative;
}
.share-box:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 26%;
    border-top: 6px solid transparent;
    border-right: 10px solid #807c87;
    border-bottom: 6px solid transparent;
}
.share-box svg{
    color: #807c87;
}
.share-box svg:hover path{
    color: #48474d;
}
.share-box a:not(:last-child){
    margin-right: 15px;
}
</style>