<template>
    <div class="box">
        <div class="box-title">
            Pasul 3 <span class="space-text">/</span>
            <span class="title-text"> Detalii identitate vizuală</span>
        </div>
        <div class="box-content">
            <div class="first-content treime">
                <div class="first-text">
                    <div style="margin-bottom: 20px">
                        <p>Bine ai venit! Firma ta este</p>
                        <p class="bold-gradient titlu-firma">FORMARE PROFESIONALA PLUS SRL</p>
                    </div>
                    <p class="text-center-margin">
                        Pentru a finaliza înregistrarea este necesar să introduci datele pentru
                        comunicatul de presă și elementele de identitate.
                    </p>
                    <p class="text-center-margin title-text">
                        Aceste informații sunt publice, îți recomandăm să le completezi cu atenție.
                        Mulțumim!
                    </p>

                    <form action="" class="box-content-text">
                        <p class="output-text">E-mail</p>
                        <input type="text" >
                    </form>
                    <form action="" class="box-content-text">
                        <p class="output-text">Telefon</p>
                        <input type="text" >
                    </form>
                    <form action="" class="box-content-text">
                        <p class="output-text">Site web (dacă există)</p>
                        <input type="text" >
                    </form>
                    <form action="" class="box-content-text">
                        <p class="output-text">Persoană contact</p>
                        <input type="text" >
                    </form>
                    <p style="text-align: center; margin: 22px 0; font-weight: bold">
                        Logo companie. Alege logo companie, dacă acesta există. Vă rugăm să selectați
                        un logo in format PNG sau JPEG
                    </p>

                    <div style="display: flex; justify-content: space-between">
                        <div style="align-self: center">
                            <ButtonGradient
                                url-buton="dadsdsss"
                                text="Alege logo-ul companiei"
                                classname="empty"
                            ></ButtonGradient>
                        </div>
                        <img class="gr-pic" alt="GR logo" src="@/assets/images/logoguvern.png" >
                    </div>
                </div>
            </div>
            <div class="first-content treime">
                <div class="first-text">
                    <p style="text-align: center">Alege datele de facturare</p>
                    <p style="text-align: center" class="bold-gradient">
                        Vom emite factura exclusiv la solicitarea ta.
                    </p>
                    <p style="text-align: center; color: #7980a6">
                        (ai acces la contul tău gratuit pentru o perioadă nelimitată)
                    </p>

                    <form action="#" style="margin-bottom: 50px">
                        <div class="radio">
                            <input id="primit" type="radio" name="radio-group" checked class="radio4" >
                            <label for="primit">Doresc ca factura să imi fie emisă pe această firma
                            </label>
                        </div>
                        <div class="radio">
                            <input id="neprimit" type="radio" name="radio-group" class="radio4" >
                            <label for="neprimit">Doresc ca factura să imi fie emisă pentru o firmă diferită</label>
                        </div>
                    </form>
                    <p class="bold-gradient" style="margin-bottom: 25px">
                        Vom emite factura exclusiv la solicitarea ta.
                    </p>
                    <form action="" class="box-content-text">
                        <p class="output-text">CUI</p>
                        <input type="text" >
                    </form>
                    <p class="bold-gradient" style="margin: 10px 0 25px 0">
                        Vom emite factura exclusiv la solicitarea ta.
                    </p>
                    <form action="" class="box-content-text">
                        <p class="output-text">Nume si prenume</p>
                        <input type="text" >
                    </form>
                    <div>
                        <p class="border-pic">
                            Contul tău <span class="bold-gradient">gratuit</span> îți permite accesul la
                            o serie limitată de funcții. Pentru deblocarea funcțiilor suplimentare este
                            necesară achiziția unei licențe
                            <span class="bold-gradient"> M2manager. </span> Pentru a realiza acest lucru
                            vă rugăm să vizitați ulterior înscrierii, contul de aplicați SMIS.
                        </p>
                    </div>
                </div>
            </div>
            <div class="first-content treime">
                <div class="first-text">
                    <p class="bold-gradient" style="text-align: center; margin: 20px 0px">
                        Elemente de identitate vizuală
                    </p>
                    <p class="text-center-margin">
                        Mulțumim pentru completarea tuturor informaților După ce faceți click pe
                        butonul Continuă, veți fi redirecționat către pagina de identitate vizuală,
                        unde ți-am pregătit complet gratuit comunicatul de presă, un micro site web de
                        prezentare a proiectului, afișul A3 obligatoriu precum și etichetele de
                        inventar și un cod embed.
                    </p>
                </div>
                <img
                    class="pic-big"
                    alt="Poza Mare"
                    src="@/assets/images/pic_CompaniaMeaP3.png"
                >
                <ButtonGradient
                    class="buton-continua"
                    url-buton="dadsdsss"
                    text="Continuă >"
                    classname="background"
                ></ButtonGradient>
            </div>
        </div>
    </div>
</template>

<script>
    import ButtonGradient from "@/common/components/ButtonGradient";
    export default {
        name: "Test",
        components: {
            ButtonGradient,
        },
    };
</script>

<style scoped>
	.box {
		align-items: center;
		color: rgb(0, 0, 0);
		margin: 10px 0 0 10px;
		border: 1px solid #e7e7e7;
		
	}
	.treime {
		width: 33%;
		border-right: 2px solid #eeeeef;
	}
	.treime:last-child {
		width: 34%;
		border-right: 0px solid #eeeeef;
	}
	.box-title {
		background-color: #f8f8f8;
		color: #5744cf;
		box-sizing: border-box;
		padding: 20px 10px 20px 25px;
	}
	.box-content {
		background-color: #fff;
		display: flex;
		flex-direction: row;
		width: 100%;
	}
	.box-content-text {
		background-color: #fff;
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-bottom: 15px;
	}
	.box-content-text input {
		padding: 4px;
		margin-left: 15px;
	}
	.first-content {
		display: flex;
		flex-direction: column;
	}
	.first-text {
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		padding-top: 20px;
		color: #787878;
		padding: 20px 30px 30px;
		width: 100%;
	}
	input[type="text"] {
		background-color: #fff;
		color: #757575;
		font-size: 17px;
		border: 2px solid #edebef;
		border-radius: 5px;
		width: 100%;
	}
	input[type="text"]::placeholder {
		color: #757575;
	}
	label {
		cursor: pointer;
		display: flex;
		align-items: center;
		margin: 20px 5px 10px 45px;
	}
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + label::before {
		content: "";
		display: inline-block;
		border: 1px solid #dfd7da;
		border-radius: 50%;
		margin: 0 0.5em;
		width: 18px;
		height: 18px;
		min-width: 18px;
	}
	input[type="radio"]:checked + label::before {
		background-color: #2b01e8;
		box-shadow: 0px 3px 10px 3px #cec4fa;
	}
	.radio4 + label::before {
		width: 1.5em;
		height: 1.5em;
	}
	.output-text {
		color: #8f8f8f;
		font-size: 15px;
		border: 2px solid #edebef;
		border-radius: 5px;
		padding: 0;
		max-width: 176px;
		width: 100%;
		text-align: center;
		padding-top: 3px;
	}
	.title-text {
		font-weight: bold;
	}
	.text-center-margin {
		text-align: center;
		margin-bottom: 30px;
	}
	.bold-gradient {
		background: -webkit-linear-gradient(#511cca, #178fc5);
		background-clip: unset;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: 900;
	}
	.gr-pic {
		width: 65px;
		height: 65px;
		padding-right: 90px;
	}
	.border-pic {
		border: 1px solid #5514ca;
		padding: 5px 10px;
		margin-top: 30px;
		max-width: 100%;
		border-radius: 5px;
		color: #8772d8;
		text-align: center;
	}
	.pic-big {
		max-width: 220px;
		height: auto;
		align-self: center;
		margin-bottom: 65px;
	}
	.buton-continua {
		align-self: flex-end;
		margin: 5px 55px;
	}
	@media(max-width:1024px) and (min-width:769px){
		label{
			margin: 0;
			margin-top: 10%;
		}
	}
	@media (max-width:768px) {
		.box-content{
			display: block;
		}
		.treime{
			width:100%;
		}
		.treime:last-child{
			width: 100%;
		}
		.buton-continua{
			align-self: start;
		}
	}
	@media (max-width:500px){
		.box-padding{
			padding: 0;
		}
		.box{
			margin: 10px;
		}
	}
</style>
