<template>
  <div>
    <DarkBlueTitleBar :data="title"></DarkBlueTitleBar>
    <PrezentareHead :data="tipCompanie"></PrezentareHead>
    <InfoCompanie :data="tipCompanie"></InfoCompanie>
  </div>
</template>

<script>
import DarkBlueTitleBar from '@/common/components/DarkBlueTitleBar';
import PrezentareHead from './components/PrezentareHead';
import InfoCompanie from './components/InfoCompanie';
export default {
  name: "PrezentareCompanie",
  components: {
    DarkBlueTitleBar,
    PrezentareHead,
    InfoCompanie,
  },
  data() {
    return {
      title: 'Profil companie',
      tipCompanie: 'basic',
    };
  },
}
</script>

<style scoped>

</style>