var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contul-meu-background"},[_c('div',{staticClass:"formular-contul-meu"},[_c('validation-observer',{ref:"accountForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.changeAccountDetails()}}},[(_vm.imageData.length > 0)?_c('b-img',{directives:[{name:"show",rawName:"v-show",value:(_vm.imageData),expression:"imageData"}],ref:"previewEl",staticClass:"preview",attrs:{"src":_vm.imageData}}):_c('b-img',{staticClass:"curent-pic",attrs:{"src":require('@/assets/images/' + _vm.imagineCurenta + '.png')}}),_c('b-form-group',{staticClass:"bold-label",attrs:{"id":"input-group-1","label":"Imagine de profil"}},[_c('b-card-text',{staticClass:"text-tip-imagine"},[_vm._v("Acceptă JPG, GIF sau PNG. Maximum 800kB")]),_c('b-form-file',{attrs:{"name":"image","accept":".jpg, .png, .gif"},on:{"change":_vm.previewImage}})],1),_c('b-form-group',{staticClass:"bold-label",attrs:{"id":"input-group-2","label":"Nume complet"}},[_c('validation-provider',{attrs:{"rules":"required","name":"mesaj","min":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","type":"text","name":"message","state":errors.length > 0 ? false : null,"placeholder":"Nume complet"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"bold-label",attrs:{"id":"input-group-3","label":"E-mail login"}},[_c('validation-provider',{attrs:{"rules":"required","name":"mesaj","min":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"bold-label",attrs:{"id":"input-group-4","label":"Telefon"}},[_c('validation-provider',{attrs:{"rules":"required","name":"mesaj","min":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Telefon"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"buton-submit-setari-cont",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_vm._v("Salvează modificările")])],1)]}}])})],1),_c('div',{staticClass:"formular-contul-meu"},[_vm._m(0),_c('validation-observer',{ref:"passwordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.changePassword()}}},[_c('b-form-group',{staticClass:"bold-label",attrs:{"id":"input-group-ps1","label":"Vechea parolă"}},[_c('validation-provider',{attrs:{"rules":"required","name":"mesaj","min":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"currentPassword","type":_vm.passwordFieldTypeOld,"state":errors.length > 0 ? false : null},model:{value:(_vm.passwordForm.currentPassword),callback:function ($$v) {_vm.$set(_vm.passwordForm, "currentPassword", $$v)},expression:"passwordForm.currentPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('span',{staticClass:"fa fa-fw fa-eye field-icon toggle-password",attrs:{"toggle":"#password-field"},on:{"click":_vm.togglePasswordOld}})],1),_c('b-form-group',{staticClass:"bold-label",attrs:{"id":"input-group-ps2","label":"Noua parolă"}},[_c('validation-provider',{attrs:{"rules":"required","name":"mesaj","min":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"newPassword","type":_vm.passwordFieldTypeNew,"state":errors.length > 0 ? false : null},model:{value:(_vm.passwordForm.newPassword),callback:function ($$v) {_vm.$set(_vm.passwordForm, "newPassword", $$v)},expression:"passwordForm.newPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('span',{staticClass:"fa fa-fw fa-eye field-icon toggle-password",attrs:{"toggle":"#password-field"},on:{"click":_vm.togglePasswordNew}})],1),_c('b-form-group',{staticClass:"bold-label",attrs:{"id":"input-group-ps3","label":"Reintroduceți noua parolă"}},[_c('validation-provider',{attrs:{"rules":"required","name":"mesaj","min":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirmPassword","type":_vm.passwordFieldTypeRetype,"state":errors.length > 0 ? false : null},model:{value:(_vm.passwordForm.confirmPassword),callback:function ($$v) {_vm.$set(_vm.passwordForm, "confirmPassword", $$v)},expression:"passwordForm.confirmPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('span',{staticClass:"fa fa-fw fa-eye field-icon toggle-password",attrs:{"toggle":"#password-field"},on:{"click":_vm.togglePasswordRetype}})],1),_c('div',{staticClass:"butoane-securitatea-contului"},[_c('b-button',{staticClass:"buton-submit-setari-cont",attrs:{"type":"submit","disabled":invalid,"variant":"primary"}},[_vm._v("Salvează modificările")]),_c('b-button',{staticClass:"buton-submit-sterge-cont",attrs:{"variant":"danger"},on:{"click":function($event){_vm.modalShow = !_vm.modalShow}}},[_vm._v("Șterge definitiv contul")])],1)],1)]}}])})],1),_c('b-modal',{attrs:{"id":"blackModal","button-size":"sm","ok-variant":"success","ok-title":"Confirmă","cancel-variant":"danger","cancel-title":"Anulează","header-bg-variant":"dark","body-bg-variant":"dark","hide-header-close":""},on:{"ok":_vm.deleteAccount},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('StergeUtilizator')],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-securitate-cont"},[_c('div',[_c('p',{staticClass:"text-gradient"},[_vm._v("Securitatea contului")]),_c('p',{staticClass:"text-mic"},[_vm._v("schimbă parola")])]),_c('img',{attrs:{"src":require("@/assets/images/ContulMeuShield.png"),"alt":""}})])}]

export { render, staticRenderFns }