<template>
    <div>
        <div>
            <BlueTitleBar
                title="COMPANIA MEA"
                subtitle="Detalii identitate vizuală"  
            ></BlueTitleBar>
        </div>
        <div class="box-padding">
            <ContentBox3></ContentBox3>
        </div>
    </div>
</template>

<script>
    import ContentBox3 from './components/ContentBox3'
    import BlueTitleBar from '@/common/components/BlueTitleBar'
    export default {
        name: "Test",
        components: {
            ContentBox3, BlueTitleBar },
        props: {},
        data() {
            return {};
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
	.box-padding {
		padding: 20px 70px 0px 20px;
	}
    @media (max-width:500px){
		.box-padding{
			padding: 0;
		}
		
	}
</style>