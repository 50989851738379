<template>
    <div id="chenarGradient">
        <div>
            <h1>Finanțări favorite</h1>
        </div>
        <div class="flexClass spaceBetween bigMargin info-platforma">
            <div>
                <p>Regăsești mai jos toate finanțările tale favorite. Simplu. Rapid. </p>
                <p>Pentru a vizualiza toate finanțările acceseaza meniul <strong>Toate Finanțările</strong>.</p>
            </div>
        </div>
        <div class="flexClass spaceBetween poza-finantarii ">
            <div class="flexClass count-finantari">
                <div class="flexClass middleAlign numar-finantari">
                    <div class="flexClass">
                        <p class="bigText">{{ count }}</p>
                    </div>
                    <div>
                        <p>Finanțări<br>prezentate</p>
                    </div>
                </div>
            </div>
            <div class="poza-finantari-container">
                <b-icon-heart></b-icon-heart>
            </div>
        </div>
    </div>
</template>

<script>
    import {BIconHeart} from 'bootstrap-vue'
    export default{
        components: {
            BIconHeart
        },
        props: {
            count: {
                type: Number,
                default: 0
            },
        }
    }
</script>

<style scoped>
h1,
p{
  color:#fff;
}
h1,
p{
  margin-bottom: 0;
}
h1{
  font-size: 20px;
  margin-bottom: 0;
}
#chenarGradient{
  width:100%;
  background: linear-gradient(180deg, #cc5e86, #de889d, #e4bd94);
  padding: 15px 30px;
  box-sizing: border-box;
}
.bigText{
  font-size: 40px;
  font-weight: 600;
  margin-right: 5px;
}
.flexClass{
  display: flex;
}

.flexClass div{
  flex-direction: row;
}
.spaceBetween{
  justify-content: space-between;
}
.textRight{
  text-align: right;
}
.middleAlign{
  align-items: center;
}
.middleAlign p{
  line-height: 13px;
}
.bigMargin{
  margin:25px 0;
}
.count-finantari{
  width: 50%;
  justify-content: space-between;
}
.poza-finantari-container svg {
  font-size: 35px;
  color: #fff;
}
.poza-finantarii img{
  object-fit: contain;
}
@media (max-width:768px){
  .poza-finantarii{
    display: block;

  }
  .count-finantari{
    justify-content: space-evenly;
    width:100% ;
    margin-bottom: 2rem;
  }
}
@media(max-width:540px){
  .poza-finantari-container img {
    display: block;
    width:280px;

  }
  .info-platforma{
    display: block;
  }
  .textRight{
    text-align: start;
  }
  .count-finantari{
    display: block;
  }
  .numar-finantari{
    display: flex;
    margin: 1.5rem 0;
  }

}
</style>