<template>
    <div class="top-bar">
        <ul>
          <li><strong><span>{{data}}</span></strong></li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            data:{
              type: String,
              default: null,
            }
        },
        data() {
            return {
            }
        },
      computed: {
      }
    }
</script>

<style scoped>
	.top-bar {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding: 12px 20px;
		background: linear-gradient(to bottom, #152856, #292043);
		color: #fff;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}
    .top-bar span{
        font-size: 15px;
        font-weight: 600;
    }
    ul{
        display: flex;
        margin: 0;
        flex-wrap: wrap;
        justify-content: center;
        
    }
    ul > li{
        margin-right: 10px;
    }
    a{
        color:#fff;
    }
@media only screen and (max-width: 900px) {
    .top-bar>div{
        margin: 5px auto;
    }  
    .top-bar {
        text-align: center;
    } 
}
</style>
