<template>
    <div class="modul-finantare-title">
        <div class="top-line">
            <span class="bold-text title">Comentarii / {{ commentsNumber }}</span>
        </div>
        <div class="modul-finantare-content">
            <div v-for="(comment, key) in parentComments" id="commentsContainer" :key="key" class="comment">
                <div class="avatar">
                    <img :src="comment.logo">
                </div>
                <div class="comment-info">
                    <p class="name">{{ comment.author }}</p>
                    <p class="date">
                        {{ formatUtcDate(comment.created, 'DD.MM.YYYY') }} la {{ formatUtcDate(comment.created, 'HH:mm') }} -
                        <span v-if="!comment.isSelectedForReply" @click="comment.isSelectedForReply = true">RĂSPUNDE</span>
                        <span v-else @click="comment.isSelectedForReply = false">ANULEAZA</span>
                        <span v-if="comment.can_be_edited === true"> | </span>
                        <span v-if="!comment.isSelectedForEdit && comment.can_be_edited === true" @click="comment.isSelectedForEdit = true">EDITEAZĂ</span>
                        <span v-else-if="comment.isSelectedForEdit" @click="comment.isSelectedForEdit = false">ANULEAZĂ</span>
                    </p>
                    <p v-if="!comment.isSelectedForEdit" class="message">{{ comment.comment }}</p>
                    <EditeazaComentariuFinantare v-if="comment.isSelectedForEdit" class="reply" :comment="comment.comment" :idComentariu="comment.id" @finishEdit="finishEdit(comment.id, $event)"></EditeazaComentariuFinantare>
                </div>
                <div v-for="(commentReply, k) in comment.children" :key="k" class="comment reply">
                    <div class="avatar">
                        <img :src="commentReply.logo">
                    </div>
                    <div class="comment-info">
                        <p class="name">{{ commentReply.author }}</p>
                        <p class="date">
                            {{ formatUtcDate(commentReply.created, 'DD.MM.YYYY') }} la {{ formatUtcDate(commentReply.created, 'HH:mm') }} -
                            <span v-if="!commentReply.isSelectedForReply" @click="commentReply.isSelectedForReply = true">RĂSPUNDE</span>
                            <span v-else @click="commentReply.isSelectedForReply = false">ANULEAZA</span>
                            <span v-if="commentReply.can_be_edited === true"> | </span>
                            <span v-if="!commentReply.isSelectedForEdit && commentReply.can_be_edited === true" @click="commentReply.isSelectedForEdit = true">EDITEAZĂ</span>
                            <span v-else-if="commentReply.isSelectedForEdit" @click="commentReply.isSelectedForEdit = false">ANULEAZĂ</span>
                        </p>
                        <p v-if="!commentReply.isSelectedForEdit" class="message">{{ commentReply.comment }}</p>
                        <EditeazaComentariuFinantare v-if="commentReply.isSelectedForEdit" class="reply" :comment="commentReply.comment" :idComentariu="commentReply.id" @finishEdit="finishEdit(commentReply.id, $event)"></EditeazaComentariuFinantare>
                        <AdaugaComentariuFinantare v-if="commentReply.isSelectedForReply" class="reply" :idComentariu="commentReply.id" :tag="commentReply.author" @newCommentAdd="addNewComm($event)"></AdaugaComentariuFinantare>
                    </div>
                </div>
                <AdaugaComentariuFinantare v-if="comment.isSelectedForReply" class="reply" :idComentariu="comment.id" :tag="comment.author" @newCommentAdd="addNewComm($event)"></AdaugaComentariuFinantare>
            </div>
            <div id="newParentComment"></div>
            <AdaugaComentariuFinantare v-if="showMainCommentTab" :idComentariu="null" @newCommentAdd="addNewComm($event)"></AdaugaComentariuFinantare>
        </div>
    </div>
</template>

<script>
    import AdaugaComentariuFinantare from "../components/AdaugaComentariuFinantare";
    import EditeazaComentariuFinantare from "../components/EditeazaComentariuFinantare";
    import api from '@/helpers/api'
    import axios from '@/helpers/axios'
    import {formatUtcDate} from '@/helpers/helpers'
    import _ from "lodash";

    export default {
        components: {
            AdaugaComentariuFinantare,
            EditeazaComentariuFinantare,
        },
        props: {
        },
        data() {
            return {
                commentsNumber: null,
                showMainCommentTab: true,
                parentComments: [],
                createdComments: [],
                allComments: null,
                selectedComment: null,
            };
        },
        mounted() {
            this.getComments();
        },
        methods: {
            getCommentChild(id) {
                if(id) {
                    this.commentFiltered = _.filter(this.allComments, v => v.parent_id === id)
                    return _.reject(this.commentFiltered, ['original_parent_id', null])
                }
            },
            getComments() {
                let id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
                axios.get(api.API_ENDPOINTS.fundingComments + '/' + id)
                    .then(response => {
                        this.commentsNumber = response.data.length
                        this.allComments = response.data;
                        response.data.forEach(comment =>{
                            if(comment.parent_id === null){

                                let children = [];

                                this.getCommentChild(comment.id).forEach(child => {
                                    children.push({
                                        id: child.id,
                                        author: child.author,
                                        logo: child.logo,
                                        parent_id: child.parent_id,
                                        original_parent_id: child.original_parent_id,
                                        comment: child.comment,
                                        can_be_edited: child.can_be_edited,
                                        created: child.created,
                                        isSelectedForReply: false,
                                        isSelectedForEdit: false,
                                    })
                                })

                                this.parentComments.push({
                                    id: comment.id,
                                    author: comment.author,
                                    logo: comment.logo,
                                    parent_id: comment.parent_id,
                                    original_parent_id: comment.original_parent_id,
                                    comment: comment.comment,
                                    can_be_edited: comment.can_be_edited,
                                    created: comment.created,
                                    children: children,
                                    isSelectedForReply: false,
                                    isSelectedForEdit: false,
                                })
                            }
                        })
                    })
            },
            addNewComm(event) {
                this.commentsNumber++;
                if(event.parent_id === null){
                    this.parentComments.push({
                        id: event.id,
                        author: event.author,
                        logo: event.logo,
                        parent_id: event.parent_id,
                        original_parent_id: event.original_parent_id,
                        comment: event.comment,
                        can_be_edited: event.can_be_edited,
                        created: event.created,
                        children: [],
                        isSelectedForReply: false,
                        isSelectedForEdit: false,
                    });
                }
                else{
                    this.parentComments.forEach(parent => {
                        if(parent.id === event.parent_id){
                            parent.children.push({
                                id: event.id,
                                author: event.author,
                                logo: event.logo,
                                parent_id: event.parent_id,
                                original_parent_id: event.original_parent_id,
                                comment: event.comment,
                                can_be_edited: event.can_be_edited,
                                created: event.created,
                                isSelectedForReply: false,
                                isSelectedForEdit: false,
                            });
                            parent.isSelectedForReply = false;
                            if(event.parent_id !== event.original_parent_id){
                                parent.children.forEach(child => {
                                    if(event.original_parent_id === child.id){
                                        child.isSelectedForReply = false;
                                    }
                                })
                            }
                        }
                    })
                }

            },
            finishEdit(id, event) {
                let BreakException = {};
                try {
                    this.parentComments.forEach(parent => {
                        if(id === parent.id){
                            parent.comment = event;
                            parent.isSelectedForEdit = false;
                            throw BreakException;
                        }
                        else{
                            parent.children.forEach(child => {
                                if(id === child.id){
                                    child.comment = event;
                                    child.isSelectedForEdit = false;
                                    throw BreakException;
                                }
                            })
                        }
                    })
                } catch (e) {
                    if (e !== BreakException) throw e;
                }
            },
            formatUtcDate,
        },
    };
</script>

<style scoped>
.title {
    font-size: var(--moduleTitleSize) !important;
}

.bold-text {
    font-weight: bold;
}

.text-companii-eligibile >>> p {
    color: #868686;
    font-size: var(--paragraphSize);
}

.finantare-prezentata img {
    max-height: 35px;
}

.space-center li {
    margin-bottom: 10px;
}

.top-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modul-finantare-content {
    margin-top: 10px;
    color: #868686;
    font-size: 14px;
}
.comment{
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
    border-bottom: 1px solid #d7d7d7;
}
.comment, .reply{
    width: 100%;
}
.reply{
    margin-left: 100px;
    border-bottom: 0;
}
.avatar img{
    max-height: 70px;
    border-radius: 50%;
    object-fit: cover;
}
.avatar{
    margin-right: 30px;
}
.name{
    color: #4b4e53;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
}
.date{
    font-size: 12px;
    color: #807c87;
    font-weight: 500;
}
.date span{
    color: #4b4e53;
    cursor: pointer;
}
.message{
    font-size: 14px;
    color: #807c87;
    margin-top: 5px;
    width: 100%;
}
@media (max-width:1000px) {
    .comment{
        display: block;
    }
    .avatar {
        float: left;
    }
    .message {
        margin-top: 25px;
    }
    .reply {
        margin-left: 30px;
    }
    .reply .avatar{
        float: right;
    }
}
@media only screen and (max-width: 710px) {

    .top-line {
        flex-direction: column;
    }
}
</style>