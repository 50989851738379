<template>
    <div>
        <div class="box-padding">
            <div class="content-box">
                <span v-if="data" class="bold-text">Despre noi</span>
                <span v-else class="bold-text">Despre mine</span>
                <div class="desc-info mb-5 mt-2" v-html="consultant.about">
                </div>
                <span class="bold-text">Servicii oferite</span>
                <div class="servicii">
                    <ul class="lista-servicii mt-3">
                        <li v-for="(serviciu, key) in consultant.services" :key="key">
                            <b-icon-check-circle-fill
                                v-if="serviciu.checked"
                                style="margin-right: 10px; fill: var(--succesColor);"
                                font-scale="1"
                            ></b-icon-check-circle-fill>
                            <b-icon-x-circle-fill
                                v-else
                                style="margin-right: 10px; fill: var(--dangerColor);"
                                font-scale="1"
                            ></b-icon-x-circle-fill>
                            <span class="service-name">{{ serviciu.name }}</span>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="content-box feedback-utilizatori" v-if="recenzii.length > 0">
                <span class="bold-text">Feedback Utilizatori</span>
                <div class="review">
                    <b-carousel
                        class="carousel-center"
                        id="feedbackSlider"
                        :interval="4000"
                        indicators
                    >

                        <b-carousel-slide v-for="(recenzie, key) in recenzii" :key="key">
                            <div class="slide">
                                <div>
                                    <div class="top-slide">
                                        <div class="left">
                                            <p><strong>{{ recenzie.name }}</strong> #63B2A</p>
                                        </div>
                                        <div class="right">
                                            <b-form-rating id="rating-lg-no-border " :value="recenzie.rating"
                                                           color="#ffbe00" no-border size="sm" readonly></b-form-rating>
                                        </div>
                                    </div>
                                    <p>{{ recenzie.comment }}</p>
                                </div>
                            </div>
                        </b-carousel-slide>

                    </b-carousel>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {BIconCheckCircleFill, BIconXCircleFill, BCarouselSlide, BCarousel, BFormRating} from 'bootstrap-vue'

export default {
    name: "InfoCompanie",
    components: {
        BIconCheckCircleFill,
        BIconXCircleFill,
        BCarousel,
        BCarouselSlide,
        BFormRating
    },
    props: {
        data: null,
        consultant: null,
        recenzii: null,
    },
    data() {
        return {
            review: {
                stars: 4.5,
            },
        };
    },
}
</script>

<style scoped>
p {
    color: #727272;
}

.box-padding {
    padding: 20px 70px 0px 20px;
}

.content-box {
    background-color: #fff;
    color: #434343;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 2px 6px 1px #dddddd;
    max-width: 60%;
    margin-bottom: 25px;
}

.bold-text {
    font-weight: bold;
}

.lista-servicii {
    column-count: 2;
}

.feedback-utilizatori {
    margin-bottom: 120px;
}

.carousel-item {
    height: 160px;
    background: transparent;
}

#feedbackSlider >>> li {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: 1px solid #666666;
}

#feedbackSlider >>> .carousel-indicators .active {
    background: #666666;
}

#feedbackSlider >>> .carousel-caption {
    position: relative;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

.slide .form-control {
    background-color: transparent !important;
    max-width: 115px;
}

.slide p {
    font-style: italic;
    font-size: 12px;
}

.top-slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left p {
    font-size: 15px;
    font-style: normal;
    color: #3d3d3d;
}

.service-name {
    font-size: 15px;
    color: #727272;
}

@media only screen and (max-width: 900px) {
    .box-padding {
        padding: 20px 20px 0px 20px;
    }

    .content-box {
        max-width: 100%;
    }

    .content-box p {
        text-align: left;
    }

    .lista-servicii {
        column-count: 1;
    }

    .carousel-item {
        height: 100%;
    }
}
</style>