<template>
    <div>
        <b-form>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4">
                            
                            <b-form-group
                                label="Județ"
                            >
                                <b-form-select 
                                    v-model="data.county" 
                                    :options="judete"
                                >
                                </b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            
                            <b-form-group
                                label="Oraș"
                            >
                                <b-form-input
                                    v-model="data.city"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group
                                v-click-outside="closeAddressSuggetions"
                                label="Adresa"
                                class="position-relative"
                            >
                                <b-form-input
                                    v-model="data.address"
                                    debounce="1500"
                                    list="lista-adrese"
                                    autocomplete="off"
                                    @input="onInputChange"
                                    @click="openAddressSuggestions"
                                ></b-form-input>
                                <div v-if="search && results.length>0" id="lista-adrese" >
                                    <div v-for="adresa in results" :key="adresa.place_id" class="lista-adrese-item" @click="selectAddress(adresa)">{{ adresa.label }}</div>
                                </div>
                            </b-form-group>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <b-form-group
                                label="Persoana de contact"
                            >
                                <b-form-input
                                    v-model="data.contact_person"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group
                                label="Email"
                            >
                                <b-form-input
                                    v-model="data.email"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group
                                label="Telefon"
                            >
                                <b-form-input
                                    v-model="data.phone"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
            <b-button class="buton-submit-setari-cont" variant="primary" @click="onSubmit()">Salvează modificările</b-button>
        </b-form>
    </div>
</template>

<script>
    import {BForm, BFormGroup, BFormInput,BFormSelect,BFormTextarea, BButton} from 'bootstrap-vue'
    import { OpenStreetMapProvider } from 'leaflet-geosearch';
    import _ from 'lodash'




    export default {
        name:'Sediu',
        components: {
            BForm,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BButton,
            BFormSelect

        },
        directives: {
            'click-outside': {
                bind: function(el, binding, vNode) {
                   
                    // Provided expression must evaluate to a function.
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) { warn += `Found in component '${compName}'` }
          
                        console.warn(warn)
                    }
                    // Define Handler and cache it on the element
                    const bubble = binding.modifiers.bubble
                
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    }
                    
                    el.__vueClickOutside__ = handler

                    // add Event Listeners
                    document.addEventListener('click', handler)
                },
      
                unbind: function(el) {
                    // Remove Event Listeners
                    document.removeEventListener('click', el.__vueClickOutside__)
                    el.__vueClickOutside__ = null

                }
            }
        },
        props: {
            data: Object,
            judete:{
                type:Array,
                default:()=>[]
            }
        },
        data() {
            return {
                provider:new OpenStreetMapProvider({
                    params: {
                        'accept-language': 'ro', // render results in Romanian
                        countrycodes: 'ro' ,// limit search results to the Romania
                        limit:40,
                    }}),
                adrese:[],
                search:false,
            }
        },
        computed:{
            results(){
                return this.adrese.filter( adresa =>{
                    return adresa.label!=this.data.address
                })
            }
        },
    
        created(){
            this.onInputChange = _.debounce(this.onInputChange,1500)
        },
        mounted() {
        },
        methods: {
            onSubmit() {
                this.$emit('submitForm');
            },
            onInputChange(address){
                console.log('api call start',address)
                this.search = true;
                this.provider.search({
                    query: address,
                }).then( (result)=>{
                    console.log('datele au ajuns',result);
                    this.adrese=result
                 
                })
            },
            selectAddress(address){ 
                this.data.address = address.label

                this.$emit('getCoordinates',{lat:address.y,long:address.x})
                this.closeAddressSuggetions();      
            },
            openAddressSuggestions(){
                this.search = true;
            },
            closeAddressSuggetions(){
                this.search = false;
            },
          

        },
    }
</script>

<style scoped>
.padding-box{
    padding: 20px;
}
input, select, textarea{
    border-radius: 0;
    border: 1px solid #e8e8e8;
}
input:focus, textarea:focus{
    box-shadow: none;
}
.buton-submit-setari-cont {
    color: #fff;
    background-image: linear-gradient(180deg, #5b3cd0, #3bc6d4);
    border: none;
    font-size: var(--paragraphSize);
    font-weight: var(--bold);
}
#lista-adrese{
    z-index: 3;
    background: #fff;
    position: absolute;
    max-height: 300px;
    overflow-y: scroll;

}
.lista-adrese-item{
    cursor: pointer;
    padding: 0.2rem 0.3rem;
    border: 1px solid #e8e8e8;  
}
.lista-adrese-item:hover{
    background: #f1f1f1;
}
</style>