<template>
    <div class="box">
        <div class="flexItems modul-finantare-title">
            <div class="item-a position-relative descriereProgramProgramOperational">
                <div class="top-line">
                    <div class="bold-text mb-4">Descrierea programului operațional</div>
                    <img :src="data.logo.path" alt="" >
                </div>
                <div v-html="data.description"></div>
            </div>
            <div class="item-e p-0 axePrioritare">
                <p class="bold-text">Axe prioritare</p>
                <b-card>
                  <b-tabs pills card>
                    <b-tab
                            v-for="(breadcrumb, key) in data.breadcrumb"
                            class="fixedHeight"
                            :key="key"
                            :title="breadcrumb.acronym ? breadcrumb.acronym : breadcrumb.name">
                        <b-card-text v-html="breadcrumb.description"></b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
            </div>
            <div class="item-g">
                <ScriereProiect></ScriereProiect>
            </div>
            <div class="item-h">
                <ApelareConsultant></ApelareConsultant>
            </div>
            <div class="item-j">
                <DepunereProiect :data="data.where_to_apply"></DepunereProiect>
            </div>
            <div class="item-l">
                <span class="bold-text">Documentele generale ale programului</span>
                <div class="modul-finantare-content">
                    <ul class="lista-documente-finantari">
                        <li v-for="(value, key, index) in data.downloads" :key="index">
                            <a style="color: inherit" :href="value" download :title="key">
                                <span>
                                    <b-icon-download
                                        font-scale="1"
                                        style="margin-right: 5px"
                                    ></b-icon-download>
                                </span>
                              {{ limitWords(key, 3) }}
                            </a>
                        </li>
                    </ul>
                </div>
                <p class="modul-finantare-content">
                    Echipa smis.ro face eforturi în actualizarea perioadică a documentelor și
                    informațiilor. Vă rugăm să vizitați și pagina oficială a finanțării.
                </p>
            </div>
            <div class="item-m">
                <DespreFinantare :data="data.about"></DespreFinantare>
            </div>

        </div>
    </div>
</template>
<script>
    import {BIconDownload, BTab, BTabs, BCard, BCardText} from 'bootstrap-vue'
    import ScriereProiect from "./ScriereProiect";
    import ApelareConsultant from "./ApelareConsultant";
    import DepunereProiect from "./DepunereProiect";
    import DespreFinantare from "./DespreFinantare";
    import {limitWords} from '@/helpers/helpers'

    export default {
        components: {
          ScriereProiect,
          ApelareConsultant,
          DepunereProiect,
          DespreFinantare,
          BIconDownload, BTab, BTabs, BCard, BCardText },
        props: {
          data: null,
        },
        data() {
            return {
                isFavorite: false,
                sursaFinantareVizibila: false,
                finantareSituatie: 3,
                review: {
                    stars: 5,
                },
                tipAplicant: [
                    {
                        nume: "Aplicare independentă",
                        stare: 1,
                    },
                    {
                        nume: "Parteneriat de mai multe companii",
                        stare: 1,
                    },
                    {
                        nume: "Parteneriat public-privat",
                        stare: 0,
                    },
                    {
                        nume: "Parteneriat autoritati publice locale",
                        stare: 0,
                    },
                ],
                tipFinantare: [
                    {
                        nume: "Ghidul Solicitantului",
                        download: "/assets/images/LogoMySmis.png",
                    },
                    {
                        nume: "Procedura de implementare",
                        download: "#",
                    },
                    {
                        nume: "Manual de identitate vizuala",
                        download: "#",
                    },
                    {
                        nume: "Legea 98/2000",
                        download: "#",
                    },
                    {
                        nume: "OUG 130 - Masura 2",
                        download: "#",
                    },
                    {
                        nume: "POCU Conditii generale",
                        download: "#",
                    },
                    {
                        nume: "Corrigendum 1",
                        download: "#",
                    },
                    {
                        nume: "Manualul beneficiarului",
                        download: "#",
                    },
                ],
            };
        },
        methods: {limitWords}
    };
</script>
<style scoped>
#rating-lg-no-border{
    width: 100%;
}

.secondMenu a{
     color: #555555;
     text-decoration: none;
     padding: 5px 15px;
     border-radius: 5px;
     font-size: 16px;
}
 .secondMenu a:hover {
     background: #02c8a1;
     color: #fff;
}
 .secondMenu{
     padding: 20px;
     background: #d9e8e5;
     margin-top: 10px;
}
 .secondMenu a{
     border:1px solid #737373;
     margin-right: 15px;
}
 .secondMenu a:hover{
     border:1px solid #d9e8e5;
}
 .secondMenu ul{
     margin-bottom: 0;
     flex-wrap: wrap;
}
 .secondMenu ul li{
     line-height: 50px;
 }
 p, li{
     font-size: var(--paragraphSize);
     color: #868686;
}
 li{
     margin-left: 18px;
     list-style-type: disc;
 }
 .box {
     align-items: center;
     color: rgb(0, 0, 0);
     margin: 10px 20px;
}
.tabs >>> .card-header{
  padding: 0px 30px;
  background: #d9e8e5;
  border-radius: 0;
  border-bottom: 0;
}
.tabs >>> .nav{
    gap: 10px;
    padding: 15px 0;
}
.card >>> .card-body{
  padding: 0;
  min-height: inherit;
}
.tabs >>> .nav-link{
  color: #555555;
  background: transparent;
  text-decoration: none;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 15px;
  border: 1px solid #737373;
}
.fixedHeight{
    max-height: 430px;
    overflow-y: auto;
}
.card{
  background-color: transparent;
  border: none;
}
.tabs >>> .active{
  background: #02c8a1;
  color: #fff;
  border: 1px solid #02c8a1;
}
.tabs >>> .tab-pane{
  background: inherit;
  border: none;
  color: #868686;
}
.tabs >>> b-card-text{
  font-size: 14px;
}
.tabs >>> b-card-text p{
  margin-left: 0;
  margin-right: 0;
  margin: 10px;
}
 .b-rating {
     background-color: #f8f8f8;
}
 .b-rating.disabled, .b-rating:disabled {
     color: #ffbe00;
     width: 100px;
}
 .flexItems {
     display: grid;
     grid-template-columns: 1fr 1fr 325px;
     grid-template-rows: auto;
     grid-auto-flow: column;
     grid-template-areas:
     "programPrezentare programPrezentare programPrezentare"
     "activitatiEligibile activitatiEligibile scrieProiect"
     ". . apeleazaConsultant"
     ". . depunProiect"
     "cheltuieli cheltuieli depunProiect"  "documenteFinantare documenteFinantare despreFinantare";
     grid-gap: 20px;
     padding: 10px;
     margin-bottom: 50px;
    padding-bottom: 50px;
}
 .item-a {
     grid-area: programPrezentare;
}
 .item-c {
     grid-area: beneficii;
}
 .item-d {
     grid-area: tipAplicant;
}
 .item-e {
     grid-area: activitatiEligibile;
     grid-column: 1/3;
     grid-row: 2/6;
}
 .item-f {
     grid-area: contributieProprie;
     display: flex;
     justify-content: space-between;
     align-items: center;
}
 .item-g {
     grid-area: scrieProiect;
}
 .item-h {
     grid-area: apeleazaConsultant;
}
 .item-j {
     grid-area: depunProiect;
}
 .item-k {
     grid-area: numarProiecte;
     display: flex;
     justify-content: space-between;
     align-items: center;
}
 .item-l {
     grid-area: documenteFinantare;
}
 .item-m {
     grid-area: despreFinantare;
}
 .top-line {
     display: flex;
     justify-content: space-between;
     flex-direction: inherit !important;
}
.item-a img{
    height: 50px;
}
 .checkbox-container {
     display: flex;
     align-items: center;
}
 label {
     display: flex;
     align-items: center;
     font-size: 14px;
     font-weight: bold;
     color: #00c9a1;
     margin: 0;
}
 label.in-pregatire {
     color: #6f6f6f;
}
 label.inactiv {
     color: #ff5a5a;
}
 .data-text {
     color: #868686;
     margin-bottom: 0px;
     font-size: 14px;
     margin-left: 40px;
}
 a {
     text-decoration: none;
     font-size: 14px;
}
 .bold-text {
     font-weight: bold;
     font-size: 1rem;
     color: #434343;
}
 .opacity-text {
     opacity: 0.3;
}
 .finantare-prezentata {
     max-width: 420px;
     margin-right: 20px;
}
 .finantare-prezentata-text {
     margin: 20px 0;
}
 .modul-finantare-text {
     text-align: justify;
}
 .circle {
     background-color: #00c9a1;
     border: 2px solid #fdfdfd;
     width: 18px;
     height: 18px;
     min-width: 18px;
     border-radius: 50%;
     margin-right: 10px;
}
 .circle.in-pregatire {
     background-color: #6f6f6f;
}
 .circle.inactiv {
     background-color: #ff5a5a;
}
 .modul-finantare-title>div {
     background-color: #f8f8f8;
     color: #434343;
     box-sizing: border-box;
     padding: 20px;
     border: 1px solid #e7e7e7;
     box-shadow: 0px 2px 6px 1px #dddddd;
}
 .modul-finantare-content-no-background {
     background-color: #f0f0f0;
     font-size: 14px;
     color: #868686;
}
 .modul-finantare-content {
     margin-top: 10px;
     color: #868686;
     font-size: 14px;
     display: flex;
}
 .lista-firme-beneficii {
     columns: 2;
     -webkit-columns: 2;
     -moz-columns: 2;
     border-right: 1px solid #d5d5d5;
     min-width: 50%;
     list-style-type: disc;
     margin-left: 18px;
}
 .lista-documente-finantari {
     columns: 3;
     -webkit-columns: 3;
     -moz-columns: 3;
     width: 100%;
     margin: 0;
     padding: 0;
}
 .companii-eligibile {
     display: flex;
     align-self: center;
}
 .nr-companii-eligibile {
     color: #00c9a1;
     font-size: 45px;
     margin: 0 30px;
}
 .text-companii-eligibile {
     color: #868686;
     font-size: 14px;
}
 .contribution-percent {
     font-size: 23px;
     font-weight: 900;
     margin-bottom: -10px;
}
 .lista-cheltuieli-eligibile {
     list-style-type: disc;
     margin-left: 18px;
}
 .lista-cheltuieli-neeligibile {
     padding-left: 150px;
}
 .logo-guvern {
     max-width: 100%;
     width: 80px;
     height: auto;
}
 .logo-50 {
     max-width: 50%;
     height: auto;
}
 .logo-100 {
     max-width: 100%;
     height: auto;
}
 .space-center {
     text-align: center;
     padding: 15px;
}
 .space-center li {
     margin-bottom: 10px;
}
 .gradient-text-box {
     background-image: linear-gradient(#ed709d, #ec8a6c);
     color: white;
     border-radius: 4px;
     padding: 2px 4px;
     white-space: nowrap;
}

.axePrioritare p{
    margin: 20px;
}
ul li{
    line-height: 17px;
}

a.despreFinantare{
    color: #4f64d2
}
.tabs >>> .card-header a {
    margin: 0;
}
 @media only screen and (max-width: 1250px) {
    .flexItems {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-auto-flow: column;
        grid-template-areas:
        "programPrezentare" "activitatiEligibile" "scrieProiect" "apeleazaConsultant" "depunProiect" "documenteFinantare" "despreFinantare";
        grid-gap: 20px;
        padding: 10px;
        margin-bottom: 50px;
    }


    .item-c .nr-companii-eligibile {
        margin: 0 5px;
    }
    .item-c p {
        text-align: initial;
    }
    .item-i {
        display: flex;
        justify-content: space-between;
    }
    .item-i .lista-cheltuieli-neeligibile {
        padding: 0;
    }
    .item-k {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .item-a .top-line {
        flex-direction: column;
    }
    .programOperational p:not(:last-child),
    .axePrioritare p:not(:last-child){
        margin-bottom: 10px !important;
    }
    .item-a p{
        margin-bottom: 10px;
    }
    .item-h a{
        color: #606060;
    }
    .item-m a{
        color: #4f64d2;
    }

   .item-j{
        display: flex;
        justify-content: space-between;
    }
    .logo-50 {
        max-width: 100%;
        height: auto;
    }
    .item-j{
        align-items: center !important;
    }
    .item-j p{
        margin: 0;
    }
     .item-e {
        grid-area: activitatiEligibile;
        grid-column: 1fr;
    }
}
 @media only screen and (max-width: 710px) {
     .item-a .modul-finantare-content {
         display: initial;
    }
	.item-j{
		display: flex;
	}
	.item-j img{
		margin: 0 10px;
	}
    .modul-finantare-content ul{
        display: flex;
        flex-direction: column;
    }
    .modul-finantare-content ul li a{
        line-height: 35px;
    }
}
@media only screen and (max-width: 610px) {
    .modul-finantare-title{
        box-sizing: border-box;
    }
	.secondMenu ul{
		flex-direction: column;
	}
	.secondMenu ul li{
		margin: 15px;
	}
    .secondMenu ul li a{
        display: block;
        text-align: center;
    }
    .logo-pocu{
        right: 33%;
    }
    .item-j{
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .item-j * {
        margin: 0;
        padding: 0;
    }
    .secondMenu a{
        margin: 0;
    }
}
@media only screen and (max-width: 410px) {
    .box{
        margin: 0;
    }
    .modul-finantare-title{
        padding: 0;
    }
    .bold-text{
        font-size: 14px;
        margin-right: 0;
        text-align: center;
    }
    .item-m{
        display: flex;
        flex-direction: column;
    }
    .checkbox-container{
        display: flex;
        flex-direction: column-reverse;
    }
    .checkbox-container *{
        text-align: center;
        padding: 0;
    }

}
</style>