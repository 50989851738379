<template>
    <div>
        <div class="meniu-contul-meu">
            <div class="nav nav-tabs">
                <div class="link-item">
                    <router-link to="/contul-meu" class="nav-link"><span class="menu-item-text">Setari cont</span>
                    </router-link>
                </div>
                <div class="link-item">
                    <router-link to="/contul-meu/notificari" class="nav-link"><span
                        class="menu-item-text">Notificari</span></router-link>
                </div>
                <div class="link-item">
                    <router-link to="/contul-meu/financiar" class="nav-link"><span
                        class="menu-item-text">Financiar</span></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import Financiar from "./Financiar";
//import Notificari from "./Notificari";
//import Setari from "./Setari";
//import {BTabs,BTab,} from 'bootstrap-vue'

export default {
    components: {
        //Financiar,
        //Notificari,
        //Setari,
        //BTabs,
        //BTab,
    },
    data() {
        return {
            tabIndex: 0,
        };
    },
    created() {
    },
    methods: {},
};
</script>

<style>
.meniu-contul-meu {
    background-color: #e8e8e8;
}

.nav-link {
    color: #575757;
    font-size: 14px;
    margin: 17px 0px;
    padding: 3px 0px;
    white-space: nowrap;
    text-align: center;
    width: 100%;
}

.nav-tabs .nav-link.active {
    background-image: linear-gradient(to bottom, #5b3cd0, #3bc6d4);
    color: #fff;
    border-radius: 3px;
    padding: 3px 9px;
    white-space: nowrap;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin: 17px 20px;
}

.nav-tabs .nav-link {
    border: none;
}

.nav-tabs {
    border-bottom: none;
}
</style>

<style scoped>
.nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-left: 20px;
}

.nav-tabs .nav-link.active {
    background-image: linear-gradient(to bottom, #5b3cd0, #3bc6d4);
    color: #fff;
    border-radius: 3px;
    padding: 3px 9px;
    white-space: nowrap;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin: 17px 20px;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #fff;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-link {
    color: #575757;
    font-size: 14px;
    white-space: nowrap;
    text-align: center;
    width: auto;
}

span {
    padding: 5px 5px;
    white-space: nowrap;
    text-align: center;
    width: 100%;
    font-size: 14px;
}

.router-link-exact-active span {
    background-image: linear-gradient(
        180deg, #5b3cd0, #3bc6d4);
    color: #fff;
    border-radius: 3px;
    box-sizing: border-box;
    font-weight: initial !important;
}
</style>
