<template>
	<div id="app">
		<Header
			v-if="this.$store.state.auth.user"
			:left-menu-opened="leftMenuOpened"
			@topMenuOpened="headerValues()"
		>
		</Header>
		<div class="content">
			<LeftNavigation
				v-if="this.$store.state.auth.user"
				:key="this.$store.state.auth.user ? this.$store.state.auth.user.fundingsWishlistNumber : 0"
				:favNumberBadge="this.$store.state.auth.user ? this.$store.state.auth.user.fundingsWishlistNumber : 0"
				:top-menu-is-opened="topMenuIsOpened"
				@leftMenuOpened="leftNavigationValues()"
			>
			</LeftNavigation>
			<router-view class="contentPage"></router-view>
		</div>
	</div>
</template>
<script>
	import Header from "./common/header/Header";
	import LeftNavigation from "./common/MenuLeftSide/LeftNavigation";

	export default {
		name: "App",
		components: {
			Header,
			LeftNavigation,
		},
		data() {
			return {
				isLoginRegister: false,
				topMenuIsOpened: false,
				leftMenuOpened: false,
			}
		},

		created() {

		},
		updated() {

		},
		methods: {
			headerValues() {
				this.topMenuIsOpened = true;
				this.leftMenuOpened = false
			},
			leftNavigationValues() {
				this.leftMenuOpened = true;
				this.topMenuIsOpened = false
			}
		},
	};
</script>
<style>
.content {
	min-height: 100%;
	display: flex;
	height: 100%;
}

#nav a {
	color: #212121;
}

#nav a.router-link-exact-active {
	color: #42b983;
}

.current-tab {
	border-radius: 3px;
	border-right: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
	width: 100%;
	box-sizing: border-box;
	background-color: #f0f0f0;
	display: flex;
}

@media (max-width: 540px) {
	.content {
		height: 100%;
	}
}
</style>