<template>
    <div class="padding-box">
        <b-tabs pills>
            <b-tab title="Informatii generale" active>
                <InformatiiGenerale :data="form" :services="Allservices" @submitForm="submitForm()"></InformatiiGenerale>
            </b-tab>
            <b-tab title="Sediu">
                <Sediu :judete="judete" :data="form" @submitForm="submitForm()" @getCoordinates="getCoordinates"></Sediu>
            </b-tab>
            <b-tab title="Birouri">
                <Birouri :judete="judete" :data="form" @submitForm="submitForm()"></Birouri>
            </b-tab>
            <b-tab title="Echipa">
                <Echipa :data="team" @submitForm="submitForm()"></Echipa>
            </b-tab>
            <b-tab title="Social Media">
                <LinkuriSociale :data="form" @submitForm="submitForm()"></LinkuriSociale>
            </b-tab>
            <b-tab title="Imagini">
                <Imagini ></Imagini>
            </b-tab>

        </b-tabs>
    </div>
</template>

<script>
    import {BTabs, BTab} from 'bootstrap-vue'
    import api from '@/helpers/api';
    import axios from '@/helpers/axios';
    import InformatiiGenerale from "./InformatiiGenerale";
    import Sediu from "./Sediu";
    import Birouri from "./Birouri";
    import Echipa from "./Echipa";
    import LinkuriSociale from "./LinkuriSociale";
    import Imagini from "./Imagini";
    import _ from 'lodash'
    export default {
        components: {
            InformatiiGenerale,
            Sediu,
            Birouri,
            Echipa,
            LinkuriSociale,
            Imagini,
            BTabs,
            BTab,
        },
        data() {
            return {
                form: {
                    name: '',
                    main_email: '',
                    main_phone: '',
                    website: '',
                    about: '',
                    description: '',
                    funded_projects: '',
                    implemented_projects: '',
                    years_of_experience: '',
                    success_rate: '',
                    services: '',
                    address: '',
                    county:'',
                    city: '',
                    contact_person: '',
                    phone: '',
                    email: '',
                    offices: [],
                    team: [],
                    facebook_link: '',
                    instagram_link: '',
                    youtube_link: '',
                    twitter_link: '',
                    linkedin_link: '',

                    coordinates:{
                        lat:'',
                        long:'',
                    }
                },
                team:[],
                judete:[],
                Allservices: [],
                originalServices: [],
            }
        },
        mounted() {
            this.getConsultants()
        },
        methods: {
            getConsultants() {
                axios.get(api.API_ENDPOINTS.consultants + '/' + this.$store.state.auth.user.consultant_id)
                    .then(response => {
                        if(response.data){
                            this.Allservices = response.data.services.map(item => { return {'id': item.id, 'name': item.name}})
                            let about = response.data.about.replace(/<div/g, "<p")
                            let description = response.data.description.replace(/<div/g, "<p")
                            this.form = {
                                name: response.data.name,
                                main_email: response.data.main_email,
                                main_phone: response.data.main_phone,
                                website: response.data.website,
                                about: about.replace(/<\/div>/g,"</p>"),
                                description: description.replace(/<\/div>/g,"</p>"),
                                funded_projects: response.data.funded_projects,
                                implemented_projects: response.data.implemented_projects,
                                years_of_experience: response.data.years_of_experience,
                                success_rate: response.data.success_rate,
                                services: _.filter(response.data.services, v => v.checked).map(item => { return {'id': item.id, 'name': item.name}}),
                                address: response.data.address,
                                county:response.data.county,
                                city: response.data.city,
                                contact_person: response.data.contact_person,
                                phone: response.data.phone,
                                email: response.data.email,
                                offices: response.data.offices,
                                facebook_link: response.data.facebook_link,
                                instagram_link: response.data.instagram_link,
                                youtube_link: response.data.youtube_link,
                                twitter_link: response.data.twitter_link,
                            }
                            this.team = response.data.team
                            this.getJudete();
                        }
                    }).finally(() => this.loading = false)
            },
            submitForm() {
                this.originalServices = this.form.services
                this.form.services = this.form.services.map(item => item.id)
                
                axios
                    .put(api.API_ENDPOINTS.consultants + '/' + this.$store.state.auth.user.consultant_id, this.form)
                    .then((response) => {
                        this.$notify(response.data.message);
                    })
                    .catch(xhr => {
                        if (xhr.response.status === 422) {
                            this.errors.record(xhr.response.data.errors);
                        }
                        this.$notify(xhr.response.data.message);
                    })
                    .finally(() =>
                        this.form.services = this.originalServices
                    );
            },
            getCoordinates(coordinates){
                this.form.coordinates = coordinates;
            },
            getJudete(){
                axios.get(api.API_ENDPOINTS.conultantsServices)
                    .then(response => {
                        this.judete = response.data.counties;
                    }).catch(err => {
                        console.log(err);
                    }) 
            }
            
        }
    }
</script>

<style scoped>
.active {
    background: transparent;
    color: inherit !important;
}
.padding-box {
    padding: 20px;
    padding-bottom: 100px;
}
.tabs >>> .nav-link {
    color: #575757;
    font-size: 14px;
    padding: 3px 0px;
    margin: 0;
    white-space: nowrap;
    text-align: center;
    width: 100%;
}
.tabs >>> .nav-item{
    margin-right: 20px;
    margin-bottom: 10px;
}
.tabs >>> .nav-link.active {
    background-image: linear-gradient(to bottom, #5b3cd0, #3bc6d4);
    color: #fff;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 3px;
    padding: 3px 9px;
    white-space: nowrap;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
}
</style>