<template>
    <div class="modul-finantare-title item-n">
        <div class="top-line">
            <span class="bold-text title">Sursa finanțării</span>
            <div>
                <b-icon-eye-fill
                    v-if="sursaFinantareVizibila === false"
                    font-scale="2"
                    @click="vizibil"
                ></b-icon-eye-fill>
                <b-icon-eye-slash-fill
                    v-else
                    font-scale="2"
                    @click="vizibil"
                ></b-icon-eye-slash-fill>
            </div>
        </div>

        <!--    <ul class="bold-text space-center">
              <li v-for="(fin, j) in tip.finantare" :key="j">
                <span v-if="fin.stare === 1" class="gradient-text-box">{{ fin.nume }}</span>
                <span v-else>{{ fin.nume }}</span>
              </li>
            </ul>-->

        <div v-if="sursaFinantareVizibila === true" class="top-line">
            <div class="modul-finantare-content">
                <div :v-if="data" class="sursa-finantare">
                    <ul
                        class="space-center m-0"
                        v-for="(level, key) in getUniqueLevel(data.programtree)"
                        :key="key">
                        <li
                            v-for="(programTree, programTreeKey) in getByLevel(data.programtree, key)"
                            :key="programTreeKey"
                            :class="(programTree.selected) ? 'gradient-text-box' : ''">
                            <span :class="(key in [0,1,2] ? 'bold-text' : '')" v-if="programTree.name" v-html="limitWords(programTree.name, 10)"></span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="finantare-prezentata">
                <img class="logo-50" :src="sursa.program.type.logo.path" alt="">
                <div class="finantare-prezentata-text">
                    <p class="bold-text text-companii-eligibile" style="margin: 0">
                        Finanțarea prezentată:
                    </p>
                    <div class="text-companii-eligibile" v-html="limitWords(sursa.program.description, 26)">
                    </div>
                </div>
                <img class="logo-50 big-logo" style="float: right" :src="sursa.program.logo.path" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import {BIconEyeFill, BIconEyeSlashFill} from 'bootstrap-vue'
import {limitWords} from '@/helpers/helpers'

export default {
    components: {
        BIconEyeFill,
        BIconEyeSlashFill
    },
    props: {
        data: null,
        sursa: null,
    },
    created() {

    },
    data() {
        return {
            sursaFinantareVizibila: true,
            uniqueLevels: []
        };
    },
    mounted() {
    },
    computed: {},
    methods: {
        limitWords,
        getUniqueLevel(array) {
            return _.uniq(_.map(array, 'level'))
        },
        getByLevel: function (array, level) {
            return _.filter(array, _.matches({'level': level}));
        },
        vizibil() {
            if (this.sursaFinantareVizibila === false) {
                this.sursaFinantareVizibila = true;
            } else {
                this.sursaFinantareVizibila = false;
            }
        }
    },
};
</script>

<style scoped>
.title {
    font-size: var(--moduleTitleSize) !important;
}

.bold-text {
    font-weight: bold;
}

.item-n {
    grid-area: sursaFinantarii;
}

.text-companii-eligibile {
    color: #868686;
    font-size: var(--paragraphSize);
}

.modul-finantare-content {
    margin-top: 10px;
    color: #868686;
    font-size: var(--paragraphSize);
    display: flex;
}

.text-companii-eligibile >>> p {
    color: #868686;
    font-size: var(--paragraphSize);
    font-weight: 300;
}

.sursa-finantare {
    display: flex;
}

.finantare-prezentata {
    max-width: 420px;
    margin-right: 20px;
}

.finantare-prezentata img {
    max-height: 35px;
}

.big-logo {
    max-height: 100px !important;
    max-width: 150px;
}

.finantare-prezentata-text {
    margin: 20px 0;
}

.gradient-text-box {
    background-image: linear-gradient(#ed709d, #ec8a6c);
    color: white;
    border-radius: 4px;
    padding: 2px 4px;
    white-space: nowrap;
}

.space-center {
    text-align: center;
    padding: 0px 20px;
}

.space-center li {
    margin-bottom: 10px;
}

.top-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 1250px) {
    .item-n {
        grid-area: sursaFinantarii;
    }

    .item-n .top-line {
        flex-direction: column;
    }
}

@media only screen and (max-width: 710px) {
    .item-n .modul-finantare-content {
        display: initial;
    }

    .top-line {
        flex-direction: column;
    }
}
</style>