export default {
    //API_ROOT: 'http://api.stage.smis.ro/api/v1',
    APP_URL: 'http://new.smis.ro/',
    API_ROOT: process.env.VUE_APP_API,
    API_ENDPOINTS: {
        // User Management
        csrf: '/csrf-cookie',
        login: '/login',
        register: '/register',
        logout: '/logout',
        deleteUser:'/users',
        userData: '/user',
        userNotificationsSettings: '/user-notifications-settings',
        userFinancialSettings: '/user-financial-settings',
        forgotPassword: '/forgot-password',
        resetPassword: '/reset-password',
        updatePassword: '/user/password',
        updateUserInfo: '/user/profile-information',
        updateUserPicture: '/user/picture',
        updateContext: '/user/update-context',
        verifyEmail: '/email/verify',

        // Funding
        finantari: '/fundings',
        finantareSingle: '/fundings',
        finantariHarta:'/fundings-map',
        programTree:'/programs-tree',
        //Eligibilities
        eligibilitateSingle: '/fundings/company-eligibilities/',
        //Eligibility Tree List
        programtree: '/programtree',
        //Wishlist
        wishlistFunding: '/wishlist/fundings',
        wishlistNews: '/wishlist/news',
        //Consultants
        consultantiHarta: "/consultants-map",

        //Program
        program: '/programs',
        //News
        news: '/news',
        //Comments
        newsComments: '/comments/news',
        fundingComments: '/comments/fundings',
        //Companies
        companyEligibility: '/companies/funding-eligibilities',
        new_company_eligibility: '/companies/new-company-eligibilities',
        //Payments
        payments: '/payments',
        companies: '/companies',
        //Menu
        primaryMenu: '/primary-app-menu',
        //RootPrograms
        rootPrograms: '/root-programs',
        //User Account Types
        userAccountTypes: '/user-account-types',
        //User Subscription Plans
        userSubscriptions: '/user-subscription-plans',
        //Consultants
        consultants: '/consultants',
        consulantsUpdateImages:'/consultants/profile-images',
        consultantsReviews: '/users',
        consultantUpdateTeam:'/consultants/team',
        consultantOffers:'/consultants/offers',
        conultantsServices:'consultants-services-counties',

        //Homepage
        homepage: '/dashboard',
        //Todos
        todos: '/todos',
        completeTodo: '/todos/complete',
        tags: '/tags',
        //Cupoane
        cupoane: '/coupons',
        //Apps
        fetchApps: '/plans',
        checkout: '/checkout',
        checkoutCoupon: '/checkout/coupon'
        // startM2: '/m2/store',
        // getM2data: '/m2/show',
        // getBannerEmbed: '/m2/get_banner',
        // updateM2data: '/m2/update',
        // updateCompanyLogo: '/m2/update-logo',
        // getCompletionStatus: '/m2/completion-status',
        // m2PdfComunicat: '/m2/pdf/comunicat_presa',
        // m2PdfComunicatFinal: '/m2/pdf/comunicat_final_presa',
        // postDateM2PdfComunicatFinal: '/m2/pdf/post_date_comunicat_final_presa',
        // m2PdfEticheta10: '/m2/pdf/eticheta_inventar_10',
        // m2PdfEticheta20: '/m2/pdf/eticheta_inventar_20',
        // m2PdfAfisA3: '/m2/pdf/afis_a3',
        // requestInvoice: '/request_invoice',
        // uploadOP: '/upload_op',
        // getUploadedOPs: '/uploaded_ops',
        // choosePackage: '/choose_package',
        // packages: '/packages',
    },
    HOME_ROUTE: '/',
    MY_ACCOUNT_ROUTE: '/',
    LOGIN_ROUTE: '/login',
}