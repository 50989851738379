<template>
    <div class="box">
        <div class="box-title">
            <span class="title-text">{{ title }}</span>
        </div>
        <div class="box-content">
            <div class="first-content">
                <div v-if="this.$slots.body" class="boxText">
                    <section class="text-padding">
                        <slot name="body"> Body </slot>
                    </section>
                </div>
                <div v-if="this.$slots.buttons" class="boxButtons">
                    <section>
                        <slot name="buttons"></slot>
                    </section>
                </div>
                <div v-if="this.$slots.poza" class="boxImage">
                    <section>
                        <slot name="poza"> Poza </slot>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Test",
        components: {},
        props: {
            title: {
                type: String,
                default: null,
            },
        },
        data() {
            return {};
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
	.box {
		align-items: center;
		color: rgb(0, 0, 0);
		border: 1px solid #e7e7e7;
		height: 100%;
		background-color: #fff;
	}
	.box-without-buttons .boxText{
		min-width: 78%;
	}
	.first-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
	}
	.text-padding {
		padding: 25px;
	}
	.box-title {
		background-color: #f8f8f8;
		color: #5744cf;
		box-sizing: border-box;
		padding: 20px 10px 20px 25px;
	}
	.title-text {
		font-weight: bold;
	}
	.box-content {
		background-color: #fff;
	}
	.boxText {
		display: flex;
		margin: 0 auto;
		width: 200px;
		justify-content: center;
        min-width: 55%
	}
	.boxImage {
        display: flex;
		margin: 0 auto;
		width: 200px;
		justify-content: center;
	}
    .boxButtons {
        display: flex;
		margin: 0 auto;
		width: 200px;
		justify-content: center;
    }
	@media(max-width:540px){
          .first-content{
			display: block;
    }  
        }
</style>
