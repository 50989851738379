<template>
    <div class="image-background" v-bind:style="{ background: 'linear-gradient(rgb(0 0 0 / 62%), rgb(0 0 0 / 62%)), url(' + data.bannerImage + ')' }">
        <div class="news-wrapper">
            <div class="left">
                <p class="date">{{formatUtcDate(data.created, 'DD MMMM YYYY | HH:mm')}}</p>
                <ul class="list-br">
                    <li><router-link to="/">ACASĂ</router-link></li>
                    <li class="link-active"><router-link to="/stiri">ȘTIRI</router-link></li>
                </ul>
            </div>
            <div class="right">
                <h1>{{data.title}}</h1>
            </div>
        </div>
    </div>
</template>

<script>
import {formatUtcDate} from '@/helpers/helpers'

export default {
    components: {
    },
    props: {
        data: null,
    },
    data() {
        return {
        };
    },
    methods: {
        formatUtcDate
    },
};
</script>

<style scoped>
.image-background{
    height: 100%;
    max-height: 335px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 30% center !important;
    padding: 20px;
}
.news-wrapper{
    width: 100%;
    height: 100%;
    max-width: 1300px;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 40px;
}
.date{
    color: #fff;
    font-size: 13px;
}
.list-br a{
    color: #c0c0c0;
    font-size: 13px;
}
.link-active a{
    color: #fff;
}
.left{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.right h1{
    color: #fff;
    font-size: 28px;
    text-align: left;
    font-weight: 400;
    margin: 0;
}
ul{
    display: flex;
    margin: 0;
    flex-wrap: wrap;
    justify-content: center;
}
ul > li:not(:last-child):after{
    content: "/";
    margin-left: 10px;
    margin-right: 10px;
    font-size: 13px;
    color: #C0C0C0FF;
}
@media (max-width:1000px){
    .image-background{
        height: auto;
        max-height: inherit;
    }
    .news-wrapper{
        display: block;
    }
    .right h1{
        font-size: 20px;
        line-height: 27px;
        margin-top: 20px;
    }
}
</style>