<template>
    <div class="flex block-mobile padding">
        <div class="alege">
            <div v-if="isSubscription" class="flex">
                <p>Abonament anual</p>
                <ToggleSwitch class="mx-3" :click="switchInvoiceType"></ToggleSwitch>
                <p>Abonament lunar</p>
            </div>
            <div v-else class="flex">
                <p>Licenta unica / o singura plata</p>
            </div>
            <div class="tipAbonament">
                <div v-if="selected_invoice_type == 'year'" class="d-flex">
                    <h3 class="text-color-gradient-pret bold mr-3">
                        <sup class="text-color-gradient-pret">€</sup>{{ plan.yearly_price }}
                    </h3>
                    <h3 class="text-color-notselected-pret bold"><sup>€</sup>{{ plan.price }}</h3>
                </div>
                <div v-else-if="selected_invoice_type == 'month'" class="d-flex">
                    <h3 class="text-color-notselected-pret bold mr-3"><sup>€</sup>{{ plan.yearly_price }}</h3>
                    <h3 class="text-color-gradient-pret bold">
                        <sup class="text-color-gradient-pret">€</sup>{{ plan.price }}
                    </h3>
                </div>
                <div v-else-if="selected_invoice_type == 'lifetime'" class="d-flex">
                    <h3 class="text-color-gradient-pret bold">
                        <sup class="text-color-gradient-pret">€</sup>{{ plan.price }}
                    </h3>
                </div>
                <p v-if="isSubscription">lunar / fără TVA / curs BNR din ziua plății</p>
                <span v-if="selected_invoice_type == 'year'" class="font-weight-bold text-gray" :class="{ 'text-color-gradient-pret': ! selected_invoice_type }">Economisești {{ plan.yearly_discount }}€ / an</span>
                <span v-if="selected_invoice_type == 'month'" class="font-weight-bold text-gray" :class="{ 'text-color-gradient-pret': ! selected_invoice_type }">Cu plata anuală poți economisi {{ plan.yearly_discount }}€</span>
            </div>
        </div>
        <div class="total-plata flex-column">
            <div class="total">
                <span>Total de plată</span>
                <h3 class="text-white bold mr-3"><sup>€</sup>{{ getTotalToPay }}</h3>
            </div>
            <div class="cumpara" @click="openCheckout">
                <span>Cumpără</span>
                <ModalPrincipal :modalShow="modalShow" @openModal="openCheckout()" @closeModal="closeCheckout()" @invoiceTypeChanged="invoiceTypeChanged()" :invoiceType="selected_invoice_type" :planId="planId"></ModalPrincipal>
            </div>
        </div>
    </div>
</template>

<script>

import ToggleSwitch from "@/common/components/ToggleSwitch";
import ModalPrincipal from "@/common/components/Checkout/ModalPrincipal";

export default {
    components: {
        ToggleSwitch,
        ModalPrincipal,
    },
    
    props: ['plan'],

    data() {
        return {
            modalShow: false,
            selected_invoice_type: '',
            planId: 0
        };
    },

    methods: {
        switchInvoiceType() {
            if(this.selected_invoice_type === 'year') {
                this.selected_invoice_type = 'month';
            } else {
                this.selected_invoice_type = 'year';
            }
        },

        openCheckout() {
            this.modalShow = true;
        },

        closeCheckout() {
            this.modalShow = false;
        },

        invoiceTypeChanged() {
            this.switchInvoiceType()
        },
    },

    computed: {
        isLifetime() {
            return this.plan.invoice_interval.includes('lifetime')
        },

        isSubscription() {
            return ['year', 'month'].every(val => this.plan.invoice_interval.includes(val))
        },

        firstInvoiceType() {
            return this.isSubscription ? 'year' : this.plan.invoice_interval[0];
        },

        hasInvoiceIntervals() {
            return this.plan.invoice_interval.length > 0;
        },

        getTotalToPay() {
            return this.selected_invoice_type === 'year' ? this.plan.total_yearly_price : this.plan.price;
        }
    },
    
    watch: {
        'plan': function () {
            if(this.hasInvoiceIntervals && this.firstInvoiceType) {
                this.selected_invoice_type = this.firstInvoiceType;
            }

            this.planId = this.plan.id;
        },
    }
}
</script>

<style scoped>
.padding {
    padding: 10px;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.alege {
    width: 72%;
}

.total-plata {
    width: 25%;
}

.text-color-gradient-pret {
    background: -webkit-linear-gradient(#04c3a2, #52a1d4);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-color-notselected-pret {
    color: #eeeeee;
}

.bold {
    font-weight: 800;
    font-size: 40px;
}

.tipAbonament {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.tipAbonament p {
    font-size: 12px;
    color: #bdbdbd;
}

.text-gray {
    color: #bdbdbd;
}

.total {
    background: -webkit-linear-gradient(#04c3a2, #52a1d4);
    text-align: center;
    color: #fff;
    padding: 10px;
}

.total span {
    font-size: 12px;
    font-weight: 600;
}

.cumpara {
    background: #52a1d4;
    padding: 10px;
    text-align: center;
    font-weight: 600;
    margin-top: 10px;
    cursor: pointer;
}

.cumpara span {
    color: #fff;
}
@media (max-width: 1500px) {
    .block-mobile {
        display: block;
    }

    .total-plata, .cumpara, .alege {
        width: 100%;
    }

    .alege {
        margin-bottom: 10px;
    }
}
</style>