<template>
    <div class="page loginRegisterWrapper">
        <div class="image">
            <LoginRegisterBG class="register"></LoginRegisterBG>
        </div>
        <div class="date-user">
            <RegisterForm></RegisterForm>
        </div>
    </div>
</template>

<script>
    import RegisterForm from "./componenets/RegisterForm";
    import LoginRegisterBG from "./componenets/LoginRegisterBG";
    export default {
        name: "Test",
        components: {
            RegisterForm,
            LoginRegisterBG,
        },
        props: {},
        data() {
            return {};
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
	.page {
		width: 100%;
		display: flex;
        background-color: #fff;
        height: auto;
	}
	.image {
		width: 73%;
		display: flex;
		flex-direction: column;
		align-self: center;
	}
	.date-user {
		width: 450px;
		background-color: #fff;
		align-self: center;
	}
	.loginRegisterWrapper {
		position: relative;
	}
     @media (max-width:1023px) and (min-width:768px){
        .image{
            width: 63%;
        }
        }
    @media (max-width: 1200px){
        .page{
            flex-direction: column-reverse;
        }
        .image{
            width: 100%;
        }
        .date-user{
            position: absolute;
            bottom: 0;
            background: transparent;
        }
    }
    @media (max-width:500px) {
        .leftNavWrapper .header-container, body{
            overflow: visible;
        }
        
        .contentPage{
            overflow-x: visible;
            
        }
        .page{
            display: block;
            
        }
        .image{
            width: 100%;
        }
        .date-user{
            width: 100%;
            padding: 0.4rem 0;
        }
    }    
</style>
