<template>
    <div>
        <div class="aplicatie-image flex padding-box">
            <div class="flex-rating">
                <b-form-rating id="rating-lg-no-border" :value="plan.rating.avg_rating" color="#ffbe00" no-border size="sm" readonly></b-form-rating>
                <span class="rating-total">({{ parseFloat(plan.rating.avg_rating).toFixed(1) }}/5)</span>
            </div>
            <img :src="plan.logo" class="desc-image" :alt="plan.name">
        </div>
        <div class="padding scroll-box">
            <p v-html="plan.description"></p>
            <div class="videoWrapper">
                <iframe width="380" height="200" :src="plan.video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="functii">
                <span class="section-title">Funcțiile aplicației</span>
                <ul class="mt-3">
                    <li v-for="feature in plan.app_features" :key="feature.position" class="functie">
                        <b-icon-check-circle-fill v-if="feature.disabled == '0'"
                            style="fill: var(--succesColor); margin-right: 15px;"
                            font-scale="1.1"
                        ></b-icon-check-circle-fill>
                        <b-icon-check-circle-fill v-else
                            style="fill: var(--dangerColor); margin-right: 15px;"
                            font-scale="1.1"
                        ></b-icon-check-circle-fill>
                        <span>{{ feature.value }}</span>
                    </li>
                </ul>
            </div>
            <span class="section-title">Vezi capturi de ecran</span>
            <img v-for="(screenshot, index) in plan.screenshots" :key="index" :src="screenshot" class="screenshot">
        </div>
    </div>
</template>

<script>

import {BIconCheckCircleFill, BFormRating} from 'bootstrap-vue'

export default {
    components: {
        BIconCheckCircleFill,
        BFormRating,
    },
    
    props: ['plan'],
};
</script>

<style scoped>
p {
    color: #878588;
}

.padding-box {
    padding: 10px;
}

.padding {
    padding: 0 20px;
}

.desc-image {
    max-width: 200px;
    object-fit: cover;
}

.aplicatie-image {
    text-align: right;
    margin-bottom: 20px;
}

.videoWrapper {
    margin: 20px 0;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 1px);
}

.section-title {
    font-weight: 600;
    font-size: 15px;
    color: #686b77;
    margin-bottom: 10px;
}

.functie {
    font-size: 12px;
}

.scroll-box {
    max-height: calc(100vh - 270px);
    overflow-y: auto;
}

.screenshot {
    width: 100%;
}

.screenshot:not(:first-child) {
    margin-top: 10px;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.flex-rating {
    display: flex;
}

.rating-total {
    color: #878588;
    font-size: 12px;
    line-height: 32px;
}

@media (max-width: 1400px) {
    .flex {
        flex-direction: column-reverse;
    }

    .flex-rating {
        width: 50%;
    }
}
</style>