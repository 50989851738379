import { render, staticRenderFns } from "./CompanieLista.vue?vue&type=template&id=1515650c&scoped=true&"
import script from "./CompanieLista.vue?vue&type=script&lang=js&"
export * from "./CompanieLista.vue?vue&type=script&lang=js&"
import style0 from "./CompanieLista.vue?vue&type=style&index=0&lang=css&"
import style1 from "./CompanieLista.vue?vue&type=style&index=1&id=1515650c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1515650c",
  null
  
)

export default component.exports