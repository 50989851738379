<template>
    <div id="filters" class="flexClass spaceBetween sticky-top">
        <div>
            <div class="align-center">
                <p @click="expandFilters()"><img src="@/assets/images/FiltreIcon.png" class="mr-2" > Filtre avansate</p></div>
            <div>
                <b-form-checkbox
                    v-model="selected.finantariActive"
                    switch
                    :disabled="filterDisable"
                    size="sm"
                    @change="sendDataToParent()"
                >Arată doar finanțări active</b-form-checkbox>
            </div>
        </div>             
        <div v-if="!mobile || expand">
            <div>
                <b-form-select v-model="selected.filtreazaRegiuneImplementare" :disabled="filterDisable" :options="filtreazaRegiuneImplementare" @change="sendDataToParent()"></b-form-select>
            </div> 
            <div class="priceSlide">
                <vue-range-slider
                    v-model="selected.valoareaFinantarii"
                    :disabled="filterDisable"
                    :min="minSlider"
                    :max="maxSlider"
                    :formatter="formatter"
                    tooltip="hover"
                    :step="5"
                    :tooltip-merge="tooltipMerge"
                    :enable-cross="enableCross"
                    @drag-start="sendDataToParent()"
                    @drag-end="sendDataToParent()"
                ></vue-range-slider>
            </div>
        </div> 
        <div v-if="!mobile || expand">
            <div>
                <b-form-select v-model="selected.startUp" :disabled="filterDisable" :options="startUp"></b-form-select>
            </div> 
            <div>
                <b-form-select v-model="selected.optiuneCofinantare" :disabled="filterDisable" :options="optiuneCofinantare" @change="sendDataToParent()"></b-form-select>
            </div>
        </div> 
        <div v-if="!mobile || expand">
            <div>
                <b-form-select v-model="selected.tipBeneficiar" :disabled="filterDisable" :options="tipBeneficiar" @change="sendDataToParent()"></b-form-select>    
            </div> 
            <div>
                <b-form-select v-model="selected.sursaFinantare" :disabled="filterDisable" :options="sursaFinantare" @change="sendDataToParent()"></b-form-select>
            </div>
        </div> 
        <div>
            <div class="formatTabSwitch">
                <b-form-checkbox
                    v-model="formatLista"   
                    switch                    
                    size="sm"   
                    @input="changeToTabListView()"               
                >
                    <span v-if="formatLista">{{ viewList }}</span>
                    <span v-if="!formatLista">{{ viewTab }}</span>
                </b-form-checkbox>
            </div> 
            <div class="expertSwitch">
                <b-form-checkbox
                    v-model="modExpert"   
                    switch  
                    size="sm"
                    @input="changeToExpertMode()"
                >
                    <span v-if="!modExpert">{{ viewModExpert }}</span>
                    <span v-if="modExpert">{{ viewDezactivareModExpert }}</span>
                    <TooltipButtonGradient></TooltipButtonGradient>
                </b-form-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
    import { BFormCheckbox, BFormSelect} from 'bootstrap-vue'
    import TooltipButtonGradient from '@/common/components/TooltipButtonGradient'
    import 'vue-range-component/dist/vue-range-slider.css'
    import VueRangeSlider from 'vue-range-component'

    export default {          
        components: {
            BFormCheckbox,
            BFormSelect,
            TooltipButtonGradient,
            VueRangeSlider,
        },
        props:{
            filterDisable:{
                type:Boolean,
                default:true
            },

        },
        data() {
            return {                
                mobile:false,
                expand:false,
                viewList:"Vezi în format listă",
                viewTab:"Vezi în format tab-uri",
                viewModExpert:"Activează modul expert",
                viewDezactivareModExpert:"Dezactivează modul expert",
                selected: {
                    finantariActive:false,
                    filtreazaRegiuneImplementare: 'all',
                    valoareaFinantarii: [0,10000],
                    startUp: 'all',
                    optiuneCofinantare: 'all',
                    tipBeneficiar: 'all',
                    sursaFinantare:'all'
                },
                defaultSelect: {
                    finantariActive:false,
                    filtreazaRegiuneImplementare: 'all',
                    valoareaFinantarii: [0,10000],
                    startUp: 'all',
                    optiuneCofinantare: 'all',
                    tipBeneficiar: 'all',
                    sursaFinantare:'all'
                },
                minSlider:0 ,
                maxSlider:10000,
                enableCross : false,
                tooltipMerge :false,
                formatLista:false,
                formatTab:false,
                modExpert:false,
                inputDisable:true,
                filtreazaRegiuneImplementare: [
                    { value: 'all', text: 'Filtrează regiune implementare - Toate' },
                    { value: 'C', text: 'Centru' },
                    { value: 'SM', text: 'Sud - Muntenia' },
                    { value: 'NV', text: 'Nord - Vest' },
                    { value: 'NE', text: 'Nord - Est' },
                    { value: 'SE', text: 'Sud - Est' },
                    { value: 'BI', text: 'București - Ilfov' },
                    { value: 'SV', text: 'Sud - Vest Oltenia' },
                    { value: 'V', text: 'Vest' },
                    { value: 'ITI-DD', text: 'ITI Delta Dunarii' },
                ],
                startUp: [
                    { value: 'all', text: 'Start-up' }, 
                    { value: 'only', text: 'da' },                               
                ],
                optiuneCofinantare: [
                    { value: 'all', text: 'Opțiune cofinanțare - Toate' }, 
                    { value: 'no_contribution', text: 'Fara cofinantare' },
                    { value: 'with_contribution', text: 'Cu cofinantare' },                               
                ],
                tipBeneficiar: [
                    { value: 'all', text: 'Tip beneficiar - Toate' },  
                    { value: 'Firme existente IMM', text: 'Firme existente IMM' },
                    { value: 'Intreprindere mare', text: 'Intreprindere mare' },
                    { value: 'Microintreprinderi', text: 'Microintreprinderi' },  
                    { value: 'Universitate', text: 'Universitate' },  
                    { value: 'Parteneriat public - privat', text: 'Parteneriat public - privat' },  
                    { value: 'Autoritate publica locala', text: 'Autoritate publica locala' },
                    { value: 'ONG', text: 'ONG' },  

                                                      
                ],
                sursaFinantare: [
                    { value: 'all', text: 'Filtreaza surse finanțare - Toate' }, 
                    { value: 'Fonduri Europene', text: 'Fonduri Europene' },
                    { value: 'Fonduri Guvernamentale', text: 'Fonduri Guvernamentale' }, 
                    { value: 'Fonduri Comisia Europeana', text: 'Fonduri Comisia Europeana' },
                    { value: 'Fonduri Norvegiene', text: 'Fonduri Norvegiene' },
                    { value: 'Fonduri Elvetiene', text: 'Fonduri Elvetiene' },
                    { value: 'Finantari Private', text: 'Finantari Private' },                              
                ],
            };
        },
        watch:{
            'selected.finantariActive':function () {
           
                if(JSON.stringify(this.defaultSelect) === JSON.stringify(this.selected) && this.modExpert===false){
                    this.$emit('noFilter');
                }
            },
            'selected.filtreazaRegiuneImplementare':function () {

                if(JSON.stringify(this.defaultSelect) === JSON.stringify(this.selected) && this.modExpert===false){
                    this.$emit('noFilter');
                }
            },
            'selected.valoareaFinantarii':function () {
                if(JSON.stringify(this.defaultSelect) === JSON.stringify(this.selected) && this.modExpert===false){
                    this.$emit('noFilter');
                }
            },
            'selected.optiuneCofinantare':function () {
                if(JSON.stringify(this.defaultSelect) === JSON.stringify(this.selected) && this.modExpert===false){
                    this.$emit('noFilter');
                }
            },
            'selected.tipBeneficiar':function () {
                if(JSON.stringify(this.defaultSelect) === JSON.stringify(this.selected) && this.modExpert===false){
                    this.$emit('noFilter');
                }
            },
            'selected.sursaFinantare':function () {
                
                if(JSON.stringify(this.defaultSelect) === JSON.stringify(this.selected) && this.modExpert===false){
                    this.$emit('noFilter');
                }
            },
            'selected.startUp':function () {
                
                if(JSON.stringify(this.defaultSelect) === JSON.stringify(this.selected) && this.modExpert===false){
                    this.$emit('noFilter');
                }
            },
            modExpert:function () {
                if(JSON.stringify(this.defaultSelect) === JSON.stringify(this.selected) && this.modExpert===false ){
                    this.$emit('noFilter');
                    
                }
            },
        },
       
        created(){
            this.formatter = value => `${value}k €`
            this.isMobile();
            this.handleUrlParameters();
        },
        mounted(){

        },
        methods: {
            isMobile() {
                if (screen.width <= 760) {
                    this.mobile = true;
                } else {
                    this.mobile = false;
                }
            },
            expandFilters(){
                if(this.mobile){
                    this.expand = !this.expand
                }
            }
            ,  
            formater(val){
                return `${val}$`;
            },

            sendDataToParent(){
                if(JSON.stringify(this.defaultSelect) !== JSON.stringify(this.selected) || this.modExpert){
                    this.$emit('sendDataToParent', this.selected)
                }
                
            },
            changeToTabListView() {
                this.$emit('changeToTabListView',this.formatLista)
            },
            changeToExpertMode() {
                this.$emit('changeToExpertMode',this.modExpert)
            },
            handleUrlParameters(){
                if(!(this.$route.query && Object.keys(this.$route.query).length===0 &&  this.$route.query.constructor === Object)){
                    let urlQuery = this.$route.query;
                    if(urlQuery['finantari-active'] ==="da" && urlQuery['finantari-active']){
                        this.selected.finantariActive = true;
                    }else{
                        this.selected.finantariActive = false;
                    }
                    if (urlQuery['regiune-implementare']) {
                        this.selected.filtreazaRegiuneImplementare = urlQuery['regiune-implementare'];
                    }
                    if (urlQuery['optiune-cofinantare'] ) {
                        if( urlQuery['optiune-cofinantare'] === 'Fara-cofinantare' ){
                            this.selected.optiuneCofinantare = 'no_contribution';
                        }else{
                            this.selected.optiuneCofinantare = 'with_contribution';
                        }
                    }
                    if(urlQuery['sursa-finantare']){
                        this.selected.sursaFinantare = urlQuery['sursa-finantare'].replace(/-/g, ' ');
                    }
                    if(urlQuery['valoare-finantare'] && urlQuery['valoare-finantare'].length>0){
                        this.selected.valoareaFinantarii[0] = parseInt( urlQuery['valoare-finantare'][0]);
                        this.selected.valoareaFinantarii[1] = parseInt( urlQuery['valoare-finantare'] [1]);
                    }
                    if (urlQuery['tip-beneficiar']) {
                        this.selected.tipBeneficiar = urlQuery['tip-beneficiar'].replace(/-/g, ' ');

                    }
                    this.$emit('sendDataToParent', this.selected)
                }
            },
        }
    };
</script>

<style scoped>
#filters > div{
    min-height: 74px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}
#filters > div:last-child{
    align-items: flex-end;
}
#filters > div > div{
    min-height: 32px;
}
#filters select{
    color: #a1a1a1;
    margin-bottom: 0;
}
#filters label, #filters p{
    color: #696969;
    line-height: 20px;
}
#filters label{
    margin: 5px 0;
}
#filters .flexClass{
	display: flex;
}
#filters .flexClass div{
	flex-direction: row;
}
#filters.spaceBetween{
	justify-content: space-between;
}
#filters{
    padding: 10px 30px;
    box-sizing: border-box;
    background: #fff;
    display: grid;
    grid-template-columns: 15% 22.5% 22.5% 22.5% 15%;
    grid-gap: 0 10px;
}
#filters .priceSlide{
    position: relative;
    top: 5px;
}
#filters .flexClass div
{
    line-height: 45px;
}
#filters label, #filters *{
    font-size: 12px !important;
}
#filters .custom-select{
    width: 100% !important;
}
.formatTabSwitch{
    width: 159px;
}
.expertSwitch{
    width: 196px;
}
@media only screen and (max-width: 1440px) {
    #filters .custom-select{
        width: 100% !important;
    }
}
@media(max-width:900px){
    #filters{
        display: block;
    }
    #filters > div:last-child{
        align-items: flex-start;
    }
}
</style>
