<template>
    <div class="modul-finantare-title item-f align-center">
        <span class="bold-text">Contribuție proprie</span>
        <div>
            <p class="contribution-percent" style="margin: 0" v-for="(item, key) in data.contributions" :key="key">{{item}}</p>
        </div>
    </div>
</template>

<script>export default{
    props: {
        data: null,
    },
    data() {
        return {}
    },
    methods: {},
}</script>

<style scoped>
    .bold-text {
        font-weight: bold;
        font-size: var(--moduleTitleSize);
    }

    .item-f {
        grid-area: contributieProprie;
        display: flex;
        justify-content: space-between;
    }

    .contribution-percent {
        font-size: 23px;
        line-height: 23px;
        margin-bottom: -10px;
        color: #434343;
        font-weight: 600;
        text-align: right;
    }

    @media only screen and (max-width: 710px) {
        .contribution-percent {
            font-size: 20px !important;
        }
    }

    @media only screen and (max-width: 400px) {
        .contribution-percent {
            font-size: 15px !important;
            margin-left: 5px;
        }
    }

    @media only screen and (max-width: 1250px) {
        .item-f {
            grid-area: contributieProprie;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
</style>