<template>
    <div>
        <div class="background-container justify-content-between align-items-center">
            <div class="user-info">
                <div class="uProf">
                    <b-img
                        class="image"
                        :src="userImage ? userImage :require('@/assets/images/icontest.png')"
                    ></b-img>
                    <!--<span class="dot"></span>-->
                </div>
                <div class="text-user mt-4">
                    <p class="nume">
                        {{ userName }}
                    </p>
                    <p class="rol">
                        <span class="pachet">{{ usepackage }}</span> | {{ role }}
                    </p>
                    <p class="mail mb-3">{{ email }}</p>
                    <p class="mail">{{ phone }}</p>
                    <p class="companie">{{ company }}</p>
                </div>
            </div>
            <div class="top-bar-right-side mr-4">
                <div class="d-felx text-right">
                    <b-img
                        :src="require('@/assets/images/logoSmisGrey.png')"
                    ></b-img>
                </div>
                <p class="nume-smis">STRUCTURAL MANAGEMENT INNOVATIVE SYSTEM</p>
                <p>CUI: 43743807</p>
                <p>Registrul comerțului: J15/217/2021</p>
                <p>RO78BACX0000002127316001</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {BImg} from 'bootstrap-vue'
    export default {
        components:{
            BImg
        },
        props: {
            userName: {
                type: String,
                default: "Nume",
            },
            userImage: {
                type: String,
                default: '',
            },
            role: {
                type: String,
                default: "Rol",
            },
            email: {
                type: String,
                default: "email",
            },
            usepackage: {
                type: String,
                default: "Basic",
            },
            phone: {
                type: String,
                default: "0707070707",
            },
            company: {
                type: String,
                default: "Compania Mea",
            },
        },
        data() {
            return {};
        },
        created() {},
        mounted() {
            
        },
        methods: {},
    };
</script>

<style scoped>
	.background-container {
		display: flex;
		background-image: linear-gradient(to right, #5f58a8, #5eb9b2);
        width: 100%;
        padding: 20px 0px;
	}
	.user-info {
		display: flex;
		align-items: center;
		margin-top: -30px;
	}
	.uProf {
		position: relative;
		display: inherit;
		padding-left: 20px;
	}
	.image {
		border-radius: 50%;
		width: 60px;
		height: 60px;
		position: relative;
		margin-right: 17px;
		background-color: #5f58a8;
	}
	.dot {
		position: absolute;
		border-radius: 50%;
		width: 11px;
		height: 11px;
		background-color: #50e7f3;
		border: 2px solid white;
		top: 5px;
		left: 20px;
	}
	.text-user {
		line-height: 1;
	}
	.nume {
		color: #fff;
		font-size: 22px;
	}
	.rol {
		color: #58ceb9;
		font-size: 15px;
	}
	.mail {
		color: #d8e0e0;
		font-size: 12px;
	}
	.pachet {
		font-weight: bold;
	}
    .companie {
        color: #8380b6;
        font-size: 12px;
    }

    .top-bar-right-side p {
        color: rgb(205,233,231, 0.37);
        font-size: 12px;
        text-align: left;
    }
    .top-bar-right-side .nume-smis {
        color: rgba(255, 255, 255, 0.37) !important;
        font-size:14px !important;
    }

    @media only screen and (max-width: 1550px) {
        .top-bar-right-side {
            margin-left: 10px;
        }
        .top-bar-right-side p {
            font-size: 10px;
        }
        .top-bar-right-side .nume-smis {
        font-size:12px !important;
    }
    @media (max-width:500px){
        .background-container{
            flex-direction: column;
        }
    }
    }
</style>
