<template>
    <div>
        <div class="doc-header">
            <span>Documente implementare M2</span>
        </div>
        <div class="doc-body">
            <ul>
                <router-link to="#"><li><b-icon-cloud-download font-scale="1.5"></b-icon-cloud-download><span>Document 1</span></li></router-link>
                <router-link to="#"><li><b-icon-cloud-download font-scale="1.5"></b-icon-cloud-download><span>Document 1</span></li></router-link>
                <router-link to="#"><li><b-icon-cloud-download font-scale="1.5"></b-icon-cloud-download><span>Document 1</span></li></router-link>
                <router-link to="#"><li><b-icon-cloud-download font-scale="1.5"></b-icon-cloud-download><span>Document 1</span></li></router-link>
                <router-link to="#"><li><b-icon-cloud-download font-scale="1.5"></b-icon-cloud-download><span>Document 1</span></li></router-link>
            </ul>
        </div>
    </div>

</template>

<script>
import {BIconCloudDownload} from 'bootstrap-vue'
export default {
    components:{
        BIconCloudDownload,
    },
    props: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.doc-header{
    padding: 10px;
    border-bottom: 0;
    background: #f8f8f8;
}
.doc-header span{
    font-size: 14px;
    font-weight: 800;
    background: -webkit-linear-gradient(#583fcf, #408cd3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.doc-body{
    background: #fff;
    padding: 20px;
}
.doc-body li{
    font-size: 14px;
    color: #737373;
    margin-bottom: 15.5px;
    display: flex;
    align-items: center;
}
.doc-body li span{
    margin-left: 14px;
}
@media (max-width:1100px){

}
</style>