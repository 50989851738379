<template>
    <div>
        <a :href="link" :class="classname" :style="stylename">{{ text }}</a>
    </div>
</template>

<script>
    export default {
        props: {
            text: {
                type: String,
                default: 'Content'
            },
            link: {
                type: String,
                default: 'javascript:void(0)'
            },
            classname: {
                type: String,
                default: 'www'
            },
            stylename: {
                type: String,
                default: ''
            }
        },
    }
</script>

<style scoped>
    a.background {
        background: linear-gradient(15deg, #0fa4c3, #6000cb 91%);
        color: #fff;
        border: 0;
        width: fit-content;
        min-width: 110px;
        display: block;
        border-radius: 3px;
        padding: 4.5px 15px;
        text-decoration: none;
        text-align: center;
        white-space: nowrap;
        font-size: 14px;
    }
    a.background:hover {
        background: linear-gradient(15deg, #6000cb 11%, #0fa4c3);
    }

    a.idenviz {
        background: linear-gradient(to bottom, #5b3cd0, #3bc6d4);
        color: #fff;
        border: 0;
        display: inline-block;
        border-radius: 3px;
        padding: 7.5px 9px;
        text-decoration: none;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        font-size: 14px;
        white-space: nowrap;
    }
    a.idenviz:hover {
        background: linear-gradient(to bottom, #3bc6d4, #5b3cd0);
    }

    a.empty {
        background: linear-gradient(to bottom, #6000cb, #0fa4c3);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: normal;
        display: inline-block;
        text-align: center;
        border: 2px solid;
        border-image-slice: 1;
        padding: 4px 7px;
        width: 100%;
        box-sizing: border-box;
        border-image-source: linear-gradient(to bottom, #6000cb, #0fa4c3);
        font-size: 14px;
        white-space: nowrap;
    }
    a.empty:hover {
        background: linear-gradient(to bottom, #6000cb, #0fa4c3);
        -webkit-text-fill-color: #fff;
        text-decoration: none;
    }

    a.orange {
        background: linear-gradient(to top, #ff8200, #ff403e);
        color: #fff;
        border: 0;
        width: fit-content;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 3px;
    }
    a.orange:hover {
        background: linear-gradient(to bottom, #ff8200, #ff403e);
    }
    a.green {
        background: linear-gradient(to top, #49a5ce, #07c2a4);
        color: #fff;
        border: 0;
        width: 100%;
        min-width: 170px;
        display: block;
        border-radius: 3px;
        padding: 3px 10px;
        text-decoration: none;
        text-align: center;
        /*white-space: nowrap;*/
        font-size: 13px;
    }
    a.green:hover {
        background: linear-gradient(to bottom, #07c2a4, #49a5ce);
    }

@media (max-width:700px) {
    a.orange{
        min-width: 100px;
        width: 150px;
        
    }
    a.green{
        white-space:normal;
    }
}
</style>
