<template>
    <div class="modul-finantare-title">
        <span class="bold-text gradient-title">Despre noi</span>
        <div class="desc-info mb-5 mt-2" v-html="data.about">
        </div>
        <span class="bold-text gradient-title">Servicii oferite</span>
        <div class="servicii">
            <ul class="lista-servicii mt-2">
                <li v-for="(serviciu, key) in data.services" :key="key">
                    <b-icon-check-circle-fill
                        v-if="serviciu.checked"
                        style="margin-right: 10px; fill: var(--succesColor);"
                        font-scale="1"
                    ></b-icon-check-circle-fill>
                    <b-icon-x-circle-fill
                        v-if="!serviciu.checked"
                        style="margin-right: 10px; fill: var(--dangerColor);"
                        font-scale="1"
                    ></b-icon-x-circle-fill>
                    <span class="service-name">{{ serviciu.name }}</span>
                </li>

            </ul>
        </div>
    </div>
</template>

<script>
import {BIconCheckCircleFill, BIconXCircleFill} from 'bootstrap-vue'

export default {
    name: "CompanieVerificata",
    components: {
        BIconCheckCircleFill,
        BIconXCircleFill
    },
    props: {
        data: null,
    },
    data() {
        return {};
    },
}
</script>

<style scoped>
.bold-text {
    font-weight: bold;
}

.lista-servicii {
    column-count: 2;
}

p, li span {
    font-size: 12px;
}

.gradient-title {
    font-size: 14px;
    color: #1c1c1c;
}

@media only screen and (max-width: 900px) {
    .lista-servicii {
        column-count: 1;
    }
}
</style>