<template>
    <div>
        <GrayTitleBar :data="title"></GrayTitleBar>
        <div class="box-padding" v-if="!loading">
            <div class="list-grid">
                <div class="modul-finantare-title" v-for="(offer, key) in offers" :key="key">
                    <div class="module-head">
                        <span class="bold-text gradient-title">Ofertă specială</span>
                        <img :alt="offer.id" :src="offer.logo">
                    </div>
                    <div class="module-title">Măsura 2 - comision 5%</div>
                    <div v-html="offer.special_offer"></div>
                </div>
            </div>
        </div>
        <LoadingSpinner v-else></LoadingSpinner>
    </div>
</template>

<script>
import GrayTitleBar from "../PrezentareCompanie/components/GrayTitleBar";
import api from '@/helpers/api'
import axios from '@/helpers/axios'
import LoadingSpinner from '@/common/components/LoadingSpinner'

export default {
    name: "OferteSpeciale",
    components: {
        GrayTitleBar,
        LoadingSpinner,
    },
    data() {
        return {
            title: 'Oferte speciale de consultanță',
            offers: [],
            loading: true,
        }
    },
    mounted() {
        this.getOffers()
    },
    methods: {
        getOffers() {
            axios.get(api.API_ENDPOINTS.consultantOffers)
                .then((response) => {
                    this.offers = response.data;
                }).catch((xhr) => {
                    this.$notify(xhr.response.data.message);
                }).finally(() => this.loading = false)
        }
    }
}
</script>

<style scoped>
.bold-text {
    font-weight: bold;
}

.box-padding {
    padding: 20px 20px 70px 20px;
}

.gradient-title {
    background: -webkit-linear-gradient(#1c1c1c, #808080);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.module-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modul-finantare-title {
    background-color: #f8f8f8;
    color: #434343;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 2px 6px 1px #dddddd;
    height: 100%;
}

.list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-template-rows: auto;
    grid-gap: 20px;
    margin: 20px 0 0 0;
}

.module-title {
    text-align: right;
    font-size: 18px;
    color: #1a1a1c;
    margin-top: 20px;
    font-weight: 700;
}
.module-head img{
    max-height: 50px;
}
</style>