<template>
    <div>
        <PersonalTopBar :user-name="this.infoCont.name"
                        :role="this.infoCont.rol"
                        :email="this.infoCont.email"
                        :user-image="this.infoCont.avatar"
                        :usepackage="this.infoCont.account_type_name"
                        :phone="this.infoCont.phone"
                        :company="this.infoCont.company"
        ></PersonalTopBar>
        <div v-if="!loading" class="padding-box">
            <div class="calendar-evenimente">
                <div class="white-box">
                    <CalendarEvenimente></CalendarEvenimente>
                </div>
            </div>
            <div class="contact">
                <div class="green-box mb-3 small-box marginmobile">
                    <ContactRapid></ContactRapid>
                </div>
                <div class="white-box mb-3 small-box marginmobile">
                    <FinantariActive :data="finantariActive"></FinantariActive>
                </div>
                <div class="white-box small-box marginmobile">
                    <UtilizatoriSmis :data="utilizatoriTotal"></UtilizatoriSmis>
                </div>
            </div>
            <div class="finantari-aplicatii">
                <div class="yellow-box mb-3 marginmobile">
                    <FinantariPrezentate :data="finantariTotal"></FinantariPrezentate>
                </div>
                <div class="white-box">
                    <AplicatiiSmis :data="aplicatiiSmis"></AplicatiiSmis>
                </div>
            </div>
            <div class="task-manager marginmobile">
                <div class="white-box">
                    <TaskManager v-if="taskManager" :data="taskManager"></TaskManager>
                </div>
            </div>
            <div class="stiri marginmobile">
                <PersonalStiri :data="stiriSmis"></PersonalStiri>
            </div>
            <div class="videoclip marginmobile">
                <div class="flexed">
                    <VideoTutorial v-if="prezentare && prezentare.title != null" class="flexed-item" :data="prezentare"></VideoTutorial>
                    <FinantariEligibileItem class="flexed-item" :data="eligibilitateFinantari"></FinantariEligibileItem>
                </div>
            </div>
            <div class="valoarea-finantarilor marginmobile">
                <div class="white-box">
                    <ValoareaFinantarilor :data="valoareaFinantarilor"></ValoareaFinantarilor>
                </div>
            </div>
        </div>
        <LoadingSpinner v-else></LoadingSpinner>
    </div>
</template>


<script>
    import LoadingSpinner from '@/common/components/LoadingSpinner'
    import PersonalTopBar from './components/PersonalTopBar'
    import CalendarEvenimente from './components/CalendarEvenimente'
    import ContactRapid from './components/ContactRapid'
    import FinantariActive from './components/FinantariActive'
    import UtilizatoriSmis from './components/UtilizatoriSmis'
    import AplicatiiSmis from './components/AplicatiiSmis'
    import TaskManager from './components/TaskManager'
    import PersonalStiri from './components/PersonalStiri'
    import VideoTutorial from './components/VideoTutorial'
    import FinantariEligibileItem from './components/FinantariEligibileItem'
    import FinantariPrezentate from './components/FinantariPrezentate'
    import ValoareaFinantarilor from './components/ValoareaFinantarilor'
    import api from '@/helpers/api';
    import axios from '@/helpers/axios';

    export default {
        components: {
            LoadingSpinner,
            PersonalTopBar,
            CalendarEvenimente,
            ContactRapid,
            FinantariActive,
            UtilizatoriSmis,
            AplicatiiSmis,
            TaskManager,
            PersonalStiri,
            VideoTutorial,
            FinantariEligibileItem,
            FinantariPrezentate,
            ValoareaFinantarilor,
        },
        props: {},
        data() {
            return {
                infoCont: {
                    name: '',
                    rol: '',
                    email: '',
                    phone: '',
                    avatar: '',
                    account_type_name: '',
                    account_type_logo: '',
                    company: '',
                },
                finantariActive: [],
                aplicatiiSmis: [],
                taskManager: [],
                stiriSmis: [],
                valoareaFinantarilor: [],
                eligibilitateFinantari: [],
                utilizatoriTotal: null,
                prezentare: null,
                finantariTotal: null,
                loading: true,
            };
        },
        mounted() {
            this.getInfoCont()
            this.getPersonalSmis()
        },
        methods: {
            getInfoCont() {
                this.infoCont.name = this.$store.state.auth.user.name
                this.infoCont.rol = this.$store.state.auth.user.role
                this.infoCont.email = this.$store.state.auth.user.email
                this.infoCont.phone = this.$store.state.auth.user.phone
                this.infoCont.avatar = (this.$store.state.auth.user.logo ? this.$store.state.auth.user.logo : null)
                this.infoCont.account_type_name = (this.$store.state.auth.user.account_type ? this.$store.state.auth.user.account_type.name : '')
                if(this.$store.state.auth.user.account_type && this.$store.state.auth.user.account_type.logo){
                    this.infoCont.account_type_logo = this.$store.state.auth.user.account_type.logo
                }
                this.infoCont.company = this.$store.state.auth.user.company
            },
            getPersonalSmis() {
                axios
                    .get(api.API_ENDPOINTS.homepage)
                    .then(response => {
                        this.finantariActive = {
                            total_fundings: response.data.total_fundings,
                            active_fundings: response.data.active_fundings,
                        }
                        this.utilizatoriTotal = response.data.total_customers
                        this.finantariTotal = response.data.total_fundings
                        this.aplicatiiSmis = response.data.smis_apps
                        this.taskManager = response.data.user_todos
                        this.stiriSmis = response.data.latest_news
                        this.prezentare = response.data.presentation
                        this.eligibilitateFinantari = response.data.eligibilities_count
                        this.valoareaFinantarilor = {
                            labelChart: response.data.funding_analytics,
                            dataChart: response.data.funding_analytics,
                        }

                    }
                    ).finally(() => this.loading = false)
            },
        },
    }
</script>

<style scoped>
.padding-box {
    display: grid;
    grid-template-columns: 1.6fr 1.6fr 1fr 1fr 1fr;
    gap: 20px;
    grid-template-areas:
    "calendar-evenimente contact stiri stiri stiri"
    "calendar-evenimente finantari-aplicatii videoclip videoclip videoclip"
    "calendar-evenimente task-manager valoarea-finantarilor valoarea-finantarilor valoarea-finantarilor";
    padding: 20px;
    padding-bottom: 100px;
}

.calendar-evenimente {
    grid-area: calendar-evenimente;
}

.contact {
    grid-area: contact;
}

.finantari-aplicatii {
    grid-area: finantari-aplicatii;
}

.task-manager {
    grid-area: task-manager;
}

.stiri {
    grid-area: stiri;
}

.videoclip {
    grid-area: videoclip;
}

.valoarea-finantarilor {
    grid-area: valoarea-finantarilor;
}

.white-box {
    background: #fff;
    padding: 10px;
    border-radius: 2px;
}

.yellow-box {
    background: #fcc100;
    padding: 10px;
    border-radius: 2px;
}

.green-box {
    background: #0cc2aa;
    padding: 10px;
    border-radius: 2px;
}

.small-box {
    min-height: 78px;
    display: flex;
    align-items: center;
}

.small-box div {
    width: 100%;
}

.flexed {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.flexed-item {
    width: 49%;
}

@media only screen and (max-width: 1600px) {
    .padding-box {
        display: block;
    }

    .marginmobile {
        margin-top: 20px;
    }
    .flexed{
        display: block;
    }
    .flexed-item{
        width: 100%;
    }
    .flexed-item:not(:first-child){
        margin-top: 20px;
    }

}
</style>