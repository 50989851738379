<template>
    <div style="cursor: pointer">
        <div v-if="isFavorite === true">
            <b-icon-heart-fill v-if="postTo === 'favoriteMethod'" class="redBackground" @click="favoriteMethod"></b-icon-heart-fill>
            <b-icon-heart-fill v-else-if="postTo === 'wishlistMethod'" class="redBackground" @click="wishlistMethod"></b-icon-heart-fill>
        </div>
        <div v-else>
            <b-icon-heart v-if="postTo === 'favoriteMethod'" @click="favoriteMethod"></b-icon-heart>
            <b-icon-heart v-else-if="postTo === 'wishlistMethod'" @click="wishlistMethod"></b-icon-heart>
        </div>
    </div>
</template>

<script>
    import {BIconHeartFill, BIconHeart} from 'bootstrap-vue';
    import api from '@/helpers/api'
    import axios from '@/helpers/axios';

    export default {
        components: {
            BIconHeartFill, BIconHeart
        },
        data() {
            return {
                remountComponentFavorite: 0,
                favorite: this.isFavorite
            }
        },
        props: {
            isFavorite: null,
            id: null,
            postTo: {
                type: String,
                default: 'favoriteMethod'
            },
        },
        methods: {
            favoriteMethod() {
                this.$store.dispatch('auth/getUserData');
                if (this.favorite === false) {
                    this.favorite = true;
                } else {
                    this.favorite = false;
                }
                if(this.isFavorite === true){
                    this.isFavorite = false
                } else if(this.isFavorite === false){
                    this.isFavorite = true
                }
                axios
                    .post(api.API_ENDPOINTS.wishlistFunding + '/'  + this.id, true, {
                    })
                    .then((response) => {
                        this.$emit('getId', this.id);
                        this.$notify(response.data.message);
                    });
            },
            wishlistMethod() {
                if (this.favorite === false) {
                    this.favorite = true;
                } else {
                    this.favorite = false;
                }
                if(this.isFavorite === true){
                    this.isFavorite = false
                } else if(this.isFavorite === false){
                    this.isFavorite = true
                }
                axios
                    .post(api.API_ENDPOINTS.wishlistNews + '/'  + this.id, true, {
                    })
                    .then(response => {
                        this.$emit('getId', this.id);
                        this.$notify(response.data.message);
                    });
            },
        }
    }
</script>

<style scoped>
    .redBackground {
        fill: #ff7070;
        color: #fff;
        font-size: 25px;
        stroke-width: 30px;
        color: white;
    }

    .b-icon {
        font-size: 25px;
        color: #fff;
    }
</style>