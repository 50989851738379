<template>
    <div>
        <DarkBlueTitleBar :data="title"></DarkBlueTitleBar>
        <GradientTopSection></GradientTopSection>
        <div class="title"><h1>Alege un pachet de servicii</h1></div>
        <div class="box-padding">
            <div class="programe">
                <div v-for="(pac, i) in pachet" :key="i">
                    <PacheteBox
                        :lunar="pac.lunar"
                        :anual="pac.anual"
                        :pret="pac.pret"
                        :poza-pachet="pac.poza"
                        :tip-pachet="pac.tippachet"
                        :economisire="pac.economisire"
                        :serviciu="pac.serviciu"
                    ></PacheteBox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PacheteBox from "./components/PacheteBox";
    import DarkBlueTitleBar from '@/common/components/DarkBlueTitleBar';
    import GradientTopSection from './components/GradientTopSection';
    export default {
        name: "Test",
        components: {
            DarkBlueTitleBar,
            GradientTopSection,
            PacheteBox,

        },
        props: {},
        data() {
            return {
                title: 'SMIS Consultant',
                pachet: [
                    {
                        poza: "LogoSmisConsultantBASIC",
                        tippachet: "abonament",
                        lunar: 4,
                        anual: 3,
                        economisire: 144,
                        serviciu: "licenta",
                    },
                    {
                        poza: "LogoSmisConsultantTRAINING",
                        tippachet: "abonament",
                        lunar: 4,
                        anual: 3,
                        economisire: 144,
                        serviciu: "licenta",
                    },
                    {
                        poza: "LogoSmisConsultantPROF",
                        tippachet: "abonament",
                        lunar: 132,
                        anual: 78,
                        economisire: 648,
                        serviciu: "none",
                    },
                ],
            };
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
.title{
  width: 100%;
  background: #fff;
  padding: 10px 30px;
}
.title h1{
  font-size: 15px;
  color: #666666;
  margin: 0;
  font-weight: 800;
}
.box-padding {
  padding: 20px 70px 0px 20px;
  margin-bottom: 130px;
}
.programe{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(488px, auto));
  grid-template-rows: auto;
  grid-gap: 20px;
  margin: 20px 0 0 0;
}
@media (max-width:560px) {
  .programe{
     grid-template-columns: repeat(auto-fill, minmax(300px, auto));
  }
}
</style>