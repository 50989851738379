<template>
    <div>
      <DarkBlueTitleBar :data="title"></DarkBlueTitleBar>
      <GradientTopSection></GradientTopSection>
      <div class="box-padding">
        <span class="page-title">Transmite o solicitare de ofertă prin <strong>Smis <sup>®</sup></strong></span>
        <div class="flexed mt-4">
          <Formular class="mr-4"></Formular>
          <CumFunctioneaza class="mt-4"></CumFunctioneaza>
        </div>
        <div class="companii-lista">
          <span class="gray-text">Companii de consultanță verificate și recomandate de SMIS.ro</span>
          <div class="list-companii">
            <CompanieLista class="mb-4"></CompanieLista>
            <CompanieLista class="mb-4"></CompanieLista>
            <CompanieLista class="mb-4"></CompanieLista>
            <CompanieLista class="mb-4"></CompanieLista>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import DarkBlueTitleBar from '@/common/components/DarkBlueTitleBar';
import Formular from './components/Formular';
import CumFunctioneaza from './components/CumFunctioneaza';
import GradientTopSection from './components/GradientTopSection';
import CompanieLista from '../CautaConsultant/components/CompanieLista';
export default {
  name: "SolicitaOferta",
  components: {
    DarkBlueTitleBar,
    Formular,
    GradientTopSection,
    CompanieLista,
    CumFunctioneaza,
  },
  data () {
    return {
      title: 'Solicită ofertă de consultanță sau formare profesională',
    }
  }
}
</script>

<style scoped>
.bold-text {
  font-weight: bold;
}
.box-padding{
  padding: 20px 20px 70px 20px;
}
.page-title{
  font-size: 19px;
  font-weight: 500;
}
.flexed{
  display: flex;
}
.list-companii{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-template-rows: auto;
  grid-gap: 20px;
  margin: 20px 0 0 0;
}
.gray-text{
  font-size: 14px;
  color: #919394;
}
.companii-lista{
  margin-top: 40px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 900px) {
  .flexed {
    display: block;
  }
  .list-companii{
    display: block;
  }
}
</style>