<template>
    <div>
        <header>
            <div class="header-container">
                <div class="header-left">
                    <LogoSmis></LogoSmis>
                    <Meniu class="showMenu" 
                           :shortcutsDropdownOpen="shortcutDropdownOpen"
                           :notificationsDropdownOpen="notificationDropdownOpen" 
                           :left-menu-opened="leftMenuOpened" 
                           :usersDropdownOpen="userDropdownOpen"
                           @isClickedTopNav="topMenuOpened"
                    ></Meniu> 
                    <BurgerMenu 
                        class="showBurgerMobile" 
                        :left-menu-opened="leftMenuOpened" 
                        @isClickedTopNav="topMenuOpened"
                    >
                    </BurgerMenu>
                </div>
                <div class="header-right">
                    <ShortcutDropdown
                        :notificationsDropdownOpen="notificationDropdownOpen"
                        @shortcutsDropdownOpen="notificationDropdownClose()"
                    >
                    </ShortcutDropdown>
                    <NotificationDropdown
                        :shortcutsDropdownOpen="shortcutDropdownOpen"
                        @notificationsDropdownOpen="shortcutDropdownClose()"
                    >
                    </NotificationDropdown>
                    <SmisTag tagnum="98T765"></SmisTag>
                    <UserIcon
                        user-name="User1"
                        role="Admin"
                        user-image="icontest"
                        :notificationsDropdownOpen="notificationDropdownOpen"
                        :shortcutsDropdownOpen="shortcutDropdownOpen"
                        @userDropdownOpen="userDropdownClose()"
                    ></UserIcon>
                    <BurgerMenu
                        class="showBurgerTablet"
                        :left-menu-opened="leftMenuOpened"
                        @isClickedTopNav="topMenuOpened"
                    >
                    </BurgerMenu>
                </div>
            </div>
        </header>
    </div>
</template>
<script>
    import NotificationDropdown from './components/NotificationDropdown';
    import ShortcutDropdown from './components/ShortcutDropdown';
    import UserIcon from "./components/UserIcon";
    import SmisTag from "./components/SmisTag";
    import LogoSmis from "./components/LogoSmis";
    import Meniu from "./components/Meniu";
    import BurgerMenu from "./components/BurgerMenu.vue";
    export default {
        components: {
            NotificationDropdown,
            ShortcutDropdown,
            UserIcon,
            SmisTag,
            LogoSmis,
            Meniu,
            BurgerMenu,
        },
        props:{
            leftMenuOpened:Boolean
        },      
        data() {
            return {
                topMenuOpenedVal: false,
                notificationDropdownOpen:false,
                shortcutDropdownOpen:false,
                userDropdownOpen:false
            }
        },
        methods:{      
            shortcutDropdownClose(){
                this.notificationDropdownOpen=true;
                this.shortcutDropdownOpen = false;
                this.topMenuOpenedVal=false;
                this.userDropdownOpen=false;
            },   
            notificationDropdownClose(){
                this.shortcutDropdownOpen=true;
                this.notificationDropdownOpen = false;
                this.topMenuOpenedVal=false;
                this.userDropdownOpen=false;
            },
            topMenuOpened(){
                this.topMenuOpenedVal=true;
                this.notificationDropdownOpen=false
                this.shortcutDropdownOpen = false;
                this.userDropdownOpen=false;
                this.$emit('topMenuOpened',this.topMenuOpenedVal);
                //this.closeLeftMenu();
            },
            userDropdownClose(){
                this.notificationDropdownOpen=false;
                this.shortcutDropdownOpen = false;
                this.topMenuOpenedVal=false;
                this.userDropdownOpen=true;
                
            },   
            // closeLeftMenu(){                
            //     let leftMenu = document.getElementById('leftSecond');
            //     leftMenu.classList.add('hideElement');
            //     let topMenu = document.getElementsByClassName('dropdown-menu')[0];
            //     topMenu.classList.remove('hideElement');
            // }
        }
    };
</script>
<style scoped>
.header-container {
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%;
}
header {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
}
.header-right,
.header-left,
.header-container {
  display: flex;
}
.showBurgerMobile{
    display: none;
}
.showBurgerTablet{
    display: none;
}
@media (max-width:900px) and (min-width:540px) {
    .showMenu{
        display: none;
    }
    .showBurgerTablet{
        display: block;
    }

}

@media (max-width:540px){
    .showMenu{
        display: none;
    }
    .showBurgerMobile{
        display: block;
    }
    
    .header-container{
        display: block;
    }
    .header-left{
        justify-content: space-between;
        border-bottom: 1px solid #f1f1f1;
        padding:0.5rem 0;
    }
    .header-right{
        justify-content: space-between;
    }
    
    
}
</style>
