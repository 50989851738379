<template> 
    <div v-click-outside="closeDropdown" class="uProf" @click="toggleDropdown()">
        <div v-if="infoCont.avatar">
            <img
                class="image"
                :src="infoCont.avatar"
                alt=""
            >
            <span class="dot"></span>
        </div>
        <div v-else>
            <img
                class="image"
                :src="require('@/assets/images/' + userImage + '.png')"
                alt=""
            >
            <span class="dot"></span>
        </div>
        <div v-if="infoCont.name" class="uName">
            <p>{{ infoCont.name }}</p>
            <p style="color: #b0b0b0; font-size: 12px; margin-top: 3px;">{{ infoCont.rol }}</p>
        </div> 
        <div class="spriteBg points" > 
            
        </div>
        <div v-if="infoCont.name">
            <div v-if="isOpen" class="dropdown">
                <router-link to="/contul-meu">
                    <div class="dropdown-item">
                        Contul tău SMIS
                    </div>
                </router-link>
                <a href="https://smis.ro" target="_blank">
                    <div class="dropdown-item">
                        Vizitează smis.ro
                    </div>
                </a>
                <router-link to="/contul-meu/financiar">
                    <div class="dropdown-item">
                        Financiar
                    </div>
                </router-link>
                <a href="javascript:void(0)" @click="logout">
                    <div class="dropdown-item border-top">
                        Logout
                    </div>
                </a>
            </div>
        </div>
        <div v-else>
            <div v-if="isOpen" class="dropdown">
                <a href="https://smis.ro" target="_blank">
                    <div class="dropdown-item">
                        Vizitează smis.ro
                    </div>
                </a>
                <router-link to="/login">
                    <div class="dropdown-item border-top">
                        Login
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/helpers/api'
    export default {
        directives: {
            'click-outside': {
                bind: function(el, binding, vNode) {
                    // Provided expression must evaluate to a function.
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) { warn += `Found in component '${compName}'` }
          
                        console.warn(warn)
                    }
                    // Define Handler and cache it on the element
                    const bubble = binding.modifiers.bubble
                
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    }
                    
                    el.__vueClickOutside__ = handler

                    // add Event Listeners
                    document.addEventListener('click', handler)
                },
      
                unbind: function(el) {
                    // Remove Event Listeners
                    
                    document.removeEventListener('click', el.__vueClickOutside__)
                    el.__vueClickOutside__ = null

                }
            }
        },
        props: {
            userName: {
                type: String,
                default: "Nume",
            },
            userImage: {
                type: String,
                default: "userimage",
            },
            role: {
                type: String,
                default: "Rol",
            },
            shortcutsDropdownOpen:{
                type:Boolean,
                default:false
            },
            notificationsDropdownOpen:{
                type:Boolean,
                default:false
            },    
        },
        data() {
            return {
                isOpen:false,
                infoCont: {
                    name: '',
                    rol: '',
                    avatar: '',
                },
                
            };
        },
        watch:{
            shortcutsDropdownOpen:function(){
                if(this.shortcutsDropdownOpen){
                    this.closeDropdown()
                }                      
            },
            notificationsDropdownOpen:function(){
                if(this.notificationsDropdownOpen){
                    this.closeDropdown()
                }                      
            }

        },
        mounted() {
            this.getInfoCont()
            this.$root.$on('userDataChanged', () => {
                this.getInfoCont()   
            });
        },
        methods: {
            toggleDropdown(){
                this.isOpen =! this.isOpen;
                if(this.isOpen)
                    this.$emit('userDropdownOpen')
            },
            closeDropdown(){
                this.isOpen=false;
            },
            getInfoCont() {
                this.infoCont.name = this.$store.state.auth.user.name
                this.infoCont.rol = this.$store.state.auth.user.role
                this.infoCont.avatar = this.$store.state.auth.user.logo
            },
            logout() {
                this.$store
                    .dispatch('auth/logout')
                    .then(() => this.$router.push({ path: api.LOGIN_ROUTE }))
            },
        },
    };
</script>

<style scoped>
.uProf {
    border-left: 1px solid #f1f1f1;
    position: relative;
    display: inherit;
    padding-left: 20px;
}
.uName {
    border-right: 1px solid #f1f1f1;
    padding: 10px 20px;
}
.uName p{
  line-height: 13px;
}
.uName p:first-child{
  padding-top: 7px;
}
.image {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    position: relative;
    margin-top: 15px;
}
.dot {
    position: absolute;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #6cc788;
    border: 2px solid;
    border-color: white;
    bottom: 22px;
    left: 42px;
}
.spriteBg {
	background: url('../../../assets/images/leftNavIcons.png') no-repeat;
	margin: 25px 50px 0 25px;
    cursor: pointer;

}
.points {
	background-position: -22px -118px;
	width: 4px;
	height: 12px;
}
.dropdown{
    position: absolute;
    background-color: white;
    border-radius: 2px;
    top:110%;
    right: 10px;
    z-index: 2;
    border:1px solid rgba(126, 124, 124,0.3);
}
.dropdown-item{
    padding: 0.45rem 1.6rem;
}
a .dropdown-item {
    color: #78777d;
    font-size: 14px;
}
.border-top{
    border-top:1px solid rgba(126, 124, 124,0.3);
}
.dropdown-item.active, .dropdown-item:active{
    background-color: transparent;
}
@media only screen and (max-width: 1630px) {
    .spriteBg {
        margin: 25px 20px 0 15px;
    }
}
@media only screen and (max-width: 1510px) {
    p{
        font-size: 13px;
    }
    .uName{
        padding: 14px;
    }
    @media (max-width:500px){
        .dot{
            top:51%;
            left: 17%;
        }
        .uProf{
            padding-left: 0;
        }
        .uName p:first-child{
          padding-top: 3px;
        }
    }
}
</style>
