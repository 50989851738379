<template>
    <div class="big-box-smis-info">
        <div class="smis-types mb-5">
            <div class="d-flex flex-column inform-box border-0" v-for="(plan, key) in tipCont" :key="key">
                <div class="mb-5">
                    <b-img
                        class="logo-smis"
                        v-if="plan.logo"
                        :src="plan.logo"
                    ></b-img>
                    <img v-else src="@/assets/images/fara-logo.png">
                </div>
                <div class="margin-par">
                    <div class="d-flex" v-for="(rules, key) in plan.rules" :key="key">
                        <b-img
                            v-if="rules.checked === true"
                            class="icon-margin"
                            :src="require('@/assets/images/iconCheck.png')"
                        ></b-img>
                        <b-img
                            v-else
                            class="icon-margin"
                            :src="require('@/assets/images/iconRedx.png')"
                        ></b-img>
                        <p>{{ rules.label }}</p>
                    </div>
                </div>
                <div>
                    <p class="text-beneficii">Beneficii:</p>
                    <p v-for="(benefit, key) in plan.benefits" :key="key">{{ benefit.label }}</p>
                </div>
            </div>

        </div>
    </div>

</template>

<script>

import {BImg} from 'bootstrap-vue'

export default {
    components: {
        BImg,
    },
    props: {
        tipCont: {
            type: Array,
            default: null
        },
    },
    data() {
        return {
            access: 1,
            companii: 29,
            aplicatii: 5,
            aplicatiiscriere: 1,
            cheltuieli: 15000,
        };
    },
    created() {
    },
    mounted() {
    },
    methods: {},
};
</script>
<style scoped>
.big-box-smis-info {
    padding-top: 20px;
}

.smis-types {
    display: flex;
}

p {
    font-size: 12px;
    color: #848484;
}

.text-user-data {
    color: #5c579e;
}

.text-beneficii {
    color: #575757;
    font-size: 14px;
    font-weight: bold;
}

.inform-box {
    border-right: 1px solid #eeeff0;
    padding: 0 80px;
}

.margin-par {
    height: 140px;
}

.icon-margin {
    margin-right: 10px;
    margin-left: -25px;
}

.logo-smis {
    max-height: 30px;
    max-width: auto;
}

@media only screen and (max-width: 1550px) {
    .big-box-smis-info {
        margin-top: -60px;
        padding: 20px 30px;
    }

    .smis-types {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(1420px, auto));
        grid-gap: 30px;
        grid-template-columns: 50% 50%;
        grid-template-rows: 250px 250px;
    }

    p {
        font-size: 10px;
    }

    .text-beneficii {
        font-size: 12px;
    }

    .margin-par {
        height: 80px;
    }

    .logo-smis {
        margin: 30px 0 -40px 0;
        max-height: auto;
    }

    .inform-box {
        border-right: 0;
        padding: 0;
        max-width: 250px;
    }
}

@media only screen and (max-width: 600px) {
    .smis-types {
        display: block;
    }

    .inform-box {
        border-right: 0;
        padding: 0;
        max-height: 170px;
    }

    .logo-smis {
        max-height: 100%;
        max-width: auto;
    }

    .margin-par {
        height: auto;
    }
}
</style>
