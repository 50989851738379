<template>
    <div class="box">
        <div class="box-title">
            Pasul 2 <span class="space-text">/</span>
            <span class="title-text">
                Informații despre compania ta - Beneficiar de Măsura 2</span>
        </div>
        <div class="compania-mea-box">
            <div class="d-flex flex-column treime">
                <div class="first-text">
                    <p class="text-center mb-4 w-75 align-self-center">
                        Tot ce trebuie să faci la acest pas este să introduci
                        <span class="bold-blue">CUI-ul firmei</span> / beneficiar de Măsura 2 (fără
                        RO)
                    </p>
                    <form action="" class="box-content-date mb-4">
                        <input id="CUI" type="text" placeholder="12345678" name="CUI" class="mr-3" >
                        <ButtonGradient
                            class="generaza-date-buton"
                            stylename="width:200px; max-width: 100%;"
                            url-buton="dadsdsss"
                            text="Generează datele >"
                            classname="background"
                        ></ButtonGradient>
                    </form>
                    <div class="mb-3">
                        <p>Bine ai venit! Firma ta este</p>
                        <p class="bold-gradient">FORMARE PROFESIONALA PLUS SRL</p>
                    </div>
                    <form action="" class="box-content-date">
                        <div class="d-flex w-100">
                            <input type="text" class="nr-rue" disabled placeholder="12345678" >
                        </div>
                        <p class="output-text">Numărul tău RUE</p>
                    </form>
                    <form action="" class="box-content-date">
                        <div class="d-flex w-100">
                            <input type="text" disabled placeholder="12345678" >
                            <span class="buton-info-date">lei</span>
                        </div>
                        <p class="output-text">Valoarea grantului tău</p>
                    </form>
                    <form action="" class="box-content-date">
                        <div class="d-flex w-100">
                            <input type="text" disabled placeholder="12345678" >
                            <span class="buton-info-date">lei</span>
                        </div>
                        <p class="output-text">Valoare cofinanțare</p>
                    </form>
                    <form action="" class="box-content-date">
                        <div class="d-flex w-100">
                            <input type="text" disabled placeholder="12345678" >
                            <span class="buton-info-date">lei</span>
                        </div>
                        <p class="output-text">Valoare totală proiect</p>
                    </form>
                    <div class="d-flex justify-content-between mt-5">
                        <div>
                            <p class="mb-3">Agenția pentru IMM responsabilă cu contractul tău:</p>
                            <form action="" class="box-content-date">
                                <input type="text" disabled placeholder="A.I.M.M.A.I.P.E. București" >
                            </form>
                        </div>
                        <b-img
                            class="gr-pic"
                            alt="GR logo"
                            :src="require('@/assets/images/logoguvern.png')"
                        ></b-img>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column treime">
                <div class="first-text">
                    <p class="text-center">Ai primit grantul pentru capital de lucru?</p>
                    <p class="bold-blue text-center">
                        Dacă ai primit grantul bifează căsuța de mai jos
                    </p>

                    <form
                        action="#"
                        class="d-flex align-items-center justify-content-center grant-radio-box"
                    >
                        <div class="radio">
                            <input id="primit" type="radio" name="radio-group" checked class="radio4" >
                            <label for="primit">Da, am primit grantul</label>
                        </div>
                        <div class="radio">
                            <input id="neprimit" type="radio" name="radio-group" class="radio4" >
                            <label for="neprimit">Nu, nu am primit grantul</label>
                        </div>
                    </form>
                    <form action="" class="box-content-date">
                        <div class="d-flex w-100">
                            <input type="text" disabled placeholder="Data semnare contract" ><span
                                class="buton-info-date"
                                style="cursor: pointer"
                                @click="showInfoPopup"
                            ><font-awesome-icon icon="info-circle" :style="{ color: '#f18989' }">
                            </font-awesome-icon></span>
                        </div>
                        <input class="output-text" type="date" >
                    </form>
                    <form action="" class="box-content-date">
                        <div class="d-flex w-100">
                            <input
                                type="text"
                                disabled
                                placeholder="Data la care ai primit grantul"
                            ><span
                                class="buton-info-date"
                                style="cursor: pointer"
                                @click="showInfoPopup2"
                            ><font-awesome-icon icon="info-circle" :style="{ color: '#f18989' }">
                            </font-awesome-icon></span>
                        </div>
                        <input class="output-text" type="date" >
                    </form>
                    <p class="mt-5">
                        Data semnării contractului este data la care contractul este semnat de către
                        toate părtile semnatare. Astfel vă rugăm să menționați în câmpul
                        <span class="title-text"> Dată semnare contract.</span> ultima dată din
                        semnătura digitală a AIMMAIPE desemnat cu gestionarea contractului.
                    </p>

                    <p class="mt-4">
                        Data la care ai primit grantul este data la care au fost recepționate sumele
                        de la AIMMAIPE în contul de grant.
                    </p>
                </div>
            </div>
            <div class="d-flex flex-column treime">
                <div class="first-text">
                    <p class="text-center">
                        Data semnării contractului precum și data primirii grantului se regăsesc în
                        contul dumneavoastră din platforma de granturi (www.granturi.imm.gov.ro) în
                        <span class="title-text"> secțiunea de notificări.</span>
                    </p>
                </div>
                <div class="align-self-center mb-3">
                    <b-img
                        class="border-pic"
                        alt="Contracte logo"
                        :src="require('@/assets/images/contracte_pic.png')"
                    ></b-img>

                    <ButtonGradient
                        class="buton-continua"
                        url-buton="dadsdsss"
                        text="Continuă >"
                        classname="background"
                    ></ButtonGradient>
                </div>
            </div>
        </div>
        <div>
            <InfoPopup v-show="isInfoPopupVisible" @close="closeInfoPopup">
                <template v-slot:header> Unde poți găsi data semnarii contractului? </template>
                <template v-slot:body>
                    <b-img
                        alt="Contracte logo"
                        class="imagine-modal"
                        :src="require('@/assets/images/CASUTA_INFO.png')"
                    ></b-img>
                </template>
            </InfoPopup>
            <InfoPopup v-show="isInfoPopupVisible2" @close="closeInfoPopup2">
                <template v-slot:header> Unde poți găsi data primirii grantului? </template>
                <template v-slot:body>
                    <b-img
                        alt="Contracte logo"
                        class="imagine-modal"
                        :src="require('@/assets/images/contract_platit.png')"
                    ></b-img>
                </template>
            </InfoPopup>
        </div>
    </div>
</template>

<script>
    import InfoPopup from "@/common/components/InfoPopup";
    import { library } from "@fortawesome/fontawesome-svg-core";
    import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
    import ButtonGradient from "@/common/components/ButtonGradient";
    import {BImg} from 'bootstrap-vue'
    library.add(faInfoCircle);
    export default {
        name: "Test",
        components: {
            InfoPopup,
            ButtonGradient,
            FontAwesomeIcon,
            BImg,
        },
        data() {
            return {
                isInfoPopupVisible: false,
                isInfoPopupVisible2: false,
            };
        },
        methods: {
            showInfoPopup() {
                this.isInfoPopupVisible = true;
            },
            closeInfoPopup() {
                this.isInfoPopupVisible = false;
            },
            showInfoPopup2() {
                this.isInfoPopupVisible2 = true;
            },
            closeInfoPopup2() {
                this.isInfoPopupVisible2 = false;
            },
        },
    };
</script>

<style scoped>
	.box {
		align-items: center;
		color: rgb(0, 0, 0);
		border: 1px solid #e7e7e7;
	}
	.compania-mea-box {
		background-color: #fff;
		display: flex;
		width: 100%;
	}
	.treime {
		width: 33%;
		border-right: 2px solid #eeeeef;
	}
	.treime:last-child {
		width: 34%;
		border-right: 0px;
	}
	.box-title {
		background-color: #f8f8f8;
		color: #5744cf;
		box-sizing: border-box;
		padding: 20px 10px 20px 25px;
	}
	.box-content-date {
		background-color: #fff;
		display: flex;
		width: 100%;
		margin-bottom: 15px;
	}
	.box-content-date input {
		padding: 4px;
	}
	.first-text {
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		padding-top: 20px;
		color: #787878;
		padding: 20px 30px 30px;
	}
	input[type="text"] {
		background-color: #f8f8f8;
		color: #757575;
		font-weight: bold;
		font-size: 17px;
		border: 2px solid #edebef;
		border-radius: 5px;
		width: 100%;
	}
	input[type="text"]::placeholder {
		color: #757575;
	}
	label {
		cursor: pointer;
		display: flex;
		align-items: center;
		margin: 40px 15px 90px 15px;
	}
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + label::before {
		content: "";
		display: inline-block;
		border: 1px solid #dfd7da;
		border-radius: 50%;
		margin: 0 0.5em;
		width: 18px;
		height: 18px;
		min-width: 18px;
	}
	input[type="radio"]:checked + label::before {
		background-color: #2b01e8;
		box-shadow: 0px 4px 10px 5px #cec4fa;
	}
	.radio4 + label::before {
		width: 1.5em;
		height: 1.5em;
	}
	.buton-info-date {
		color: #8f8f8f;
		font-size: 17px;
		border: 2px solid #edebef;
		border-radius: 1px;
		background-color: #fff;
		font-weight: normal;
		padding: 2px 10px;
		margin: 0 15px 0 -3px;
	}
	.buton-info-date svg {
		margin-top: 5px;
	}
	.output-text {
		color: #8f8f8f;
		font-size: 15px;
		border: 2px solid #edebef;
		border-radius: 5px;
		padding: 0;
		max-width: 200px;
		width: 100%;
		text-align: center;
		padding-top: 3px;
	}
	.title-text {
		font-weight: bold;
	}
	.bold-blue {
		color: #314081;
		font-weight: bold;
	}
	.bold-gradient {
		background: -webkit-linear-gradient(#511cca, #178fc5);
		background-clip: unset;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: 900;
	}
	.gr-pic {
		width: 65px;
		max-height: 65px;
	}
	.border-pic {
		border: 2px solid #5219ca;
		padding: 3px 3px;
		margin: 10px 55px;
		max-height: 405px;
		max-width: 480px;
		border-radius: 5px;
	}
	.buton-continua {
		margin: 5px 55px;
		text-align: -webkit-right;
	}

	p {
		font-size: 14px;
		color: #787878;
	}
	.nr-rue {
		margin-right: 15px;
	}

	@media only screen and (max-width: 1360px) {
		.compania-mea-box {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(390px, auto));
			grid-gap: 20px;
		}
		.treime {
			width: 100%;
			border: 0;
		}
		.treime:last-child {
			width: 100%;
		}
		.imagine-modal {
			max-width: -webkit-fill-available;
			height: auto;
		}
	}
	@media only screen and (max-width: 1760px) {
		.box-content-date {
			display: flex;
			flex-direction: column;
		}
		.output-text {
			margin-top: 10px;
			max-width: 100%;
			box-sizing: border-box;
		}
		.buton-info-date {
			margin: 0 0 0 -3px;
		}
		.generaza-date-buton {
			margin-top: 10px;
		}
		.nr-rue {
			margin: 0;
		}
	}
	@media only screen and (max-width: 600px) {
		.compania-mea-box {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(290px, auto));
			grid-gap: 20px;
		}
		.buton-info-date,
		.output-text,
		p,
		label {
			font-size: 12px;
		}
		label {
			margin: 20px;
		}
		input[type="text"] {
			font-size: 13px;
		}
		.grant-radio-box {
			flex-direction: column;
			justify-content: space-between;
		}
		.border-pic {
			margin: 10px 20px;
			max-height: 200px;
			max-width: 200px;
		}
		.first-text {
			padding: 25px 45px 20px 10px;
		}
	}
</style>
