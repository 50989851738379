<template>
    <div class="burgerMenu">
        <button class="burgerButton" @click="toggleMenu()"><i :class="menuClosed ?'fas fa-bars':'fas fa-times'"></i></button>
        <sidebar-menu width="500px" :menu="meniuFront" :hide-toggle="hideToggle" :class="menuClosed ? 'hide':''" :collapsed="menuClosed" theme="white-theme" ></sidebar-menu>

    </div>
</template>

<script>  

    import { SidebarMenu } from 'vue-sidebar-menu'
    export default {
        components: { 
            SidebarMenu,
           
        },
        props: {
            leftMenuOpened:Boolean
        },
        data() {
            return {
                menuClosed:true,
                topNavClicked: false,          
                hideToggle:true,
                meniuFront: [
                    {
                        title: "Știri",
                        href: "stiri",
                    },
                    {
                        title: "Harta finanțărilor",
                        href: "harta-finantarilor",
                    },
                    {
                        title: "Toate Finanțările",
                        
                        child: [
                            {
                                title: "Alocare 2014-2020",
                                
                                notification: 10,
                                child: [
                                    {
                                        title: "Prezentare program",
                                        href: "prezentare-program",
                                    },
                                    {
                                        title: "PO Capital Uman (POCU)",
                                        href: "pocu",
                                    },
                                    {
                                        title: "PO Competitivitate",
                                        href: "competitivitate",
                                    },
                                    {
                                        title: "Programul National Dezvoltare Rurala (PNDR)",
                                        href: "pndr",
                                    },
                                    {
                                        title: "PO Capacitate Administrativa (POCA)",
                                        href: "poca",
                                    },
                                    {
                                        title: "PO Infrastructură Mare (POIM)",
                                        href: "poim",
                                    },
                                    {
                                        title: "PO Regional (POR - Regio)",
                                        href: "porr",
                                    },
                                    {
                                        title: "Programul Operațional (POAD)",
                                        href: "poad",
                                    },
                                    {
                                        title: "Programul Operațional Asistență Tehnică (POAT)",
                                        href: "poat",
                                    },
                                ],
                            },
                            {
                                title: "ALOCARE 2021 - 2027",
                               
                                child: [
                                    {
                                        title: "Programul Operaţional Tranziție Justă (POTJ)",
                                        href: "POTJ",
                                    },
                                    {
                                        title: "Programul Operațional Dezvoltare Durabilă (PODD)",
                                        href: "PODD",
                                    },
                                    {
                                        title: "Programul Operațional Transport (POT)",
                                        href: "POT",
                                    },
                                    {
                                        title: "Programului Operațional  (POCIDIF)",
                                        href: "POCIDIF",
                                    },
                                    {
                                        title: "Programul Operațional Sănătate (POS)",
                                        href: "#",
                                    },
                                    {
                                        title: "Programul Operațional Educație și Ocupare (POEO)",
                                        href: "#",
                                    },
                                    {
                                        title: "Programul Operaţional Incl. și Demnitate Socială (POIDS)",
                                        href: "#",
                                    },
                                    {
                                        title: "Programe Operaţionale Regionale",
                                        href: "#",
                                    },
                                ],
                            },
                            {
                                title: "PNRR  2021 - 2026",
                               
                                notification: 16,
                                child: [
                                    {
                                        title: "Tranziția verde",
                                        href: "#",
                                    },
                                    {
                                        title: "Transformarea Digitală",
                                        href: "#",
                                    },
                                    {
                                        title: "Creștere inteligentă, durabilă ",
                                        href: "#",
                                    },
                                    {
                                        title: "Coeziune socială si și teritorială",
                                        href: "#",
                                    },
                                    {
                                        title: "Sanătare și reziliență economică, ",
                                        href: "#",
                                    },
                                    {
                                        title: "Politici pentru generația următoare,",
                                        href: "#",
                                    },
                                ],
                            },
                            {
                                title: "FINANȚĂRI NAȚIONALE",
                                
                                child: [
                                    {
                                        title: "Granturi de sprijin pentru microîntreprinderi",
                                        href: "#",
                                    },
                                    {
                                        title: "Măsura 2 - Capital de lucru",
                                        href: "#",
                                    },
                                    {
                                        title: "INNOTECH",
                                        href: "#",
                                    },
                                    {
                                        title: "Digitalizare",
                                        href: "#",
                                    },
                                    {
                                        title: "HORECA 2021",
                                        href: "#",
                                    },
                                ],
                            },
                            {
                                title: "ALTE FINANȚĂRI",
                                
                                child: [
                                    {
                                        title: "Granturile SEE și Norvegiene",
                                        href: "#",
                                    },
                                    {
                                        title: "Erasmus +",
                                        href: "#",
                                    },
                                    {
                                        title: "IMM INVEST",
                                        href: "#",
                                    },
                                    {
                                        title: "AgroINVEST",
                                        href: "#",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        title: "Harta consultanților",
                        href: "harta-consultantilor",
                    },
                    {
                        title: "Task manager",
                        href: "task-manager",
                    },
                    {
                        title: "Notificările mele",
                        href: "notificarile-mele",
                    },
                    {
                        title: "SMIS Flowchart",
                        href: "smis-flowchart",
                        tooltip: "Nou",
                    },
                    {
                        title: "Register",
                        href: "register",
                        tooltip: "Nou",
                    },
                ],
                smallLaptop: false
            };
        },
        watch : {
            leftMenuOpened:function(){
                if(this.leftMenuOpened){
                    this.closeTopMenu()
                }    
    
                           
            }
        },            
        methods: {
            toggleMenu(){
                
                this.menuClosed = !this.menuClosed;
                
                this.isClickedTopNav();
            },
            showChild(index){
                const child= this.$el.querySelector('.children'+ index);
                if(child.classList.contains('hide')){
                    child.classList.remove('hide');
                }else{
                    child.classList.add('hide');
                }
            },
            showGrandchild(index){
                const child= this.$el.querySelector('.grandchildren'+ index);
                if(child.classList.contains('hide')){
                    child.classList.remove('hide');
                }else{
                    child.classList.add('hide');
                }
            },
                     
            isClickedTopNav() {
                this.topNavClicked=true
                this.$emit('isClickedTopNav',this.topNavClicked)
            }, 
            closeTopMenu(){
                this.menuClosed=true ;
            }        
        },
    };
</script>

<style scoped>
.hide{
    display: none;
}


    .v-sidebar-menu {
        position: absolute;
        top: 100%;
        left: 0;
        width:100vw ;
        height: 100vh;
        bottom: auto;
        width: 500px !important;

    }
    .burgerMenu button{
        height: 100%;
        background-color: white;
        border: none;
        padding-right: 1rem;
        text-align: center;
        color:#212121
    }
    .burgerMenu button:focus{
        outline:none;
    }
    .burgerMenu button i{
         font-size: 2rem;
         padding-top: 0.5rem;
         padding-right:0.5rem;
         font-weight: 700;
    }

</style>
