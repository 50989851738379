import { render, staticRenderFns } from "./ContentBox2.vue?vue&type=template&id=e64c1acc&scoped=true&"
import script from "./ContentBox2.vue?vue&type=script&lang=js&"
export * from "./ContentBox2.vue?vue&type=script&lang=js&"
import style0 from "./ContentBox2.vue?vue&type=style&index=0&id=e64c1acc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e64c1acc",
  null
  
)

export default component.exports