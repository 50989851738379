<template>
    <div>
        <span class="time-left">Mai ai la dispoziție: <span class="blue">28 zile</span></span>
        <p class="time-info">Pentru a cheltui banii și a transmite documentele de implementare în raportul de progres din aplicația granturi.imm.gov.ro, Conform Procedurii de Implementare, art 6. alin 6.8 (3)*</p>
        <div class="col-md-12 main-col">
            <div class="row">
                <div class="col-md-3 col-xs-12 sec-col">
                    <p>Data începerii proiectului</p>
                    <p class="text-bold">16.12.2020</p>
                </div>
                <div class="col-md-3 col-xs-12 sec-col">
                    <p>Data curentă</p>
                    <p class="text-bold">16.12.2021</p>
                </div>
                <div class="col-md-3 col-xs-12 sec-col">
                    <p>Data finalizării proiectului</p>
                    <p class="text-bold">14.06.2021</p>
                </div>
                <div class="col-md-3 col-xs-12 sec-col flex">
                    <span class="procent">{{ parseInt(24*100/35) }}%</span>
                    <apexchart type="pie" width="90" :options="chartOptions" :series="series"></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
export default {
    components: {},
    props:{
        data: null
    },
    data() {
        return {
            series: [24, 35],
            chartOptions: {
                colors: ['#f1f2f3', '#0c6ac2'],
                plotOptions: {
                    pie: {
                        expandOnClick: false
                    },
                },
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false
                },
                states: {
                    normal: {
                        filter: {
                            type: 'none',
                        }
                    },
                    hover: {
                        filter: {
                            type: 'none',
                        }
                    },
                    active: {
                        filter: {
                            type: 'none',
                        }
                    },
                },
                tooltip: {
                    enabled: false,
                },
            }
        }
    },
}
</script>

<style scoped>
.procent{
    font-size: 35px;
    color: #f1f1f3;
    font-weight: 700;
    line-height: 50px;
}
.flex{
    display: flex;
    align-items: center;
}
.time-left{
    font-size: 14px;
}
.text-bold{
    font-weight: 600;
    font-size: 14px;
}
.blue{
    font-weight: 700;
    color: #314081;
}
.time-info{
    max-width: 65%;
}
.main-col{
    border-top: 1px solid #e8e8e8;
    margin-top: 10px;
}
.sec-col{
    margin: 10px 0;
}
.sec-col p{
    text-align: center;
}
.sec-col:not(:last-child){
    border-right: 1px solid #e8e8e8;
}
.sec-col:last-child{
    border-left: 1px solid #e8e8e8;
}
@media (max-width:1100px){
    .time-info{
        max-width: 100%;
    }
    .sec-col{
        border-left: 0 !important;
        border-right: 0 !important;
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 10px;
        margin-top: 10px;
        margin-bottom: 0;
    }
    .sec-col:last-child{
        border-bottom: 0;
    }
    .main-col{
        border-top: 0 !important;
        margin-top: 0;
    }
    .flex{
        justify-content: center;
    }
}
</style>