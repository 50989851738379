import Vue from 'vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import router from '@/common/router'
import "./assets/css/globalStyle.css";

import App from './App.vue';

import store from '@/helpers/store'
import { notify } from '@/helpers/helpers';
import VueToast from 'vue-toast-notification';

Vue.prototype.$notify = notify;

Vue.use(VueToast);

Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
