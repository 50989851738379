<template>
    <div>
        <div class="cifra-afaceri">
            <p class="info-text">Cifra de afaceri {{cifra_afaceri[0].an}}</p>
            <p class="section-title m-0">{{Number(cifra_afaceri[0].suma).toLocaleString('ro-RO')}} lei</p>
        </div>
        <apexchart type="area" height="210" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        cifra_afaceri: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            loaded: false,
            series: [{
                name: "Cifra afaceri",
                data: this.cifra_afaceri.map(function(item) {
                    return Number(item.suma)
                })
            }],
            chartOptions: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                    height: 360,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    fontFamily: 'Montserrat'
                },
                colors: ['#9ee7dd'],
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: 'smooth',
                    colors:['#9ee7dd'],
                },
                grid: {
                    row: {
                        colors: ['transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                yaxis: {
                    show: false,
                },
                xaxis: {
                    categories: this.cifra_afaceri.map(function(item) {
                        return item.an
                    }),
                },
            },
        }
    },
}
</script>

<style scoped>
.info-text{
    color: #878588;
    text-align: left;
    font-size: 13px;
}
.cifra-afaceri p {
    color: #878588;
}

.section-title {
    font-size: 14px;
    color: #878588;
    font-weight: 600;
}
</style>