<template>
    <div>
        <BlueGreenTopBar></BlueGreenTopBar>
        <div class="apps-content">
            <SidebarApps :apps="apps" :selected="selectedId" class="sidebar" @planChanged="changeSelectedPlan($event)"></SidebarApps>
            <div class="app-page-content" v-show="!loading">
                <div class="costuri">
                    <Costuri></Costuri>
                </div>
                <div class="abonament white-box">
                    <AbonamentAplicatie :plan="plan"></AbonamentAplicatie>
                </div>
                <div class="achizitie white-box">
                    <Achizitie></Achizitie>
                </div>
                <div class="optiuni-incercare gray-box">
                    <span class="align-right">Opțiuni de încercare</span>
                    <p class="align-right">Anumite aplicații dezvoltate au opțiuni gratuite sau opțiuni de
                        încercare. Acestea vor fi prezentate mai jos</p>
                </div>
                <div class="free-trial d-flex justify-content-between">
                    <div class="white-box free padding padding-left">
                        <div class="free-info flex-column align-center">
                            <span class="label green">free</span>
                            <img src="@/assets/images/horeca-logo.png" class="image-app-small mb-3">
                            <span class="font-weight-bold free-title">Alege pachet gratuit</span>
                            <p>(funcții limitate)</p>
                        </div>
                    </div>
                    <div class="white-box trial padding padding-right">
                        <div class="free-info flex-column align-center">
                            <span class="label orange-gradient">free</span>
                            <img src="@/assets/images/horeca-logo.png" class="image-app-small mb-3">
                            <span class="font-weight-bold free-title">Alege pachet gratuit</span>
                            <p>(funcții limitate)</p>
                        </div>
                    </div>
                </div>
                <div class="feedback gray-box">
                    <span class="align-right">Feedback utilizatori</span>
                    <p class="align-right">Nu ești încă decis? Mai jos regăsești feedback de la utilizatori
                        Smis care au implementat utilizand HORECAmanager.</p>
                </div>
                <div class="feedback-content white-box">
                    <FeedbackAplicatie :selected="selectedId" class="padding"></FeedbackAplicatie>
                </div>
                <div class="descriere white-box">
                    <DescriereAplicatie :plan="plan"></DescriereAplicatie>
                </div>
                <div class="formular-feedback white-box">
                    <FormularFeedback :selected="selectedId" class="padding"></FormularFeedback>
                </div>
                <div class="functii-extinse white-box padding">
                    <span class="align-right">Vrei să încerci funcțiile extinse?</span>
                    <p class="align-right flex-column">Poți opta pentru varianta trial - 10 zile<br>
                       Click aici pentru a incepe perioada trial</p>
                </div>
                <div class="multumit white-box padding d-flex align-items-end flex-column">
                    <span class="align-right">Mulțumit?</span>
                    <p class="align-right flex-column">Alege varianta cu licență. Mulțumim!</p>
                    <b-button class="gray-button mt-4 align-self-center">Achiziționează licență</b-button>
                </div>
            </div>
            <LoadingSpinner v-show="loading"></LoadingSpinner>
        </div>

    </div>
</template>

<script>
import LoadingSpinner from '@/common/components/LoadingSpinner'
import BlueGreenTopBar from './components/BlueGreenTopBar'
import SidebarApps from './components/SidebarApps'
import DescriereAplicatie from './components/DescriereAplicatie'
import Costuri from './components/Costuri'
import AbonamentAplicatie from './components/AbonamentAplicatie'
import Achizitie from './components/Achizitie'
import FeedbackAplicatie from './components/FeedbackAplicatie'
import FormularFeedback from './components/FormularFeedback'
import {BButton} from 'bootstrap-vue'
import axios from '@/helpers/axios'
import api from '@/helpers/api'

export default {
    components: {
        LoadingSpinner,
        BlueGreenTopBar,
        SidebarApps,
        DescriereAplicatie,
        Costuri,
        AbonamentAplicatie,
        Achizitie,
        FeedbackAplicatie,
        FormularFeedback,
        BButton,
    },

    props: {},

    data() {
        return {
            apps: [],
            plan: {
                rating: {
                    avg_rating: 0
                },
                invoice_interval: []
            },
            selectedId: 0,
            loading: true,
        };
    },

    created() {
        this.fetchApps();
    },

    methods: {
        fetchApps() {
            return axios.get(api.API_ENDPOINTS.fetchApps)
                .then((response) => {
                    this.apps = response.data;

                    if(this.apps.length > 0) {
                        this.selectedId = response.data[0].id
                    }
                }).catch((xhr) => {
                    this.$notify(xhr.responseJSON.message);
                });
        },

        fetchSingleApp() {
            return axios.get(api.API_ENDPOINTS.fetchApps + '/' + this.selectedId)
                .then((response) => {
                    this.plan = response.data
                    console.log('plan', this.plan)
                }).catch((xhr) => {
                    this.$notify(xhr.responseJSON.message);
                }).finally(() => this.loading = false)
        },

        changeSelectedPlan(id) {
            this.loading = true;
            this.selectedId = id;
            console.log(id)
        }
    },

    watch: {
        'selectedId': function () {
            this.fetchSingleApp();
        },
    }
};
</script>

<style scoped>
.apps-content{
    display: flex;
    height: calc(100vh - 160px);
    justify-content: space-between;
}
.sidebar{
    width: 14%;
}
.app-page-content {
    padding-top: 10px;
    padding-right: 10px;
    width: 85%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px 10px;
    grid-auto-flow: row;
    grid-template-areas:
    "descriere descriere descriere costuri costuri costuri achizitie achizitie achizitie"
    "descriere descriere descriere abonament abonament abonament achizitie achizitie achizitie"
    "descriere descriere descriere optiuni-incercare optiuni-incercare optiuni-incercare functii-extinse functii-extinse functii-extinse"
    "descriere descriere descriere free-trial free-trial free-trial multumit multumit multumit"
    "descriere descriere descriere feedback feedback feedback formular-feedback formular-feedback formular-feedback"
    "descriere descriere descriere feedback-content feedback-content feedback-content formular-feedback formular-feedback formular-feedback"
    "descriere descriere descriere feedback-content feedback-content feedback-content formular-feedback formular-feedback formular-feedback";
}
.costuri { grid-area: costuri; }
.abonament { grid-area: abonament; }
.achizitie { grid-area: achizitie; }
.optiuni-incercare { grid-area: optiuni-incercare; }
.free-trial { grid-area: free-trial; }
.feedback { grid-area: feedback; }
.feedback-content { grid-area: feedback-content; }
.descriere { grid-area: descriere; }
.formular-feedback { grid-area: formular-feedback; }
.functii-extinse { grid-area: functii-extinse; }
.multumit { grid-area: multumit; }
.white-box{
    background: #fff;
}
.free, .trial{
    width: 48%;
}
.padding{
    padding: 10px;
}
.align-right{
    display: flex;
    justify-content: end;
    text-align: right;
}
.gray-box{
    background: #707070;
    padding: 10px;
}
.gray-box span{
    font-size: 18px;
    color: #fff;
    font-weight: 600;
}
.white-box span{
    font-size: 18px;
    color: #707070;
    font-weight: 600;
}
.white-box p{
    color: #707070;
    font-size: 12px;
}
.gray-box p{
    color: #fff;
    font-size: 12px;
}
.label{
    padding: 1px 7px;
    color: #fff !important;
    font-weight: 600;
    border-radius: 3px;
    font-size: 11px !important;
    align-self: flex-start;
    margin-left: 10px;
    margin-bottom: 5px;
}
.green{
    background: #0cc2af;
}
.orange-gradient{
    background: -webkit-linear-gradient(#ff5748, #ffad82);
}
.image-app-small{
    max-width: 150px;
    object-fit: cover;
    filter: grayscale(100%);
}
.free-title{
    font-size: 15px !important;
    color: #707070;
}
.free-info p{
    font-size: 12px;
    color: #707070;
    text-align: center;
}
.gray-button{
    padding: 5px 10px;
    background: #707070;
    color: #fff;
    border-radius: 0px;
    font-size: 12px;
    margin-top: 10px;
}
@media (max-width: 900px) {
    .apps-content{
        display: block;
    }
    .sidebar, .app-page-content{
        width: 100%;
    }
}
@media (max-width: 1300px) {
    .image-app-small{
        width: 100%;
    }
}
@media (max-width: 1150px) {
    .app-page-content{
        display: block;
    }
    .costuri, .abonament, .achizitie, .optiuni-incercare, .free-trial, .feedback, .feedback-content, .descriere, .formular-feedback, .functii-extinse, .multumit {
        margin-bottom: 10px;
    }
    .apps-content{
        height: 100%;
    }
}
.padding-left{
    padding-left: 30px;
}
.padding-right{
    padding-right: 30px;
}
</style>