<template>
    <div>
        <div class="background">
            <div>
                <img alt="SMIS Logo" class="logo-smis" src="@/assets/images/LogoSmisLogin.png" >
            </div>
            <div class="poza-login">
                <img alt="Poza Mare" src="@/assets/images/PozaLogin.png" >
            </div>
            <div class="bottom-text">
                <div class="div-ue">
                    <img class="logo-ue" alt="Logo UE" src="@/assets/images/LogoUELogin.png" >
                    <p class="text-ue">
                        Proiect cofinanțat din Fondul Social European prin Programul Operațional
                        Capital Uman
                    </p>
                    <p class="text-mare">Sistem inovativ</p>
                    <p class="opacity-text">
                        pentru monitorizarea și managementul proiectelor finanțate din fonduri
                        nerambursabile
                    </p>
                </div>
                <div class="right-side">
                    <p class="right-side-text" style="font-size: 14px">
                        STRUCTURAL MANAGEMENT INNOVATIVE SYSTEM
                    </p>
                    <p class="right-side-text">
                        CUI: 43743807 / Registrul comerțului: J15/217/2021
                    </p>
                    <p class="right-side-text">
                        office@smis.ro / (+4) 031.402.50.10 / DB 137121 România
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Test",
        props: {},
        data() {
            return {};
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
	.background {
		background-image: linear-gradient(to bottom right, #1f1f20, #5f656e);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 3.1rem;
		box-sizing: border-box;
		height: 100vh;
	}
	.poza-login img {
		float: right;
		margin-right: 12.5rem;
		max-height: 41vh;
	}
	.text-ue {
		color: #f6f6f6;
		margin-top: 0.6rem;
		font-family: "Montserrat SemiBold", sans-serif;
		font-size: 0.75rem;
	}
	.text-mare {
		color: #f6f6f6;
		margin-top: 3.75rem;
		font-size: 3.5rem;
		font-weight: bold;
	}
	.opacity-text {
		margin-top:-0.6rem;
		font-family: "Montserrat Light", sans-serif;
		font-size: 1.5rem;
		color: #9e9e9e;
		text-align: initial;
		max-width: 650px;
		overflow: hidden;
	}
	.bottom-text {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.right-side {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
	}
	.right-side-text {
		color: #8f9192;
		font-size: 0.75rem;
		text-align: center;
	}
	@media (max-width:1023px) and (min-width:768px){
        .bottom-text{
			position: relative;
			bottom:25%;
			right: 7%;
			}
			}
    @media (max-width: 1300px){
        .poza-login img{
            width: 100%;
            object-fit: contain;
            margin: 0;
        }
        .bottom-text{
            display: block;
        }
        .text-mare{
            font-size: 2.5rem;
        }
        .opacity-text{
            font-size: 1rem;
        }
        .right-side{
            margin-top: 15px;
            align-items: flex-start;
        }
        .right-side p{
            font-size: 12px !important;
        }
        .register .bottom-text{
            display: none;
        }
        .register .poza-login img{
            position: absolute;
            top: 16%;
            left: 0;
            padding: 0 50px;
            width: 100%;
        }
    }
    @media (max-width:800px){
        .text-mare, .opacity-text, .right-side{
            display: none;
        }
        .poza-login img{
            position: absolute;
            top: 16%;
            left: 0;
            padding: 0 50px;
            width: 100%;
            max-height: 100%;
        }
        .bottom-text{
            display: none;
        }
        .background{
            position: relative;
        }
    }
	@media (max-width:500px) {
		.logo-ue{
			width:100%;
		}
		.text-mare{
			text-align: center;
			font-size: 3rem;
			
		}
		.opacity-text{
			margin-top: 0.1rem;
			font-size: 1.3rem;
			text-align: center;
		}
		
		.right-side	{
			padding-top: 0.6rem;
		}	
		.right-side-text{
		
			text-align: center;
		}
        .register .logo-smis{
            position: absolute;
            top: 2%;
        }
        .register .poza-login img{
            top: 30%;
        }
	}		
	@media (min-width:1024px) and (max-width:1440px) {
		.bottom-text{
			display: flex;
			position: relative;
			bottom: 23%;
			right: 3%;
		}

	}	
</style>
