<template>
    <div>
        <div class="companie-head">
            <div class="info-left">
                <div class="title">
                    <h1>{{ consultant.name }}</h1>
                    <p class="text-uppercase">{{ consultant.city }}</p>
                </div>
                <div class="rating">
                    <b-form-rating id="rating-lg-no-border " :value="consultant.rating.avg_rating" color="#ffbe00" no-border size="sm"
                                   readonly></b-form-rating>
                    <p>({{ consultant.rating.count }} păreri / {{ parseFloat(consultant.rating.avg_rating).toFixed(2) }})</p>
                </div>
                <div class="info">
                    <p v-if="data && consultant.adress">{{ consultant.adress }}</p>
                    <p class="mt-3">Telefon: {{ consultant.phone }}</p>
                    <p>Email: {{ consultant.email }}</p>
                    <p v-if="data && consultant.website" class="mt-3">{{ consultant.website }}</p>
                </div>
            </div>
            <div class="logo-right">
                <img v-if="consultant.logo" :src="consultant.logo" alt="">
                <img v-else :src="avatar" class="user-avatar">
            </div>
        </div>
    </div>
</template>

<script>
import {BFormRating} from 'bootstrap-vue'

export default {
    name: "PrezentareHead",
    components: {
        BFormRating
    },
    props: {
        data: null,
        consultant: null,
    },
    data() {
        return {
            isOpen: false,
            review: {
                stars: 4.5,
            },
            avatar: null,
        };
    },
    mounted() {
        this.getPozaCont()
    },
    methods: {
        getPozaCont() {
            this.avatar = this.$store.state.auth.user.logo
        },
    }
}
</script>

<style scoped>
.companie-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f6f6f6;
    padding: 30px 40px;
    border-bottom: 1px solid #d9d9d9;
}
.user-avatar{
    max-height: 120px;
    border-radius: 50%;
}
.info-left h1 {
    font-size: 16px;
    color: #3d3d3d;
    font-weight: 700;
    margin: 0;
    line-height: 23px;
}

.info-left p {
    font-size: 12px;
}

.rating .form-control {
    background-color: transparent !important;
    max-width: 115px;
}

.form-control-sm {
    padding: 0;
}

.rating {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.rating p {
    margin-left: 10px;
    width: 100%;
}
.details img{
    max-height: 100px;
}
@media only screen and (max-width: 900px) {
    .companie-head {
        display: block;
    }

    .companie-head p {
        text-align: left;
    }

    .box-padding {
        padding: 20px 20px 0px 20px;
    }

    .content-box {
        width: 100%;
    }
}
</style>