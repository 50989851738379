<template>
    <div class="top-bar">
        <div class="top-bar-text">
            <span v-if="title" class="text">{{ title }}</span>
            <span v-if="companie.name" class="space-text">|</span>
            <span v-if="companie.name"> {{ companie.name }}</span>
            <span v-if="cont" class="cont">{{ cont }}</span>
        </div>
        <div class="flex">
            <b-form>
                <v-select
                    v-model="companie"
                    dir="ltr"
                    :options="getCompaniesData()"
                    label="name"
                    :reduce="(option) => option"
                    input-id="tags"
                    class="selecteaza-companie"
                    @input="selectCompanie()"
                    :clearable="false"
                ></v-select>
            </b-form>
            <ButtonGradient url-buton="dadsdsss" text="Adaugă companie" classname="orange" class="buton-select"></ButtonGradient>
        </div>
    </div>
</template>

<script>
import api from '@/helpers/api'
import axios from '@/helpers/axios'
import {BForm} from 'bootstrap-vue'
import ButtonGradient from './ButtonGradient'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    components: {
        ButtonGradient,
        BForm,
        vSelect,
    },
    props: {
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        cont: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            companies: [],
            companie: {
                name: null,
                id: null,
            },
        };
    },
    mounted() {
        console.log(this.companies.length);
        if(this.companies <= 0){
            this.getCompanies()
        } else{
            console.log('selectat')
        }
    },
    methods: {
        getCompanies() {
            console.log('getCompanies');
            axios.get(api.API_ENDPOINTS.companies)
                .then(response => {
                    this.companies = response.data
                }).finally(() => {
                    this.companie.name = this.companies[0].name
                    this.companie.id = this.companies[0].id
                })

        },
        getCompaniesData() {
            return this.companies.map(function (companie) {
                return {
                    'name': companie.name,
                    'id': companie.id,
                }
            })
        },
        selectCompanie() {
            console.log(this.companie);
        }
    },
};
</script>

<style scoped>
.selecteaza-companie{
    width: 250px;
}
.flex{
    display: flex;
    align-items: center;
}
.top-bar {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    min-height: 55px;
    background: linear-gradient(to bottom, #583fcf, #4e67d2);
    color: #fff;
    flex-direction: row;
    justify-content: space-between;
}
.text {
    font-weight: bold;
    width: 30%;
    height: 100%;
}
.top-bar-text span {
    font-size: 14px;
}
.cont {
    color: #dcb458;
}
.selecteaza-companie >>> .vs__dropdown-toggle{
    border-radius: 0.25rem !important;
    border: 1px solid white;
    max-height: 28px;
    margin: 0 !important;
    margin-right: 10px !important;
    background: transparent;
}
.selecteaza-companie >>> svg.vs__open-indicator{
    fill: white;
}
.selecteaza-companie >>> button.vs__clear{
    fill: white;
    transform: scale(0.8);
}
.selecteaza-companie >>> .vs__open-indicator{
    transform: scale(0.7);
}
.selecteaza-companie >>> .vs__close-indicator{
    transform: scale(0.7);
}
.selecteaza-companie >>> .vs--open .vs__open-indicator{
    transform: rotate(180deg) scale(0.7);
}
.selecteaza-companie >>> .vs__actions{
    padding: 0;
}
.selecteaza-companie >>> .vs__selected{
    font-size: 14px;
    color: #fff;
    margin: 0;
    padding: 0;
    line-height: 14px;
    display: block;
}
@media (max-width:1100px){
    .top-bar{
        display: block;
    }
    .flex{
        display: block;
        margin-top: 10px;
    }
    .buton-select{
        margin-top: 10px;
    }

}
</style>