<template>
    <div>
        <div class="pasi">
            <div class="pas">
                <div class="bullet bullet-active">1</div><span>Confirmarea serviciilor</span>
            </div>
            <div class="pas">
                <div class="bullet">2</div><span>Date de facturare</span>
            </div>
            <div class="pas">
                <div class="bullet">3</div><span>Modalitatea de plată</span>
            </div>
            <div class="pas">
                <div class="bullet">4</div><span>Confirmarea plății</span>
            </div>
        </div>
        <div class="alegere">
            <div class="flex justify-content-center align-items-center">
                <span class="span-title">Ai ales</span><img :src="checkout.logo">
            </div>
            <div class="divider">
                <img src="@/assets/images/arrow-divider.png">
            </div>
            <div :class="{'flex': true, 'justify-content-between': isSubscription, 'justify-content-center': isLifetime}">
                <div v-if="isSubscription" class="anual-price">
                    <p class="span-title">Vei achita <strong>anual</strong></p>
                    <p class="price-info" v-if="invoiceType === 'month'"><span class="price"><sup>€</sup>{{ checkout.price * 12 }}</span> <span v-if="checkout.vat_enabled">+ TVA</span> / an</p>
                    <p class="price-info" v-else><span class="price"><sup>€</sup>{{ checkout.total_yearly_price }}</span> <span v-if="checkout.vat_enabled">+ TVA</span> / an</p>
                    <p class="yellow-text price-details" v-if="invoiceType === 'month'">(cu plată lunară)</p>
                    <p class="yellow-text price-details" v-else>(cu plată anuală)</p>
                </div>

                <div class="main-price" v-if="isSubscription && invoiceType === 'month'">
                    <p class="span-title">Abonament <strong>lunar</strong></p>
                    <p class="price-info"><span class="price"><sup>€</sup>{{ getSubTotal }}</span> <span v-if="checkout.vat_enabled">+ TVA</span></p>
                    <p class="yellow-text price-details"><strong>(vei achita prima lună. apoi {{ getSubTotal }}€/lună - facturare lunară automată)</strong></p>
                    <p class="yellow-text price-details">poți renunța oricând la abonament, din contul tău - secțiunea serviciile mele</p>
                </div>

                <div class="main-price" v-else-if="isSubscription">
                    <p class="span-title">Abonament <strong>anual</strong></p>
                    <p class="price-info"><span class="price"><sup>€</sup>{{ getSubTotal }}</span> <span v-if="checkout.vat_enabled">+ TVA</span></p>
                    <p class="yellow-text price-details">(cu plată unică) Economisești <span class="green-text">€{{ checkout.yearly_discount}}</span> <span v-if="checkout.vat_enabled">+ TVA</span></p>
                </div>

                <div class="main-price" v-else>
                    <p class="span-title">Abonament <strong>lifetime</strong></p>
                    <p class="price-info"><span class="price"><sup>€</sup>{{ getSubTotal }}</span> <span v-if="checkout.vat_enabled">+ TVA</span></p>
                </div>

                <div class="change-price" v-if="isSubscription && invoiceType === 'month'">
                    <p class="span-title">Vreau să achit <strong>anual</strong></p>
                    <p class="price-info"><span class="price"><sup>€</sup>{{ checkout.yearly_price * 12 }}</span> <span v-if="checkout.vat_enabled">+ TVA</span> / an</p>
                    <p class="yellow-text price-details">(cu plată unică) Economisești <span class="green-text">€{{ checkout.yearly_discount}}</span> <span v-if="checkout.vat_enabled">+ TVA</span><br><span class="green-text pointer" @click="changeInvoiceType">Aleg această opțiune</span></p>
                </div>
                
                <div class="change-price" v-else-if="isSubscription">
                    <p class="span-title">Vreau să achit <strong>lunar</strong></p>
                    <p class="price-info"><span class="price"><sup>€</sup>{{ checkout.price }}</span> <span v-if="checkout.vat_enabled">+ TVA</span> / luna</p>
                    <p class="yellow-text price-details"><strong>(vei achita prima lună. apoi {{ checkout.price }}€/lună)</strong></p>
                    <p class="yellow-text price-details">poți renunța oricând la abonament, din contul tău - secțiunea serviciile mele</p>
                    <p class="yellow-text price-details"><span class="green-text pointer" @click="changeInvoiceType">Aleg această opțiune</span></p>
                </div>
            </div>

            <div v-if="checkout.require_company" class="divider">
                <img src="@/assets/images/arrow-divider.png">
            </div>

            <div v-if="checkout.require_company" class="alege-companie">
                <span><strong>Alege o companie pentru care achiziționezi această aplicație</strong></span>
                <span>Nu ai adăugat încă compania în contul tău? Poți face acest lucru <span class="yellow-text pointer" @click="addNewCompany()">aici</span></span>
                    <v-select
                        dir="ltr"
                        :options="checkout.companies"
                        label="name"
                        :reduce="(option) => option.id"
                        v-model="form.company_id"
                        input-id="company_id"
                        class="selecteaza-companie"
                        :clearable="false"
                        ref="selecteaza"
                        :class="inputError ? 'inputError' : ''"
                    ></v-select>
                    <span class="error-message" v-show="inputError">Selectează o companie.</span>
            </div>
            <div class="flex justify-content-center">
                <b-button class="next-step" @click="nextStep()"><strong>Pasul 2</strong> - Date de facturare</b-button>
            </div>
        </div>
        <ModalAdaugaCompanie :newCompanyModal="newCompanyModal" @hideTempModal="closeAddCompany()"></ModalAdaugaCompanie>
    </div>
</template>

<script>
import {BButton} from "bootstrap-vue";
import vSelect from 'vue-select'
import ModalAdaugaCompanie from './ModalAdaugaCompanie'
import api from '@/helpers/api'
import axios from '@/helpers/axios'

export default{
    components:{
        BButton,
        vSelect,
        ModalAdaugaCompanie,
    },

    props: ['data', 'invoiceType', 'checkout', 'newCompanyAdded', 'planId'],

    data() {
        return {
            form: {
                business_invoice: '',
                invoice_company_id: '',
                new_company: false,
                company_id: '',
                plan_id: '',
                plan_type: '',
                payment_method: '',
                customer_email: '',
                customer_phone: '',
                billing: {
                    first_name: '',
                    last_name: '',
                    address: '',
                    state: '',
                    city: '',
                },
                business_id: '',
                company_name: '',
                reg_no: '',
                bank_name: '',
                bank_account: '',
                contact_person: '',
                contact_person_job: '',
                coupons: [],
                customer_sold: 0,
            },
            newCompanyModal: false,
            tempCloseModal: false,
            inputError: false,
        }
    },

    async mounted() {
        this.form.coupons = this.checkout.coupons;
        axios.get(api.API_ENDPOINTS.checkout + '/' + this.planId + '/' + this.invoiceType)
            .then((response) => {
                this.checkout.companies = response.data.companies;
                let lastCompany = this.getLastCompanyId
                this.form.company_id = lastCompany.id
            })
    },
    methods: {
        changeInvoiceType() {
            this.$emit('invoiceTypeChanged');
        },
        addNewCompany() {
            this.newCompanyModal = true;
            this.tempCloseModal = true;
            this.$emit('tempCloseModal', this.tempCloseModal)

        },
        closeAddCompany() {
            this.newCompanyModal = false;
            this.tempCloseModal = false;
            this.$emit('tempCloseModal', this.tempCloseModal)
        },

        nextStep() {
            if(this.form.company_id !== '' || ! this.checkout.require_company) {
                this.$emit('stepActive', {'pageNumber': 2, 'form': this.form, 'submit': false})
            } else {
                this.inputError = true;
            }
        },
        // TODO: FINISH MODAL CHECKOUT
    },

    computed: {
        isLifetime() {
            return this.checkout.invoice_interval ? this.checkout.invoice_interval.includes('lifetime') : false;
        },

        isSubscription() {
            return ['year', 'month'].every(val => this.checkout.invoice_interval ? this.checkout.invoice_interval.includes(val) : false)
        },

        getSubTotal() {
            return this.invoiceType === 'year' ? this.checkout.total_yearly_price : this.checkout.price;
        },

        getLastCompanyId() {
            return this.checkout.require_company && this.checkout.companies ? this.checkout.companies.slice(-1)[0] : {id: null};
        }
    },

    watch: {
        'form.company_id': {
            handler: function(id) {
                this.form.invoice_company_id = id;
                this.form.business_invoice = '2';
            },
            immediate: true
        },
    }
}
</script>

<style scoped>
p, span, h1, h2, h3{
    color: #fff;
}
.pasi{
    display: flex;
    justify-content: space-between;
    padding-bottom: 70px;
}
.pas{
    display: flex;
    font-size: 14px;
    cursor: pointer;
}
.align-items-center{
    align-items: center;
}
.bullet{
    height: 20px;
    width: 20px;
    background: #fff;
    font-size: 14px;
    color: #343434;
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
}
.bullet-active{
    background: #ffcc00;
}
.flex{
    display: flex;
}
.span-title{
    font-size: 24px;
}
.divider{
    padding: 15px 0;
    text-align: center;
}
.yellow-text{
    color: #ffcc00;
}
.anual-price{
    opacity: 0.8;
}
.change-price{
    opacity: 0.8;
}
.change-price, .anual-price{
    width: 25%;
}
.change-price p, .change-price span{
    color: #fff;
    text-align: center;
}
.anual-price p, .main-price p, .anual-price span, .main-price span{
    text-align: center;
    color: #ffcc00;
}
.price-info{
    font-weight: 600;
}
.price{
    font-size: 28px;
}
.green-text{
    color: #00ffc0 !important;
    font-weight: 600;
}
.pointer{
    cursor: pointer;
}
.alege-companie{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.alege-companie span{
    font-size: 13px;
}
.selecteaza-companie{
    width: 50%;
    margin-top: 20px;
    background: transparent;
    border: 1px solid #ffcc00;
    border-radius: 2px;
}
.v-select >>> .vs__dropdown-toggle{
    margin-bottom: 0;
    background: transparent;
}
.v-select >>> .vs__selected, .v-select >>> .vs__open-indicator{
    color: #ffcc00;
    fill: #ffcc00;
}
.v-select >>> .vs__dropdown-menu{
    background: rgba(21, 20, 20, 0.85);
    max-height: 200px;
    overflow-y: auto;
}
.v-select >>> .vs__dropdown-option{
    color: #ffcc00;
}
.next-step{
    background: #ffcc00;
    color: #272727;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid #ffcc00;
    margin: 70px 0 0;
}
.alegere img{
    max-height: 50px;
    margin-left: 15px;
}
.inputError {
    border: 1px solid red;
}
.inputError >>> svg{
    fill: red !important;
}
.error-message{
    font-size: 12px;
    color: red;
    font-weight: 600;
    margin-top: 5px;
}
@media (max-width: 1000px){
    .flex{
        display: block;
    }
    .anual-price, .change-price, .main-price, .selecteaza-companie{
        width: 100%;
    }
    .alege-companie{
        text-align: center;
    }
    .alegere{
        text-align: center;
    }
    .main-price{
        margin: 30px 0;
    }
    .pasi{
        display: block;
    }
    .pas{
        justify-content: center;
        margin-bottom: 10px;
    }
}
</style>