<template>
    <div v-click-outside="closeMenu" class="border-right" @click="toggleMenu()">
        <div class="menu-item" >
            <div class="posRelative">
                <div class="spriteBg bell"></div>
                <span class="dot">
                    <span class="notific-nr">5</span>
                </span>
            </div>
            <transition name="fade" appear>
                <div v-if="isOpen" class="sub-menu">
                    <div v-for="(item, i) in notificari" :key="i" class="menu-item" >
                        <div class="list-group-item text-color flex" :class="item.active ? 'active':''">
                            <img src="@/assets/images/small-avatar.png" class="avatar-picture">
                            <a>
                                <span class="title-text">{{ item.title }}</span>
                                {{ item.message }} <br >
                                <span class="date-text">{{ item.time }} ago</span>
                            </a>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NotificationDropdown",
        directives: {
            'click-outside': {
                bind: function(el, binding, vNode) {
                   
                    // Provided expression must evaluate to a function.
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) { warn += `Found in component '${compName}'` }
          
                        console.warn(warn)
                    }
                    // Define Handler and cache it on the element
                    const bubble = binding.modifiers.bubble
                
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    }
                    
                    el.__vueClickOutside__ = handler

                    // add Event Listeners
                    document.addEventListener('click', handler)
                },
      
                unbind: function(el) {
                    // Remove Event Listeners
                    
                    document.removeEventListener('click', el.__vueClickOutside__)
                    el.__vueClickOutside__ = null

                }
            }
        },
        props: {
            shortcutsDropdownOpen:{
                type:Boolean,
                default:true
                
            },
            title: {
                type: String,
                default: null,
            },

        },

        data() {
            return {
                isOpen: false,
                notificari: [
                    {
                        title: "Sent a message",
                        message: "hello",
                        time: "1 hour",
                        active:true,
                    },
                    {
                        title: "message 2",
                        message: "hi",
                        time: "1 hour",
                        active:true,
                    },
                    {
                        title: "message 3",
                        message: "hei",
                        time: "1 hour",
                        active:false,
                    },
                ],
            };
        },
        watch:{
            shortcutsDropdownOpen(){
              
                if(this.shortcutsDropdownOpen){
                    this.closeMenu();
                }
            }
        },
        created() {},
        methods: {
            toggleMenu(){
                this.isOpen=!this.isOpen;
                if(this.isOpen)
                {this.$emit('notificationsDropdownOpen','1234');}
            },
            closeMenu(){
                this.isOpen=false;
            }
        },
    };
</script>

<style scoped>
.sub-menu {
  position: absolute;
  width: 280px;
  margin: auto;
  text-align: 0;
  top: 110%;
  z-index: 4;
}
.flex{
    display: flex;
    align-items: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  transform: translateY(310px);
  opacity: 0;
}
.avatar-picture{
    max-width: 40px;
    max-height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px;
}
.list-group-item {
  border-color: rgba(120, 130, 140, 0.065);
  padding: 10px 16px;
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: rgba(120, 130, 140, 0.13);
  font-size: 14px;
  margin-bottom: 5px;
  border-radius: 5px;
}
.text-color {
  color: rgba(0, 0, 0, 0.45);
  border-color: rgba(27, 30, 33, 0.13);
  background-clip: padding-box;
  box-shadow: 0 0 1px rgb(0 0 0 / 15%);
}
.title-text {
  color: #0cc2aa !important;
  font-weight: 600;
  font-size: 14px;
}
.date-text {
  color: inherit !important;
  opacity: 0.6;
  font-size: 80%;
}

.posRelative {
  position: relative;
}

.notific-nr {
  font-weight: bold;
  color: #fff;
  position: absolute;
  left: 7px;
  padding-top: 3px;
  font-size: 10px;
}

.dot {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #0cc2aa;
  bottom: 5px;
  left: 20px;
}
.spriteBg {
	background: url('../../../assets/images/leftNavIcons.png') no-repeat;
	margin: 25px 10px;
}
.bell {
	background-position: 0 -224px;
	width: 14px;
	height: 14px;
}
.border-right{
  border-right: 1px solid #f1f1f1;
  margin-right: 20px;
  padding-right: 20px;
  cursor: pointer;
}
.active{
    color:white;
    background-color:#2b2c3d ;
}
</style>
