<template>
    <div v-if="!loading" class="main-news-body">
        <StireBanner :data="banner"></StireBanner>
        <StireContent :data="stire" :labels="labels"></StireContent>

    </div>
    <LoadingSpinner v-else></LoadingSpinner>
</template>

<script>
import LoadingSpinner from '@/common/components/LoadingSpinner';
import StireBanner from './components/StireBanner';
import StireContent from './components/StireContent';
import api from '@/helpers/api';
import axios from '@/helpers/axios';
import _ from "lodash";

export default {
    components: {
        LoadingSpinner,
        StireBanner,
        StireContent,
    },
    props: {},
    data() {
        return {
            stire: [],
            labels: [],
            banner: null,
            loading: true,
        };
    },
    created() {
    },
    mounted() {
        this.getStireSingle()
        this.getLabels()
    },
    methods: {
        getStireSingle() {
            let id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
            axios.get(api.API_ENDPOINTS.news + '/' + id)
                .then(response => {
                    this.stire = {
                        description: response.data.description,
                    }
                    this.banner = {
                        bannerImage: response.data.banner,
                        title: response.data.title,
                        created: response.data.created,
                    }
                })
        },
        getLabels() {
            axios.get(api.API_ENDPOINTS.news)
                .then(response => {
                    this.labels = {
                        funders: _.compact(response.data.funders),
                        programs: _.reject(response.data.programs, ['acronym', null]),
                    }
                }).finally(() => this.loading = false)
        },
    },
};
</script>

<style scoped>
.main-news-body{
    padding-bottom: 80px;
}
@media (max-width: 1200px) {

}
</style>