<template>
    <div class="padding-bottom">
        <div>
            <UserTopBar :user-name="this.infoCont.name"
                        :role="this.infoCont.rol"
                        :email="this.infoCont.email"
                        :user-image="this.infoCont.avatar"
                        :usepackage="this.infoCont.account_type_name"
                        :phone="this.infoCont.phone"
                        :company="this.infoCont.company"
            ></UserTopBar>
        </div>
        <ContulMeuTabs></ContulMeuTabs>
        <div v-if="!loading" class="contul-meu-background">
            <div>
                <FinanciarModul class="mb-3" :data="financial"></FinanciarModul>
                <FinanciarFacturiSMIS :items="financial"></FinanciarFacturiSMIS>
                <div class="grid-informatii-vouchere mt-3">
                    <div class="w-100 info-box">
                        <div class="box-title">
                            <p>Vouchere de reduceri</p>
                        </div>
                        <div class="card cupon-list">
                            <div v-for="(cupon, key) in cupoane.coupons" :key="key" class="card-body d-flex card-info-flex align-items-center justify-content-between border-bottom cupon-body">
                                <div>
                                    <p class="text-bold text-left">{{ cupon.name }}</p>
                                </div>
                                <b-img
                                    class="cupon-logo"
                                    :src="cupon.logo"
                                ></b-img>
                            </div>
                        </div>
                    </div>
                    <div v-if="cupoane.sold > 0" class="w-100 info-box">
                        <div class="box-title">
                            <p>Sold de cheltuieli SMIS</p>
                        </div>
                        <div class="card">
                            <div class="card-body d-flex card-info-flex align-items-center">
                                <p class="mr-4">
                                    <span class="text-big">{{ cupoane.sold }}</span><span class="text-bold"
                                                                                          style="color: #bdd0d4"
                                    >lei</span>
                                </p>
                                <p class="text-sold">
                                    Poți utiliza acest sold pentru orice achiziție din platforma SMIS.ro<br>
                                    Alege discount-ul la emiterea facturii fiscale
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <FinanciarCarduri class="mt-3 mb-3"></FinanciarCarduri>
            </div>
        </div>
        <div v-else class="loading">
            <LoadingSpinner></LoadingSpinner>
        </div>
    </div>
</template>

<script>
    import LoadingSpinner from '@/common/components/LoadingSpinner'
    import {BImg} from 'bootstrap-vue'
    import FinanciarCarduri from "./FinanciarCarduri";
    import FinanciarFacturiSMIS from "./FinanciarFacturiSMIS";
    import FinanciarModul from "./FinanciarModul";
    import axios from '@/helpers/axios'
    import api from '@/helpers/api'
    import ContulMeuTabs from './ContulMeuTabs'
    import UserTopBar from './UserTopBar'

    export default {
        components: {
            LoadingSpinner,
            FinanciarCarduri,
            FinanciarFacturiSMIS,
            FinanciarModul,
            BImg,
            ContulMeuTabs,
            UserTopBar,
        },
        data() {
            return {
                loading: true,
                financial: [],
                infoCont: {
                    name: '',
                    rol: '',
                    email: '',
                    phone: '',
                    avatar: '',
                    account_type_name: '',
                    account_type_logo: '',
                    company: '',
                },
            };
        },
        mounted() {
            this.getAccountFinancial();
            this.getInfoCont()
            this.getCoupons()
        },
        methods: {
            getAccountFinancial() {
                return axios.get(api.API_ENDPOINTS.userFinancialSettings)
                    .then(response => {
                        this.financial = response.data;
                    })
            },
            getCoupons() {
                return axios.get(api.API_ENDPOINTS.cupoane)
                    .then(response => {
                        this.cupoane = response.data;
                    }).finally(() => {
                        this.loading = false
                    })
            },
            getInfoCont() {
                this.infoCont.name = this.$store.state.auth.user.name
                this.infoCont.rol = this.$store.state.auth.user.role
                this.infoCont.email = this.$store.state.auth.user.email
                this.infoCont.phone = this.$store.state.auth.user.phone
                this.infoCont.avatar = this.$store.state.auth.user.logo
                if(this.$store.state.auth.user.account_type){
                    this.infoCont.account_type_name = this.$store.state.auth.user.account_type.name
                    this.infoCont.account_type_logo = this.$store.state.auth.user.account_type.logo
                }
                this.infoCont.company = this.$store.state.auth.user.company
            }
        },
    };
</script>

<style scoped>
.cupon-list{
    max-height: 168px;
    min-height: 168px;
    overflow-y: auto;
    height: inherit !important;
}
.cupon-body{
    min-height: 56px;
}
.loading{
    height: 150px;
    width: 100%;
}
.padding-bottom {
    padding-bottom: 150px;
}
.contul-meu-background {
    background-color: #f0f0f0;
    width: 100%;
    padding: 20px;
    display: grid;
    gap: 30px 20px;
    grid-template-columns: 100%;
}
.grid-informatii-vouchere {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 20px;
}
.box-title {
    background-image: linear-gradient(to right, #b9dbd9, #c3c2cd);
    height: 60px;
    border-top: 1px solid #d7d8de;
    display: flex;
    align-items: center;
}
.box-title p {
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    padding-left: 30px;
}
p {
    font-size: 12px;
    color: #787878;
}
.text-bold {
    font-size: 14px;
    font-weight: bold;
}
.text-big {
    font-size: 38px;
    font-weight: bold;
    color: #bdd0d4;
}
.text-sold {
    color: #bababa;
}
.cupon-logo {
    max-width: 100%;
    max-height: 35px;
}
.card {
    box-shadow: 0px 15px 19px -13px #dbdbdb;
    height: 73%;
    border-radius: 0px;
}
@media only screen and (max-width: 560px) {
    .grid-informatii-vouchere {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }

    .card-info-flex {
        flex-direction: column;
        padding-bottom: 50px;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

    }

    .horeca-open {
        align-self: center;
        margin-top: 15px;
    }

    .info-box {
        margin-bottom: -10px;
    }
}

@media (max-width: 425px) {
    .contul-meu-background {
        grid-template-columns: 100% 1fr;
    }
}
</style>