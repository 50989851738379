<template>
    <div class="header-style">
        <div class="hartaConsultantiLogo">
            <img src="@/assets/images/Harta_Consultanti_logo.png" alt="">
        </div>
        <div class="header-component">
            <span class="number-component">62</span>
            <span class="text-component"><strong>Firme de consultanta</strong><br> si consultanti independenti</span>
        </div>
        <div class="header-component">
            <span class="number-component">34</span>
            <span class="text-component"><strong>Consultanti</strong><br> specializati</span>
        </div>
        <div class="header-component">
            <span class="number-component">8</span>
            <span class="text-component"><strong>Centre de formare profesionala</strong><br> management de proiect</span>
        </div>
        <div class="smis-logo-image"> 
            <img src="@/assets/images/LogoSmisMicLogin.png" alt="">
        </div>
        
    </div>
</template>
<script>
    export default {
        props:{

        },
        data() {
            return {
                
            }
        },
        methods: {
            
        },

    
    }
</script>
<style scoped>
    .header-style{
        background-color: #f6f6f6;
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items:center ;
        margin-bottom: 1rem ;
        padding:0 1rem;
        position: relative;
    }
    .header-component{
        display: flex;
        align-items: stretch;
        justify-items: space-between;
        
    }
    .number-component{
        font-weight: 700;
        font-size: 2rem;
        margin-right:5px ;
        line-height: 3rem;
        color: #242424;
        margin: auto;
    }

    .text-component{
        line-height: 1rem;
        font-weight: 200;
        color:#707070;
        margin: auto;
    }
    .text-component strong{
        font-size: 1.1rem;
        font-weight: 600;
        color: #242424;
        
    }
    @media (max-width:800px) {
        .header-style{
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            padding: 1rem;
        }
    }
    .hartaConsultantiLogo{
        order: 0;
    }
    .smis-logo-image{
        position: relative;
        order: 2;

    }
    
    .header-component{
        order: 1;
        margin: 0.8rem 0.2rem;
    }
</style>