<template>
    <div>
        <div>
            <div class="flexed">
                <div class="info">
                    <p class="info-text">Avem super vești!</p>
                    <p class="nume-companie">Aplicatia Smis® a identificat deja finantari pentru care compania dumneavoastra este eligibila. <br>Lista finantarilor este disponibila prin aplicatia Smis Business.</p>
                </div>
                <div class="finantari-eligibile">
                    <span class="numar-finantari">{{count}}</span>
                    <p class="green-text">finantari identificate</p>
                </div>
            </div>
            <div class="alege-pachet">
                <div class="checkbox-container" @change="onSubmit()">
                    <div class="check_box">
                        <input id="adauga-pachet" v-model="form.pachet" type="checkbox" >
                        <label for="adauga-pachet"></label>
                    </div>
                    <label class="nume-checkbox" for="adauga-pachet" ><span class="text-bold">Adaugă pachet</span> Business!</label>
                </div>
            </div>
            <div v-if="form.pachet" class="pachet-business">
                <img :src="data.logo" class="mt-4">
                <p class="nume-companie mt-2" v-html="data.description"></p>

                <div
                    class="border-sus-jos align-items-center mt-3"
                >
                    <p>Abonament anual</p>
                    <ToggleSwitch class="mx-3" :click="switchInvoiceType"></ToggleSwitch>
                    <p>Abonament lunar</p>
                </div>

                <div>
                    <div v-if="selected_invoice_type == 'year'" class="flex">
                        <h3 class="text-color-gradient-pret mr-3 price">
                            <sup class="text-color-gradient-pret">€</sup>{{data.yearly_price}}
                        </h3>
                        <h3 class="text-color-notselected-pret price"><sup>€</sup>{{data.price}}</h3>
                    </div>
                    <div v-else-if="selected_invoice_type == 'month'" class="flex">
                        <h3 class="text-color-notselected-pret mr-3 price"><sup>€</sup>{{data.yearly_price}}</h3>
                        <h3 class="text-color-gradient-pret price">
                            <sup class="text-color-gradient-pret">€</sup>{{data.price}}
                        </h3>
                    </div>
                    <p class="flex">lunar / fără TVA / curs BNR din ziua plății</p>
                    <div class="flex">
                        <p v-if="selected_invoice_type == 'year'" class="text-color-gradient-pret fs-17">
                            Economisești <span class="font-weight-bold">{{data.yearly_discount}}€</span> / an
                        </p>
                        <p v-else class="text-color-gradient-pret fs-17">
                            Cu plata anuală poți economisi
                            <span class="font-weight-bold">{{data.yearly_discount}}</span>€
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import { BFormCheckbox } from 'bootstrap-vue'
import ToggleSwitch from "@/common/components/ToggleSwitch";
export default{
    components:{
        //BFormCheckbox,
        ToggleSwitch

    },
    props: {
        data: [],
        count: null,
    },
    data() {
        return{
            form: {
                pachet: null,
            },
            selected_invoice_type: 'year',
        }
    },
    methods: {
        onSubmit() {
            this.$emit('adaugareBusiness', this.form.pachet);
        },
        anualLunar() {
            this.tipAbonament = !this.tipAbonament;
        },
        invoiceTypeChanged() {
            this.switchInvoiceType()
        },
        switchInvoiceType() {
            if(this.selected_invoice_type === 'year') {
                this.selected_invoice_type = 'month';
            } else {
                this.selected_invoice_type = 'year';
            }
        },
    },
}
</script>

<style scoped>
.flex{
    display: flex;
    justify-content: center;
}
.price{
    font-size: 40px;
    font-weight: 800;
    margin-top: 15px;
}
.text-color-notselected-pret {
    color: #eeeeee;
}
.text-color-gradient-pret {
    background: -webkit-linear-gradient(#04c3a2, #52a1d4);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.align-items-center{
    display: flex;
    justify-content: center;
}
.border-sus-jos {
    border-bottom: 1px solid #f1f1f1;
    border-top: 1px solid #f1f1f1;
    padding: 12px 0px 8px;
}
.text-bold{
    font-weight: 600;
}
.flexed{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.info{
    max-width: 55%;
}
.info p{
    text-align: left;
}
.numar-finantari{
    font-size: 40px;
    font-weight: 600;
    background: -webkit-linear-gradient(#09c1a5, #42a8ca);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 50px;
}
.green-text{
    font-size: 14px;
    font-weight: 600;
    background: -webkit-linear-gradient(#09c1a5, #42a8ca);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}
.finantari-eligibile{
    text-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    padding: 10px;
    max-width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.info-text{
    color: #878588;
    text-align: left;
    font-size: 13px;
    font-weight: 600;
}
.nume-companie{
    color: #878588;
    font-size: 13px;
}


/* checkbox */
.checkbox-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;
}
.nume-checkbox {
    margin-left: 10px;
    color: #848484;
    cursor: pointer;
    font-size: 12px;
}
.check_box {
    display: flex;
    position: relative;
}
.check_box input[type="checkbox"] {
    display: none;
}
.check_box label {
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    border: 0px solid #f2f1f2;
    width: 20px;
    height: 20px;
    transition: all 0.1s ease;
    z-index: 2;
    box-shadow: 0px 0px 0px 2px #f2f1f2;
    display: block;
    background: white;
}
.check_box input[type="checkbox"]:checked ~ label {
    border: 5px solid white;
    background: #0c5ec2;
}
/* checkbox */

</style>