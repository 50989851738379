<template>
    <div class="modul-finantare-title item-k">
        <span class="bold-text">Număr aproximativ de proiecte finanțate</span>
        <h2 class="contribution-percent" style="margin: 0">{{data.projects}}</h2>
    </div>
</template>

<script>
export default{
  props: {
    data: null,
  },
  data() {
    return{
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.bold-text {
  font-weight: bold;
  font-size: var(--moduleTitleSize);
}
.contribution-percent {
  font-size: 23px;
  font-weight: 700;
  margin-bottom: -10px;
}
.item-k {
  grid-area: numarProiecte;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 1250px) {
  .item-k {
    grid-area: numarProiecte;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .item-k .contribution-percent {
    font-size: 21px;
  }
}
@media only screen and (max-width: 710px) {
  .contribution-percent {
    font-size: 15px !important;
  }
}
</style>