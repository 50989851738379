<template>
    <div id="tooltipGradient">
        <span class="tooltipGradient" >{{ text }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            text: {
                type: String,
                default: 'Nou'
            } 
        },
    }
</script>

<style scoped>
#tooltipGradient{
    position: relative;
}
.tooltipGradient {
    position: absolute;
    top: -31px;
    right: 0;
    width: 37px;
    height: 13px;
    background: linear-gradient(to right, #ff8a00, #ed3784);
    color: #fff;
    font-size: 10px;
    line-height: 13px;
    border-radius: 5px;
    -webkit-text-fill-color: #fff;
    text-align: center;
}
</style>
