<template>
    <div class="modul-finantare-title">
        <span class="bold-text gradient-title">Sediu central</span>
        <div class="adress-info mb-4">
            <h3 class="text-uppercase" v-if="data.city">{{ data.city }}</h3>
            <p v-if="data.adress">Adresa: {{ data.adress }}</p>
            <p v-if="data.phone">Telefon: {{ data.phone }} – {{ data.contactPerson }}</p>
            <p v-if="data.email">E-mail: {{ data.email }}</p>
        </div>
        <span class="bold-text gradient-title">Birouri teritoriale</span>
        <div v-if="data.offices">
            <div class="adress-info mb-4" v-for="(sediu, key) in data.offices" :key="key">
                <h3 class="text-uppercase" v-if="sediu.city">{{ sediu.city }}</h3>
                <p v-if="sediu.address">Adresa: {{ sediu.address }}</p>
                <p v-if="sediu.phone">Telefon: {{ sediu.phone }} – {{ sediu.contact_person }}</p>
                <p v-if="sediu.email">E-mail: {{ sediu.email }}</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "CompanieVerificata",
    components: {},
    props: {
        data: null,
    },
    data() {
        return {};
    },
}
</script>

<style scoped>
.bold-text {
    font-weight: bold;
}

.adress-info h3 {
    font-size: 15px;
    font-weight: 700;
    color: #5355d1;
    margin: 0;
    margin-top: 15px;
}

.adress-info p {
    font-size: 13px;
    text-align: left;
}

.gradient-title {
    font-size: 14px;
    color: #1c1c1c;
}
</style>