<template>
    <div>
        <b-form @submit="onSubmit">
            <div class="white-box flex-box">
                <b-form-rating
                    id="rating"
                    v-model="form.rating"
                    color="#ffbe00"
                    no-border
                    size="lg"
                ></b-form-rating>
            </div>
            <span
                class="error-message"
                v-if="errors.has('rating')"
                v-text="errors.get('rating')"
            ></span>
            <b-form-group>
                <b-form-textarea
                    id="comment"
                    v-model="form.comment"
                    class="mt-3 feedback-company"
                    placeholder="Feedback (obligatoriu)"
                ></b-form-textarea>
                <span
                    class="error-message"
                    v-if="errors.has('comment')"
                    v-text="errors.get('comment')"
                ></span>

            </b-form-group>
            <b-button type="submit" class="gray-button" variant="primary">Trimite feedback-ul
                tău
            </b-button>
        </b-form>
    </div>
</template>

<script>
import Errors from '@/helpers/Errors';
import axios from '@/helpers/axios';
import api from '@/helpers/api';
import {
    BForm,
    BFormRating,
    BFormTextarea,
    BButton,
    BFormGroup
} from "bootstrap-vue";

export default {
    components: {
        BForm,
        BFormRating,
        BFormTextarea,
        BButton,
        BFormGroup,
    },

    props: ['selected'],

    data() {
        return {
            loading: true,
            title: 'Evaluează aplicație',
            consultant: null,
            form: {
                comment: null,
                rating: null,
            },
            errors: new Errors(),
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            axios
                .post(api.API_ROOT + '/plans/' + this.selected + '/reviews', this.form)
                .then((response) => {
                    this.$notify(response.data.message);
                })
                .catch(xhr => {
                    if (xhr.response.status === 422) {
                        this.errors.record(xhr.response.data.errors);
                    }
                    this.$notify(xhr.response.data.message);
                })
                .finally(() =>
                    this.form.comment = null,
                    this.form.rating = null
                );
        },
    }
}
</script>

<style scoped>
textarea{
    font-size: 12px;
    margin-top: 0 !important;
}
.gray-button{
    padding: 5px 10px;
    background: #707070;
    border: #707070;
    color: #fff;
    border-radius: 0px;
    font-size: 12px;
    margin-top: 0 !important;
}
</style>