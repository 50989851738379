<template>
    <div>
        <NotificarileMeleTopBar></NotificarileMeleTopBar>
        <div class="viewPagePadding">
            <MesajeNotificare></MesajeNotificare>
        </div>
    </div>
</template>

<script>
    import MesajeNotificare from './components/MesajeNotificare'
    import NotificarileMeleTopBar from "./components/NotificarileMeleTopBar";
    export default {
        components: {
            MesajeNotificare, NotificarileMeleTopBar },
        props: {},
        data() {
            return {};
        },
        created() {},
        methods: {},
    };
</script>

<style scoped></style>
