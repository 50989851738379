<template>
    <b-container class="window" >    
        <b-row>
            <b-col col>
                <b-col col sm>
                    <p class="consultant-name">{{ consultant.name }}</p>  
                </b-col>
                <b-col col sm>
                    <b-form-rating id="rating-lg-no-border " :value="consultant.rating" color="#ffbe00" no-border size="sm" readonly></b-form-rating>
                </b-col>
            </b-col>    
            <b-col col sm class="logo">
                <img class="company-logo mt-1" :src="consultant.logo" alt="" >
            </b-col>  
        </b-row>
        <b-row v-if="consultant.is_verified">
            <b-col >
                <img src="@/assets/images/CompanieVerificata.png" alt="" >
            </b-col>    
        </b-row>
        
        
        <b-row>
            <b-col class="dateConstact"> 
                <p>Adresa:{{ consultant.address }}</p>
                <p>Email:{{ consultant.email }}</p>
                <p>{{ consultant.phone }}</p>
            </b-col> 
        </b-row>      

    </b-container>
</template>
<script>
    import {BContainer,BRow,BCol,BFormRating} from 'bootstrap-vue'
    

    export default {
    
        components:{
            BContainer,
            BRow,
            BCol,
            BFormRating
        },
        props:{
            consultant:{
                type:Object,
                default:()=>{}
            }
        },
        data() {
            return {
                
            }
        },
    }
</script>

<style scoped >
    .window{
        height: 200px;
        width: 400px;

      
    }
    .consultant-name{
        font-size: 1rem ;
        font-weight: 600 !important;
    }
    #rating-lg-no-border{
    padding-left: 0 !important;
    padding-right: 50px !important ;
  }
    .logo{
        padding: 15px;
    }
    .b-rating{
        text-align: start !important;
        display: block !important;;
    }

</style>