import Cookies from 'js-cookie'

import api from '@/helpers/api'
import store from '@/helpers/store'
import axios from '@/helpers/axios'

/**
 * Initialize new session on front-end
 */
export const startSession = async () => {
    if (Cookies.get('XSRF-TOKEN')) return
    return axios.get(api.API_ENDPOINTS.csrf)
}

export const isLoggedIn = () => {
    return store.state.auth.authenticated
}

export const getUserData = () => {
    axios
        .get(api.API_ENDPOINTS.userData)
        .then(() => {
            this.$store.dispatch('auth/getUserData')
        })
        .catch(e => {
            console.log(e)
            //TODO: Handle different errors => 403 -> redirect to login with message
        })
}
