<template>
    <div class="page loginRegisterWrapper">
        <div class="image">
            <LoginRegisterBG></LoginRegisterBG>
        </div>
        <div class="date-user">
            <LoginForm></LoginForm>
        </div>
    </div>
</template>

<script>
    import LoginForm from "./componenets/LoginForm";
    import LoginRegisterBG from "./componenets/LoginRegisterBG";
    import axios from '@/helpers/axios'
    import api from '@/helpers/api'

    export default {
        name: "Test",
        components: {
            LoginForm,
            LoginRegisterBG,
        },
        props: {},
        data() {
            return {};
        },
        mounted() {
            this.verificaMail();
        },
        methods: {
            verificaMail(){
                if(this.$route.name === "EmailVerify") {
                    axios.get(this.$route.fullPath)
                        .then(response=> {
                            this.$notify(response.data.message);
                            this.$router.push({ path: api.LOGIN_ROUTE })
                        }).catch(err => {
                            this.$notify(err.data.message);
                        })
                }
            }
        },
    };
</script>
<style >
   
</style>
<style scoped>
	.page {
		width: 100%;
		display: flex;
        background-color: #fff;
        height: auto;
	}
	.image {
		width: 73%;
		display: flex;
		flex-direction: column;
		align-self: center;
	}
	.date-user {
		width: 450px;
		background-color: #fff;
		align-self: center;
	}
	.loginRegisterWrapper {
		position: relative;
	}
    @media (max-width:1023px) and (min-width:768px){
        .image{
            width: 63%;
        }
    }
    @media (max-width:800px){
        .page{
            flex-direction: column-reverse;
        }
        .image{
            width: 100%;
        }
        .date-user{
            position: absolute;
            bottom: 0;
            background: transparent;
        }
    }
    @media (max-width:500px) {
        .page{
            display: block;
            
        }
        .image{
            width: 100%;
        }
        .date-user{
            width: 100%;
            padding: 0.4rem 0;
        }
    }
</style>
