<template>
  <div>
    <div class="flexed">
      <div class="video-top">
        <div class="text-overlay">
          <h3 class="cifra-finantari">{{ data }}</h3>
          <p>finanțări disponibile pentru companiile tale</p>
        </div>
        <img src="@/assets/images/finantari-eligibile-background.png" class="w-100">
      </div>
      <div class="video-info">
        <p class="video-title">Eligibilitate finanțări</p>
        <p>Se anunta concurenta mare pentru apelul de proiecte edicat fermierilor care vor depune derei de.. Se anunta concurenta mare pentru apelul de proiecte edicat fermierilor care vor depune derei de..</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
      data: null,
  },
  data() {
    return {
  }
  },
}
</script>

<style scoped>
.flexed{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.contact-rapid p{
  color: #fff;
}
.video-top{
  position: relative;
}
.video-top img{
  max-height: 212px;
}
.text-overlay{
  position: absolute;
  top: 22%;
  right: 9%;
  text-align: center;
}
.text-overlay h3{
  font-size: 70px;
  color: #587499;
  font-weight: bold;
  margin: 0;
}
.text-overlay p {
  font-size: 14px;
  color: #4b4b4f;
  font-weight: 500;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 1px);
}
.video-info{
  background: #fff;
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.video-title{
  font-weight: 500;
  font-size: 14px;
}
@media only screen and (max-width: 1600px) {
  .flexed {
    display: block;
  }
}
</style>