<template>
    <div>
        <div class="background-container justify-content-between align-items-center">
            <div class="titlu-notificari mx-3">
                <h4>Notificările mele</h4>
                <p>
                    Te rugăm să vizualizezi mai jos notificările tale. Poți modifica setările din
                    <span> Contul tău </span>
                </p>
            </div>
            <div class="listă-notificari">
                <div v-for="(notificare, i) in notificari" :key="i" class="d-flex mb-3">
                    <div class="notificari mr-2" :style="notificare.culoare"></div>
                    <p>Notificari &nbsp;</p>
                    <p>{{ notificare.nume }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Test",
        props: {},
        data() {
            return {
                notificari: [
                    {
                        nume: "aplicații",
                        culoare: "background-color: #2b2c3d;",
                    },
                    {
                        nume: "SMIS",
                        culoare: "background-color: #5f6771;",
                    },
                    {
                        nume: "financiar",
                        culoare: "background-color: #faaa84;",
                    },
                    {
                        nume: "Marketing",
                        culoare: "background-color: #5363d4;",
                    },
                    {
                        nume: "știri",
                        culoare: "background-color: #fff;",
                    },
                    {
                        nume: "termen limită",
                        culoare: "background-color: #ff646d;",
                    },
                    {
                        nume: "lansări apeluri",
                        culoare: "background-color: #53d4cb;",
                    },
                    {
                        nume: "finanțări favorite",
                        culoare: "background-color: #a2a2a2;",
                    },
                ],
            };
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
	.background-container {
		display: flex;
		background-image: linear-gradient(to bottom, #5b6366 30%, #80898f);
		min-height: 105px;
	}
	.titlu-notificari h4 {
		font-size: 14px;
		font-weight: bold;
		color: #fff;
	}
	.titlu-notificari p {
		font-size: 12px;
		color: #fff;
	}
	.titlu-notificari span {
		font-size: 12px;
		color: #53d4cb;
	}
	.notificari {
		background-color: #2b2c3d;
		border: 1px solid #fff;
		height: 15px;
		border-radius: 50%;
		width: 15px;
	}
	.listă-notificari p {
		color: rgb(205, 233, 231, 0.7);
		font-size: 12px;
		text-align: left;
	}
	.listă-notificari {
		column-count: 4;
		column-width: 200px;
		margin-top: 35px;
        margin-right: 25px;
	}
    @media only screen and (max-width: 740px) {
        .background-container {
		flex-direction: column;
	}
    .listă-notificari div {
        place-content: center;
    }
    .titlu-notificari {
        margin-top: 20px;
    }
    .listă-notificari {
        column-count: 4;
		column-width: 163px;
        margin: 25px 10px 0 10px;
	}
    }
</style>
