<template>
    <div class="top-bar">
        <ul>
          <li><span><strong>PREZENTARE</strong></span></li>
          <li class="text-uppercase"><span>{{data}}</span></li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            data:{
              type: String,
              default: null,
            }
        },
        data() {
            return {

            }
        },
      computed: {
      }
    }
</script>

<style scoped>
	.top-bar {
		display: flex;
		align-items: center;
		min-height: 50px;
		box-sizing: border-box;
		padding: 0px 20px;
		background: linear-gradient(to bottom, #5b6366, #818a91);
		color: #fff;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}
    ul{
        display: flex;
        margin: 0;
        flex-wrap: wrap;
        justify-content: center;
        
    }
    ul > li{
        margin-right: 10px;
    }
	.text {
		font-weight: bold;
	}
    a{
        color:#fff;
    }
@media only screen and (max-width: 900px) {
    .top-bar>div{
        margin: 5px auto;
    }  
    .top-bar {
        text-align: center;
    } 
}
</style>
