<template>
    <div>
        <b-modal
                v-model="openModalShow"
                size="xl"
                ok-variant="success"
                ok-title="Confirmă"
                cancel-variant="danger"
                cancel-title="Anulează"
                header-bg-variant="dark"
                body-bg-variant="dark"
                hide-footer
                hide-header
                centered
                id="checkoutModal"
                @hide="hideTempModal()"
        >
            <div class="modal-body">
                <img src="@/assets/images/smisPay.png" class="pay-logo">
                <div class="modal-header">
                    <span>Ce este</span>
                    <img src="@/assets/images/smisPay.png" class="ml-3">
                </div>
            </div>
            <div class="flex flex-column align-items-center justify-content-center mt-4">
                <p>E ok, il poti folosi.</p>
                <p>Daca nu ai bani, treci la munca</p>
            </div>

        </b-modal>
    </div>
</template>

<script>
    import {BModal, BForm, BFormGroup, BFormInput, BButton} from 'bootstrap-vue';
    export default {
        components: {
            BModal,
            BForm,
            BFormGroup,
            BFormInput,
            BButton,
        },
        props: {
            smisPayModal: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                reversed: false,
                form: {
                    business_id: '',
                    reg_no: '',
                    address: '',
                    email: '',
                    phone: '',
                    bank_account: '',
                    bank_name: '',
                    name: '',
                    job: '',
                }
            };
        },
        computed: {
            openModalShow: {
                get(){
                    return this.smisPayModal
                },
                set(smisPayModal){
                    return smisPayModal
                }
            }
        },
        methods: {
            hideTempModal() {
                this.$emit('hideTempModal')
            }
        },
    };
</script>

<style scoped>
    .modal-dialog.modal-xl {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    p {
        color: #fff;
    }

    #checkoutModal .modal-xl {
        max-width: 1640px;
    }

    .modal-body {
        padding: 0;
    }

    .modal-body > > > .pay-logo {
        position: absolute;
        top: 0;
        left: 0;
    }

    .modal-header span {
        font-size: 36px;
        font-weight: 700;
        color: #fff;
    }

    .modal-header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        position: relative;
    }

    .new-company-form {
        margin-top: 70px;
        width: 48%;
        position: relative;
    }

    .new-company-form:nth-child(2):before {
        content: 'Informații reprezentant legal (pentru contract)';
        color: #fff;
        position: absolute;
        top: -9%;
        font-size: 14px;
        font-weight: 600;
    }

    .modal-header:before {
        content: ' ';
        width: 50%;
        height: 1px;
        position: absolute;
        bottom: -10%;
        background: #fff;
    }

    .modal-pas {
        padding: 0 50px;
        margin-top: 20px;
    }

    input {
        background: transparent;
        border: 1px solid #ffcc00;
        border-radius: 3px;
        color: #ffcc00;
        font-size: 15px;
    }

    .form-control[readOnly] {
        background-color: transparent;
        color: #ffcc00;
    }

    input::placeholder {
        color: #ffcc00;
    }

    input:focus {
        box-shadow: none;
        background-color: transparent;
        border-color: #ffcc00;
        color: #ffcc00;
    }

    .flex {
        display: flex;
        justify-content: space-between;
    }

    .next-step {
        background: #ffcc00;
        color: #272727;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 3px;
        border: 1px solid #ffcc00;
        align-self: flex-end;
    }

    @media (max-width: 1100px) {
        .flex {
            display: block;
        }

        .new-company-form {
            width: 100%;
        }

        .new-company-form:before {
            top: -30% !important;
        }

        .modal-header {
            padding-top: 50px;
        }
    }
</style>