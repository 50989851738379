<template>
    <div id="tabelFinanciarContulmeu">
        <div class="box-title">
            <p>Facturile tale SMIS</p>
        </div>
        <b-table
            id="my-table"
            responsive
            striped
            hover
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields"
        >
            <template v-slot:cell(status)="data">
                <span>{{ data.value }}</span>
            </template>
            <template v-slot:cell(view)="data">
                <div>
                    <span v-b-tooltip.hover.topleft class="mr-3" title="Descarcă factura">
                        <b-icon-download class="icon-color-grey" icon="download" font-scale="2"></b-icon-download>
                    </span>
                    <span v-b-tooltip.hover class="mr-3" title="OP încărcat și confirmat">
                        <b-icon-upload
                            v-if="data.item.status === 'Achitată'"
                            class="icon-color-green"
                            icon="upload"
                            font-scale="2"
                        ></b-icon-upload>
                        <b-icon-upload v-else class="icon-color-grey" icon="upload" font-scale="2"></b-icon-upload>
                    </span>
                    <span v-b-tooltip.bottom="data.item.status === 'Neachitată' ? 'Editează datele' : 'Editează datele'" class="mr-3">
                        <b-icon-pencil-square
                            v-if="data.item.status === 'Neachitată'"
                            class="icon-color-grey"
                            icon="pencil-square"
                            font-scale="2"
                        ></b-icon-pencil-square>
                        <b-icon-pencil-square
                            v-else
                            class="icon-color-transparent"
                            icon="pencil-square"
                            font-scale="2"
                        ></b-icon-pencil-square>
                    </span>
                    <span v-b-tooltip.bottomright="data.item.status === 'Neachitată' ? 'Solicită anulare factură' : 'În curs de procesare'">
                        <b-icon-x-circle
                            v-if="data.item.status === 'Neachitată'"
                            class="icon-color-grey"
                            icon="x-circle"
                            font-scale="2"
                        ></b-icon-x-circle>
                        <b-icon-x-circle
                            v-else
                            class="icon-color-transparent"
                            icon="x-circle"
                            font-scale="2"
                        ></b-icon-x-circle>
                    </span>
                </div>
            </template>
        </b-table>
        <div class="box-pagination">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                align="center"
                aria-controls="my-table"
            ></b-pagination>
        </div>
    </div>
</template>

<script>
    import {
        BTable,
        BPagination,
        VBTooltip,
        BIconDownload,
        BIconUpload,
        BIconPencilSquare,
        BIconXCircle
    } from 'bootstrap-vue'
    
    export default {
        components: {
            BTable,
            BPagination,
            BIconDownload,
            BIconUpload,
            BIconPencilSquare,
            BIconXCircle
        },
        directives:{
            'b-tooltip':VBTooltip
        },
        props: {
            items: Array,
        },
        data() {
            return {
                perPage: 5,
                currentPage: 1,
                fields: [
                    {
                        key: "crt",
                        label: "Crt.",
                        sortable: false,
                    },
                    {
                        key: "smartbill",
                        label: "Serie & număr factură",
                        sortable: true,
                    },
                    {
                        key: "name",
                        label: "Beneficiar",
                        sortable: true,
                    },
                    {
                        key: "plan",
                        label: "Serviciu facturat",
                        sortable: true,
                    },
                    {
                        key: "amount",
                        label: "Suma totală",
                        sortable: true,
                    },
                    {
                        key: "status",
                        label: "Status",
                        sortable: true,
                    },
                    {
                        key: "view",
                        label: "Acțiuni",
                    },
                ],
            };
        },
        computed: {
        },
        created() {},
        methods: {},
    };
</script>

<style>
	#tabelFinanciarContulmeu .box-title {
		background-image: linear-gradient(to right, #b9dbd9, #c3c2cd);
		width: 100%;
		height: 60px;
		border-top: 1px solid #d7d8de;
		display: flex;
		align-items: center;
	}
	#tabelFinanciarContulmeu .box-pagination {
		background-color: #fff;
		height: 60px;
		margin-top: -20px;
		width: 100%;
		padding: 10px;
		width: 99.9%;
		margin-left: 1px;
        box-shadow: 0px 15px 19px -13px #dbdbdb;
	}
	#tabelFinanciarContulmeu .box-title p {
		font-weight: bold;
		font-size: 16px;
		color: #fff;
		padding-left: 30px;
	}
	#tabelFinanciarContulmeu .icon-color-grey {
		color: #c1c4ce;
		cursor: pointer;
	}
	#tabelFinanciarContulmeu .icon-color-green {
		color: #6cc7a7;
		cursor: pointer;
	}
	#tabelFinanciarContulmeu .icon-color-transparent {
		color: #eaedef;
	}

	#tabelFinanciarContulmeu .page-link {
		color: #646762;
        font-weight: 600;
	}
    #tabelFinanciarContulmeu td{
        color: #787878;
        font-size: 13px;
        text-transform: uppercase;
    }
	#tabelFinanciarContulmeu .page-item.active .page-link {
		background-color: #0cc2aa;
		border-color: #5dcdbf;
        color: #fff;
	}
	#tabelFinanciarContulmeu .page-link:focus {
		box-shadow: none;
	}
	#tabelFinanciarContulmeu .page-link:hover {
		color: #646762;
	}

	#tabelFinanciarContulmeu .table {
		background: #fff;
		border: 1px solid #e9e9e9;
		font-size: 13px;
	}
	#tabelFinanciarContulmeu .table > tr > th {
		border-bottom-color: #f9fafa !important;
	}
	#tabelFinanciarContulmeu .table-backgroundGry,
	#tabelFinanciarContulmeu .table-backgroundGreen,
	#tabelFinanciarContulmeu .table-backgroundRed {
		white-space: nowrap;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	#tabelFinanciarContulmeu .table-backgroundGry span,
	#tabelFinanciarContulmeu .table-backgroundGreen span,
	#tabelFinanciarContulmeu .table-backgroundRed span {
		padding: 3px 7px;
		color: #fff;
		font-size: 11px;
		font-weight: bold;
	}
	#tabelFinanciarContulmeu .table-backgroundGry span {
		background: #b8b8b7;
		border-radius: 4px;
	}
	#tabelFinanciarContulmeu .table-backgroundGreen span {
		background: #6cc7a7;
		border-radius: 4px;
	}
	#tabelFinanciarContulmeu .table-backgroundRed span {
		background: #ff7d76;
		border-radius: 4px;
	}
	#tabelFinanciarContulmeu .table {
		text-align: center;
	}
	#tabelFinanciarContulmeu .table-bold {
		font-weight: bold;
	}
	.table th,
	.table td {
		vertical-align: middle;
	}
	.table-striped tbody tr:nth-of-type(odd) {
		background-color: #f9f9f9;
	}
	td {
		border-top: 1px solid #f3f4f4 !important;
	}
	.table thead th {
		border-bottom: 2px solid #f9fafa !important;
		color: #292929;
		font-size: 14px;
        position: relative;
	}
</style>