<template>
    <div>
        <div class="pasi">
            <div class="pas" @click="sendDataTo(1)">
                <div class="bullet">1</div><span>Confirmarea serviciilor</span>
            </div>
            <div class="pas">
                <div class="bullet bullet-active">2</div><span>Date de facturare</span>
            </div>
            <div class="pas">
                <div class="bullet">3</div><span>Modalitatea de plată</span>
            </div>
            <div class="pas">
                <div class="bullet">4</div><span>Confirmarea plății</span>
            </div>
        </div>

        <div>
            <validation-observer #default="{ invalid }">
                <b-form>
                    <div class="flex justify-content-between">
                        <div class="left-sidebar">
                            <img :src="checkout.logo" class="subscription-logo mb-2">

                            <div class="main-price" v-if="isSubscription && invoiceType === 'month'">
                                <p class="span-title">Abonament <strong>lunar</strong></p>
                                <p class="price-info"><span class="price"><sup>€</sup>{{ getSubTotal }}</span> <span v-if="checkout.vat_enabled">+ TVA</span></p>
                                <p class="yellow-text price-details"><strong>(vei achita prima lună. apoi {{ getSubTotal }}€/lună - facturare lunară automată)</strong></p>
                                <p class="yellow-text price-details">poți renunța oricând la abonament, din contul tău - secțiunea serviciile mele</p>
                                <p class="yellow-text price-details mt-3 font-italic">Factura se va emite la cursul BNR din ziua plății</p>
                            </div>

                            <div class="main-price" v-else-if="isSubscription">
                                <p class="span-title">Abonament <strong>anual</strong></p>
                                <p class="price-info"><span class="price"><sup>€</sup>{{ getSubTotal }}</span> <span v-if="checkout.vat_enabled">+ TVA</span></p>
                                <p class="yellow-text price-details">(cu plată unică) Economisești <span class="green-text">€{{ checkout.yearly_discount}}</span> <span v-if="checkout.vat_enabled">+ TVA</span></p>
                                <p class="yellow-text price-details mt-3 font-italic">Factura se va emite la cursul BNR din ziua plății</p>
                            </div>

                            <div class="main-price" v-else>
                                <p class="span-title">Abonament <strong>lifetime</strong></p>
                                <p class="price-info"><span class="price"><sup>€</sup>{{ getSubTotal }}</span> <span v-if="checkout.vat_enabled">+ TVA</span></p>
                                <p class="yellow-text price-details mt-3 font-italic">Factura se va emite la cursul BNR din ziua plății</p>
                            </div>

                            <div class="change-price mt-4">
                                <p class="span-title">Vei plăti <strong>astăzi</strong></p>
                                <p class="price-info"><span class="price"><sup style="font-size: 16px;">LEI</sup>{{ Math.floor(checkout.subtotal) }},{{ parseInt(checkout.subtotal % 1 * 100) }}</span> <span v-if="checkout.vat_enabled">+ TVA</span></p>
                            </div>
                            <div class="tip-facturare">
                                <span>Doresc factură pentru:</span>
                                <b-form-radio-group class="facturare" >
                                    <b-form-radio v-model="form.business_invoice" value="1">Persoană fizică</b-form-radio>
                                    <b-form-radio v-model="form.business_invoice" value="2">Persoană juridică</b-form-radio>
                                </b-form-radio-group>
                            </div>
                        </div>
                        <div class="flex space-between percent-width">
                            <div class="form" v-if="form.business_invoice === '2'">
                                <b-form-group v-if="! form.new_company">
                                    <v-select
                                        dir="ltr"
                                        :options="checkout.companies"
                                        label="name"
                                        :reduce="(option) => option.id"
                                        input-id="invoice_company_id"
                                        class="selecteaza-companie"
                                        :clearable="false"
                                        v-model="form.invoice_company_id"
                                        placeholder="Alege una dintre companiile tale asociate contului"
                                    ></v-select>
                                </b-form-group>
                                <b-form-group>
                                    <div class="fake-input">
                                        <label for="checkbox">Doresc facturare pe datele unei companii distincte</label>
                                        <b-form-checkbox v-model="form.new_company" id="checkbox" class="checkbox-fake" @change="newCompany"></b-form-checkbox>
                                    </div>
                                </b-form-group>
                                <b-form-group v-if="form.new_company">
                                    <b-input-group>
                                        <b-form-input
                                            :class="cuiInputError ? 'cuiInputError' : ''"
                                            id="business_id"
                                            v-model="form.business_id"
                                            type="text"
                                            placeholder="CUI"
                                            required
                                            @blur="getByCui(form.business_id)"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button @click="getByCui(form.business_id)" variant="outline-info">Interogare
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group v-else>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="mesaj"
                                    >
                                        <b-form-input
                                            id="business_id"
                                            v-model="form.business_id"
                                            type="text"
                                            name="business_id"
                                            placeholder="CUI"
                                            v-bind:readonly="!form.new_company"
                                            :state="errors.length > 0 ? false : null"
                                        ></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="mesaj"
                                    >
                                        <b-form-input
                                            id="reg_no"
                                            v-model="form.reg_no"
                                            type="text"
                                            placeholder="Număr înregistrare Registrul Comerțului"
                                            readonly
                                            :state="errors.length > 0 ? false : null"
                                        ></b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group>
                                    <v-select
                                        dir="ltr"
                                        :options="checkout.states"
                                        label="name"
                                        input-id="state_id"
                                        :reduce="(option) => option.code"
                                        :clearable="false"
                                        v-model="form.billing.state"
                                        placeholder="Alege judet"
                                        disabled
                                    ></v-select>
                                </b-form-group>
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="mesaj"
                                    >
                                        <b-form-input
                                            id="city"
                                            v-model="form.billing.city"
                                            type="text"
                                            placeholder="Oras"
                                            readonly
                                            :state="errors.length > 0 ? false : null"
                                        ></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="mesaj"
                                    >
                                        <b-form-input
                                            id="address"
                                            v-model="form.billing.address"
                                            type="text"
                                            placeholder="Adresa"
                                            readonly
                                            :state="errors.length > 0 ? false : null"
                                        ></b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required|email"
                                        name="mesaj"
                                    >
                                        <b-form-input
                                            id="customer_email"
                                            v-model="form.customer_email"
                                            type="text"
                                            placeholder="E-mail"
                                            :state="errors.length > 0 ? false : null"
                                        ></b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="telefon"
                                    >
                                        <b-form-input
                                            id="customer_phone"
                                            v-model="form.customer_phone"
                                            type="text"
                                            placeholder="Telefon"
                                            :state="errors.length > 0 ? false : null"
                                        ></b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>
                            <div class="form2" v-if="form.business_invoice === '2'">
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="cont bancar"
                                        >
                                            <b-form-input
                                                id="bank_account"
                                                v-model="form.bank_account"
                                                type="text"
                                                placeholder="Cont bancar"
                                                :state="errors.length > 0 ? false : null"
                                            ></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="bancă"
                                        >
                                            <b-form-input
                                                id="bank_name"
                                                v-model="form.bank_name"
                                                type="text"
                                                placeholder="Bancă"
                                                :state="errors.length > 0 ? false : null"
                                            ></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="persoană de contact"
                                        >
                                            <b-form-input
                                                id="contact_person"
                                                v-model="form.contact_person"
                                                type="text"
                                                placeholder="Persoana de contact"
                                                :state="errors.length > 0 ? false : null"
                                            ></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="funcție"
                                        >
                                            <b-form-input
                                                id="contact_person_job"
                                                v-model="form.contact_person_job"
                                                type="text"
                                                placeholder="Funcție"
                                                :state="errors.length > 0 ? false : null"
                                            ></b-form-input>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                            </div>
                        </div>
                        <div class="form-fizica" v-if="form.business_invoice === '1'">
                            <b-form-group>
                                <b-form-input
                                    id="first_name"
                                    v-model="form.billing.first_name"
                                    type="text"
                                    placeholder="Nume"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group>
                                <b-form-input
                                    id="last_name"
                                    v-model="form.billing.last_name"
                                    type="text"
                                    placeholder="Prenume"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group>
                                <b-form-input
                                    id="cnp"
                                    v-model="form.business_id"
                                    type="text"
                                    placeholder="Cod Numeric Personal"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group>
                                <v-select
                                    dir="ltr"
                                    :options="checkout.states"
                                    label="name"
                                    :reduce="(option) => option.code"
                                    :clearable="false"
                                    v-model="form.billing.state"
                                    placeholder="Alege judet"
                                ></v-select>
                            </b-form-group>
                            <b-form-group>
                                <b-form-input
                                    id="city"
                                    v-model="form.billing.city"
                                    type="text"
                                    placeholder="Oras"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group>
                                <b-form-input
                                    id="address"
                                    v-model="form.billing.address"
                                    type="text"
                                    placeholder="Adresa"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group>
                                <b-form-input
                                    id="email"
                                    v-model="form.customer_email"
                                    type="text"
                                    placeholder="E-mail"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group>
                                <b-form-input
                                    id="phone"
                                    v-model="form.customer_phone"
                                    type="text"
                                    placeholder="Telefon"
                                ></b-form-input>
                            </b-form-group>
                    </div>
                    </div>
                    <div class="flex justify-content-center">
                        <b-button class="next-step" :disabled="invalid" @click="sendDataTo(3)"><strong>Pasul 3</strong> - Modalitate de plată</b-button>
                    </div>
            </b-form>
        </validation-observer>
        </div>
    </div>
</template>

<script>
import {BButton, BFormRadio, BFormRadioGroup, BFormGroup, BFormInput, BForm, BFormCheckbox, BInputGroupAppend, BInputGroup} from "bootstrap-vue";
import vSelect from 'vue-select'
import axios from '@/helpers/axios'
import states from './state-codes.json'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
require('@/helpers/validations/validations')

export default {
    components: {
        BButton,
        BFormRadio,
        BFormRadioGroup,
        BFormGroup,
        BFormInput,
        BForm,
        BFormCheckbox,
        BInputGroupAppend,
        BInputGroup,
        vSelect,
        ValidationProvider,
        ValidationObserver,
    },

    props: ['data', 'invoiceType', 'checkout'],

    data() {
        return {
            newCompanyModal: false,
            tempCloseModal: false,
            cuiInputError: null,
            form: {
                business_invoice: '',
                invoice_company_id: '',
                new_company: false,
                company_id: '',
                plan_id: '',
                plan_type: '',
                payment_method: '',
                customer_email: '',
                customer_phone: '',
                billing: {
                    first_name: '',
                    last_name: '',
                    address: '',
                    state: '',
                    city: '',
                },
                business_id: '',
                company_name: '',
                reg_no: '',
                bank_name: '',
                bank_account: '',
                contact_person: '',
                contact_person_job: '',
                coupons: [],
                customer_sold: 0,
            },
            selectedCompany: {}
        }
    },

    update() {
        this.$mount();
    },

    mounted() {
        this.form = this.data.form;
    },

    methods: {
        addNewCompany() {
            this.newCompanyModal = true;
            this.tempCloseModal = true;
            this.$emit('tempCloseModal', this.tempCloseModal)

        },

        closeAddCompany() {
            this.newCompanyModal = false;
            this.tempCloseModal = false;
            this.$emit('tempCloseModal', this.tempCloseModal)
        },
        
        newCompany() {
            this.form.invoice_company_id = '';
            if(!this.form.new_company) {
                this.form.business_id = '';
                this.form.company_name = '';
                this.form.billing.state = '';
                this.form.billing.city = '';
                this.form.billing.address = '';
                this.form.customer_email = '';
                this.form.customer_phone = '';
                this.form.reg_no = '';
            }
        },

        updateForm() {
            this.form.business_id = this.selectedCompany != undefined ? this.selectedCompany.business_id : '';
            this.form.company_name = this.selectedCompany != undefined ? this.selectedCompany.name : '';
            this.form.billing.state = this.selectedCompany != undefined ? this.selectedCompany.state : '';
            this.form.billing.city = this.selectedCompany != undefined ? this.selectedCompany.city : '';
            this.form.billing.address = this.selectedCompany != undefined ? this.selectedCompany.address : '';
            this.form.customer_email = this.selectedCompany != undefined ? this.selectedCompany.email : '';
            this.form.customer_phone = this.selectedCompany != undefined ? this.selectedCompany.phone : '';
            this.form.reg_no = this.selectedCompany != undefined ? this.selectedCompany.reg_no : '';
            this.form.bank_name = this.selectedCompany != undefined ? this.selectedCompany.bank_name : '';
            this.form.bank_account = this.selectedCompany != undefined ? this.selectedCompany.bank_account : '';
            this.form.contact_person = this.selectedCompany != undefined ? this.selectedCompany.contact_person : '';
            this.form.contact_person_job = this.selectedCompany != undefined ? this.selectedCompany.contact_person_job : '';
        },

        sendDataTo(step) {
            this.$emit('stepActive', {'pageNumber': step, 'form': this.form, 'submit': false})
        },
        
        getByCui(cui)  {
            this.cuiInputError = ! this.form.business_id ? true : false;

            return axios.get('https://termene.ro/api/dateFirmaSumar.php?cui=' + cui, {
                auth: {
                    username: 'smisro',
                    password: 'lfU&53s$!AU5'
                }
            }).then(response => {
                this.form.company_name = response.data.DateGenerale.nume
                this.form.billing.state = states.find((state) => {
                    return state.name.toLowerCase() == response.data.DateGenerale.judet.toLowerCase()
                }).code
                this.form.billing.city = response.data.DateGenerale.judet
                this.form.billing.address = response.data.DateGenerale.adresa
                this.form.customer_email = response.data.DateGenerale.email
                this.form.customer_phone = response.data.DateGenerale.telefon
                this.form.reg_no = response.data.DateGenerale.cod_inmatriculare

            }).catch(() => {
                this.cuiInputError = true;
                this.form.company_name = '';
                this.form.reg_no = '';
                this.form.address = '';
                this.form.phone = '';
            })
        },
    },

    computed: {
        isLifetime() {
            return this.checkout.invoice_interval ? this.checkout.invoice_interval.includes('lifetime') : false;
        },

        isSubscription() {
            return ['year', 'month'].every(val => this.checkout.invoice_interval ? this.checkout.invoice_interval.includes(val) : false)
        },

        getSubTotal() {
            return this.invoiceType === 'year' ? this.checkout.total_yearly_price : this.checkout.price;
        }
    },

    watch: {
        'form.invoice_company_id': function(id) {
            this.selectedCompany = this.checkout.companies.find(company => company.id == id);
            this.updateForm();
        },

        'form.business_invoice': function() {
            this.form.invoice_company_id = '';
        }
    },
}
</script>

<style scoped>
p, span, h1, h2, h3 {
    color: #fff;
}
.space-between{
    justify-content: space-between;
}
.pasi {
    display: flex;
    justify-content: space-between;
    padding-bottom: 70px;
}
.percent-width{
    width: 75%;
}
.pas {
    display: flex;
    font-size: 14px;
    cursor: pointer;
}
.bullet {
    height: 20px;
    width: 20px;
    background: #fff;
    font-size: 14px;
    color: #343434;
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
}
.bullet-active {
    background: #ffcc00;
}

.flex {
    display: flex;
}

.subscription-logo {
    max-width: 100%;
    max-height: 55px;
}

.span-title {
    font-size: 24px;
}

.main-price p, .main-price span {
    text-align: left;
    color: #ffcc00;
}
.cuiInputError {
    border-color: red;
}
input:-webkit-autofill{
    background: transparent;
}
.price {
    font-size: 28px;
    font-weight: 700;
}

.left-sidebar {
    width: 25%;
}

.form, .form2 {
    width: 48%;
}

.form-fizica {
    width: 70%;
}

.v-select >>> .vs__dropdown-toggle {
    margin-bottom: 0;
    background: transparent;
    border:1px solid #fff;
    padding: 3px 10px;
}

.v-select.vs--disabled >>> .vs__dropdown-toggle {
    border: 1px solid #ffcc00;
}

.v-select >>> .vs__selected, .v-select >>> .vs__open-indicator {
    color: #fff;
    fill: #fff;
    font-size: 15px;
}

.v-select >>> .vs__search::placeholder {
    color: #fff;
}

.v-select.vs--disabled >>> .vs__selected, .v-select.vs--disabled >>> .vs__open-indicator, .v-select.vs--disabled >>> .vs__search::placeholder {
    color: #ffcc00;
    fill: #ffcc00;
}

.v-select.vs--disabled >>> .vs__search, .v-select.vs--disabled >>> .vs__open-indicator {
    background-color: transparent;
}

.v-select >>> .vs__dropdown-menu {
    background: rgba(21, 20, 20, 0.85);
    max-height: 200px;
    overflow-y: auto;
}

.v-select >>> .vs__dropdown-option {
    color: #fff;
}

.next-step {
    background: #ffcc00;
    color: #272727;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid #ffcc00;
    margin: 40px 0;
}
.btn-outline-info {
    color: #ffcc00;
    border-color: #ffcc00;
    font-size: 15px;
    background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active{
    color: #000;
    border-color: #ffcc00;
    font-size: 15px;
    background-color: #ffcc00;
}
.tip-facturare {
    margin-top: 20px;
}

.tip-facturare span {
    font-size: 13px;
    color: #fff;
}

.facturare >>> .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #ffcc00 !important;
    border-color: #ffcc00 !important;
}

.facturare >>> label {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

input:read-only {
    background: transparent;
    border: 1px solid #ffcc00;
    border-radius: 3px;
    color: #fff;
    font-size: 15px;
}
input[readOnly]:focus {
    box-shadow: none;
    background-color: transparent;
    border-color: #ffcc00;
    color: #fff;
}
.form-control[readOnly] {
    background-color: transparent;
    color: #ffcc00;
}
input[readOnly]::placeholder {
    color: #ffcc00;
}
input::placeholder{
    color: #fff;
}
input{
    background: transparent;
    border: 1px solid #fff;
    border-radius: 3px;
    color: #fff;
    font-size: 15px;
}
input:focus{
    box-shadow: none;
    background-color: transparent;
    border-color: #fff;
    color: #fff;
}


.fake-input {
    display: flex;
    border: 1px solid #fff;
    border-radius: 3px;
    align-items: center;
    justify-content: space-between;
    padding: 0.375rem 0.75rem;
}

.fake-input label {
    color: #fff;
    margin: 0;
}

.checkbox-fake >>> .custom-control-label::before {
    background-color: transparent;
    border: #fff solid 1px;
}
@media (max-width: 1000px) {
    .flex {
        display: block;
    }

    .form, .form2, .form-fizica, .left-sidebar {
        width: 100%;
    }

    .left-sidebar {
        margin-bottom: 10px;
    }

    .pasi {
        display: block;
    }

    .pas {
        justify-content: center;
        margin-bottom: 10px;
    }
}
</style>