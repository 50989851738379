<template>
    <div>
        <b-modal
                v-model="openModalShow"
                size="xl"
                ok-variant="success"
                ok-title="Confirmă"
                cancel-variant="danger"
                cancel-title="Anulează"
                hide-header-close
                header-bg-variant="dark"
                body-bg-variant="dark"
                hide-footer
                hide-header
                id="checkoutModal"
                @hide="hideTempModal()"
        >
            <div class="modal-body">
                <img src="@/assets/images/smisPay.png" class="pay-logo">
                <div class="modal-header">
                    <span>Adăugare companie</span>
                </div>
            </div>
            <b-form class="flex" @submit.prevent="addCompany()">
                <div class="new-company-form">
                    <div class="mb-3">
                        <b-input-group>
                            <b-form-input
                                    :class="cuiInputError ? 'cuiInputError' : ''"
                                    id="cui"
                                    v-model="form.business_id"
                                    type="text"
                                    placeholder="CUI"
                                    required
                                    @blur="getByCui(form.business_id)"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button @click="getByCui(form.business_id)" variant="outline-info">Interogare
                                </b-button>
                            </b-input-group-append>

                        </b-input-group>
                        <p class="error" v-if="cuiInputError">CUI-ul introdus nu este valid</p>
                    </div>
                    <b-form-group>
                        <b-form-input
                                id="company_name"
                                v-model="form.company_name"
                                type="text"
                                placeholder="Nume Companie"
                                required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                                id="reg_no"
                                v-model="form.reg_no"
                                type="text"
                                placeholder="Număr înregistrare Registrul Comerțului"
                                required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                                id="address"
                                v-model="form.address"
                                type="text"
                                placeholder="Adresa"
                                required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                                id="phone"
                                v-model="form.phone"
                                type="text"
                                placeholder="Telefon"
                                required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                                id="email"
                                v-model="form.email"
                                type="email"
                                placeholder="E-mail"
                                required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                                id="bank_account"
                                v-model="form.bank_account"
                                type="text"
                                placeholder="Cont bancar"
                                required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                                id="bank_name"
                                v-model="form.bank_name"
                                type="text"
                                placeholder="Bancă"
                                required
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="new-company-form">
                    <b-form-group>
                        <b-form-input
                                id="name"
                                v-model="form.name"
                                type="text"
                                placeholder="Nume și prenume"
                                required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-form-input
                                id="job"
                                v-model="form.job"
                                type="text"
                                placeholder="Funcție"
                                required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <b-button type="submit" class="next-step">
                            <strong>Adaugă compania</strong>
                        </b-button>
                    </b-form-group>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
    import {
        BModal,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BInputGroup,
        BInputGroupAppend
    } from 'bootstrap-vue';
    import axios from '@/helpers/axios';
    import api from '@/helpers/api'
    export default {
        components: {
            BModal,
            BForm,
            BFormGroup,
            BFormInput,
            BButton,
            BInputGroup,
            BInputGroupAppend
        },
        props: {
            newCompanyModal: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                cuiInputError: false,
                reversed: false,
                form: {
                    business_id: '',
                    company_name: '',
                    reg_no: '',
                    address: '',
                    email: '',
                    phone: '',
                    bank_account: '',
                    bank_name: '',
                    name: '',
                    job: '',
                    city: '',
                    turnover: '',
                    main_caen: '',
                    founded: '',
                }
            };
        },
        computed: {
            openModalShow: {
                get(){
                    return this.newCompanyModal
                },
                set(newCompanyModal){
                    return newCompanyModal
                }
            }
        },
        methods: {
            hideTempModal() {
                this.$emit('hideTempModal')
            },
            getByCui(cui)  {
                console.log(this.form.business_id)
                if (!this.form.business_id) {
                    this.cuiInputError = true
                } else {
                    this.cuiInputError = false
                }
                return axios.get('https://termene.ro/api/dateFirmaSumar.php?cui=' + cui, {
                    auth: {
                        username: 'smisro',
                        password: 'lfU&53s$!AU5'
                    }
                }).then(response => {
                    console.log('response', response.data);
                    this.form.company_name = response.data.DateGenerale.nume
                    this.form.reg_no = response.data.DateGenerale.cod_inmatriculare
                    this.form.address = response.data.DateGenerale.adresa_anaf
                    this.form.phone = response.data.DateGenerale.telefon
                    this.form.city = response.data.DateGenerale.judet
                    this.form.main_caen = response.data.DateGenerale.cod_caen
                    this.form.founded = response.data.DateGenerale.vechime_firma.data
                    this.form.turnover = response.data.Bilanturi ? response.data.Bilanturi.map(function (item) {
                        return {
                            'an': item.an,
                            'suma': item.cifra_de_afaceri_neta
                        }
                    }) : null

                }).catch((err) => {
                    console.log('catch', err)
                    this.cuiInputError = true;
                    this.form.company_name = '';
                    this.form.reg_no = '';
                    this.form.address = '';
                    this.form.phone = '';
                })
            },
            submitForm() {
                if(!this.form.business_id) {
                    this.getByCui()
                }
            },
            addCompany() {
                console.log(this.companie)
                this.companieNoua = {
                    name: this.form.company_name,
                    business_id: this.form.business_id,
                    city: this.form.city,
                    address: this.form.address,
                    reg_no: this.form.reg_no,
                    turnover: this.form.turnover && this.form.turnover[0] ? this.form.turnover[0].suma : null,
                    main_caen: this.form.caen,
                    phone: this.form.phone,
                    founded: this.form.founded,
                    email: this.form.email,
                    contact_person: this.form.name,
                    bank_name: this.form.bank_name,
                    is_active: 1, //TODO: active

                }
                console.log('catre api companie', this.companieNoua)
                axios.post(api.API_ENDPOINTS.companies, this.companieNoua)
                    .then(response => {
                        this.$notify(response.data.message);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.form = {
                            business_id: '',
                                company_name: '',
                                reg_no: '',
                                address: '',
                                email: '',
                                phone: '',
                                bank_account: '',
                                bank_name: '',
                                name: '',
                                job: '',
                                city: '',
                                turnover: '',
                                main_caen: '',
                                founded: '',
                        }
                        this.hideTempModal()
                })
            }
        },
    };
</script>

<style scoped>
    #checkoutModal .modal-xl {
        max-width: 1640px;
    }

    .modal-body {
        padding: 0;
    }

    .modal-body > > > .pay-logo {
        position: absolute;
        top: 0;
        left: 0;
    }

    .modal-header span {
        font-size: 36px;
        font-weight: 700;
        color: #fff;
    }

    .modal-header {
        display: flex;
        justify-content: center;
        padding: 0;
        position: relative;
    }

    .new-company-form {
        margin-top: 70px;
        width: 48%;
        position: relative;
    }

    .cuiInputError {
        border-color: red;
    }

    .btn-outline-info {
        color: #101010;
        border-color: #ffcc00;
        font-size: 15px;
        background-color: #ffcc00;
    }

    .error {
        color: red;
        font-weight: 600;
    }

    .new-company-form:first-child:before {
        content: 'Informații companie';
        color: #fff;
        position: absolute;
        top: -9%;
        font-size: 14px;
        font-weight: 600;
    }

    .new-company-form:nth-child(2):before {
        content: 'Informații reprezentant legal (pentru contract)';
        color: #fff;
        position: absolute;
        top: -9%;
        font-size: 14px;
        font-weight: 600;
    }

    .modal-header:before {
        content: ' ';
        width: 50%;
        height: 1px;
        position: absolute;
        bottom: -10%;
        background: #fff;
    }

    .modal-pas {
        padding: 0px 50px;
        margin-top: 20px;
    }

    input {
        background: transparent;
        border: 1px solid #ffcc00;
        border-radius: 3px;
        color: #fff;
        font-size: 15px;
    }

    .form-control[readOnly] {
        background-color: transparent;
        color: #ffcc00;
    }

    input::placeholder {
        color: #ffcc00;
    }

    input:focus {
        box-shadow: none;
        background-color: transparent;
        border-color: #ffcc00;
        color: #ffcc00;
    }

    .flex {
        display: flex;
        justify-content: space-between;
    }

    .next-step {
        background: #ffcc00;
        color: #272727;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 3px;
        border: 1px solid #ffcc00;
        align-self: flex-end;
    }

    @media (max-width: 1100px) {
        .flex {
            display: block;
        }

        .new-company-form {
            width: 100%;
        }

        .new-company-form:before {
            top: -30% !important;
        }

        .modal-header {
            padding-top: 50px;
        }
    }
</style>