<template>
    <div>
        <b-form>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4">
                            <b-form-group
                                label="Numele companiei"
                            >
                                <b-form-input
                                    v-model="data.name"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group
                                label="Email"
                            >
                                <b-form-input
                                    v-model="data.main_email"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group
                                label="Telefon"
                            >
                                <b-form-input
                                    v-model="data.main_phone"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group
                                label="Servicii"
                            >
                                <v-select
                                    v-model="data.services"
                                    dir="ltr"
                                    multiple
                                    :close-on-select="false"
                                    :options="services"
                                    label="name"
                                    :reduce="(option) => { return {'id': option.id, 'name': option.name}}"
                                ></v-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group
                                label="Activ"
                            >
                                <b-form-input
                                    v-model="data.main_email"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <b-form-group
                                label="Despre noi"
                            >
                                <vue-editor v-model="data.about" :editorToolbar="customToolbar"></vue-editor>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <b-form-group
                                label="Descriere extinsă"
                            >
                                <vue-editor v-model="data.description" :editorToolbar="customToolbar"></vue-editor>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <b-form-group
                                label="Proiecte finanțate"
                            >
                                <b-form-input
                                    v-model="data.funded_projects"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-3">
                            <b-form-group
                                label="Proiecte implementate"
                            >
                                <b-form-input
                                    v-model="data.implemented_projects"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-3">
                            <b-form-group
                                label="Ani de experiență"
                            >
                                <b-form-input
                                    v-model="data.years_of_experience"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-3">
                            <b-form-group
                                label="Rată de succes"
                            >
                                <b-form-input
                                    v-model="data.success_rate"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
            <b-button @click="onSubmit()" class="buton-submit-setari-cont" variant="primary">Salvează modificările</b-button>
        </b-form>
    </div>
</template>

<script>
import {BForm, BFormGroup, BFormInput, BFormTextarea, BButton} from 'bootstrap-vue'
import { VueEditor } from "vue2-editor";
import vSelect from 'vue-select'
export default {
    components: {
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        VueEditor,
        vSelect,
    },
    props: {
        data: [],
        services: []
    },
    data() {
        return {
            customToolbar: [
                [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
                ["bold", "italic", "underline", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ align: "" }, { align: "center" }, { align: "right"}, { align: "justify"}],
                [{ indent: '-1'}, { indent: '+1' }],
                ["clean"],
            ],
        }
    },
    mounted() {
    },
    methods: {
        onSubmit() {
            this.$emit('submitForm');
        }
    }
}
</script>

<style scoped>
.padding-box{
    padding: 20px;
}
input, textarea{
    border-radius: 0;
    border: 1px solid #e8e8e8;
}
input:focus, textarea:focus{
    box-shadow: none;
}
.buton-submit-setari-cont {
    color: #fff;
    background-image: linear-gradient(180deg, #5b3cd0, #3bc6d4);
    border: none;
    font-size: var(--paragraphSize);
    font-weight: var(--bold);
}
.quillWrapper{
    background: #fff;
}
.quillWrapper >>> .ql-editor{
    min-height: inherit !important;
    font-size: 12px;
    max-height: 220px;
}
.form-group >>> legend{
    font-size: 14px;
}
.form-group >>> input{
    border-radius: 3px;
    font-size: 12px;
}
#vs2__combobox >>> .vs__dropdown-toggle{
    border-radius: 3px;
    font-size: 12px;
}
.v-select >>> .vs__selected{
    margin: 0 !important;
    margin-right: 5px !important;
    font-size: 12px;
    line-height: 20px;
}
.v-select >>> .vs__dropdown-toggle{
    height: 100%;
}
</style>