<template>
    <div>
        <div>
            <BlueTitleBar
                title="IDENTITATE VIZUALĂ"
            ></BlueTitleBar>
        </div>
        <div class="flexItems">
            <div>
                <Fisier title="Comunicatul tău de presă">
                    <template v-slot:body>
                        <p>
                            Comunicatul de presă este întocmit în conformitate cu art. 6.12. din Procedura
                            de implementare a măsurii “Granturi pentru capital de lucru acordate
                            IMM-urilor”, cu modelul de comunicat prezentat pe imm.gov.ro si cu Manualul de
                            identitate vizuală FSE. Atașat poți vizualiza sau descărca comunicatul tău.
                        </p>
                        <p style="margin-top: 20px">
                            Comunicatul de presă este publicat pe
                            <a href="" style="color: #5a43d1">www.smis.ro</a> si
                            <a href="" style="color: #5a43d1">www.comunicate-proiecte.ro</a>
                        </p>
                    </template>
                    <template v-slot:buttons>
                        <div class="buton-margin">
                            <ButtonGradient
                                url-buton="dadsdsss"
                                text="Vezi comunicatul publicat"
                                classname="idenviz"
                            ></ButtonGradient>
                        </div>
                        <div class="buton-margin">
                            <ButtonGradient
                                url-buton="dadsdsss"
                                text="Descarcă comunicatul PDF"
                                classname="idenviz"
                            ></ButtonGradient>
                        </div>
                        <div class="buton-margin">
                            <ButtonGradient
                                url-buton="dadsdsss"
                                text="Obligații identitate vizuală"
                                classname="empty"
                            ></ButtonGradient>
                        </div>
                    </template>
                    <template v-slot:poza>
                        <img
                            class="img-padding"
                            alt="Comunicatul_Tau_de_Presa"
                            src="@/assets/images/Comunicatul_Tau_de_Presa.png"
                        >
                    </template>
                </Fisier>
            </div>
            <div>
                <Fisier title="Microsite de prezentare">
                    <template v-slot:body>
                        <p>
                            Ți-am pregătit un microsite de prezentare al grantului obținut. Astfel poți
                            trimite adresa site-ului web furnizorilor, partenerilor sau il poți distribui
                            pe rețelele sociale.
                        </p>
                        <p style="margin-top: 20px">
                            Link-ul catre site-ul de prezentare pentru proiectul tău este:
                        </p>
                        <p style="font-weight: bold; color: #5f5f5f">
                            https://formare-profesionala-plus.sites.smis.ro/
                        </p>
                    </template>
                    <template v-slot:buttons>
                        <div class="buton-margin">
                            <ButtonGradient
                                url-buton="dadsdsss"
                                text="Vezi microsite-ul tău"
                                classname="idenviz"
                            ></ButtonGradient>
                        </div>
                        <div class="buton-margin">
                            <ButtonGradient
                                url-buton="dadsdsss"
                                text="Obligații identitate vizuală"
                                classname="empty"
                            ></ButtonGradient>
                        </div>
                    </template>
                    <template v-slot:poza>
                        <img
                            alt="Microsite_de_Prezentare"
                            src="@/assets/images/Microsite_de_Prezentare.png"
                        >
                    </template>
                </Fisier>
            </div>
            <div>
                <Fisier title="Etichete de inventar">
                    <template v-slot:body>
                        <p>
                            Pentru informarea publicului cu privire la faptul că mijloacele fixe au fost
                            achiziționate în cadrul unui proiect cofinanțat de UE, vor fi folosite
                            autocolante sau plăcuțe. Prin mijloace fixe se înțeleg acele obiecte care,
                            conform legislației contabile naționale depășesc valoarea de 2.500 lei și au o
                            durată de viață mai mare de 1 an. Acestea vor fi plasate pe partea cea mai
                            vizibilă pentru public a mijloacelor fixe. Dimensiunea minima recomandată este
                            100 x100 mm.
                        </p>
                    </template>
                    <template v-slot:buttons>
                        <div class="buton-margin">
                            <ButtonGradient
                                url-buton="dadsdsss"
                                text="Descarcă etichetă 10x10 cm"
                                classname="idenviz"
                            ></ButtonGradient>
                        </div>
                        <div class="buton-margin">
                            <ButtonGradient
                                url-buton="dadsdsss"
                                text="Descarcă etichetă 20x20 cm"
                                classname="idenviz"
                            ></ButtonGradient>
                        </div>
                        <div class="buton-margin">
                            <ButtonGradient
                                url-buton="dadsdsss"
                                text="Obligații identitate vizuală"
                                classname="empty"
                            ></ButtonGradient>
                        </div>
                    </template>
                    <template v-slot:poza>
                        <img
                            alt="Etichete_de_Inventar"
                            src="@/assets/images/Etichete_de_Inventar.png"
                        >
                    </template>
                </Fisier>
            </div>
            <div>
                <Fisier title="Afișul proiectului - A3">
                    <template v-slot:body>
                        <p>
                            În cazul operațiunilor care nu depășesc 500.000 euro, se va expune public,
                            într-un loc de vizibilitate dar asociat operațiunii, cum ar fi zona de intrare
                            a unei clădiri, cel puțin un afiș cu informații, de dimensiune minimă A3, cu
                            respectarea cerințelor minime prevăzute pentru panoul de afișare temporară
                            inclusiv despre contribuția din partea Uniunii Europene.
                        </p>
                    </template>
                    <template v-slot:buttons>
                        <div class="buton-margin">
                            <ButtonGradient
                                url-buton="dadsdsss"
                                text="Descarcă afișul A3"
                                classname="idenviz"
                            ></ButtonGradient>
                        </div>
                        <div class="buton-margin">
                            <ButtonGradient
                                url-buton="dadsdsss"
                                text="Obligații identitate vizuală"
                                classname="empty"
                            ></ButtonGradient>
                        </div>
                    </template>
                    <template v-slot:poza>
                        <img
                            class="img-padding"
                            alt="Afisul_Proiectului_A3"
                            src="@/assets/images/Afisul_Proiectului_A3.png"
                        >
                    </template>
                </Fisier>
            </div>
            <div class="box-without-buttons">
                <Fisier title="Banner pentru site web">
                    <template v-slot:body>
                        <p>
                            În cazul  în care beneficiarul are un site propriu, acesta trebuie să afișeze o
                            scurtă descriere a proiectului, care să includă: numele proiectului, scopul și
                            obiectivele proiectului, rezultatele preconizate, valoarea totală a acestuia,
                            cofinanțare, etc. <span style="font-weight:bold"> Copiază embed-ul de mai jos și inserează-l pe site-ul tău.</span>
                        </p>
                        <textarea>asdasdasdsd</textarea>
                    </template>

                    <template v-slot:poza>
                        <img
                            alt="Banner_pentru_site_web"
                            src="@/assets/images/Etichete_de_Inventar.png"
                        >
                    </template>
                </Fisier>
            </div>
        </div>
    </div>
</template>

<script>
    import ButtonGradient from "@/common/components/ButtonGradient";
    import Fisier from "./components/Fisier";
    import BlueTitleBar from "@/common/components/BlueTitleBar";
    export default {
        components: {
            ButtonGradient,
            Fisier,
            BlueTitleBar,
        },
        props: {},
        data() {
            return {};
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
	.flexItems{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
		grid-gap: 20px;
		padding: 20px;
        margin-bottom: 50px;
	}
	.buton-margin {
		margin-bottom: 10px;
		min-width: 200px;
	}
	.img-padding {
		padding: 20px;
		min-width: 50px;
	}
	textarea {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		height: 35%;
		width: 100%;
		border-radius: 3px;
	}
        @media(max-width:540px){
            .flexItems{
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            }
        }
</style>
