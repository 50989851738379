<template>
    <div class="modul-finantare-title item-c">
        <span class="bold-text title">Beneficiari eligibili</span>
        <b-icon-check-circle-fill
                style="margin-left: 5px; fill: var(--succesColor);"
                font-scale="1"
        ></b-icon-check-circle-fill>

        <div class="modul-finantare-content fixedHeight">
            <ul class="lista-firme-beneficii" :class="data.beneficiaries.length > 8 ? 'twoColumns' : ''">
                <li v-for="(beneficiar, key) in data.beneficiaries" :key="key">{{ beneficiar }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {BIconCheckCircleFill} from 'bootstrap-vue';
    export default{
        components: {
            BIconCheckCircleFill,
        },
        props: {
            data: null,
        },
        data() {
            return {}
        },
        methods: {},
    }
</script>

<style scoped>
    .fixedHeight{
        height: 163px;
        overflow-y: auto;
    }
    .title {
        font-size: var(--moduleTitleSize);
    }

    .item-c {
        grid-area: beneficii;
    }

    .danger {
        color: #dc3545 !important;
    }

    .warning {
        color: #ffc107 !important;
    }

    .bold-text {
        font-weight: bold;
    }

    .modul-finantare-content {
        margin-top: 10px;
        color: #868686;
        font-size: 14px;
    }

    .companii-eligibile {
        display: flex;
        align-self: center;
    }

    .nr-companii-eligibile {
        color: #00c9a1;
        font-size: 45px;
        margin: 0 30px;
    }

    .text-companii-eligibile {
        color: #868686;
        font-size: var(--paragraphSize);
    }

    .lista-firme-beneficii {
        list-style-type: disc;
        margin-left: 18px;
        font-weight: 500;
        font-size: var(--paragraphSize);
    }

    .lista-firme-beneficii.twoColumns {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }

    @media only screen and (max-width: 1250px) {
        .item-c {
            grid-area: beneficii;
        }

        .item-c .nr-companii-eligibile {
            margin: 0 5px;
        }

        .item-c .lista-firme-beneficii {
            columns: 1;
        }

        .item-c p {
            text-align: initial;
        }

        .modul-finantare-content {
            flex-direction: column-reverse;
        }

        .lista-firme-beneficii {
            border: none;
        }

        .companii-eligibile {
            margin-bottom: 10px;
        }
    }

    @media only screen and (max-width: 710px) {
        .item-n .modul-finantare-content {
            display: initial;
        }
    }
</style>