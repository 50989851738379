<template>
    <div v-if="!loading">
        <div>
            <GrayTitleBar :data="program.name"></GrayTitleBar>
            <!--<FinantareSingleTitleBackground :data="head"></FinantareSingleTitleBackground>-->
        </div>
        <div>
            <FinantareSingleContentBox :data="program"></FinantareSingleContentBox>
        </div>
    </div>
    <LoadingSpinner v-else></LoadingSpinner>
</template>

<script>
    import LoadingSpinner from '@/common/components/LoadingSpinner'
    import FinantareSingleContentBox from "./components/FinantareSingleContentBox";
    import GrayTitleBar from "@/common/components/GrayTitleBar";
    import FinantareSingleTitleBackground from "./components/FinantareSingleTitleBackground";
    import api from '@/helpers/api'
    import axios from '@/helpers/axios'
    export default {
        components: {
            FinantareSingleContentBox,
            FinantareSingleTitleBackground,
            GrayTitleBar,
            LoadingSpinner,
        },
        props: {},
        data() {
            return {
                loading: true,
                title: "PROGRAM OPERAȚIONAL",
                program: [],
                head: {},
            };
        },
        mounted () {
            this.getProgram();
        },
        watch: {
            $route(to) {
                this.getProgram(to.params.id)
            }
        },
        methods: {
            getProgram(id) {
                let slug
                if(!id) {
                    slug = this.$route.params.id;
                } else {
                    slug = id
                }
                return axios.get(api.API_ENDPOINTS.program + '/' + slug)
                    .then(response => {
                        let data = response.data
                        this.program = data
                        this.head = {
                            name: response.data.name,
                            type: response.data.type,
                            banner: response.data.banner,
                            acronym: response.data.acronym,
                            budget: response.data.total_budget,
                            main: response.data.main_program,
                        }
                    }).finally(() => this.loading = false)
            }
        },
    };
</script>

<style scoped>
    .text-eligibilitate {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #000;
        line-height: 0.1em;
        margin: 10px 20px 20px;

    }

    .text-eligibilitate span {
        background-color: #f0f0f0;
        padding: 0 10px;
    }
</style>
