<template>
    <div :click="$emit('click')">
        <label class="switch">
            <input type="checkbox" @click="click">
            <span class="slider round"></span>
        </label>
    </div>
</template>

<script>
    export default {
        name: "Test",
        props: {
            click: {
                type: Function,
                default:function(){}
            },
        },
        data() {
            return {};
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: .4s;
  border: 2px solid #04c3a2;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: #04c3a2;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #04c3a2;
}
input:focus + .slider {
  box-shadow: 0 0 1px #04c3a2;
}
input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
  background-color: #fff;
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}
.slider.round:before {
  border-radius: 50%;
}
</style>