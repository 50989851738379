<template>
    <div>
        <div id="tooltip-target-1" class="tags" @click="copyTag(tagnum)">
            <p><span class="smis">SMIS</span>tag</p>
            <p class="tag">#{{ tagnum }}</p>
        </div>
        <b-tooltip target="tooltip-target-1" triggers="hover">
            Apasă pentru a copia tag-ul.
        </b-tooltip>
    </div>
</template>

<script>
    import {BTooltip} from 'bootstrap-vue';
    export default {
        components: {
            BTooltip
        },
        props: {
            tagnum: {
                type: String,
                default: "92435G",
            },
        },
        data() {
            return {};
        },
        created() {},
        methods: {
            copyTag(tag){
                navigator.clipboard.writeText(tag)
                this.$notify('SMIStag copiat cu succes!');
            }
        },
    };
</script>

<style scoped>
.smis {
    font-weight: 800;
    color: #212121;
}
.tag {
    color: #4c71d3;
    font-weight: 600;
}
.tags{
    padding-top:1rem;
    margin-right: 1.25rem;
    cursor: pointer;
}
.tags p{
  line-height: 15px;
}
.b-tooltip >>> .tooltip-inner{
    max-width: 200px;
    padding: 4px 10px;
    text-align: center;
    background: #eef2f9;
    color: #676d86;
    border-radius: 0.25rem;
}
.b-tooltip >>> .arrow:before{
    border-width: 0 0.3rem 0.3rem;
    border-bottom-color: #eef2f9;
}
@media only screen and (max-width: 1510px) {
    p{
        font-size: 14px;
    }
    .tag {
        font-size: 13px
    }
}
</style>
