<!-- eslint-disable -->
<template>
    <div>
        <div class="formular-contul-meu">
            <div class="tip-user">
                <p class="text-modul">Salut! Ești utilizator</p>
                <b-img
                    class="image"
                    :src="$store.state.auth.user.account_type.logo"
                ></b-img>
            </div>
            <div>
                <p class="text-modul">Servicii active <span class="text-bold-36">{{total}}</span></p>
            </div>
            <div>
                <p class="text-modul">Trial<span class="text-bold-36">{{ trial }}</span></p>
            </div>
            <div>
                <p class="text-modul">
                    Licențe de utilizare<span class="text-bold-36 text-color">{{ licenta }}</span>
                </p>
            </div>

            <div class="icon-question" @click="showInfoPopup">
                <b-icon-question icon="question" class="text-modul" scale="3"></b-icon-question>
            </div>
        </div>
         <div>
            <InfoPopup v-show="isInfoPopupVisible" @close="closeInfoPopup">
                <template v-slot:header>
                    <div class="mb-3"></div>
                </template>
                <template v-slot:body>
                  <FinanciarModalInfoContent :tipCont="tipCont" v-if="!loading"></FinanciarModalInfoContent>
                  <LoadingSpinner v-else></LoadingSpinner>
                </template>
            </InfoPopup>
        </div>
    </div>
</template>

<script>
    import FinanciarModalInfoContent from "../../ContulMeu/components/FinanciarModalInfoContent";
    import InfoPopup from "@/common/components/InfoPopup";
    import LoadingSpinner from '@/common/components/LoadingSpinner'
    import {BImg,BIconQuestion} from 'bootstrap-vue'
    import api from '@/helpers/api';
    import axios from '@/helpers/axios';
    export default {
        components: {
            FinanciarModalInfoContent,
            InfoPopup,
            BImg,
            BIconQuestion,
            LoadingSpinner
        },
        props: {
          total: null,
          trial: null,
          licenta: null,
        },
        data() {
            return {
                isInfoPopupVisible: false,
                loading: true,
                tipCont: null
            };
        },
        created() {},
        methods: {
            showInfoPopup() {
                this.isInfoPopupVisible = true;
                return  axios.get(api.API_ENDPOINTS.userAccountTypes)
                    .then(response => {
                      this.tipCont = response.data
                    }).finally(() => this.loading = false)
            },
            closeInfoPopup() {
                this.isInfoPopupVisible = false;
            },
        },
    };
</script>
<style scoped>
	.formular-contul-meu {
		background-color: #f8f8f8;
		padding: 10px 0 10px 20px;
		border: 1px solid #e7e7e7;
		border-radius: 4px;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(210px, auto));
		grid-gap: 30px;
		justify-content: space-between;
	}
	.text-modul {
		font-size: 16px;
		color: #a5a5a5;
		align-self: center;
		text-align: left;
	}
	.text-bold-36 {
		font-size: 36px;
		margin-left: 10px;
		font-weight: bold;
	}
	.text-color {
		background: -webkit-linear-gradient(#593dcf, #7fccdf);
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.image {
		max-width: 230px;
		height: 45px;
		margin: 0 20px;
	}
	.icon-question {
		border-left: 1px solid #e0e0e0;
		display: flex;
		padding: 0 20px;
		cursor: pointer;
		justify-self: center;
		margin-left: auto;
	}
	.tip-user {
		display: flex;
	}

	@media only screen and (max-width: 1500px) {
		.formular-contul-meu {
			padding: 20px 0 40px 20px;
		}
		.formular-contul-meu div {
			justify-self: center;
		}
		.icon-question {
			border-left: 0;
			margin-left: 0;
		}
	}
	@media only screen and (max-width: 700px) {
		.tip-user {
			flex-direction: column;
		}
        .tip-user p{
			margin-bottom: 10px;
		}
	}
  
    
    @media (max-width:1024px) and (min-width:769px){
        .formular-contul-meu{
            grid-template-columns: repeat(auto-fit, minmax(80px, auto));
        }
    }
      @media (max-width:768px) and (min-width:426px){
        .formular-contul-meu{
            grid-template-columns: repeat(auto-fit, minmax(154px, auto));
            grid-template-rows: 39px 30px;
            grid-gap: 16px;
        }
    }
</style>