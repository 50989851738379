<template>
  <div class="modul-finantare-title">
    <div class="module-header">
      <span class="bold-text gradient-title">Ofertă specială</span>
      <img alt="Vue logo" :src="logo">
    </div>
    <div class="module-title" v-html="data"></div>


  </div>
</template>

<script>

export default {
    name: "OfertaSpeciala",
    components: {
    },
    props: {
        data: null,
        logo: null,
    }
}
</script>

<style scoped>
.bold-text {
  font-weight: bold;
}
.gradient-title{
    font-size: 14px;
  color: #1c1c1c;
}
.module-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.module-title{
  text-align: right;
  font-size: 18px;
  color: #1a1a1c;
  margin-top: 20px;
  font-weight: 700;
}
.module-title >>> p{
    font-weight: 400;
    text-align: left;
}
img{
    max-height: 50px;
}
</style>