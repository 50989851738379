<template>
    <div id="chenarGradient">
        <div class="flexClass info-platforma">
            <img src="@/assets/images/evalueaza-consultant.png" class="mr-4">
            <p><strong>Apelează la consultanți specializați în scrierea și implementarea de proiecte cu finanțare nerambursabilă</strong><br>
                Atât SMIS.ro cât și oricare dintre angajații săi nu oferă niciun fel de garanție și nu are nicio răspundere, implicită sau expresă, referitoare la consultamții contactați prin intermediul platformei SMIS.</p>
        </div>
    </div>
</template>

<script>
    export default{
        props: {
            count: {
                type: Number,
                default: 0
            },
        }
    }
</script>

<style scoped>
h1,
p{
  color:#fff;
}
h1,
p{
  margin-bottom: 0;
}
h1{
  font-size: 20px;
  margin-bottom: 0;
}
#chenarGradient{
  width:100%;
  background: linear-gradient(180deg, #3456a7, #2c92c4, #30c7b2);
  padding: 35px 30px;
  box-sizing: border-box;
}
.flexClass{
  display: flex;
  align-items: flex-end;
}
.middleAlign p{
  line-height: 13px;
}
.poza-finantari-container svg {
  font-size: 35px;
  color: #fff;
}
.poza-finantarii img{
  object-fit: contain;
}
@media (max-width:768px){
  .info-platforma p{
    text-align: left;
  }
  .middleAlign p{
    text-align: left;
  }
}
@media(max-width:500px){
  .poza-finantari-container img {
    display: block;
    width:280px;

  }
  .info-platforma{
    display: block;
  }

}

@media only screen and (max-width: 1250px) {

}
</style>