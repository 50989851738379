<template>
    <div id="ViewList">
        <b-table
                v-if="data && data.length > 0"
                :key="data.id"
                striped
                hover
                :items="data"
                :fields="tHead"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-compare-options="{ numeric: true, sensitivity: 'base' }"
        >
            <template v-slot:cell(finantator)="data">
                <img :alt="data.item.program.type.title" class="tip-finantare-logo" v-if="data.item.program.type"
                     :src="data.item.program.type.logo.path">
            </template>
            <template v-slot:cell(name)="data">
                <router-link :to="'/finantare/'+data.item.id" :title="data.item.name">{{ limitWords(data.item.name, 30)
                    }}
                </router-link>
            </template>
            <template v-slot:cell(min_budget)="data">
                <router-link :to="'/finantare/'+data.item.id">
                    <span class="totalBudget">{{ data.item.min_budget.toLocaleString('ro-RO') }} € <span
                            v-if="data.item.max_budget > 0"
                    >- {{ data.item.max_budget.toLocaleString('ro-RO')
                        }} €</span></span>
                </router-link>
            </template>
            <template v-slot:cell(status)="data">
                <router-link :to="'/finantare/'+data.item.id">
                    <span v-if="data.item.status === 'pending'" class="table-backgroundGry">Apel în consultare</span>
                    <span v-else-if="data.item.status === 'closed'" class="table-backgroundRed">Apel inactiv</span>
                    <span v-else-if="data.item.status === 'opened'" class="table-backgroundGreen">Apel deschis!</span>
                </router-link>
            </template>
            <template v-slot:cell(start_date)="data">
                <router-link :to="'/finantare/'+data.item.id">
                    <span class="totalBudget">{{ formatUtcDate(data.item.start_date)
                        }} - {{ formatUtcDate(data.item.end_date) }}</span>
                </router-link>
            </template>
            <template v-slot:cell(program)="data">
                <router-link :to="'/program/'+data.item.program.id">
                    <img :alt="data.item.program.name" :title="data.item.program.name" v-if="data.item.program.logo"
                         :src="data.item.program.logo.path" class="imagine-program">
                </router-link>
            </template>
            <template v-slot:cell(total_budget)="data">
                <router-link :to="'/finantare/'+data.item.id">
                    <span class="totalBudget">{{ data.item.total_budget.toLocaleString('ro-RO') }}</span>
                </router-link>
            </template>
            <template v-slot:cell(projects)="data">
                <router-link :to="'/finantare/'+data.item.id">
                    {{ data.item.projects }}
                </router-link>
            </template>
            <template v-slot:cell(count_eligibilities)="data">
                <router-link v-if="data.item.count_eligibilities === null || data.item.count_eligibilities === false"
                             :to="'/finantare/'+data.item.id"
                >
                    <span v-if="data.item.count_eligibilities === null" class="table-companiiEligibileNa">N/A</span>
                    <span v-else-if="data.item.count_eligibilities === false"
                          class="table-companiiEligibileMinus"
                    >-</span>
                </router-link>
                <router-link v-else :to="{ path: '/finantare/' + data.item.id, hash: '#companii-eligibile' }">
                    <span class="table-companiiEligibileGreen">{{ data.item.count_eligibilities }}</span>
                </router-link>
            </template>
            <template v-slot:cell(favorite)="data">
                <HeartFavorite
                        :id="data.item.id"
                        class="favoriteIconList"
                        :isFavorite="data.item.wishlisted"
                        @getId="getId(data.item.id)"
                ></HeartFavorite>
            </template>
        </b-table>

    </div>
</template>

<script>
    import HeartFavorite from '@/common/components/HeartFavorite'
    import {BTable} from 'bootstrap-vue'
    import {formatUtcDate, limitWords} from '@/helpers/helpers'
    export default {
        components: {
            HeartFavorite,
            BTable
        },
        props: {
            data: Array,
        },
        data() {
            return {
                currentPage: 1,
                sortBy: 'count_eligibilities',
                sortDesc: true,
                tHead: [
                    {
                        "key": "finantator",
                        "label": "Finanțator",
                        "sortable": true
                    },
                    {
                        "key": "name",
                        "label": "Apel de proiecte",
                        "sortable": false,
                        tdClass: "w-35 text-left"
                    },
                    {
                        "key": "min_budget",
                        "label": "Valoare proiect",
                        "sortable": true,
                        tdClass: "w-15"
                    },
                    {
                        "key": "status",
                        "label": "Status apel",
                        "sortable": true
                    },
                    {
                        "key": "start_date",
                        "label": "Calendar",
                        formatter: (value, key, item) => {
                            return this.formatUtcDate(item.start_date)
                        },
                        sortable: true,
                        sortByFormatted: true,
                        filterByFormatted: true,
                        tdClass: "w-12"
                    },
                    {
                        "key": "program",
                        "label": "Program",
                        "sortable": true
                    },
                    {
                        "key": "total_budget",
                        "label": "Alocare totală",
                        "sortable": true,
                        tdClass: "w-8"
                    },
                    {
                        key: "projects",
                        label: "Proiecte finanțate",
                        sortable: true
                    },
                    {
                        "key": "count_eligibilities",
                        "label": "Companii eligibile",
                        "sortable": true
                    },
                    {
                        "key": "favorite",
                        "label": "Favorite",
                        "sortable": true
                    }
                ],

            };
        },
        methods: {
            formatUtcDate,
            limitWords,
            getId(id){
                this.$emit('getId', id);
            },
        }
    };
</script>

<style>
    a {
        color: initial;
    }

    a:hover {
        color: initial;
        text-decoration: initial
    }
    .w-8 {
        width: 8%
    }
    .w-12 {
        width: 12%
    }

    .w-15 {
        width: 15%
    }
    .w-35 {
        width: 35%
    }

    .tip-finantare-logo {
        max-height: 20px;
    }

    #ViewList {
        padding: 20px 20px 10px;
        box-sizing: border-box;
        background: #f0f0f0;
        overflow: scroll;
    }

    img.imagine-program {
        max-height: 25px;
    }

    .totalBudget {
        font-weight: 600;
        color: #717171;
    }

    #ViewList .table {
        background: #fff;
        border: 1px solid #e9e9e9;
        font-size: 12px;
    }

    #ViewList .table > tr > th {
        border-bottom-color: #f9fafa !important;
    }

    #ViewList .table-apelDeProiecte {
        width: 22%;
        text-align: left;
    }

    #ViewList .table-backgroundGry,
    #ViewList .table-backgroundGreen,
    #ViewList .table-backgroundRed {
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .favoriteIconList .b-icon {
        color: #8b8b8b !important;
    }

    #ViewList .table-backgroundGry,
    #ViewList .table-backgroundGreen,
    #ViewList .table-backgroundRed {
        padding: 1px 5px;
        color: #fff;
        margin: 5px;
        font-weight: 700;
        font-size: 11px;
    }

    #ViewList .table-backgroundGry {
        background: #969696;
    }

    #ViewList .table-backgroundGreen {
        background: #08c7a2;
    }

    #ViewList .table-backgroundRed {
        background: #ff6666;
    }

    #ViewList .table {
        text-align: center;
    }

    #ViewList .table-bold {
        font-weight: bold;
    }

    #ViewList .table-companiiEligibileNa {
        background: #b5b5b5;
        text-align: center;
        vertical-align: middle;
        border-radius: 50%;
        color: #fff;
        padding: 4px 2px;
    }

    #ViewList .table-companiiEligibileMinus {
        background: #676767;
        text-align: center;
        vertical-align: middle;
        border-radius: 50%;
        color: #fff;
        padding: 4px 9px;
        font-weight: bold;
    }

    #ViewList .table-companiiEligibileGreen {
        background: #08c7a2;
        text-align: center;
        vertical-align: middle;
        border-radius: 50%;
        color: #fff;
        padding: 4px 9px;
        font-weight: bold;
    }

    #ViewList .bi-heart,
    #ViewList .bi-heart-fill,
    #ViewList .bi-box-arrow-up-right {
        font-size: 20px;
    }

    #ViewList .table th,
    #ViewList .table td {
        vertical-align: middle;
    }

    #ViewList .table-striped tbody tr:nth-of-type(odd) {
        background-color: #f9f9f9;
    }

    #ViewList td {
        border-top: 1px solid #f3f4f4 !important;
    }

    #ViewList .table thead th {
        border-bottom: 2px solid #f9fafa !important;
        color: #292929;
        position: relative;
        font-size: var(--paragraphSize);
    }

    .page-link:focus {
        box-shadow: none;
    }

    .page-link {
        color: #0cc2aa;
    }

    .page-item.active .page-link {
        background-color: #0cc2aa !important;
        border-color: #0cc2aa !important;
    }

    .table td, .table th {
        padding: .5em .75rem;
    }

    @media only screen and (min-width: 1300px) {
        #ViewList {
            overflow: hidden;
        }
    }
</style>