<template>
    <span class="apelareConsultant">
        <span class="bold-text">Apelează la un consultant</span>
        <div>
            <p class="bold-text">Goodwill consulting</p>
            <b-form-rating
                id="rating-lg-no-border"
                v-model="review.stars"
                disabled
                color="#ffbe00"
                no-border
                size="sm"
            ></b-form-rating>
        </div>
        <div class="top-line">
            <img src="@/assets/images/CompanieVerificata.png" alt="" >
            <img src="@/assets/images/LogoGoodwillConsulting.png" alt="" >
        </div>
        <a href="//gwconsulting.ro">www.gwconsulting.ro</a>
    </span>
</template>
<script>
    import {BFormRating} from 'bootstrap-vue'
    export default {
        components:{
            BFormRating
        },
        props: {},
        data() {
            return {
                review: {
                    stars: 5,
                }
            };
        }
    };
</script>
<style scoped>
#rating-lg-no-border{
    width: 100%;
    padding: 0;
}
.b-rating >>> .b-rating-star, .b-rating .b-rating-value {
    padding: 0 3px 0 0;
    flex-grow: 0 !important;
}
.b-rating {
     background-color: #f8f8f8;
}
.b-rating.disabled, .b-rating:disabled {
     color: #ffbe00;
     width: 100px;
}
.top-line {
     display: flex;
     justify-content: space-between;
     flex-direction: inherit !important;
}
a {
     font-size: 14px;
}
.bold-text {
     font-weight: bold;
      font-size: var(--moduleTitleSize);
     color: #434343;
}
.apelareConsultant a{
  font-size: var(--paragraphSize);
}

@media only screen and (max-width: 410px) {
    .bold-text{
        font-size: 14px;
        margin-right: 0;
        text-align: center;
    }
}
</style>