<template>
    <div class="padding-box">
        <div class="card-contact">
            <span class="title">Această secțiune vă permite să ne trimiteți mesaje. Vă vom răspunde în cel mai scurt timp.</span>
            <validation-observer ref="loginForm" #default="{ invalid }">
                <form @submit="submit" class="row pt-4">
                    <div class="col-md-4">
                        <b-form-group
                            label="Nume și prenume"
                            label-for="fullName"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                            >
                                <b-form-input
                                    id="fullName"
                                    v-model="form.fullName"
                                    :state="errors.length > 0 ? false : null"
                                    name="fullName"
                                    type="text"
                                    placeholder="Nume și prenume"
                                ></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div class="col-md-4">
                        <b-form-group
                            label="Email"
                            label-for="email"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required|email"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="form.email"
                                    name="email"
                                    :state="errors.length > 0 ? false : null"
                                    type="email"
                                    placeholder="Email"
                                ></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div class="col-md-4">
                        <b-form-group
                            label="Telefon"
                            label-for="phone"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                            >
                                <b-form-input
                                    id="phone"
                                    v-model="form.phone"
                                    type="tel"
                                    name="phone"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Telefon"
                                ></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div class="col-md-12">
                        <b-form-group
                            label="Mesaj"
                            label-for="message"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required|min:50"
                                name="mesaj"
                                min="50"
                            >
                                <b-form-textarea
                                    id="message"
                                    v-model="form.message"
                                    type="text"
                                    name="message"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Mesaj"
                                    rows="5"
                                ></b-form-textarea>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div class="col-md-4">
                        <b-button
                            type="submit"
                            class="background"
                            url-buton="dadsdsss"
                            text="Trimite mesajul"
                            classname="background"
                            :disabled="invalid"
                        >Trimite</b-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
require('@/helpers/validations/validations')
import {BButton, BFormInput, BFormTextarea, BFormGroup, BForm} from 'bootstrap-vue'
import ButtonGradient from '@/common/components/ButtonGradient'

export default {

    components: {
        ValidationProvider,
        ValidationObserver,
        BButton,
        BFormInput,
        BFormTextarea,
        BFormGroup,
        BForm,
        ButtonGradient,
    },
    data() {
        return {
            form: {
                fullName: '',
                email: '',
                phone: '',
                message: ''
            },
            showForm: true
        }
    },
    mounted() {
        this.form.fullName = this.$store.state.auth.user.name
        this.form.email = this.$store.state.auth.user.email
        this.form.phone = this.$store.state.auth.user.phone
    },
    methods: {
        submit(e){
            e.preventDefault();
            console.log('aici');
        }
    },
}
</script>

<style scoped>
.padding-box{
    padding: 20px;
}
.card-contact{
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(34,41,47,.125);
    border-radius: .428rem;
    padding: 20px;
}
.title{
    font-size: 19px;
    font-weight: 500;
}
.form-group >>> label{
    font-size: 14px;
    margin: 0;
}
.form-group >>> input{
    font-size: 14px;
    margin-top: 5px;
}
.form-group >>> textarea{
    font-size: 14px;
    margin-top: 5px;
}
.background {
    background: linear-gradient(180deg, #5b3cd0, #3bc6d4);
    color: #fff;
    border: 0;
    width: fit-content;
    min-width: 110px;
    display: block;
    border-radius: 3px;
    padding: 4.5px 15px;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    font-size: 14px;
}
.background:hover {
    background: linear-gradient(180deg, #5b3cd0, #3bc6d4);
}
</style>