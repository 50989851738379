<template>
    <div id="filters" class="flexClass spaceBetween">
        <div>
            <div>
                <v-select v-model="selected.Company" placeholder="Beneficiar" class="select-company" label="name" :options="getCompanies()" @input="getCompanyId()"></v-select>
            </div>
        </div>
    </div>
</template>

<script>

    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {          
        components: {
            vSelect
        },   
        props: {
            ststusView: Boolean,
            companii: null,
        },
             
        data() {
            return {
                selected: {
                    Company: '',

                },
                cautaCompanie: [
                    { value: '', text: 'Alege companie de consultanță' },
                    { value: '1', text: 'Județ2' },
                ],
            };
        },
        methods: {
            getCompanies() {
                return this.companii.map(function (companie) {
                    return {
                        'name': companie.name,
                        'id': companie.customer_id,
                    }
                })
            },
            getCompanyId() {
                this.$emit('getCompanyId', this.selected.Company.id);
            }

        }
    };
</script>

<style scoped>

#filters select{
    color: #a1a1a1;
    margin-bottom: 5px;
}
#filters label, #filters p{
    color: #696969;
    line-height: 20px;
}
#filters label{
    margin: 5px 0;
}
#filters .flexClass{
	display: flex;
}
#filters .flexClass div{
	flex-direction: row;
}
#filters.spaceBetween{
	justify-content: space-between;
}
#filters{
    padding: 10px 30px 5px 30px;
    box-sizing: border-box; 
    background: #fff;
    display: flex;
    align-items: center;
}
#filters .flexClass div
{
    line-height: 45px;
}
#filters label, #filters *{
    font-size: 12px !important;
}
.select-company{
  width: 370px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border-radius: 0.25rem;
}
.vs__dropdown-toggle{
  border-radius: 0.25rem !important;
  margin-top: 7px;
}
svg.vs__open-indicator{
  fill: #343a40;
}
button.vs__clear{
  fill: #343a40;
  transform: scale(0.9);
}
.vs__open-indicator{
  transform: scale(0.7);
}
.vs__close-indicator{
  transform: scale(0.7);
}
.vs--open .vs__open-indicator{
  transform: rotate(180deg) scale(0.7);
}
.vs__actions{
  padding: 0;
}
@media only screen and (max-width: 1440px) {
    #filters .custom-select{
        width: 100% !important;
    } 
}
@media(max-width:500px){
    #filters{
        display: block;
    }
}
@media (max-width: 900px){
    .select-company{
        width: 100% !important;
    }
}
</style>
