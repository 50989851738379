<template>
    <div>
        <div v-if="!loading">
            <div>
                <GreenTitleBar
                        :breadcrumb="breadcrumb.breadcrumb"
                        :data="title"
                        :program="slugProgram"
                ></GreenTitleBar>
            </div>
            <div>
                <FinantareSingleTitleBackground :data="singleTitleBackground"
                                                :breadcrumb="breadcrumb.breadcrumb"></FinantareSingleTitleBackground>
            </div>
            <div class="box-padding">
                <div>
                    <div>
                        <StatusFinantare :data="statusFinantare" class="mb-3"></StatusFinantare>
                        <div class="flexItems" :scroll-to="{ el: 'companii-eligibile' }">
                            <DescriereaFinantarii :data="descriereaFinantarii"></DescriereaFinantarii>
                            <div class="modul-finantare-title item-b item-right">
                                <FinantareSingleRegiuni :data="supported_regions"></FinantareSingleRegiuni>
                            </div>
                            <BeneficiariEligibili :data="beneficiariEligibili"></BeneficiariEligibili>
                            <div>
                                <TipAplicant :data="tipAplicanti"></TipAplicant>
                                <NrCompaniiEligibile :data="tipAplicanti"></NrCompaniiEligibile>
                            </div>
                            <ActivitatiEligibile :data="activitatiEligibile"></ActivitatiEligibile>
                            <ContributieProprie :data="contributieProprie"></ContributieProprie>
                            <div class="modul-finantare-title item-g">
                                <ScriereProiect></ScriereProiect>
                            </div>
                            <div class="modul-finantare-title item-h">
                                <ApelareConsultant></ApelareConsultant>
                            </div>
                            <CheltuieliEligibile :data="cheltuieliEligibileNeeligibile"></CheltuieliEligibile>
                            <div class="modul-finantare-title item-j">
                                <DepunereProiect :data="undeDepun"></DepunereProiect>
                            </div>
                            <NumarAproxProiecte :data="proiecteFinantate"></NumarAproxProiecte>
                            <DocumenteleFinantarii :data="documenteDescarcabile"></DocumenteleFinantarii>
                            <div class="modul-finantare-title item-m">
                                <DespreFinantare :data="despreFinantare"></DespreFinantare>
                            </div>
                            <SursaFinantarii :data="programTree" :sursa="sursaFinantarii"></SursaFinantarii>
                        </div>
                    </div>
                </div>
                <div id="companii-eligibile">
                    <div>
                        <h2 class="text-eligibilitate">
                            <span class="biggerText">Eligibilitatea companiilor tale</span>
                        </h2>
                    </div>
                    <Companii :data="eligibilitateInfo"></Companii>
                </div>
                <div class="comentarii">
                    <ComentariiFinantare></ComentariiFinantare>
                </div>
            </div>
        </div>
        <LoadingSpinner v-else></LoadingSpinner>
    </div>
</template>

<script>
    import VueScrollTo from 'vue-scrollto'
    import LoadingSpinner from '@/common/components/LoadingSpinner'
    import api from '@/helpers/api'
    import axios from '@/helpers/axios'

    import Companii from './components/Companii';
    import ApelareConsultant from "@/pages/PrezentareProgram/components/ApelareConsultant";
    import FinantareSingleTitleBackground from "./components/FinantareSingleTitleBackground";
    import GreenTitleBar from "@/common/components/GreenTitleBar";
    import StatusFinantare from "./components/StatusFinantare";
    import DescriereaFinantarii from "./components/DescriereaFinantarii";
    import BeneficiariEligibili from "./components/BeneficiariEligibili";
    import TipAplicant from "./components/TipAplicant";
    import NrCompaniiEligibile from "./components/NrCompaniiEligibile";
    import ActivitatiEligibile from "./components/ActivitatiEligibile";
    import ContributieProprie from "./components/ContributieProprie";
    import CheltuieliEligibile from "./components/CheltuieliEligibile";
    import NumarAproxProiecte from "./components/NumarAproxProiecte";
    import DocumenteleFinantarii from "./components/DocumenteleFinantarii";
    import SursaFinantarii from "./components/SursaFinantarii";
    import ScriereProiect from "@/pages/PrezentareProgram/components/ScriereProiect";
    import DepunereProiect from "@/pages/PrezentareProgram/components/DepunereProiect";
    import FinantareSingleRegiuni from "./components/FinantareSingleRegiuni";
    import ComentariiFinantare from "./components/ComentariiFinantare";
    import DespreFinantare from "@/pages/PrezentareProgram/components/DespreFinantare";

    export default {
        components: {
            LoadingSpinner,
            Companii,
            FinantareSingleTitleBackground,
            GreenTitleBar,
            StatusFinantare,
            DescriereaFinantarii,
            BeneficiariEligibili,
            TipAplicant,
            NrCompaniiEligibile,
            ActivitatiEligibile,
            ContributieProprie,
            CheltuieliEligibile,
            NumarAproxProiecte,
            DocumenteleFinantarii,
            SursaFinantarii,
            ScriereProiect,
            ApelareConsultant,
            DepunereProiect,
            FinantareSingleRegiuni,
            DespreFinantare,
            ComentariiFinantare
        },
        props: {},
        mounted () {
            let id = this.$route.params.id;
            axios
                .get(api.API_ENDPOINTS.finantareSingle + '/' + id)
                .then(response => {
                        this.statusFinantare = {
                            status: response.data.status,
                            start_date: response.data.start_date,
                            end_date: response.data.end_date,
                            wishlisted: response.data.wishlisted,
                            id: response.data.id
                        }
                        this.singleTitleBackground = {
                            name: response.data.name,
                            program: response.data.program,
                            total_budget: response.data.total_budget,
                            project_budget: response.data.project_budget
                        }
                        this.descriereaFinantarii = {
                            description: response.data.description,
                        }
                        this.supported_regions = Object.keys(response.data.supported_regions),
                            this.beneficiariEligibili = {
                                beneficiaries: response.data.beneficiaries,
                                count_eligibilities: response.data.count_eligibilities,
                            }
                        this.tipAplicanti = {
                            applicants: response.data.applicants,
                            value: response.data.applicants.value,
                            supported: response.data.applicants.supported,
                        }
                        this.activitatiEligibile = {
                            activities: response.data.activities,
                        }
                        this.contributieProprie = {
                            contribution_type: response.data.contribution_type,
                            contributions: response.data.contributions,
                        }
                        this.cheltuieliEligibileNeeligibile = {
                            supported_expenses: response.data.supported_expenses,
                            unsupported_expenses: response.data.unsupported_expenses,
                        }
                        this.undeDepun = response.data.program.where_to_apply
                        this.proiecteFinantate = {
                            projects: response.data.projects,
                        }
                        this.documenteDescarcabile = {
                            downloads: response.data.downloads,
                        }
                        this.slugProgram = {
                            acronym: response.data.program.acronym,
                            id: response.data.program.id,
                        }
                        this.despreFinantare = response.data.program.about
                        this.sursaFinantarii = {
                            program: response.data.program,
                        }
                        this.breadcrumb = {
                            breadcrumb: response.data.breadcrumb,
                        }
                        // this.finantare = response.data
                        this.loading = false
                    }
                ).finally(() => {
                    axios.get(api.API_ENDPOINTS.programtree + '/' + id)
                        .then(response => {
                            let data = response.data.programtree
                            this.programTree = {
                                programtree: data,
                                program: response.data.program,
                                //count: response.data.count,
                            }
                        })
                    axios.get(api.API_ENDPOINTS.eligibilitateSingle + id)
                        .then(response => {
                            this.eligibilitateInfo = {
                                companies: response.data,
                            }
                            if (this.$router.currentRoute['hash']) {
                                VueScrollTo.scrollTo(this.$router.currentRoute['hash'], 1500, {
                                    container: '.contentPage',
                                    easing: 'ease-in',
                                    lazy: true
                                })
                            }
                        })
            })
        },
        data() {
            return {
                loading: true,
                title: "Toate finanțările",
                statusFinantare: [],
                singleTitleBackground: [],
                descriereaFinantarii: [],
                supported_regions: [],
                beneficiariEligibili: [],
                tipAplicanti: [],
                activitatiEligibile: [],
                contributieProprie: [],
                cheltuieliEligibileNeeligibile: [],
                undeDepun: [],
                proiecteFinantate: [],
                documenteDescarcabile: [],
                despreFinantare: [],
                eligibilitateInfo: [],
                breadcrumb: [],
                slugProgram: [],
                finantare: [],
                programTree: [],
                sursaFinantarii: [],
                companies: []
            };
        },
        created() {
        },
        methods: {},
    };
</script>

<style scoped>
    .modul-finantare-title {
        background-color: #f8f8f8;
        color: #434343;
        box-sizing: border-box;
        padding: 15px 20px;
        border: 1px solid #e7e7e7;
        box-shadow: 0px 2px 6px 1px #dddddd;
    }

    .box-padding {
        padding: 20px 30px 0 30px;
    }

    .item-b {
        grid-area: harta;
        max-height: 500px;
    }

    .item-h {
        grid-area: apeleazaConsultant;
    }

    .biggerText {
        font-size: 25px;
    }

    .comentarii {
        padding-bottom: 100px;
    }

    .padding-bottom {
        padding-bottom: 50px;
    }

    .text-eligibilitate {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #000;
        line-height: 0.1em;
        margin: 10px 20px 20px;
    }

    .item-j {
        grid-area: depunProiect;
    }

    .flexItems {
        display: grid;
        grid-template-rows: auto;
        grid-auto-flow: column;
        grid-template-areas: "descriere descriere harta" "beneficii beneficii tipAplicant" "activitatiEligibile activitatiEligibile contributieProprie" ". . scrieProiect" ". . apeleazaConsultant" "cheltuieli cheltuieli depunProiect" ". . numarProiecte" "documenteFinantare documenteFinantare despreFinantare" "sursaFinantarii sursaFinantarii sursaFinantarii";
        grid-gap: 20px;
        margin-bottom: 50px;
        grid-template-columns: 1fr 1fr 325px;
    }

    .text-eligibilitate span {
        background-color: #f0f0f0;
        padding: 0 10px;
    }

    @media only screen and (max-width: 900px) {
        .box-padding {
            padding: 0;
        }
    }

    @media only screen and (max-width: 1100px) {
        .flexItems {
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: auto;
            grid-auto-flow: column;
            grid-template-areas: "descriere" "harta" "beneficii" "tipAplicant" "activitatiEligibile" "contributieProprie" "scrieProiect" "apeleazaConsultant" "cheltuieli" "depunProiect" "numarProiecte" "documenteFinantare" "despreFinantare" "sursaFinantarii";
            grid-gap: 20px;
            padding: 10px;
            margin-bottom: 50px;
        }
    }

    @media only screen and (max-width: 1250px) {
        .flexItems {
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: auto;
            grid-auto-flow: column;
            grid-template-areas: "descriere" "harta" "beneficii" "tipAplicant" "activitatiEligibile" "contributieProprie" "scrieProiect" "apeleazaConsultant" "cheltuieli" "depunProiect" "numarProiecte" "documenteFinantare" "despreFinantare" "sursaFinantarii";
            grid-gap: 20px;
            padding: 10px;
            margin-bottom: 50px;
        }

        .item-b {
            grid-area: harta;
            max-height: 500px;
        }

        .item-h {
            grid-area: apeleazaConsultant;
        }

        .item-j {
            grid-area: depunProiect;
        }
    }

    @media only screen and (max-width: 710px) {
        .text-eligibilitate span {
            font-size: 20px;
        }

        .text-eligibilitate {
            margin: 0 0 10px 0;
            box-sizing: border-box;
        }

    }
</style>
