<template>
    <div class="header-style">
        <div class="image-title-conatiner">
            <img src="@/assets/images/harta-finantari-logo.png" alt="logo-Finantari">
        </div>
        <div class="header-component">
            <span class="number-component">{{ finantari }}</span>
            <span class="text-component"><strong>Finanțări</strong><br>prezentate</span>
        </div>
        <div class="header-component">
            <span class="number-component">{{ finantariDeschise }}</span>
            <span class="text-component"><strong>Finanțări</strong><br>cu apel de proiecte deschis</span>
        </div>
        <div class="header-component">
            <span class="number-component">8</span>
            <span class="text-component"><strong>Finanțări</strong><br> start-ups</span>
        </div>
        <div class="logo-image-container">
            <img src="@/assets/images/LogoSmisMicLogin.png" alt="logo-smis">
        </div>
        
    </div>
</template>
<script>
    export default {
        props:{
            finantari:{
                type:Number,
                default:0
            },
            finantariDeschise:{
                type:Number,
                default:0
            }


        },
        data() {
            return {
                
            }
        },
        methods: {
            
        },

    
    }
</script>
<style scoped>
    .header-style{
        background-color: #f6f6f6;
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items:center ;
        padding:0.5rem 1.3rem;
        margin-bottom: 0.8rem ;
    }
    .header-component{
        display: flex;
        align-items: stretch;
        
    }
    .number-component{
        font-weight: 600;
        font-size: 3rem;
        margin-right:5px ;
        color: #242424;
        margin: auto 0;
    }

    .text-component{
        font-weight: 200;
        color:#707070;
        margin: auto 0;
    }
    .text-component strong{
        font-size: 18px;
        font-weight: 600;
        color: #242424;
    }
    @media(max-width:900px){
        .header-style{
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            padding: 1rem;
        }
        .header-component{
            order: 1;
            margin: 0.5rem;
        }
        .logo-image-container{
            position: absolute;
            right: 1rem;
        }
    }
</style>