<template>
    <div class="spinner">
        <img alt="" src="@/assets/images/loader.gif">
    </div>
</template>
<script>
export default {
    components:{
    }
}
</script>
<style scoped>
    .spinner{
        width: 100%;
        height: 100%;
        text-align: center;
        position: relative;
    }
    .modal-body .spinnerInner{
      position: inherit;
      margin-top: 80px;
    }
    .spinnerInner {
        width: 3.5rem;
        height: 3.5rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
</style>