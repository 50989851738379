<template>
    <div class="padding-box">
        <div class="white-box">
            <span class="bold-text gradient-title">Cereri de ofertă</span>
            <div class="review">
                <b-carousel
                    class="carousel-center"
                    id="feedbackSlider"
                    :interval="4000"
                    indicators
                >
                    <b-carousel-slide>
                        <div class="slide">
                            <p>Nume: <span>Demo</span></p>
                            <p>Email: <span>demo@yahoo.com</span></p>
                            <p>Telefon: <span>0722222222</span></p>
                            <p>Mesaj: <span>Lorem ipsum</span></p>
                        </div>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <div class="slide">
                            <p>Nume: <span>Demo</span></p>
                            <p>Email: <span>demo@yahoo.com</span></p>
                            <p>Telefon: <span>0722222222</span></p>
                            <p>Mesaj: <span>Lorem ipsum</span></p>
                        </div>
                    </b-carousel-slide>
                    <b-carousel-slide>
                        <div class="slide">
                            <p>Nume: <span>Demo</span></p>
                            <p>Email: <span>demo@yahoo.com</span></p>
                            <p>Telefon: <span>0722222222</span></p>
                            <p>Mesaj: <span>Lorem ipsum</span></p>
                        </div>
                    </b-carousel-slide>

                </b-carousel>
            </div>
        </div>
    </div>
</template>

<script>
import {BCarouselSlide, BCarousel, BFormRating} from 'bootstrap-vue'

export default {
    name: "FeedbackCompanie",
    components: {
        BCarousel,
        BCarouselSlide,
        BFormRating
    },
    props: {
        data: [],
    },
    data() {
        return {
            review: {
                stars: 4.5,
            },
        };
    },
}
</script>

<style scoped>
.padding-box{
    padding: 20px;
}
.white-box{
    background: #fff;
    padding: 15px;
}
.bold-text {
    font-weight: bold;
}
.right{
    position: relative;
}
.contesta p{
    position: absolute;
    top: -30%;
    right: 5%;
    white-space: nowrap;
    color: var(--dangerColor);
    font-weight: 600;
}
.carousel-item {
    height: 150px;
    background: transparent;
}

#feedbackSlider >>> li {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: 1px solid #666666;
}

#feedbackSlider >>> .carousel-indicators .active {
    background: #666666;
    height: 12px;
    width: 12px;
}

#feedbackSlider >>> .carousel-caption {
    position: relative;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

.slide .form-control {
    background-color: transparent !important;
    max-width: 115px;
}

.slide p {
    font-size: 12px;
}

.top-slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gradient-title {
    font-size: 14px;
    color: #1c1c1c;
}

@media only screen and (max-width: 900px) {
    .carousel-item {
        height: 100%;
    }
}
</style>