<template>
    <div class="tree-container">
    </div>
</template>


<script>

    export default {
        components: {
        },
        data() {
            return {
                data: {

                }
            };
        },
        methods: {}
    };
</script>
<style>
</style>