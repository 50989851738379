<template>
    <div class="grid-notificari">
        <div v-for="(notificare, i) in notificari" :key="i">
            <div class="card">
                <div class="card-body d-flex justify-content-center">
                    <i :class="notificare.icon" style="color: #a2a2a2"></i>
                    <p class="ml-3 font-weight-bold" style="place-self: center">
                        {{ notificare.titlu }}
                    </p>
                </div>
            </div>
            <div v-for="(copil, j) in notificare.child ? _.orderBy(notificare.child, 'citit') : notificare.child" :key="j" class="card mt-3">
                <div
                    v-if="copil.citit === false"
                    class="card-body d-flex cutii-notificare"
                    :class="copil.numeclasa"
                >
                    <b-img
                        class="align-self-center"
                        :src="require('@/assets/images/' + copil.logo + '.png')"
                    ></b-img>
                    <div
                        v-if="notificare.titlu === 'Notificări SMIS & Marketing'"
                        class="ml-3 notificari-smis-marketing"
                    >
                        <div v-if="copil.tip === 'M2' || copil.tip === 'Premium'">
                            <p class="notificari-smis-titlu">{{ copil.titlu | subStr }}</p>
                            <p class="text-pastel-cyan">{{ copil.subtitlu | subStr }}</p>
                            <p>
                                Astăzi: {{ copil.data }}
                                {{ copil.ora }}
                            </p>
                        </div>
                        <div v-else-if="copil.tip === 'Financiar'">
                            <p class="notificari-smis-titlu">{{ copil.titlu | subStr }}</p>
                            <p class="financiar-subtitlu">{{ copil.subtitlu | subStr }}</p>
                            <p class="data-financiar">
                                Astăzi: {{ copil.data }} &nbsp;
                                <span class="ora-financiar">{{ copil.ora }}</span>
                            </p>
                        </div>
                        <div v-else-if="copil.tip === 'Marketing'">
                            <p>
                                Marketing: <span class="marketing-titlu">{{ copil.titlu | subStr }}</span>
                            </p>
                            <p class="marketing-subtitlu">{{ copil.subtitlu | subStr }}</p>
                            <p class="data-marketing">
                                Astăzi: {{ copil.data }} &nbsp;
                                {{ copil.ora }}
                            </p>
                        </div>
                    </div>
                    <div
                        v-else-if="notificare.titlu === 'Știri & Noutăți'"
                        class="ml-3 stiri-noutati"
                    >
                        <p><span class="text-stiri">Știri:</span> {{ copil.titlu | subStr }}</p>
                        <p>{{ copil.subtitlu | subStr }}</p>
                        <p>
                            Astăzi: <span class="data-notificare">{{ copil.data }}</span>
                            {{ copil.ora }}
                        </p>
                    </div>
                    <div
                        v-else-if="notificare.titlu === 'Notificări închidere apel'"
                        class="ml-3 notificari-inchidere-apel"
                    >
                        <p>{{ copil.titlu | subStr }}</p>
                        <p>{{ copil.subtitlu | subStr }}</p>
                        <p class="notificari-data-primire">
                            Astăzi: <span class="data-notificare">{{ copil.data }}</span> &nbsp;
                            <span class="ora-notificare">{{ copil.ora }}</span>
                        </p>
                    </div>
                    <div
                        v-else-if="notificare.titlu === 'Notificări lansare apel'"
                        class="ml-3 notificari-lansare-apel"
                    >
                        <p class="apel-deschis">Apel deschis!</p>
                        <p class="lansare-apel-titlu">{{ copil.titlu | subStr }}</p>
                        <p class="notificari-data-primire">
                            Astăzi: <span class="data-notificare">{{ copil.data }}</span> &nbsp;
                            <span class="ora-notificare">{{ copil.ora }}</span>
                        </p>
                    </div>
                    <div
                        v-else-if="notificare.titlu === 'Notificări favorite'"
                        class="ml-3 notificari-favorite"
                    >
                        <p class="apel-deschis">Au fost adaugate informatii noi</p>
                        <p class="notificari-favorite-titlu">{{ copil.titlu | subStr }}</p>
                        <p class="notificari-data-primire">
                            Astăzi: <span class="data-notificare">{{ copil.data }}</span> &nbsp;
                            <span class="ora-notificare">{{ copil.ora }}</span>
                        </p>
                        <b-iconstack font-scale="1.3" class="icon-favorit">
                            <b-icon-heart-fill
                                stacked
                                icon="heart-fill"
                                style="color: #ff3440"
                                scale="0.88"
                            ></b-icon-heart-fill>
                            <b-icon-heart stacked icon="heart" style="color: #fff"></b-icon-heart>
                        </b-iconstack>
                    </div>
                </div>
                <div
                    v-if="copil.citit === true"
                    class="card-body d-flex notificare-citita cutii-notificare"
                    :class="copil.numeclasa + '-citit'"
                >
                    <b-img
                        class="align-self-center"
                        :src="require('@/assets/images/' + copil.logo + '.png')"
                    ></b-img>
                    <div
                        v-if="notificare.titlu === 'Notificări SMIS & Marketing'"
                        class="ml-3 notificari-smis-marketing"
                    >
                        <div v-if="copil.tip === 'M2' || copil.tip === 'Premium'">
                            <p class="notificari-smis-titlu">{{ copil.titlu | subStr }}</p>
                            <p class="text-pastel-cyan">{{ copil.subtitlu | subStr }}</p>
                            <p>
                                Astăzi: {{ copil.data }}
                                {{ copil.ora }}
                            </p>
                        </div>
                        <div v-else-if="copil.tip === 'Financiar'">
                            <p class="notificari-smis-titlu">{{ copil.titlu | subStr }}</p>
                            <p class="financiar-subtitlu">{{ copil.subtitlu | subStr }}</p>
                            <p class="data-financiar">
                                Astăzi: {{ copil.data }} &nbsp;
                                <span class="ora-financiar">{{ copil.ora }}</span>
                            </p>
                        </div>
                        <div v-else-if="copil.tip === 'Marketing'">
                            <p>
                                Marketing: <span class="marketing-titlu">{{ copil.titlu | subStr }}</span>
                            </p>
                            <p class="marketing-subtitlu">{{ copil.subtitlu | subStr }}</p>
                            <p class="data-marketing">
                                Astăzi: {{ copil.data }} &nbsp;
                                {{ copil.ora }}
                            </p>
                        </div>
                    </div>
                    <div
                        v-else-if="notificare.titlu === 'Știri & Noutăți'"
                        class="ml-3 stiri-noutati"
                    >
                        <p><span class="text-stiri">Știri:</span> {{ copil.titlu | subStr }}</p>
                        <p>{{ copil.subtitlu | subStr }}</p>
                        <p>
                            Astăzi: <span class="data-notificare">{{ copil.data }}</span>
                            {{ copil.ora }}
                        </p>
                    </div>
                    <div
                        v-else-if="notificare.titlu === 'Notificări închidere apel'"
                        class="ml-3 notificari-inchidere-apel"
                    >
                        <p>{{ copil.titlu | subStr }}</p>
                        <p>{{ copil.subtitlu | subStr }}</p>
                        <p class="notificari-data-primire">
                            Astăzi: <span class="data-notificare">{{ copil.data }}</span> &nbsp;
                            <span class="ora-notificare">{{ copil.ora }}</span>
                        </p>
                    </div>
                    <div
                        v-else-if="notificare.titlu === 'Notificări lansare apel'"
                        class="ml-3 notificari-lansare-apel"
                    >
                        <p class="apel-deschis">Apel deschis!</p>
                        <p class="lansare-apel-titlu">{{ copil.titlu | subStr }}</p>
                        <p class="notificari-data-primire">
                            Astăzi: <span class="data-notificare">{{ copil.data }}</span> &nbsp;
                            <span class="ora-notificare">{{ copil.ora }}</span>
                        </p>
                    </div>
                    <div
                        v-else-if="notificare.titlu === 'Notificări favorite'"
                        class="ml-3 notificari-favorite"
                    >
                        <p class="apel-deschis">Au fost adaugate informatii noi</p>
                        <p class="notificari-favorite-titlu">{{ copil.titlu | subStr }}</p>
                        <p class="notificari-data-primire">
                            Astăzi: <span class="data-notificare">{{ copil.data }}</span> &nbsp;
                            <span class="ora-notificare">{{ copil.ora }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        BImg,
        BIconstack,
        BIconHeartFill,
        BIconHeart
    } from 'bootstrap-vue';
    import _ from 'lodash'
    export default {
        components:{
            BImg,
            BIconstack,
            BIconHeartFill,
            BIconHeart
        },
        filters: {
            subStr: function (string) {
                if (string.length > 30) {
                    return string.substring(0, 30) + "...";
                } else {
                    return string.substring(0, 30);
                }
            },
        },
        computed:{
            _(){
                return _;
            }
        },
        props: {},
        data() {
            return {
                notificari: [
                    {
                        titlu: "Notificări SMIS & Marketing",
                        icon: "fas fa-bullhorn fa-2x",
                        child: [
                            {
                                titlu: "document de clarificare",
                                subtitlu: "M2 Manager",
                                data: "02.02.2020",
                                logo: "logoguvern",
                                numeclasa: "black-box",
                                tip: "M2",
                                citit: false,
                            },
                            {
                                titlu: "functie noua",
                                subtitlu: "M2 Manager",
                                data: "02.02.2020",
                                logo: "EU_Flag",
                                numeclasa: "cyan-blue-box",
                                tip: "Premium",
                                citit: false,
                            },
                            {
                                titlu: "Contul tau",
                                subtitlu: "A fost emisa factura SM",
                                data: "02.02.2020",
                                ora: "10:00",
                                logo: "m2",
                                numeclasa: "orange-box",
                                tip: "Financiar",
                                citit: false,
                            },
                            {
                                titlu: "Curs Project Manager",
                                subtitlu: "40% reducere pe SMIS",
                                data: "02.02.2020",
                                ora: "10:00",
                                logo: "logoguvern",
                                numeclasa: "blue-box",
                                tip: "Marketing",
                                citit: false,
                            },
                            {
                                titlu: "document de clarificare",
                                subtitlu: "M2 Manager",
                                data: "02.02.2020",
                                logo: "logoguvern",
                                numeclasa: "black-box",
                                tip: "M2",
                                citit: true,
                            },
                            {
                                titlu: "functie noua",
                                subtitlu: "M2 Manager",
                                data: "02.02.2020",
                                logo: "EU_Flag",
                                numeclasa: "cyan-blue-box",
                                tip: "Premium",
                                citit: true,
                            },
                            {
                                titlu: "Contul tau",
                                subtitlu: "A fost emisa factura SM",
                                data: "02.02.2020",
                                ora: "10:00",
                                logo: "m2",
                                numeclasa: "orange-box",
                                tip: "Financiar",
                                citit: true,
                            },
                            {
                                titlu: "Curs Project Manager",
                                subtitlu: "40% reducere pe SMIS",
                                data: "02.02.2020",
                                ora: "10:00",
                                logo: "logoguvern",
                                numeclasa: "blue-box",
                                tip: "Marketing",
                                citit: true,
                            },
                        ],
                    },
                    {
                        titlu: "Știri & Noutăți",
                        icon: "far fa-newspaper fa-2x",
                        child: [
                            {
                                titlu: "Guvernul a adoptat",
                                subtitlu: "Conform procedurii de guv",
                                data: "02.02.2020",
                                logo: "logoguvern",
                                numeclasa: "white-box",
                                citit: true,
                            },
                            {
                                titlu: "Guvernul a adoptat",
                                subtitlu: "Conform procedurii de guv",
                                data: "02.02.2020",
                                logo: "logoguvern",
                                numeclasa: "white-box",
                                citit: false,
                            },
                        ],
                    },
                    {
                        titlu: "Notificări închidere apel",
                        icon: "far fa-times-circle fa-2x",
                        child: [
                            {
                                titlu: "Termen limita depunere",
                                subtitlu:
                                    "Conform procedurii de guvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv sss",
                                data: "02.02.2020",
                                ora: "10:00",
                                logo: "logoguvern",
                                numeclasa: "pink-red-box",
                                citit: true,
                            },
                            {
                                titlu: "Termen limita depunere",
                                subtitlu: "Conform procedurii de guv",
                                data: "02.02.2020",
                                ora: "10:00",
                                logo: "logoguvern",
                                numeclasa: "pink-red-box",
                                citit: false,
                            },
                        ],
                    },
                    {
                        titlu: "Notificări lansare apel",
                        icon: "fas fa-rocket fa-2x",
                        child: [
                            {
                                titlu: "POR 2.3 IONTECH",
                                data: "02.02.2020",
                                ora: "10:00",
                                logo: "logoguvern",
                                numeclasa: "cyan-box",
                                citit: true,
                            },
                            {
                                titlu: "POR 2.3 IONTECH",
                                data: "02.02.2020",
                                ora: "10:00",
                                logo: "logoguvern",
                                numeclasa: "cyan-box",
                                citit: false,
                            },
                        ],
                    },
                    {
                        titlu: "Notificări favorite",
                        icon: "far fa-heart fa-2x",
                        child: [
                            {
                                titlu: "Guvernul a adoptat",
                                subtitlu: "Conform procedurii de guv",
                                data: "02.02.2020",
                                ora: "10:00",
                                logo: "logoguvern",
                                numeclasa: "grey-box",
                                citit: true,
                            },
                            {
                                titlu: "Guvernul a adoptat",
                                subtitlu: "Conform procedurii de guv",
                                data: "02.02.2020",
                                ora: "10:00",
                                logo: "logoguvern",
                                numeclasa: "grey-box",
                                citit: false,
                            },
                        ],
                    },
                ],
            };
        },

        created() {},
        methods: {},
    };
</script>

<style scoped>
	.grid-notificari {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
		grid-template-rows: auto;
		grid-gap: 20px;
	}
	.notificare-citita {
		opacity: 0.3;
	}
	p {
		text-align: left;
	}
	.text-stiri {
		color: #53d4cb;
	}

	.notificari-data-primire,
	.stiri-noutati .data-notificare,
	.ora-financiar,
	.data-marketing,
	.marketing-titlu {
		font-weight: bold;
	}
	.notificari-inchidere-apel .data-notificare,
	.notificari-inchidere-apel .ora-notificare,
	.notificari-favorite .data-notificare,
	.financiar-subtitlu,
	.data-financiar {
		color: #292929;
	}
	.notificari-lansare-apel .apel-deschis,
	.notificari-lansare-apel .ora-notificare,
	.notificari-favorite p,
	.notificari-smis-marketing .notificari-smis-titlu,
	.ora-financiar,
	.data-marketing {
		color: #fff;
	}
	.notificari-inchidere-apel .ora-notificare,
	.notificari-lansare-apel .lansare-apel-titlu,
	.notificari-lansare-apel .ora-notificare,
	.notificari-favorite .ora-notificare,
	.financiar-subtitlu,
	.marketing-subtitlu {
		font-weight: 900;
	}
	.notificari-favorite .ora-notificare {
		color: #ffdaae;
		position: relative;
	}
	.icon-favorit {
		position: absolute;
        right: 5px;
        bottom: 5px;
	}

	.black-box {
		background-color: #2b2c3d;
	}
	.black-box-citit {
		background-color: #262731;
	}
	.cyan-blue-box {
		background-color: #5f6771;
	}
	.cyan-blue-box-citit {
		background-color: #555e68;
	}
	.orange-box {
		background-color: #faaa84;
	}
	.orange-box-citit {
		background-color: #eccfc0;
	}
	.blue-box {
		background-color: #5363d4;
	}
	.blue-box-citit {
		background-color: #a5aeee;
	}
	.white-box {
		background-color: #fff;
	}
	.pink-red-box {
		background-color: #ff646d;
	}
    .pink-red-box-citit {
		background-color: #bbbaba;
	}
	.cyan-box {
		background-color: #53d4cb;
	}
    .cyan-box-citit {
		background-color: #929191;
	}
	.grey-box {
		background-color: #a2a2a2;
	}
    .grey-box-citit {
		background-color: #a7a7a7;
	}

	.black-box p,
	.black-box-citit p,
	.blue-box p,
	.blue-box-citit p,
	.cyan-blue-box p,
	.cyan-blue-box-citit p,
	.pink-red-box p,
	.pink-red-box-citit p {
		color: white;
	}
	.white-box p,
	.white-box-citit p {
		color: #2b2c3d !important;
	}
	.text-pastel-cyan {
		color: #55c8c1 !important;
	}

	img {
		max-width: 50px;
	}

	@media only screen and (max-width: 430px) {
		.grid-notificari {
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		}
		.cutii-notificare {
			flex-direction: column;
		}
		img {
			margin-bottom: 10px;
		}
		.icon-favorit {
			right: 15px;
			top: 127px;
		}
	}
</style>
