<template>
    <div>
        <div class="stiri">
            <div class="eveniment greenBorder" v-for="(eveniment, key) in data" :key="key">
                <div class="image-stire">
                    <img src="@/assets/images/stire-logo.png">
                </div>
                <div class="info-stire">
                    <router-link :to="'/stire/'+eveniment.id">
                    <p class="stire-title">{{ eveniment.title }}</p>
                    </router-link>
                    <p v-html="eveniment.description+'...'"></p>
                </div>
                <HeartFavorite class="stire-favorita" postTo="wishlistMethod" :isFavorite="eveniment.wishlisted" :id="eveniment.id"></HeartFavorite>
            </div>
        </div>
    </div>
</template>

<script>
import HeartFavorite from '../../../../common/components/HeartFavorite';

export default {
    components: {HeartFavorite},
    data() {
        return {}
    },
    props: {
        data: null
    },
}
</script>

<style>
.stire-favorita .b-icon {
    color: #8b8b8b !important;
}
</style>
<style scoped>
.stiri {
    max-height: 272px;
    overflow-y: scroll;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(208, 208, 208, 0.38);
}

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(208, 208, 208, 0.38);
    border: 2px solid #95a3cb;
}

.eveniment{
    padding: 10px;
    display: flex;
    background: #fff;
    align-items: center;
}

.image-stire img {
    border-radius: 50%;
}

.image-stire {
    width: 8%;
}

.info-stire {
    width: 88%;
}

.stire-favorita {
    width: 4%;
}

.stire-title {
    font-weight: 500;
}

.greenBorder {
    border-left: 2px solid #00c9a1;
}

.redBorder {
    border-left: 2px solid #ff7070;
}

.grayBorder {
    border-left: 2px solid #74748b;
}

.image-stire img {
    max-width: 48px;
    max-height: 48px;
    object-fit: contain;
}

@media only screen and (max-width: 1600px) {
    .eveniment {
        display: block;
    }

    .stire-favorita {
        text-align: right;
        width: 100%;
    }

    .image-stire {
        width: 100%;
    }

    .info-stire {
        width: 100%;
    }

}
</style>