<template>
    <div>
        <div v-if="!loading && companies.length>0" class="grid-companii">
            <CompanieContulTau
                v-for="(tab, key) in companies" 
                :id="tab.id"
                :key="key"
                :nume="tab.name"
                :image="tab.logo"
                :cui="tab.business_id"
                @idCompanie="deleteCompany()"
            ></CompanieContulTau> 
        </div>
        <LoadingSpinner v-else-if="loading" class="mt-5"></LoadingSpinner>
        <p v-else>Nicio companie nu a fost adaugată in cont.</p>
    </div>
</template>

<script>
    import LoadingSpinner from '@/common/components/LoadingSpinner'
    import CompanieContulTau from "./CompanieContulTau";
    import api from '@/helpers/api';
    import axios from '@/helpers/axios';

    export default {
        components: {
            CompanieContulTau,
            LoadingSpinner,
        },
        props: {},
        data() {
            return {
                companies: [],
                loading: true
            };
        },
        created() {
        },
        mounted() {
            this.getCompanies()
        },
        methods: {
            getCompanies() {
                return axios.get(api.API_ENDPOINTS.companies)
                    .then(response => {
                        this.companies = response.data;
                    }).finally(() => this.loading = false)
            },
            deleteCompany() {
                this.getCompanies();
            }
        },
    };
</script>

<style scoped>
.grid-companii {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-template-rows: auto;
    grid-gap: 20px;
}

@media only screen and (max-width: 500px) {
    .grid-companii {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}
</style>
