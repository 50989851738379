<template>
    <div class="flexed" v-if="angajati">
        <div>
            <p class="info-text">Numar mediu salariați {{angajati.an}}</p>
            <p class="section-title">{{angajati.numar}} {{angajati.numar == 1 ? 'salariat' : 'salariați'}}</p>
        </div>
        <div>
            <div class="icon"><font-awesome-icon :icon="['fas', 'user-friends']"></font-awesome-icon></div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons'

library.add(faUserFriends)
export default {
    components: {
        FontAwesomeIcon
    },
    props: {
        angajati: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {}
    },
}
</script>

<style scoped>
.section-title {
    font-size: 14px;
    color: #878588;
    font-weight: 600;
}
.flexed{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
p{
    text-align: left;
}
.info-text{
    color: #878588;
    text-align: left;
    font-size: 13px;
}
.icon{
    background: #a88add;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fa-user-friends{
    color: #fff;
    font-size: 13px;
}
@media only screen and (max-width: 1600px) {
    .flexed {
        display: block;
    }
}
</style>