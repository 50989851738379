<template>
    <div>
        <DarkBlueTitleBar :data="title"></DarkBlueTitleBar>
        <EligibilitateTitleBackground></EligibilitateTitleBackground>
        <div class="eligibilitati" v-if="!loading">
            <div class="grid-companii">
                <CompanieEligibilitate v-for="(companie, key) in eligibilities" :key="key" :data="companie" :hasFinantari="companie.fundings.length > 0"></CompanieEligibilitate>
            </div>
        </div>
        <div class="loading" v-else>
            <LoadingSpinner></LoadingSpinner>
        </div>
    </div>
</template>

<script>
import LoadingSpinner from '@/common/components/LoadingSpinner'
import EligibilitateTitleBackground from './components/EligibilitateTitleBackground'
import CompanieEligibilitate from './components/CompanieEligibilitate'
import DarkBlueTitleBar from '@/common/components/DarkBlueTitleBar';
import api from '@/helpers/api';
import axios from '@/helpers/axios';

export default {
    components: {
        LoadingSpinner,
        EligibilitateTitleBackground,
        CompanieEligibilitate,
        DarkBlueTitleBar,
    },
    props: {},
    data() {
        return {
            loading: true,
            title: 'Eligibilitatea companiilor tale',
            hasFinantari: true,
            eligibilities: [],
        }
    },
    mounted() {
        this.getEligibilities()
    },
    methods: {
        getEligibilities() {
            return axios.get(api.API_ENDPOINTS.companyEligibility)
                .then(response => {
                    this.eligibilities = response.data;
                }).finally(() => this.loading = false)
        },
    },
}
</script>

<style scoped>
.loading{
    height: 150px;
    width: 100%;
}
.eligibilitati {
    padding: 20px;
    padding-bottom: 100px;
}

.grid-companii {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-template-rows: auto;
    grid-gap: 20px;
    margin: 20px 0 0 0;
}

@media only screen and (max-width: 500px) {
    .grid-companii {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}
</style>