<template>
    <div v-if="locatie">
        <div class="flexed">
            <div >
                <p class="info-text">{{locatie.localitate.toLowerCase()}}</p>
                <strong class="nume-companie">{{locatie.judet}}</strong>
            </div>
            <img src="@/assets/images/pin-icon.png">
        </div>
    </div>
</template>

<script>
export default{
    components:{
    },
    props: {
        locatie: {
            type: Object,
            default: null,
        },
    },
    data() {
        return{
            max: 10,
            form: {
                cui: '',
            },
        }
    }
}
</script>

<style scoped>
.flexed{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.info-text{
    color: #878588;
    text-align: left;
    font-size: 13px;
    text-transform: capitalize;
}
.nume-companie{
    color: #878588;
    text-transform: uppercase;
    font-size: 15px;
}

</style>