<template>
    <div class="modul-finantare-title item-i">
        <div class="flex">
            <div class="w-50">
                <span class="bold-text">Cheltuieli eligibile</span>
                <b-icon-check-circle-fill
                        style="margin-left: 5px; fill: var(--succesColor);"
                        font-scale="1"
                ></b-icon-check-circle-fill>
            </div>
            <div class="w-50">
                <span class="bold-text">Cheltuieli neeligibile</span>
                <b-icon-x-circle-fill
                        style="margin-left: 5px; fill: var(--dangerColor);"
                        font-scale="1"
                ></b-icon-x-circle-fill>
            </div>
        </div>
        <div class="item-i flex fixedHeight">
            <div class="cheltuieli-eligibile w-50">
                <div class="modul-finantare-content">
                    <ul class="lista-cheltuieli-eligibile">
                        <li v-for="(eligibil, index) in data.supported_expenses" :key="index">{{ eligibil }}</li>
                    </ul>
                </div>
            </div>
            <div class="lista-cheltuieli-neeligibile w-50">
                <div class="modul-finantare-content">
                    <ul class="lista-cheltuieli-eligibile">
                        <li v-for="(neeligibil, index) in data.unsupported_expenses" :key="index">{{ neeligibil }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {BIconCheckCircleFill, BIconXCircleFill} from 'bootstrap-vue';
    export default {
        components: {
            BIconCheckCircleFill,
            BIconXCircleFill
        },
        props: {
            data: null,
        },
        data() {
            return {}
        },
        methods: {},
    }
</script>

<style scoped>
    .flex{
        display: flex;
        justify-content: space-between;
    }
    .fixedHeight {
        height: 232px;
        overflow-y: auto;
    }
    .w-50{
        width: 50%;
    }

    .bold-text {
        font-weight: bold;
        font-size: var(--moduleTitleSize);
    }

    .modul-finantare-content {
        margin-top: 10px;
        color: #868686;
        font-size: var(--paragraphSize);
        display: flex;
    }

    .item-i {
        grid-area: cheltuieli;
        grid-row: 6 / 8;
    }

    .lista-cheltuieli-eligibile {
        list-style-type: disc;
        margin-left: 18px;
    }

    @media only screen and (max-width: 1250px) {
        .item-i {
            grid-area: cheltuieli;
            display: flex;
            justify-content: space-between;
            grid-row: 7 / 8;
        }

        .item-i .lista-cheltuieli-neeligibile {
            padding: 0;
        }
    }

    @media only screen and (max-width: 1100px) {
        .item-i {
            grid-area: cheltuieli;
            grid-template-rows: auto;
        }
    }

    @media only screen and (max-width: 710px) {
        .item-i {
            grid-area: cheltuieli;
            display: flex;
            grid-column: 1;
        }

        .item-i {
            flex-direction: column;
        }
    }
</style>
