<template>
    <div class="companii-eligibile modul-finantare-title">
        <h1 v-if="data.count_eligibilities == null" class="nr-companii-eligibile warning">?</h1>
        <h1 v-else-if="data.count_eligibilities == false" class="nr-companii-eligibile danger">0</h1>
        <h1 v-else class="nr-companii-eligibile">{{ data.count_eligibilities }}</h1>
        <p v-if="data.count_eligibilities == null" class="text-companii-eligibile">
            momentan nu avem  <span class="bold-text">date</span> suficiente despre compania dumneavoastră.
        </p>
        <p v-else class="text-companii-eligibile">
            dintre companiile tale sunt <strong>eligibile*</strong> pentru
            <span class="bold-text">această finanțare.</span><br> * Pentru mai multe detali
            consultă ghidul solicitantului și regulile extinse de eligibilitate.
        </p>
    </div>
</template>

<script>

    export default {
        components: {},
        props: {
            data: null,
        },
        data() {
            return {}
        },
        methods: {},
    }
</script>

<style scoped>
    .modul-finantare-title{
        display: flex;
        align-items: center;
    }

    .title{
        font-size: var(--moduleTitleSize);
    }
    .item-c {
        grid-area: beneficii;
    }
    .danger{
        color: #dc3545 !important;
    }
    .warning{
        color: #ffc107 !important;
    }
    .bold-text {
        font-weight: bold;
    }
    .modul-finantare-content {
        margin-top: 10px;
        color: #868686;
        font-size: 14px;
        display: flex;
    }
    .companii-eligibile {
        display: flex;
        align-self: center;
    }
    .nr-companii-eligibile {
        color: #00c9a1;
        font-size: 45px;
        margin-right: 30px;
        margin-bottom: 0;
    }
    .text-companii-eligibile {
        color: #868686;
        font-size: var(--paragraphSize);
    }
    .lista-firme-beneficii {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        border-right: 1px solid #d5d5d5;
        min-width: 50%;
        list-style-type: disc;
        margin-left: 18px;
        max-width: 50%;
        font-weight: 500;
        font-size: var(--paragraphSize);
    }
    @media only screen and (max-width: 1250px) {
        .item-c {
            grid-area: beneficii;
        }
        .item-c .nr-companii-eligibile {
            margin: 0 5px;
        }
        .item-c .lista-firme-beneficii{
            columns: 1;
        }
        .item-c p {
            text-align: initial;
        }
        .modul-finantare-content{
            flex-direction: column-reverse;
        }
        .lista-firme-beneficii{
            border: none;
        }
        .companii-eligibile{
            margin-bottom: 10px;
        }
    }
    @media only screen and (max-width: 710px) {
        .item-n .modul-finantare-content {
            display: initial;
        }
    }
</style>