import { render, staticRenderFns } from "./EditeazaInformatii.vue?vue&type=template&id=036e14c0&scoped=true&"
import script from "./EditeazaInformatii.vue?vue&type=script&lang=js&"
export * from "./EditeazaInformatii.vue?vue&type=script&lang=js&"
import style0 from "./EditeazaInformatii.vue?vue&type=style&index=0&id=036e14c0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "036e14c0",
  null
  
)

export default component.exports