<template>
    <div>
        <div class="faq-header">
            <span>Întrebări și răspunsuri oficiale Ministerul Economiei</span>
        </div>
        <div class="faq-body">
            <div class="items">
                <app-collapse
                    accordion
                    type="margin"
                    class="mt-2"
                >
                    <app-collapse-item title="test">
                        test
                    </app-collapse-item>
                    <app-collapse-item title="test2">
                        test2
                    </app-collapse-item>
                    <app-collapse-item title="test3">
                        test3
                    </app-collapse-item>
                    <app-collapse-item title="test4">
                        test4
                    </app-collapse-item>
                    <app-collapse-item title="test4">
                        test4
                    </app-collapse-item>
                    <app-collapse-item title="test4">
                        test4
                    </app-collapse-item>
                    <app-collapse-item title="test4">
                        test4
                    </app-collapse-item>
                    <app-collapse-item title="test4">
                        test4
                    </app-collapse-item>
                </app-collapse>
            </div>
        </div>
    </div>

</template>

<script>
import AppCollapse from '@/common/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/common/components/app-collapse/AppCollapseItem.vue'
export default {
    components:{
        AppCollapse,
        AppCollapseItem
    },
    props: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.faq-header{
    padding: 10px;
    border-bottom: 0;
    background: #f8f8f8;
}
.faq-header span{
    font-size: 14px;
    font-weight: 800;
    background: -webkit-linear-gradient(#583fcf, #408cd3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.items{
    max-height: 225px;
    overflow-y: auto;
}
@media (max-width:1100px){

}
</style>

<style>
.collapse-margin{
    margin: 0 !important;
}
.card{
    border: 0;
}
.open .lead{
    font-weight: 600;
}
.card-header{
    padding: 10px 50px;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
}
.lead{
    font-size: 14px;
    position: relative;
}
.open .lead:before{
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}
.lead:before{
    position: absolute;
    top: 50%;
    left: -100%;
    margin-top: -8px;
    content: '˅';
    font-size: 13px;
    font-weight: 100 !important;
    -webkit-transition: all .3s linear 0s;
    transition: all .3s linear 0s;
}
.card-body{
    padding: 10px;
    font-size: 12px;
    color: #5395d7;
}

</style>