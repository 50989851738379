<template>
    <div>
        <div class="mb-3">
            <h3 class="section-title mb-1">Valoarea finanțărilor - platforma Smis</h3>
            <p class="m-0">Echipa noastră actualizează zilnic sursele de finanțare. Mai jos regăsești evoluția surselor
                prezentate</p>
        </div>
        <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        data: null
    },
    data() {
        return {
            series: [{
                name: "Valoarea finanțării",
                data: this.data.dataChart.data
            }],
            chartOptions: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                markers: {
                    size: 6,
                },
                colors:['#458eb7'],
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'smooth'
                },
                grid: {
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: this.data.labelChart.labels,
                },
            },
        }
    },
}
</script>

<style scoped>
.section-title {
    font-size: var(--moduleTitleSize);
    color: #4b4b4f;
}

@media only screen and (max-width: 1600px) {
}
</style>