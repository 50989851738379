<template>
    <div>
        <b-form>
            <div v-for="(office, key) in data.offices" :key="key" class="row align-items-center border-bottom py-3 ">
                <div class="row col-10 justify-content-start">
                    <b-form-group
                        label="Județ"
                        class="group col-md"
                    >
                        <b-form-select 
                            v-model="office.county" 
                            :options="judete"
                        >
                        </b-form-select>
                    </b-form-group>
                    <b-form-group
                        label="Oraș"
                        class="group col-md"
                    >
                        <b-form-input
                            v-model="office.city"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                   
                        v-click-outside="closeAddressSuggestions"
                        label="Adresa"
                        class="group position-relative col-md"
                    >
                        <b-form-input
                            v-model="office.address"
                            autocomplete="off"
                            @input="getAddress($event,key)"
                            @click="openAddressSuggestion(key)"
                        ></b-form-input>
                        <div v-if="cautare===key" class="lista-adrese">
                        
                            <div v-for="adresa in adrese[key]" :key="adresa.place_id" class="lista-adrese-item" @click="selectAddress(adresa,key)">{{ adresa.label }}</div>
                        </div>
                    </b-form-group>
                </div>
                <div class="row col-10">
                    <b-form-group
                        label="Persoana de contact"
                        class="group col-md"
                    >
                        <b-form-input
                            v-model="office.contact_person"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="Email"
                        class="group col-md"
                    >
                        <b-form-input
                            v-model="office.email"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="Telefon"
                        class="group col-md"
                    >
                        <b-form-input
                            v-model="office.phone"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-md h-100 ">
                    <span class="delete-icon ml-2" @click="removeItems(key)"><b-icon-trash variant="danger" font-scale="1.1"></b-icon-trash></span>
                    <br>
                    <br>
                    <br>
                    <br>
                </div>
            </div>
            <b-button class="buton-submit-setari-cont mr-3 button-add-office" variant="primary" @click="addItems">Adaugă un birou</b-button>
            <b-button class="buton-submit-setari-cont" variant="primary" @click="onSubmit()">Salvează modificările</b-button>
        </b-form>
    </div>
</template>

<script>
    import {BForm, BFormGroup, BFormInput,BFormSelect, BFormTextarea, BButton, BIconTrash} from 'bootstrap-vue'
    import _ from 'lodash';
    import { OpenStreetMapProvider } from 'leaflet-geosearch';



    export default {
        components: {
            BForm,
            BFormGroup,
            BFormInput,
            BFormSelect,
            BFormTextarea,
            BButton,
            BIconTrash,
        },
        directives: {
            'click-outside': {
                bind: function(el, binding, vNode) {
                    // Provided expression must evaluate to a function.
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) { warn += `Found in component '${compName}'` }
          
                        console.warn(warn)
                    }
                    // Define Handler and cache it on the element
                    const bubble = binding.modifiers.bubble
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            e.stopPropagation();
                            binding.value(e)
                            
                            
                        }
                    }
                    
                    el.__vueClickOutside__ = handler

                    // add Event Listeners
                    document.addEventListener('click', handler)
                },
      
                unbind: function(el) {
                    // Remove Event Listeners
                    document.removeEventListener('click', el.__vueClickOutside__)
                    el.__vueClickOutside__ = null

                }
            }
        },
        props: {
            data: [],
            judete:{
                type:Array,
                default:()=>[]
            }
        },
        data() {
            return {
                provider:new OpenStreetMapProvider({
                    params: {
                        'accept-language': 'ro', // render results in Romanian
                        countrycodes: 'ro' ,// limit search results to the Romania
                        limit:40,
                    }}),
                adrese:[],
                cautari:[],
                cautare:-1,  
            }
        },
        created() {
            this.getAddress = _.debounce(this.getAddress,1500)

        },
        mounted() {
            this.data.offices.forEach(()=>{
                this.cautari.push(false);
            })
        },
        methods: {
            addItems() {
                this.data.offices.push({
                    address: '',
                    city: '',
                    contact_person: '',
                    phone: '',
                    email: '',
                    lat: '',
                    long: '',
                })
                this.cautari.push(false);
            },
            removeItems(key) {
                this.data.offices.splice(key,1)
            },
            onSubmit() {
                this.$emit('submitForm');
            },
            getAddress(address,index){
                this.provider.search({
                    query:address
                }).then( result => {
                    this.$set(this.adrese,index,result)
                    this.openAddressSuggestion(index);
                });
                
            },
            selectAddress(address,index){
                this.data.offices[index].address = address.label;
                this.data.offices[index].lat = address.y;
                this.data.offices[index].long = address.x;
                this.closeAddressSuggestions();
            },
            openAddressSuggestion(index){
                this.cautare=index
            },
            closeAddressSuggestions(){
                this.cautare=-1
            }  
        }
    }
</script>

<style scoped>
.padding-box {
    padding: 20px;
}
input,select,textarea {
    border-radius: 0;
    border: 1px solid #e8e8e8;
}
input:focus, textarea:focus {
    box-shadow: none;
}
.buton-submit-setari-cont {
    color: #fff;
    background-image: linear-gradient(180deg, #5b3cd0, #3bc6d4);
    border: none;
    font-size: var(--paragraphSize);
    font-weight: var(--bold);
}
.button-add-office{
    background: #3bc6d4;
}
.group:not(:last-child) {
    margin-right: 10px;
}
.delete-icon{
    cursor: pointer;
}
.lista-adrese{
    z-index: 3;
    background: #fff;
    position: absolute;
    max-height: 300px;
    overflow-y: scroll;

}
.lista-adrese-item{
    cursor: pointer;
    padding: 0.2rem 0.3rem;
    border: 1px solid #e8e8e8;  
}
.lista-adrese-item:hover{
    background: #f1f1f1;
}
.row{
    margin-right: 0;
    margin-left: 0;
}
select{
    margin-bottom : 0;
}
</style>