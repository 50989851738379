import Vue from 'vue'
import axios from 'axios'
import api from '@/helpers/api'
import router from '@/common/router'
// import store from '@/helpers/store'

const axiosIns = axios.create({
    baseURL: api.API_ROOT,
    withCredentials: true,
})

axiosIns.interceptors.request.use(request => {
    request.headers.common['Accept'] = 'application/json'
    request.headers.common['Content-Type'] = 'application/json'

    return request
})

axiosIns.interceptors.response.use(
    response => {
        return response
    },

    error => {
        if (error.response.status === 401 || error.response.status === 419) {
            localStorage.removeItem('user')
            // router.push({path: api.LOGIN_ROUTE});
            // window.location.reload()
        }
        else if (error.response.status === 404) {
            router.push({path: '/404'});
        }

        return Promise.reject(error)
    }
)

Vue.prototype.$http = axiosIns

export default axiosIns
