<template>
    <div class="h-100">
        <div class="card">
            <div class="card-body align-self-center w-100 text-center">
                <div class="imgWrapper">
                    <b-img
                        class="image"
                        v-if="subscriptie.plan.logo"
                        :src="subscriptie.plan.logo"
                        @click="bigSmall"
                    ></b-img>
                    <img v-else src="@/assets/images/fara-logo.png">
                </div>
                <div class="d-flex justify-content-end detalii" @click="bigSmall">
                    <p>detalii</p>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="#8be2d6"
                        class="bi bi-caret-down-fill align-self-center"
                        viewBox="0 0 17 17"
                    >
                        <path
                            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                        />
                    </svg>
                </div>

                <div v-if="isOpen === false"></div>
                <div v-else class="card-body align-self-center w-100 text-center">
                    <div v-if="!subscriptie.plan.invoice_interval.includes('lifetime')" class="d-flex border-sus-jos">
                        <b-icon-cart-4
                            v-if="subscriptie.status === null"
                            style="color: #5c4ad0"
                            icon="cart4"
                            font-scale="1.5"
                        ></b-icon-cart-4>
                        <div>
                            <p v-if="subscriptie.status === 'paid' && !subscriptie.plan.invoice_interval.includes('lifetime')" class="text-color-gradient mt-1">
                                Prelungește licența de utilizare
                            </p>
                            <div v-else-if="subscriptie.status === null && !subscriptie.plan.has_trial" class="d-flex">
                                <p class="fs-14">Opțiunea nu beneficiază de</p>
                                <p class="ml-1 min-width-50 serviciu-none">trial</p>
                            </div>
                            <p
                                v-else-if="subscriptie.status === null"
                                class="text-color-gradient mt-1"
                            >
                                Cumpără această aplicație
                            </p>
                            <p
                                v-if="subscriptie.status !== null && !subscriptie.status.includes('paid')"
                                class="text-color-gradient-factura font-weight-bold fs-17"
                            >
                              Factura neachitata!
                            </p>
                        </div>
                    </div>
                    <div v-else-if="subscriptie.plan.invoice_interval.includes('lifetime')">
                        <p v-if="subscriptie.status === 'paid'" class="text-color-gradient-pret fs-17 font-weight-bold border-sus-jos">
                          {{ subscriptie.license }}
                        </p>
                        <div class="d-flex border-sus-jos" v-else-if="subscriptie.status !== 'paid'">
                          <p class="text-color-gradient-factura font-weight-bold fs-17">Factura neachitata!</p>
                        </div>

                    </div>
                    <div class="mb-4">
                        <p class="my-3 text-center" v-html="subscriptie.plan.description"></p>
                        <a :href="'https://'+subscriptie.plan.app_link" target="_blank"><p class="text-faux-steel-blue">Vezi prezentarea aplicației</p></a>
                    </div>
                    <div
                        v-if="!subscriptie.plan.invoice_interval.includes('lifetime')"
                        class="d-flex border-sus-jos align-items-center"
                    >
                        <p>Abonament anual</p>
                        <ToggleSwitch class="mx-3" :click="anualLunar"></ToggleSwitch>
                        <p>Abonament lunar</p>
                    </div>
                    <div
                        v-else-if="subscriptie.plan.invoice_interval.includes('lifetime')"
                        class="d-flex border-sus-jos align-items-center"
                        style="margin-bottom: 14px"
                    >
                        <b-icon-check-circle-fill
                            icon="check-circle-fill"
                            style="margin-right: 10px; fill: var(--succesColor);"
                            font-scale="1.5"
                        ></b-icon-check-circle-fill>
                        <p>Licenta unica / o singura plata</p>
                    </div>

                    <div v-if="!subscriptie.plan.invoice_interval.includes('lifetime')">
                        <div v-if="tipAbonament === false" class="d-flex">
                            <h3 class="text-color-gradient-pret mr-3">
                                <sup class="text-color-gradient-pret">€</sup>{{ subscriptie.plan.price }}
                            </h3>
                            <h3 class="text-color-notselected-pret"><sup>€</sup>{{ subscriptie.plan.yearly_price }}</h3>
                        </div>
                        <div v-else class="d-flex">
                          <h3 class="text-color-notselected-pret mr-3"><sup>€</sup>{{ subscriptie.plan.price }}</h3>
                          <h3 class="text-color-gradient-pret">
                            <sup class="text-color-gradient-pret">€</sup>{{ subscriptie.plan.yearly_price }}
                          </h3>
                        </div>
                        <p>lunar / fără TVA / curs BNR din ziua plății</p>
                        <div>
                            <p v-if="tipAbonament === false" class="text-color-gradient-pret fs-17">
                                Economisești <span class="font-weight-bold">{{ subscriptie.plan.yearly_discount }}€</span> / an
                            </p>
                            <p v-else class="text-color-gradient-pret fs-17">
                                Cu plata anuală poți economisi
                                <span class="font-weight-bold">{{ subscriptie.plan.yearly_discount }}</span>€
                            </p>
                        </div>
                    </div>
                    <div v-else-if="subscriptie.plan.invoice_interval.includes('lifetime')">
                        <div class="d-flex">
                            <h3 class="text-color-gradient-pret">
                                <sup class="text-color-gradient-pret">€</sup>{{ subscriptie.plan.price }}
                            </h3>
                        </div>
                        <p>Fără TVA / curs BNR din ziua plății</p>
                        <div>
                            <p v-if="subscriptie.status === 'paid'" class="text-color-gradient-pret fs-17">
                                <span class="font-weight-bold">Achitat!</span> Iti multumim!
                            </p>
                        </div>
                    </div>

                    <div class="d-flex border-sus-jos mt-2" style="border-bottom: 0">
                        <p v-if="subscriptie.plan.invoice_interval !== null">Serviciu ales</p>
                        <div class="mx-2 min-width-50">
                            <p v-if="!subscriptie.on_trial" class="serviciu-licenta">licență</p>
                            <p v-else-if="subscriptie.on_trial" class="serviciu-trial">trial</p>
                            <p v-else-if="subscriptie.plan.price === '0'" class="serviciu-free">free</p>
                            <div v-else-if="subscriptie.plan.invoice_interval === null" class="d-flex">
                                <b-icon-cart-4
                                    style="color: #5c4ad0; margin-top: -4px"
                                    icon="cart4"
                                    font-scale="1.5"
                                ></b-icon-cart-4>
                                <p class="text-color-gradient ml-1">Cumpără această aplicație</p>
                            </div>
                        </div>
                        <div v-if="subscriptie.status !== null">
                            <p v-if="subscriptie.ends.indexOf('9999') !== 0" class="text-faux-steel-blue">
                                (valabilitate: 23.11.2022)
                            </p>
                            <p v-else class="text-faux-steel-blue">
                                (valabilitate:
                                <span class="text-color-gradient-pret font-weight-bold">NELIMITAT</span>)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {BImg,BIconCart4,BIconCheckCircleFill} from 'bootstrap-vue'
    import ToggleSwitch from "@/common/components/ToggleSwitch";
    export default {
        name: "Test",
        components: {
            ToggleSwitch,
            BImg,
            BIconCart4,
            BIconCheckCircleFill,
        },
        props: {
            pozaPachet: {
                type: String,
                default: "LogoSmisConsultantPROF",
            },
            tipPachet: {
                type: String,
                default: "abonament",
            },
            lunar: {
                type: Number,
                default: 0,
            },
            anual: {
                type: Number,
                default: 0,
            },
            pret: {
                type: Number,
                default: 0,
            },
            economisire: {
                type: Number,
                default: 0,
            },
            serviciu: {
                type: String,
                default: "none",
            },
            subscriptie: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                isOpen: false,
                tipAbonament: false,
                facturaAchitata: false,
            };
        },
        created() {},
        methods: {
            bigSmall() {
                this.isOpen = !this.isOpen;
            },
            anualLunar() {
                this.tipAbonament = !this.tipAbonament;
            },
        },
    };
</script>

<style scoped>
	.card-body p {
		text-align: center !important;
		color: #818181;
		font-size: 12px;
	}
	.card-body div {
		justify-content: center;
	}
	.card-body div h3 {
		font-size: 40px;
		font-weight: 900;
	}
	.card-body div h3 sup {
		font-size: 24px;
		font-weight: bold;
	}
  .text-center >>> p{
    text-align: center;
  }
	.fs-17 {
		font-size: 17px !important;
	}
	.fs-14 {
		font-size: 14px !important;
	}
	.imgWrapper {
		height: 41px;
		display: flex;
		align-items: center;
	}
	.image {
		width: 100%;
		max-width: 200px;
		height: auto;
		max-height: 50px;
        object-fit: cover;
	}
	.detalii {
		cursor: pointer;
	}
	.detalii svg {
		margin: 2px 0px 0 6px;
	}
	.border-sus-jos {
		border-bottom: 1px solid #f1f1f1;
		border-top: 1px solid #f1f1f1;
		padding: 12px;
	}
	.text-color-gradient {
		background: -webkit-linear-gradient(#6211cd, #52a1d4);
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.text-color-gradient-pret {
		background: -webkit-linear-gradient(#04c3a2, #52a1d4);
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.text-color-gradient-factura {
		background: -webkit-linear-gradient(#ff934c, #fc686f);
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.text-color-notselected-pret {
		color: #eeeeee;
	}
	.text-faux-steel-blue {
		color: #347eb7 !important;
	}
	.serviciu-free,
	.serviciu-licenta,
	.serviciu-trial,
	.serviciu-none {
		background-image: linear-gradient(#3bbfd2, #1ccbae);
		color: white !important;
		font-weight: bold;
		border-radius: 3px;
		font-size: 10px !important;
		align-self: center;
	}
	.serviciu-licenta {
		background-image: linear-gradient(#3a3a3a, #2c2c2c);
	}
	.serviciu-trial {
		background-image: linear-gradient(#ee3c7c, #fe9727);
	}
	.serviciu-none {
		background-image: linear-gradient(#969696, #a5a5a5, #8a8a8a);
	}
	.min-width-50 {
		min-width: 50px;
	}
</style>
