<template>
  <div class="modul-finantare-title">
    <div class="modul-header">
      <span class="bold-text gradient-title">Companie verificată și recomandată SMIS <sup>®</sup></span>
      <img src="@/assets/images/CompanieVerificata.png" alt="">
    </div>
    <div class="modul-content">
      <ul class="info-companie">
        <li>
          <b-icon-check-circle-fill
              style="margin-right: 10px; fill: #6118cd;"
              font-scale="1"
          ></b-icon-check-circle-fill>
          <span>Compania are date de contact valide și un site web public</span>
        </li>
        <li>
          <b-icon-check-circle-fill
              style="margin-right: 10px; fill: #5f2ace;"
              font-scale="1"
          ></b-icon-check-circle-fill>
          <span>Compania a furnizat CUI si documente ONRC fiind autorizată pentru activități de consultanță</span>
        </li>
        <li>
          <b-icon-check-circle-fill
              style="margin-right: 10px; fill: #5c45d0;"
              font-scale="1"
          ></b-icon-check-circle-fill>
          <span>Minim 5 proiecte finanțate - transmise și verificate de către echipa SMIS</span>
        </li>
        <li>
          <b-icon-check-circle-fill
              style="margin-right: 10px; fill: #5962d1;"
              font-scale="1"
          ></b-icon-check-circle-fill>
          <span>Compania a furnizat contactele a minim 5 clienți - care au transmis un feedback pozitiv către echipa SMIS</span>
        </li>
        <li>
          <b-icon-check-circle-fill
              style="margin-right: 10px; fill: #567ed2;"
              font-scale="1"
          ></b-icon-check-circle-fill>
          <span>Cel puțin un membru al echipei companiei este acreditat în domeniul managementului de proiect</span>
        </li>
        <li>
          <b-icon-check-circle-fill
              style="margin-right: 10px; fill: #5395d3;"
              font-scale="1"
          ></b-icon-check-circle-fill>
          <span>Cel puțin un membru al echipei companiei este certificat ca Expert în accesarea fondurilor structurale și de coeziune europene</span>
        </li>
        <div class="rating"><img src="@/assets/images/verificata-rating.png"></div>
      </ul>
    </div>
  </div>
</template>

<script>
import {BIconCheckCircleFill, BFormRating} from 'bootstrap-vue'

export default {
  components: {
    BIconCheckCircleFill,
    BFormRating
  },
  data() {
    return {
      review: {
        stars: 5,
      }
    };
  },
}
</script>

<style scoped>
.bold-text {
  font-weight: bold;
}
.text-purple{
  color: #6118cd;
}
.info-companie{
  position: relative;
}
.rating img {
  max-width: 185px;
  position: absolute;
  right: 0px;
  bottom: -20px;
}
.modul-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
li span{
  font-size: 12px;
}
.gradient-title{
    font-size: 14px;
  color: #1c1c1c;
}
@media only screen and (max-width: 900px) {
  .modul-header{
    display: block;
  }
}
</style>