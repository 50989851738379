<template>
    <div class="add-comment">
        <p class="cat-title">Adaugă un comentariu</p>
        <b-form @submit="onSubmit">
            <div class="com-form">
                <div class="flex">
                    <b-form-textarea
                        v-model="form.comment"
                        type="text"
                        class="message-field mt-1"
                    ></b-form-textarea>
                    <span
                        v-if="errors.has('comment')"
                        class="error-message"
                        v-text="errors.get('comment')"
                    ></span>
                </div>
                <b-button type="submit" class="send-button mt-2">Postează mesajul</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
    import Errors from '@/helpers/Errors';
    import {BForm, BFormTextarea, BButton} from "bootstrap-vue";
    import api from '@/helpers/api';
    import axios from '@/helpers/axios';
    export default {
        components: {
            BForm,
            BFormTextarea,
            BButton,
        },
        props: {
            idComentariu: null,
            tag: null,
        },
        data() {
            return {
                form: {
                    comment: null,
                    parent_id: this.idComentariu,
                },
                newComment: null,
                errors: new Errors(),
            };
        },
        mounted() {
            if(this.tag){
                this.form.comment = '@' + this.tag + ' ';
            }
        },
        methods: {
            onSubmit(event) {
                event.preventDefault();
                let id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
                axios
                    .post(api.API_ENDPOINTS.fundingComments + '/' + id, this.form)
                    .then().catch(err => {
                        console.log(err)
                    }).then(response => {
                        this.newComment = response.data.comment;
                        this.$emit('newCommentAdd', this.newComment);
                        this.$notify(response.data.message);

                    }).catch(xhr => {
                        if (xhr.response.status === 422) {
                            this.errors.record(xhr.response.data.errors);
                        }
                        this.$notify(xhr.response.data.message);
                    }).finally(() => {
                        this.form.comment = null
                        this.form.parent_id = null
                    }
                    );
            },
        },
    };
</script>

<style scoped>
    .cat-title {
        font-size: 14px;
        color: #434343;
        font-weight: 600;
    }

    .add-comment {
        width: 100%;
        margin-top: 10px;
    }

    .flex {
        display: flex;
    }

    .send-button {
        background: transparent;
        border: 1px solid #4b4e53;
        color: #4b4e53;
    }

    .send-button:hover {
        background: #4b4e53;
        color: #fff;
    }

    .message-field {
        background: transparent;
        border: 1px solid #4b4e53;
        color: #4b4e53;
        font-size: 14px;
        height: 4em;
        transition: 0.6s;
    }

    .message-field:focus {
        box-shadow: none;
        height: 10em;
    }
</style>