<template>
    <div v-if="!loading">
        <DarkBlueTitleBar :data="title"></DarkBlueTitleBar>
        <GradientTopSection></GradientTopSection>
        <Filters v-model="form.id" :companii="consultant" @getCompanyId="getCompanyId($event)"></Filters>
        <div class="box-padding">
            <div class="form-wrapper">
                <validation-observer ref="accountForm" #default="{ invalid }">
                    <b-form @submit="onSubmit">
                        <div class="white-box flex-box">
                                <b-form-rating
                                    id="rating"
                                    v-model="form.rating"
                                    color="#ffbe00"
                                    no-border
                                    size="lg"
                                ></b-form-rating>
                            <p>Alege o notă de la 1 la 5 selectând numărul de stele corespunzător unde 1 stea înseamnă<br>
                                Foarte nemulțumit (nu recomanzi compania) și 5 stele înseamnă Foarte mulțumit (recomanzi
                                compania)</p>
                        </div>
                        <span
                            class="error-message"
                            v-if="errors.has('rating')"
                            v-text="errors.get('rating')"
                        ></span>
                        <b-form-group>
                            <validation-provider
                                #default="{ errors }"
                                rules="required|min:20"
                                name="feedback"
                            >
                                <b-form-textarea
                                    id="comment"
                                    v-model="form.comment"
                                    :state="errors.length > 0 ? false : null"
                                    name="fullName"
                                    type="text"
                                    placeholder="Feedback (obligatoriu)"
                                    class="mt-3 feedback-company"
                                ></b-form-textarea>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>
                        <b-button type="submit" :disabled="invalid" class="buton-submit-setari-cont mt-3" variant="primary">Trimite feedback-ul
                            tău
                        </b-button>
                    </b-form>
                </validation-observer>
            </div>
        </div>
    </div>
    <LoadingSpinner v-else></LoadingSpinner>
</template>

<script>
import LoadingSpinner from '@/common/components/LoadingSpinner'
import Errors from '@/helpers/Errors';
import DarkBlueTitleBar from '@/common/components/DarkBlueTitleBar';
import GradientTopSection from './components/GradientTopSection';
import Filters from './components/Filters';
import api from '@/helpers/api';
import axios from '@/helpers/axios';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
require('@/helpers/validations/validations')
import {
    BForm,
    BFormRating,
    BFormTextarea,
    BButton,
    BFormGroup
} from "bootstrap-vue";

export default {
    components: {
        LoadingSpinner,
        DarkBlueTitleBar,
        GradientTopSection,
        Filters,
        BForm,
        BFormRating,
        BFormTextarea,
        BButton,
        BFormGroup,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            loading: true,
            title: 'Evaluează companie de consultanță',
            consultant: null,
            form: {
                comment: null,
                rating: null,
                id: null,
            },
            errors: new Errors(),
        }
    },
    mounted() {
        this.getCompanies()
    },
    methods: {
        getCompanies() {
            return axios.get(api.API_ENDPOINTS.consultants)
                .then(response => {
                    this.consultant = response.data;
                }).finally(() => this.loading = false)
        },
        getCompanyId(id) {
            this.$emit('getCompanyId', id);
            this.form.id = id;
        },
        onSubmit(event) {
            event.preventDefault();
            axios
                .post(api.API_ROOT + '/users/' + this.form.id + '/reviews', this.form)
                .then((response) => {
                    this.$notify(response.data.message);
                })
                .catch(xhr => {
                    if (xhr.response.status === 422) {
                        this.errors.record(xhr.response.data.errors);
                    }
                    this.$notify(xhr.response.data.message);
                })
                .finally(() =>
                        this.form.comment = null,
                    this.form.rating = null
                );
        },
    }
}
</script>

<style scoped>
.box-padding {
    padding: 20px 70px 0px 20px;
    margin-bottom: 130px;
}
#rating{
    width: 10%;
}
.form-wrapper {
    max-width: 55%;
}
#rating-lg-no-border {
    max-width: 180px;
}
.flex-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.white-box {
    background: #fff;
    border: #dddddd;
    border-radius: 4px;
    padding: 10px;
}
.feedback-company {
    background: #fff;
    border: #dddddd;
    border-radius: 4px;
    padding: 15px;
    font-size: 12px;
    color: var(--mainColorText);
    min-height: 200px;
}
@media only screen and (max-width: 900px) {
    .list-companii {
        grid-template-columns: auto;
    }

    .box-padding {
        padding: 20px 20px 0px 20px;
    }

    .form-wrapper {
        max-width: 100%;
    }

    .select-company {
        width: 300px !important;

    }
    .flex-box{
        display: block;
    }
}
</style>
<style>
.select-company {
    width: 300px;
}
</style>