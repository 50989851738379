<template>
    <div v-if="caen">
        <p class="info-text">CAEN principal</p>
        <strong class="nume-companie">{{caen}}</strong>
    </div>
</template>

<script>
export default{
    components:{
    },
    props: {
        caen: {
            type: String,
            default: null,
        },
    },
    data() {
        return{
        }
    },
    methods: {
    },
}
</script>

<style scoped>
.info-text{
    color: #fff;
    text-align: left;
    font-size: 13px;
    line-height: 18px;
}
.nume-companie{
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 16px;
}

</style>