import { render, staticRenderFns } from "./CifraAfaceri.vue?vue&type=template&id=19c00c36&scoped=true&"
import script from "./CifraAfaceri.vue?vue&type=script&lang=js&"
export * from "./CifraAfaceri.vue?vue&type=script&lang=js&"
import style0 from "./CifraAfaceri.vue?vue&type=style&index=0&id=19c00c36&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c00c36",
  null
  
)

export default component.exports