<template>
    <div class="flexed">
        <div>
            <img src="@/assets/images/finantari-prezente.png">
        </div>
        <div class="flexed-profit">
            <div
                class="profitDiv"
                v-for="(item, index) in profit"
                :key="index">
                <p class="text-bold">
                    Profit {{item.an}}
                </p>
                <p>
                    <span :class="item.hasProfit ? 'profit' : 'pierdere'">
                        {{item.hasProfit ? '+' : '-'}}
                    </span>
                    {{Number(item.suma).toLocaleString('ro-RO')}}
                    lei
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        profit: {
            type: Array,
            default: null,
        },
    }
}
</script>

<style scoped>
.flexed {
    display: flex;
    align-items: center;
}
.flexed-profit {
    display: flex;
    align-items: center;
    margin-left: 30px;
    width: 75%;
    justify-content: space-around;
}
.profitDiv p{
    text-align: center;
    color: #fff;
    font-size: 13px;
}
.profit{
    color: var(--succesColor);
    font-weight: 800;
}
.pierdere{
    color: #f6272b;
    font-weight: 800;
}
.text-bold{
    font-weight: 600;
}
p {
    text-align: left;
}

.finantari-prezente p {
    color: #fff;
    margin-left: 15px;
}

@media only screen and (max-width: 1600px) {
    .flexed {
        display: block;
    }
}
</style>