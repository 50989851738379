<template>
    <div class="padding-box">
        <b-form>
            <b-form-group
                label="Oferta speciala"
            >
                <b-form-textarea
                    v-model="form.offer"
                    rows="10"
                ></b-form-textarea>
            </b-form-group>
            <b-button type="submit" class="buton-submit-setari-cont" variant="primary">Salvează modificările</b-button>
        </b-form>
        {{consultant}}
    </div>
</template>

<script>
import {BForm, BFormGroup, BFormTextarea} from 'bootstrap-vue'
import api from '@/helpers/api';
import axios from '@/helpers/axios';

export default {
    components: {
        BForm,
        BFormGroup,
        BFormTextarea,
    },
    data() {
        return {
            form: {
                offer: null,
            }
        }
    },
    mounted() {
        this.getConsultants()
    },
    methods: {
        getConsultants() {
            return  axios.get(api.API_ENDPOINTS.consultants + '/' + this.$store.state.auth.user.consultant_id)
                .then(response => {
                    this.consultant = response.data
                }).finally(() => this.loading = false)
        },
    }
}
</script>

<style scoped>
.padding-box{
    padding: 20px;
}
input, textarea{
    border-radius: 0;
    border: 1px solid #e8e8e8;
}
input:focus, textarea:focus{
    box-shadow: none;
}
.buton-submit-setari-cont {
    color: #fff;
    background-image: linear-gradient(180deg, #5b3cd0, #3bc6d4);
    border: none;
    font-size: var(--paragraphSize);
    font-weight: var(--bold);
    padding: 6px 10px;
    border-radius: 3px;
}
</style>