<template>
    <div>
        <div class="background-container"
             v-bind:style="{ background: 'linear-gradient(rgb(25 25 25 / 80%), rgb(19 19 19 / 80%)),' + ' url(' + data.banner + ')' }">
            <div class="hdr mt-5">
                <h3 v-if="data.name" class="text-left text-bold m-0 p-0 text-uppercase">{{ data.name }} <img
                    src="@/assets/images/checkMark.png" class="ml-2"></h3>
                <p v-if="data.city || data.adress" class="m-0 text-uppercase">{{ data.city }}, {{ data.adress }}</p>
                <div class="rating">
                    <b-form-rating v-if="data.rating" id="rating-lg-no-border" class="p-0 mr-2"
                                   :value="data.rating.avg_rating" color="#ffbe00" no-border size="sm"
                                   readonly></b-form-rating>
                    <p>({{ data.rating.count }} păreri / {{ parseFloat(data.rating.avg_rating).toFixed(2) }})</p>
                </div>
                <div class="flex">
                    <div class="info-business">
                        <p v-if="data.phone" class="text-left p-0 mt-3" style="margin:0">
                            Telefon: {{ data.phone }}
                        </p>
                        <p v-if="data.email" class="text-left p-0">
                            Email: {{ data.email }}
                        </p>
                    </div>
                    <div class="companie-partener">
                        <p class="mt-3">Companie membru</p>
                        <img src="@/assets/images/amcor.png" alt="smis">
                    </div>
                    <div class="companie-partener">
                        <p class="mt-3">Companie membru</p>
                        <img src="@/assets/images/acrafe.png" alt="smis">
                    </div>
                </div>
                <a v-if="data.website" :href="data.website">
                    <p class="text-left m-0 p-0">{{ formatUrl(data.website, true) }}</p>
                </a>

            </div>
            <div class="details mb-2">
                <img v-if="data.logo" :src="data.logo">
            </div>
        </div>
    </div>
</template>

<script>
import {BFormRating} from 'bootstrap-vue'
import {formatUrl} from '@/helpers/helpers'

export default {
    components: {
        BFormRating,
    },
    props: {
        data: null,
    },
    data() {
        return {
            review: {
                stars: 4.5,
            },
        }
    },
    methods: {
        formatUrl,
    }

}
</script>

<style scoped>
.background-container {
    display: flex;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 20px 5px 20px;
    align-items: flex-end;
    background-position: center !important;
}

.text-uppercase {
    text-align: left;
}

.background-container .details {
    display: flex;
}
.flex{
    display: flex;
}
p {
    text-align: center;
    font-size: 12px;
    color: #fff;
}

h3 {
    font-size: 18px;
    color: #fff;
}

.buget {
    width: 300px;
}

.buget p {
    font-size: 15px;
}

.rating {
    display: flex;
    align-items: center;
}

.rating .form-control {
    background-color: transparent !important;
    max-width: 115px;
}

.finantare {
    border-right: 3px dotted #f4f6f6;
    border-left: 3px dotted #f4f6f6;
    text-align: center;
    width: 210px;
    padding: 0 20px;
}

.text-left {
    text-align: left;
    padding-left: 10px;
}

.text-bold,
.margin-text-b {
    font-weight: bold;
}

.margin-text-b {
    margin-bottom: 10px;
}

.list-axa {
    position: relative;
}

.list-axa:not(:last-child) {
    margin-right: 10px;
}

.list-axa:not(:last-child):before {
    content: "";
    position: absolute;
    width: 1px;
    height: 80%;
    background: #fff;
    right: -3%;
    bottom: 0;
}

.details img {
    max-height: 100px;
}
.companie-partener{
    margin-left: 70px;
    min-height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 200px;
    position: relative;
}
.info-business{
    position: relative;
    padding-right: 60px;
}
.info-business:before{
    content: '';
    position: absolute;
    right: 0;
    top: 10px;
    border-right: 2px dotted #fff;
    display: block;
    min-height: 60px;
    width: 2px;
    height: 90%;
    opacity: 0.3;
}
.companie-partener:before{
    content: '';
    position: absolute;
    right: 0;
    top: 10px;
    border-right: 2px dotted #fff;
    display: block;
    min-height: 60px;
    width: 2px;
    height: 90%;
    opacity: 0.3;
}
.companie-partener img{
    max-height: 33px;
    object-fit: none;
}
@media only screen and (max-width: 1030px) {
    .background-container {
        flex-direction: column;
    }

    .finantare {
        border: none;
    }

    .buget {
        display: flex;
    }

    .buget > span {
        margin: 10px;
    }

    .details {
        margin-top: 20px;
        flex-direction: row-reverse;
        flex-direction: column;
    }

    .finantare, .buget {
        width: auto;
    }

    .hdr * {
        text-align: left !important;
    }
}

@media only screen and (max-width: 450px) {
    .buget {
        flex-direction: column;
    }
}
</style>
