<template>
    <div class="modul-finantare-title item-e">
        <span class="bold-text">Activități eligibile</span>
        <div class="modul-finantare-text fixedHeight" v-html="data.activities"></div>
    </div>
</template>

<script>
    export default{
        props: {
            data: null,
        },
        data() {
            return {
                items: []
            }
        },
        methods: {},
    }
</script>

<style scoped>
    .fixedHeight {
        height: 280px;
        overflow-y: auto;
        padding-right: 10px;
    }

    .bold-text {
        font-weight: bold;
        font-size: var(--moduleTitleSize);
    }

    .item-e {
        grid-area: activitatiEligibile;
        grid-row: 3 / 6;
    }

    .modul-finantare-content {
        margin-top: 10px;
        color: #868686;
        font-size: var(--paragraphSize);
        display: flex;
    }

    .modul-finantare-text > > > p {
        font-size: var(--paragraphSize);
        color: #868686;
    }

    .modul-finantare-text > > > h1, .modul-finantare-text > > > h2, .modul-finantare-text > > > h3, .modul-finantare-text > > > h4, .modul-finantare-text > > > h5 {
        font-size: var(--moduleTitleSize);
        margin-top: 10px;
        color: #868686;
    }

    @media only screen and (max-width: 1250px) {
        .item-e {
            grid-area: activitatiEligibile;
            grid-row: 4 / 4;
        }
    }

    @media only screen and (max-width: 1100px) {
        .item-e {
            grid-area: activitatiEligibile;
            grid-template-rows: auto;
        }
    }

    @media only screen and (max-width: 710px) {
        .item-e {
            grid-column: 1;
        }
    }
</style>