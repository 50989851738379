<template>
    <div>
        <div class="box-title"> 
            Pasul 1 <span class="space-text">/</span> <span class="title-text"> Termeni &amp; Condiții</span>
        </div>
        <div class="box-content">
            <div class="d-flex">
                <div class="first-text">
                    <h3 class="mb-2">Salut!</h3>
                    <p class="mb-2">Îți mulțumim pentru că alegi să utilizezi M2manager.</p> 
                    <p class="mb-2">Instrumentul de implementare se adresează atât firmelor care au primit grantul pentru capital de lucru, cât și firmelor ce urmează a 
                        primi grantul. În cele ce urmează te rugăm să completezi câteva informații. Acestea ne ajută in configurarea profilului tău si sunt necesare aplicației de implementare.
                    </p>
                    <p>
                        Citește mai jos Termenii si condițiile.
                    </p>
                </div>
                
                <b-img
                    class="logo-smis-pas1"
                    :src="require('@/assets/images/SmisLogoCompaniaMea.png')"
                    alt="SMIS Logo"
                ></b-img>

            </div>
            <div class="text-mare">
                <p class="mb-3 title-text">
                    TERMENI GENERALI – PREZENTARE
                </p>
                <p class="mb-3">
                    <a href="//smis.ro">www.smis.ro</a> este o platformă de informare cu privire la finanțările europene, integrează funcționalități de implementare.
                </p>
                <p class="mb-3">
                    Pentru a accesa aplicația prin care potenţialii beneficiari din Romania vor putea solicita bani europeni pentru perioada de programare 2014-2020 sau 2021-2027, vă invitam să vizitați MySMIS.ro
                </p>
                <p class="mb-3">
                    Platforma <a href="//smis.ro">www.smis.ro</a> aparţine şi este controlată de către SOCIETATEA STRUCTURAL MANAGEMENT INNOVATIVE SYSTEM SRL, CUI 43743807, J15/217/2021, numită in cele ce urmează Societatea/ Furnizor/ Producător sau SMIS. Utilizarea platformei presupune acordul dumneavoastră cu Structural Management Innovative System SRL de a respecta şi a acţiona în conformitate cu Termenii şi condiţiile de utilizare a website-ului. Proiectul SMIS.ro reprezintă răspunsul corect la procesul de transparența, digitalizare și informare a societății cu privire la programele de finanțare prin fonduri europene și naționale.
                </p>
                <p class="mb-3">
                    SMIS este o platformă complexă ce reunește sursele de finanțare disponibile din fonduri europene și naționale, aplicații software de scriere și implementare proiecte, precum și secțiuni online cu informații și analize ale echipei proprii și alte informații preluate de la terti parteneri sau din presă.
                </p>
                <p class="title-text">
                    CE REGĂSIM PE PLATFORMA SMIS
                </p>
                <p>
                    Informare: Este o platformă de informare asupra surselor de finanțare, culese din zeci de surse și transpuse într-o formă accesibilă utilizatorilor. Platforma lansează știri, analize, comunicate de presă, interviuri
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus felis lectus, ac convallis metus mattis in. Aliquam molestie lorem ut arcu semper, quis scelerisque lorem venenatis. Morbi sed cursus metus. Praesent sodales vestibulum enim vitae vestibulum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh nibh, consectetur vel elementum id, tincidunt vitae nisi. Integer ultrices lacus nec placerat malesuada. Fusce gravida, lacus ac lacinia rhoncus, purus odio pellentesque sapien, hendrerit dignissim turpis urna id purus. Sed vel tortor vestibulum, ultricies lectus a, scelerisque ante. Nullam ac ullamcorper massa. Aenean in dapibus justo. Nunc tempus quam id dolor tincidunt, vel lobortis augue fermentum. Sed tempor lorem et convallis blandit. In vitae faucibus mi, dapibus commodo leo. Duis mattis quam sed enim pharetra, et placerat ex accumsan.

                    Nulla facilisi. Sed dignissim consequat mattis. Maecenas laoreet sit amet ligula eu blandit. Morbi semper elit dui, non commodo arcu interdum sed. Nullam tincidunt leo quam, in dictum nisi tincidunt quis. Suspendisse at sem sed lacus consectetur rutrum. Praesent ac efficitur eros. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Praesent dui urna, fermentum eget placerat fringilla, commodo vel est. Nullam ac aliquet ipsum, eget mattis urna. Suspendisse at lobortis purus. Praesent ornare nec tortor non volutpat.

                    Suspendisse aliquet varius augue, ut varius ante. Pellentesque fermentum diam leo, quis viverra orci sagittis in. Integer id bibendum orci. Nam a euismod tellus. Morbi finibus egestas tincidunt. Ut est nulla, volutpat at ante quis, porta bibendum massa. Suspendisse pulvinar laoreet urna eget vehicula. Aenean vestibulum pellentesque commodo. Sed porttitor lacus lacus, a luctus tortor mattis at. Mauris aliquet ligula eget dui convallis, nec placerat elit laoreet. Nullam cursus eu ipsum vitae sollicitudin. Donec eget velit a felis porttitor pharetra non sed lectus. Nunc tempor consectetur vulputate. Integer cursus ligula quis ipsum maximus bibendum. Morbi risus eros, malesuada nec tellus vel, venenatis tempus ligula. Mauris convallis, arcu sit amet gravida condimentum, dui nibh gravida velit, non sollicitudin ex odio ut turpis.

                    Praesent tincidunt ligula ac lectus tincidunt iaculis. Etiam sit amet neque lacus. Cras aliquam, odio a vestibulum euismod, ante libero elementum ipsum, non fringilla sem sem sagittis felis. Vivamus dignissim, mi non rhoncus vestibulum, lorem eros volutpat tortor, in lacinia nisi neque sed mauris. Vivamus quis volutpat velit, eget porta ex. Maecenas laoreet ex id gravida dapibus. Etiam feugiat vitae sapien quis porttitor. Suspendisse tristique enim ac mollis hendrerit. Ut at erat ex. Sed ut rhoncus sem. Sed ultrices et sapien a viverra. Nullam in mi in turpis condimentum fermentum placerat non ex. Integer bibendum vitae mauris et euismod.

                    Aenean ante est, maximus sit amet tincidunt vel, ultrices et leo. Morbi molestie dolor vitae ligula ultrices, in bibendum purus pharetra. Pellentesque ex dui, placerat vel dolor in, posuere dapibus enim. Vivamus vel lacinia erat, tincidunt congue nisi. Maecenas varius sagittis scelerisque. Pellentesque cursus pharetra nunc ut lobortis. Vestibulum aliquet sagittis massa, in placerat augue placerat ut. Vestibulum viverra at nisi sed porta. Integer nec augue id ipsum facilisis venenatis. Vivamus at eleifend libero. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla et magna eu nunc sagittis faucibus.

                    Sed pellentesque vel diam ac laoreet. Nulla sed sapien eget sem blandit efficitur a at libero. Aenean laoreet lobortis libero. Morbi ac interdum quam. Curabitur interdum molestie diam, vitae cursus est condimentum id. Phasellus nisi felis, viverra non eros at, tempor iaculis purus. Nunc a leo vel quam tempus aliquet. Proin vulputate velit sem, id sollicitudin neque fermentum ac. Ut aliquam, diam ac accumsan pulvinar, tellus odio pulvinar lectus, gravida volutpat tellus erat eget tortor. Donec venenatis, tellus eget aliquet consequat, massa dolor lobortis risus, vitae tincidunt risus urna sed lorem. Praesent feugiat gravida nisl, eget varius enim euismod ac. Nulla tellus ligula, fringilla vel finibus ultrices, tristique ullamcorper sem.

                    Morbi rutrum ante enim, nec tincidunt sem tincidunt sed. Nulla facilisis varius sem sed aliquam. Mauris luctus arcu sed sapien mollis, eu venenatis eros scelerisque. Nunc felis nunc, molestie ut massa id, egestas consectetur risus. Donec eget condimentum odio. In pharetra magna tortor, non dictum felis laoreet ac. Vestibulum imperdiet hendrerit quam ac vestibulum. Nullam ac posuere augue, a faucibus quam. Suspendisse egestas feugiat nulla ut convallis. Phasellus porta elementum nisl non convallis. Quisque nec odio in tortor hendrerit maximus eu id ligula. Praesent molestie metus et nisl vestibulum feugiat. Donec tincidunt elementum libero, ut facilisis tortor bibendum vitae.

                    Quisque volutpat libero vestibulum eros pellentesque molestie. Quisque rutrum massa tristique urna tempus, a ornare felis ullamcorper. Nam ultricies mauris nisl, et mattis erat hendrerit et. Praesent rhoncus efficitur elit, sed condimentum nibh porta vitae. Praesent quam risus, varius et pulvinar ultricies, rutrum sit amet erat. Nunc at placerat massa. Nam sagittis bibendum massa, sed sodales nunc scelerisque vitae. Ut at porttitor mi, a dictum eros. Curabitur dignissim diam elit, convallis facilisis libero aliquet quis. Donec eleifend tempor lacinia.

                    Phasellus nec ante id dolor consequat ultricies ultrices a est. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed enim risus, scelerisque eget ligula eget, molestie vulputate nibh. Donec ipsum urna, finibus in finibus ut, mollis a eros. Vestibulum volutpat, nulla in convallis venenatis, odio lacus lacinia augue, ornare viverra tortor neque a eros. Sed nibh ipsum, facilisis a molestie non, finibus quis augue. Aliquam non scelerisque orci. Fusce eget massa sit amet magna consectetur sollicitudin ut ac nisi. Fusce est dui, ornare tincidunt felis ut, efficitur ultricies dolor. Integer pellentesque vehicula est sit amet imperdiet.

                    Donec nisl est, venenatis ac interdum non, eleifend at risus. Etiam sed ligula pretium, tristique urna vitae, fermentum nisi. Nulla sit amet pellentesque diam. Vestibulum sit amet elit lorem. Suspendisse consectetur nunc vitae faucibus dictum. Etiam dui dolor, condimentum quis lorem ornare, finibus accumsan quam. Nam non suscipit enim. Donec vel mauris tellus. Phasellus eget ipsum consectetur tellus pharetra ullamcorper ac et odio. Nam in eros dictum, porttitor lacus et, imperdiet diam.

                    Sed faucibus, lacus vel pulvinar auctor, magna leo interdum dui, nec congue leo ante et felis. Etiam blandit interdum est, nec hendrerit risus vehicula a. Nullam mattis pellentesque augue a mattis. Pellentesque posuere metus est, ultricies consequat arcu pulvinar id. Vivamus vel iaculis odio. Morbi blandit et tortor sit amet accumsan. Aliquam ullamcorper lorem tempor metus tristique hendrerit. Duis in finibus lorem. Fusce iaculis velit eu elementum bibendum. Sed luctus tempor libero nec iaculis. Suspendisse pellentesque bibendum felis. Vivamus non tortor lacinia, pulvinar velit nec, posuere nisi.

                    In in rhoncus nisi. Praesent eu feugiat orci. Sed vel sem sit amet lacus vehicula egestas. Fusce scelerisque ac turpis sed venenatis. Pellentesque ut efficitur eros. Duis non accumsan est. Suspendisse volutpat nunc sapien. Nam laoreet eleifend nulla, ut imperdiet est semper id. Maecenas purus eros, ullamcorper ac finibus pulvinar, convallis sed justo. Donec finibus est eu massa scelerisque egestas.

                    Donec at vulputate erat. Nunc eu est eu eros molestie euismod ullamcorper blandit tortor. Ut vitae lacus quis massa convallis vehicula. Maecenas lectus ipsum, luctus nec libero quis, rhoncus tempus sem. Mauris nec fermentum turpis. Quisque efficitur neque vel justo condimentum, at viverra velit pharetra. Vivamus gravida commodo ligula, vitae vestibulum urna malesuada vel.

                    Suspendisse in velit nunc. Maecenas vitae velit ac risus tincidunt vehicula. Fusce commodo egestas velit vitae fermentum. Donec dictum leo id vulputate aliquam. Praesent est lectus, tempor eu congue eu, lacinia vitae justo. Proin vel lectus facilisis, bibendum mauris eu, cursus tortor. Vestibulum id magna quis libero tincidunt aliquet. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Phasellus interdum sed erat dictum tempor. Morbi facilisis lorem et dui vehicula iaculis. Curabitur semper et ligula at fringilla. Mauris purus nulla, efficitur et eleifend in, laoreet at dui. Proin pulvinar facilisis orci in sodales. Cras viverra, nulla ut hendrerit mattis, magna magna sollicitudin eros, et maximus lorem urna in purus.

                    Duis pretium, justo quis tincidunt vestibulum, ante tellus ullamcorper nulla, non hendrerit nunc turpis in risus. Nunc rutrum commodo nisi, nec euismod lorem tempus ut. In id interdum augue, id blandit metus. Nulla venenatis diam ut neque elementum placerat. Mauris sagittis diam eros. Fusce eleifend ligula a facilisis eleifend. In tincidunt feugiat purus, in auctor urna tempor in. Curabitur sed urna gravida lacus ornare mollis. In ultricies purus at diam tristique condimentum. Etiam vitae lectus lacinia odio venenatis scelerisque. Proin finibus, turpis eu scelerisque mollis, quam ante fermentum eros, eu sagittis lectus lorem eget nunc. Ut pellentesque tincidunt malesuada. Proin porttitor odio in nibh condimentum scelerisque. Duis scelerisque laoreet metus vel dictum.

                    Aenean molestie ultrices tortor, vel euismod ipsum sollicitudin vitae. Nulla lobortis purus vitae magna semper, eu venenatis ex sagittis. In nec pellentesque lectus, ac ornare tellus. Sed convallis ullamcorper ligula, at sollicitudin neque lobortis nec. Aenean fringilla finibus tortor at lobortis. Vivamus ac pretium massa. Ut facilisis dolor at nibh aliquam, quis dapibus velit luctus. Quisque nec consequat erat, quis sodales felis.

                    Praesent vel ante et erat pellentesque malesuada. Suspendisse tellus ipsum, feugiat id feugiat eu, egestas eget risus. Vivamus suscipit orci in faucibus maximus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Etiam et ex euismod, tincidunt dolor dignissim, molestie ex. Aliquam et ullamcorper eros, ac dapibus erat. Nam vestibulum mattis arcu, non gravida ante varius sit amet. Maecenas rutrum semper eros, ut hendrerit ante venenatis convallis. Nullam congue ultrices iaculis.

                    Phasellus viverra leo est, eget faucibus ante consectetur a. Fusce faucibus suscipit lectus volutpat egestas. Quisque dignissim, velit a pulvinar malesuada, est turpis ultricies lacus, vitae posuere libero nunc quis lectus. Pellentesque a nisi luctus sem iaculis congue ac quis enim. Ut tincidunt vehicula tortor sed porttitor. Praesent porttitor lacinia sapien. Curabitur condimentum orci ac erat cursus fermentum. Vestibulum vitae massa sed est imperdiet facilisis. Sed placerat at magna sed ultrices. Curabitur fringilla tempor lobortis. Quisque pharetra tincidunt pretium. Pellentesque id nibh ac massa viverra pharetra et non quam. Cras molestie tellus laoreet ornare tempor. Morbi vehicula dolor eu lorem aliquet eleifend.

                    Pellentesque ut urna tincidunt, pellentesque eros eget, tempor nisi. Pellentesque pretium purus nec elit dignissim, sed accumsan risus luctus. Sed sem erat, rutrum et nisi id, pellentesque porttitor augue. Ut accumsan massa ut nunc elementum, non consequat justo varius. Quisque mollis aliquam purus, eu vulputate est facilisis et. Sed egestas sodales tortor, sit amet semper nisl dignissim et. Maecenas vel iaculis lorem, at scelerisque lorem. Praesent est magna, efficitur sit amet auctor et, fermentum sed magna. Morbi nec elit tristique, faucibus ipsum eu, interdum tellus. Vivamus vehicula at libero nec porta. Sed vitae leo aliquam, ornare libero eu, accumsan elit. Etiam lacinia orci arcu, sit amet venenatis elit maximus eu. Pellentesque ut augue dui. Nulla augue nulla, imperdiet id magna id, vehicula placerat sapien. Duis convallis diam id lacus cursus dignissim. Vestibulum eleifend erat justo, sit amet egestas ligula vehicula a.

                    Proin laoreet massa vitae tellus ornare suscipit. Sed quis arcu quis nulla gravida efficitur interdum vel erat. In non sodales risus, et sagittis odio. Mauris porttitor imperdiet lorem eget sollicitudin. Ut molestie, nibh sit amet porttitor imperdiet, eros lacus scelerisque est, imperdiet porta nibh erat ac neque. Ut lectus dolor, viverra vitae interdum in, pretium sed neque. Curabitur vestibulum facilisis velit, a suscipit nulla lacinia sodales. Pellentesque mollis at ipsum eu maximus. Maecenas cursus mi ac ex posuere consequat.
                </p>
            </div>
            <div class="checkbox-container">
                <div>
                    <input id="tAndC" class="checkbox" type="checkbox">
                    <label for="tAndC" class="checkbox-paragraph">Am citit și sunt de acord cu termenii si condițiile de mai sus
                        <br><span class="checkbox-date">Acceptat în data de 11.03.2021, 12:59:16</span>
                    </label>
                </div>
                <ButtonGradient class="next-button" url-buton="dadsdsss" text="Continuă >" classname="background"></ButtonGradient>
            </div>
        </div>
    </div>
</template>



<script>
    import ButtonGradient from '@/common/components/ButtonGradient'
    import {BImg} from 'bootstrap-vue'
    export default {
        name: "Test",
        components: {
            ButtonGradient,
            BImg },
    };
</script>

<style scoped>
.logo-smis-pas1 {
	padding: 20px 40px 0 0;
}
.box {
	align-items: center;
	color: rgb(0, 0, 0);
	margin: 10px 0 0 10px;
	border: 1px solid #e7e7e7;
}
.box-title {
	background-color: #f8f8f8;
	color: #5744cf;
	box-sizing: border-box;
	padding:20px 10px 20px 25px;
}
.title-text {
	font-weight: bold;
}
.box-content {
	background-color: #fff;
}
p {
    font-size: 14px;
}
.first-text {
	display: flex;
	align-items: center;
	flex-direction: column;
	max-width: 70%;
	margin: 0 auto;
	padding-top: 20px;
}
.first-text h3 {
    font-size: 16px;
     color: #314081;
    font-weight: bolder;
}
.first-text p {
    color: #314081;
    font-size: 14px;
    text-align: center;
}
.text-mare {
	overflow-y: scroll;
	text-align: left;
	border: 1px solid #e7e7e7;
	margin: 30px;
	padding: 12px;
	height: 300px;
}

.checkbox-container {
	padding: 10px 10px 25px 10px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
    position: relative;
}
.checkbox {
	width: 20px;
	height: 20px;
	cursor: pointer;
	margin-bottom: 10px;
}
.checkbox:checked {
	box-shadow: 0px 4px 10px 5px #b3d2f9;
}
.checkbox-paragraph {
	font-weight: normal;
	margin-left: 10px;
	color: #5495d7;
	font-size: 12px;
}
.checkbox-date {
	align-items: center;
	flex-direction: row;
	justify-content: center;
	display: flex;
	color: #6a729d;
	font-size: 10px;
}
.next-button {
    position: absolute;
    right: 30px;
}
@media only screen and (max-width: 1160px) {
.checkbox-container {
    display: flex;
    flex-direction: column;
}
.next-button { 
    position: relative;
    right: 0;
}
}
</style>
