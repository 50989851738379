<template>
    <div class="sidebar">
        <div class="sidebar-body">
            <b-button class="add-task" variant="primary" @click="$emit('openSidebar', 'adaugare')">Adaugă task</b-button>
            <ul class="sidebar-menu">
                <li class="s-menu-item"
                 @click="$emit('filter')">
                    <b-icon-envelope
                        style="margin-right: 10px"
                        class="menu-icon"
                        :class="{ 'item-active': activeMenu === 'toate' }"
                        font-scale="1"
                    ></b-icon-envelope>
                    <span :class="{ 'item-active': activeMenu === 'toate' }">Toate taskurile</span>
                </li>
                <li class="s-menu-item"
                    @click="$emit('filter', 'de-facut')">
                    <b-icon-pencil
                        style="margin-right: 10px"
                        class="menu-icon"
                        :class="{ 'item-active': activeMenu === 'de-facut' }"
                        font-scale="1"
                    ></b-icon-pencil>
                    <span :class="{ 'item-active': activeMenu === 'de-facut' }">De făcut</span>
                </li>
                <li class="s-menu-item"
                @click="$emit('filter', 'importante')">
                    <b-icon-star
                        style="margin-right: 10px"
                        class="menu-icon"
                        :class="{ 'item-active': activeMenu === 'importante' }"
                        font-scale="1"
                    ></b-icon-star>
                    <span :class="{ 'item-active': activeMenu === 'importante' }">Importante</span>
                </li>
                <li class="s-menu-item"
                    @click="$emit('filter', 'finalizate')">
                    <b-icon-check2-circle
                        style="margin-right: 10px"
                        class="menu-icon"
                        :class="{ 'item-active': activeMenu === 'finalizate' }"
                        font-scale="1"
                    ></b-icon-check2-circle>
                    <span :class="{ 'item-active': activeMenu === 'finalizate' }">Finalizate</span>
                </li>
            </ul>
            <div class="add-label">
                <span class="labels-title">Etichete</span>
                <b-button class="add-label-button" variant="primary" @click="modalShow = !modalShow">Adaugă</b-button>

                <b-modal
                    v-model="modalShow"
                    button-size="sm"
                    ok-variant="success"
                    ok-title="Confirmă"
                    cancel-variant="danger"
                    cancel-title="Anulează"
                    centered
                    @ok="onSubmit"
                >
                    <b-form>
                        <b-form-group id="input-name" class="form-label">
                            <label>Nume etichetă</label>
                            <b-form-input
                                id="name"
                                v-model="form.name"
                                type="text"
                                placeholder="Nume etichetă"
                                class="w-100"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group id="input-color" class="form-label">
                            <label class="w-100">Culoare</label>
                            <v-input-colorpicker v-model="form.color"></v-input-colorpicker>
                        </b-form-group>

                    </b-form>
                </b-modal>
            </div>
            <div class="labels">
                <div v-for="(tag, key) in tags" :key="key" @click="$emit('filterTags', tag.id)" class="tag-item">
                    <span class="bullet bullet-sm mr-1" :style="{ 'background-color': tag.color }"></span>
                    <span class="label-title">{{ tag.name }}</span>
                    <span style="float: right; display: flex;">
                        <b-icon-pencil-square
                            class="todo-icons"
                            v-on:click.stop
                            @click="infoTag(tag)"
                        ></b-icon-pencil-square>
                        <b-icon-trash
                            class="todo-icons"
                            v-on:click.stop
                            @click="removeTag(tag.id)"
                        ></b-icon-trash>
                    </span>
                </div>
                <b-modal
                    v-model="modalTagInfo"
                    button-size="sm"
                    ok-variant="success"
                    ok-title="Confirmă"
                    cancel-variant="danger"
                    cancel-title="Anulează"
                    centered
                    @ok="changeTag(form.id)"
                >
                    <b-form>
                        <b-form-group id="input-name" class="form-label">
                            <label>Nume etichetă</label>
                            <b-form-input
                                id="name"
                                v-model="form.name"
                                type="text"
                                placeholder="Nume etichetă"
                                class="w-100"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group id="input-color" class="form-label">
                            <label class="w-100">Culoare</label>
                            <v-input-colorpicker v-model="form.color"></v-input-colorpicker>
                        </b-form-group>
                    </b-form>
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
import {BButton, BIconEnvelope, BIconPencil, BIconStar, BIconCheck2Circle, BModal, BForm, BFormGroup, BFormInput, BIconTrash, BIconPencilSquare} from 'bootstrap-vue'
import InputColorPicker from "vue-native-color-picker";
import api from '@/helpers/api'
import axios from '@/helpers/axios'

export default{
    components:{
        BButton,
        BIconEnvelope,
        BIconPencil,
        BIconStar,
        BIconCheck2Circle,
        BModal,
        BForm,
        BFormGroup,
        BFormInput,
        BIconPencilSquare,
        BIconTrash,
        "v-input-colorpicker": InputColorPicker
    },
    props: {
        tags: [],
        activeMenu: null,
    },
    data() {
        return{
            form: {
                id: '',
                name: null,
                color: '#000',
            },
            modalShow: false,
            modalTagInfo: false,
        }
    },
    methods: {
        onSubmit() {
            axios.post(api.API_ENDPOINTS.tags, this.form)
                .then((response) => {
                    this.$notify(response.data.message);
                    this.tags.push(response.data.tag)
                }).catch((xhr) => {
                if (xhr.status === 422) {
                    this.errors.record(xhr.responseJSON.errors);
                }

                this.$notify(xhr.responseJSON.message);
                });
        },
        infoTag(infoTag){
            this.modalTagInfo = !this.modalTagInfo
            this.form.id = infoTag.id
            this.form.name = infoTag.name
            this.form.color = infoTag.color
        },
        changeTag(id) {
            axios.put(api.API_ENDPOINTS.tags + '/' + id, this.form)
                .then((response) => {
                    this.$notify(response.data.message);
                }).catch((xhr) => {
                if (xhr.status === 422) {
                    this.errors.record(xhr.responseJSON.errors);
                }

                this.$notify(xhr.responseJSON.message);
            });
        },
        removeTag(id){
            axios.delete(api.API_ENDPOINTS.tags + '/' + id)
                .then((response) => {
                    this.$notify(response.data.message);
                }).catch((xhr) => {
                if (xhr.status === 422) {
                    this.errors.record(xhr.responseJSON.errors);
                }

                this.$notify(xhr.responseJSON.message);
            });
            let idToRemove = id;
            this.tags = this.tags.filter(function(item) {
                return item.id != idToRemove;
            });
            this.modalTagInfo = false

        }
    }
}
</script>

<style scoped>
.todo-icons{
    fill: var(--mainColorText);
    font-size: 14px;
    margin-right: 5px;
    display: none;
}
.tag-item:hover .todo-icons{
    display: flex;
}
.sidebar-body{
    padding: 20px;
}
.add-task {
    color: #fff;
    background: linear-gradient(180deg, #5b3cd0, #3bc6d4);
    border: none;
    width: 100%;
    font-size: var(--paragraphSize);
    font-weight: var(--bold);

}
.sidebar-menu{
    margin-top: 15px;
}
.menu-icon{
    font-size: 18px;
}
.s-menu-item{
    display: flex;
    align-items: center;
    padding: 9px 0;
    cursor: pointer;
}
.s-menu-item span{
    font-size: 14px;
    line-height: 1;
}
.item-active{
    font-weight: bold;
    background: -webkit-linear-gradient(#6404cd, #4593d2);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.add-label{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.labels-title{
    text-transform: uppercase;
    color: #b9b9c3;
    font-size: 13px;
}
.add-label-button {
    color: #fff;
    background: -webkit-linear-gradient(#6404cd, #4593d2);
    border: none;
    padding: 4px 10px;
    font-size: var(--paragraphSize);
    font-weight: var(--bold);
}
.form-label label{
    font-size: 14px;
}
input{
    font-size: 14px;
}
.icp__input{
    height: 25px;
    border-radius: 50%;
    width: 25px;
}
.bullet.bullet-sm {
    width: .714rem;
    height: .714rem;
}
.bullet {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    display: inline-block;
}
.label-title{
    font-size: 14px;
}
.tag-item{
    cursor: pointer;
}
@media (max-width: 1300px){
    .sidebar-menu{
        display: flex;
        justify-content: space-around;
    }
    .add-label{
        margin-top: 10px;
    }
}
</style>