<template>
    <div v-if="!loading">
        <OrangeTitleBar :data="title"></OrangeTitleBar>
        <div class="page-body">
            <div class="news-container">
                <div class="first-column">
                    <NewsCategory
                            :title="catTitle.one"
                            :logo="catImages.one"
                            @isVisible="newsVisible = $event"
                            class="padding-box mb-3"
                    ></NewsCategory>
                    <div class="scroll-news" v-bind:class="{ hidden: newsVisible === false }">
                        <StireListare
                                v-for="(stire, key) in newsOfficialFiltered"
                                :key="key"
                                :stire="stire"
                                class="list-news"
                                @refreshFavNews="getAllNews"
                        ></StireListare>
                    </div>
                </div>
                <div class="second-column">
                    <NewsCategory
                            :title="catTitle.two"
                            :logo="catImages.two"
                            @isVisible="newsVisibleTwo = $event"
                            class="padding-box mb-3"
                    ></NewsCategory>
                    <div class="scroll-news" v-bind:class="{ hidden: newsVisibleTwo === false }">
                        <StireListare
                                v-for="(stire, key) in newsSmisFiltered"
                                :key="key"
                                :stire="stire"
                                class="list-news"
                                @refreshFavNews="getAllNews"
                        ></StireListare>
                    </div>
                </div>
                <div class="third-column">
                    <NewsCategory :title="catTitle.three" :logo="catImages.three" @isVisible="newsVisibleThree = $event"
                                  class="padding-box mb-3"></NewsCategory>
                    <div class="scroll-news" v-bind:class="{ hidden: newsVisibleThree === false }">
                        <StireListare
                                v-for="(stire, key) in newsFavorite"
                                :key="key"
                                :stire="stire"
                                class="list-news"
                                @refreshFavNews="getAllNews"
                        ></StireListare>
                    </div>
                </div>
                <div class="sidebar-column">
                    <div class="padding-box sidebar-top mb-3"></div>
                    <div class="sidebar padding-box">
                        <SidebarStiri
                                :data="sidebar"
                                @filter="filterTags($event)"
                        ></SidebarStiri>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <LoadingSpinner v-else></LoadingSpinner>
</template>

<script>
    import LoadingSpinner from '@/common/components/LoadingSpinner';
    import api from '@/helpers/api';
    import axios from '@/helpers/axios';
    import OrangeTitleBar from '@/common/components/OrangeTitleBar';
    import NewsCategory from "./components/NewsCategory";
    import StireListare from "./components/StireListare";
    import SidebarStiri from "./components/SidebarStiri";
    import _ from "lodash";

    export default {
        components: {
            LoadingSpinner,
            OrangeTitleBar,
            NewsCategory,
            StireListare,
            SidebarStiri,
        },
        props: {},
        data() {
            return {
                news: [],
                sidebar: [],
                newsSmis: [],
                newsOfficial: [],
                newsFavorite: [],
                loading: true,
                title: 'STIRI',
                catTitle: {
                    one: 'Știri oficiale Ministere',
                    two: 'Știri Smis',
                    three: 'Știrile tale salvate',
                },
                catImages: {
                    one: 'logoguvern',
                    two: 'stiri-smis',
                    three: null,
                },
                newsVisible: true,
                newsVisibleTwo: true,
                newsVisibleThree: true,
                allNews: [],
                newsSmisFiltered: [],
                newsOnly: [],
                newsFiltered: [],
                newsOfficialFiltered: [],
            };
        },
        created() {
        },
        mounted() {
            this.getNews()
            this.getFavoriteNews()
        },
        methods: {
            getNews() {
                return axios.get(api.API_ENDPOINTS.news)
                    .then(response => {
                        this.news = response.data;
                        this.newsOnly = response.data.news;
                        this.sidebar = {
                            programs: _.reject(response.data.programs, ['acronym', null]),
                            funders: _.reject(response.data.funders, ['id', null])
                        }
                        this.newsSmis = _.filter(response.data.news, v => v.type === 'business_management' || v.type === 'funding');
                        this.newsOfficial = _.filter(response.data.news, v => v.type === 'official');
                        this.newsSmisFiltered = this.newsSmis
                        this.newsOfficialFiltered = this.newsOfficial
                    })
            },
            getFavoriteNews() {
                return axios.get(api.API_ENDPOINTS.wishlistNews)
                    .then(response => {
                        this.newsFavorite = response.data;
                    }).finally(() => this.loading = false)
            },
            filterTags(event) {
                if (event.type === 'funder') {
                    this.newsFiltered = _.filter(this.newsOnly, {funder: {id: event.id}});
                    this.newsSmisFiltered = _.filter(this.newsFiltered, v => v.type === 'smis');
                    this.newsOfficialFiltered = _.filter(this.newsFiltered, v => v.type === 'official');
                } else if (event.type === 'program') {
                    this.newsFiltered = _.filter(this.newsOnly, {program: {id: event.id}});
                    this.newsSmisFiltered = _.filter(this.newsFiltered, v => v.type === 'smis');
                    this.newsOfficialFiltered = _.filter(this.newsFiltered, v => v.type === 'official');
                } else if (event.type === 'toate') {
                    this.newsSmisFiltered = this.newsSmis
                    this.newsOfficialFiltered = this.newsOfficial
                }
            },
            getAllNews() {
                this.getNews()
                this.getFavoriteNews()
                this.$mount()
            }
        },
    };
</script>

<style scoped>

    .sidebar-top {
        min-height: 127px;
    }

    .padding-box {
        padding: 20px;
    }

    .news-container {
        display: flex;
        justify-content: space-between;
    }

    .page-body {
        height: calc(100vh - 118px);
    }

    .first-column, .second-column, .third-column, .cat-box {
        width: 28%;
    }

    .sidebar {
        background: #fff;
        width: 100%;
        height: 90%;
    }

    .sidebar-column {
        width: 11%;
    }

    .scroll-news .list-news:not(:last-child) {
        margin-bottom: 1rem;
    }

    .scroll-news {
        max-height: 650px;
        height: 100%;
        overflow-y: auto;
        padding: 0 20px;
        transition: .5s;
        position: relative;
    }

    .hidden {
        max-height: 0;
        position: relative;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(208, 208, 208, 0.38);
    }

    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(208, 208, 208, 0.38);
        border: 2px solid #95a3cb;
    }

    @media (max-width: 1200px) {
        .news-container {
            display: block;
        }

        .sidebar-column {
            width: 100%;
            padding: 20px;
        }

        .sidebar {
            height: 100%;
            position: relative;
            top: inherit;
        }

        .first-column, .second-column, .third-column {
            width: 100%;
        }
    }
</style>