<template>
    <div>
        <div v-if="isOpen === false" class="companie" @click="bigSmall">
            <img v-if="image" class="companie-imagine-mica" :src="image" alt="">
            <img v-else src="@/assets/images/fara-logo.png">
            <p class="nume-companie-mica text-uppercase">{{ nume }}</p>
        </div>
        <div v-else class="companie-mare">
            <div class="companie-mare-margin">
                <div class="mare-header" @click="bigSmall">
                    <img
                        v-if="image"
                        class="companie-imagine-mare"
                        :src="image"
                        alt=""
                    >
                    <img v-else src="@/assets/images/fara-logo.png">
                    <div class="companie-eligibilitate">
                        <p class="nume-companie-mare text-uppercase">{{ nume }}</p>
                    </div>
                </div>
                <div class="mt-4">
                    <div v-for="(tab, index) in situatie" :key="index">
                        <div v-if="tab.nume === 'Finanțări eligibile'" class="mb-2 d-flex justify-content-between">
                            <div class="d-flex">
                                <b-icon-star
                                    :style="tab.style"
                                    variant="danger"
                                    font-scale="1.1"
                                ></b-icon-star>
                                <router-link :to="tab.link">
                                    <p class="text-meniu" :class="tab.classname">
                                        {{ tab.nume }}
                                    </p>
                                </router-link>

                            </div>
                            <p class="notificari-fara-dropdown">{{ tab.notificari }}</p>
                        </div>
                        <div v-else-if="tab.nume === 'Aplicații SMIS'" @click="appsmisOpen">
                            <div>
                                <div
                                    v-if="appsmis === false"
                                    class="d-flex justify-content-between aplicatii-dropdown"
                                >
                                    <div class="d-flex">
                                        <b-icon-arrow-right-circle
                                            :style="tab.style"
                                            variant="danger"
                                            font-scale="1.1"
                                        ></b-icon-arrow-right-circle>
                                        <div class="align-self-center">
                                            <p class="text-meniu" :class="tab.classname">{{ tab.nume }}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="notificari">{{ tab.notificari }}</p>
                                        <svg
                                            v-if="tab.child"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="13"
                                            height="13"
                                            fill="#8be2d6"
                                            class="bi bi-caret-down-fill align-self-center"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="d-flex aplicatii-dropdown justify-content-between">
                                        <div class="d-flex">
                                            <b-icon
                                                :icon="tab.icon"
                                                :style="tab.style"
                                                variant="danger"
                                                font-scale="1.1"
                                            ></b-icon>
                                            <p class="text-meniu" :class="tab.classname">
                                                {{ tab.nume }}
                                            </p>
                                        </div>
                                        <div>
                                            <p class="notificari">{{ tab.notificari }}</p>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="13"
                                                height="13"
                                                fill="#8be2d6"
                                                class="bi bi-caret-up-fill align-self-center"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-for="(subcat, k) in tab.child" :key="k">
                                        <router-link :to="subcat.link">
                                            <p class="text-subcat-appsmis">{{ subcat.title }}</p>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else-if="tab.nume === 'Financiar'" class="mb-2" @click="financiarOpen">
                            <div>
                                <div
                                    v-if="financiar === false"
                                    class="d-flex financiar-dropdown justify-content-between"
                                >
                                    <div class="d-flex">
                                        <b-icon-briefcase
                                            :style="tab.style"
                                            variant="danger"
                                            font-scale="1.1"
                                        ></b-icon-briefcase>
                                        <div class="align-self-center">
                                            <p class="text-meniu" :class="tab.classname">{{ tab.nume }}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="notificari">{{ tab.notificari }}</p>
                                        <svg
                                            v-if="tab.child"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="13"
                                            height="13"
                                            fill="#6a6a6a"
                                            class="bi bi-caret-down-fill align-self-center"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div v-else class="mb-2">
                                    <div class="d-flex financiar-dropdown justify-content-between">
                                        <div class="d-flex">
                                            <b-icon
                                                :icon="tab.icon"
                                                :style="tab.style"
                                                variant="danger"
                                                font-scale="1.1"
                                            ></b-icon>
                                            <p class="text-meniu" :class="tab.classname">
                                                {{ tab.nume }}
                                            </p>
                                        </div>
                                        <div>
                                            <p class="notificari">{{ tab.notificari }}</p>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="13"
                                                height="13"
                                                fill="#6a6a6a"
                                                class="bi bi-caret-up-fill align-self-center"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-for="(subcat, k) in tab.child" :key="k">
                                        <router-link :to="subcat.link">
                                            <p class="text-subcat-financiar">{{ subcat.title }}</p>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="tab.nume === 'Editează informații'" class="mb-2 d-flex justify-content-between">
                            <div class="d-flex">
                                <b-icon-list
                                    :style="tab.style"
                                    variant="danger"
                                    font-scale="1.1"
                                ></b-icon-list>
                                <router-link :to="{ name:'AdaugaCompanie', params: { cui: tab.cuiFirma, id: tab.idFirma }}">
                                    <p class="text-meniu" :class="tab.classname">
                                        {{ tab.nume }}
                                    </p>
                                </router-link>

                            </div>
                            <p class="notificari-fara-dropdown">{{ tab.notificari }}</p>
                        </div>
                        <div v-else-if="tab.nume === 'Șterge compania'" class="mb-2 d-flex justify-content-between">
                            <div class="d-flex">
                                <b-icon-trash
                                    :style="tab.style"
                                    variant="danger"
                                    font-scale="1.1"
                                ></b-icon-trash>
                                <p class="text-meniu" :class="tab.classname" @click="modalShow = !modalShow">
                                    {{ tab.nume }}
                                </p>

                                <b-modal
                                    v-model="modalShow"
                                    button-size="sm"
                                    ok-variant="success"
                                    ok-title="Confirmă"
                                    cancel-variant="danger"
                                    cancel-title="Anulează"
                                    header-bg-variant="dark"
                                    body-bg-variant="dark"
                                    hide-header-close
                                    id="blackModal"
                                    @ok="confirmAlert"
                                ><StergeCompania></StergeCompania>
                                </b-modal>
                            </div>
                            <p class="notificari-fara-dropdown">{{ tab.notificari }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {BIcon, BModal, BIconStar, BIconArrowRightCircle, BIconBriefcase, BIconTrash, BIconList} from 'bootstrap-vue';
import StergeCompania from "./StergeCompania";
import api from '@/helpers/api';
import axios from '@/helpers/axios';
import Errors from '@/helpers/Errors';

export default {
    name: "Test",
    components: {
        BIcon,
        StergeCompania,
        BModal,
        BIconStar,
        BIconArrowRightCircle,
        BIconBriefcase,
        BIconTrash,
        BIconList,
    },
    props: {
        nume: {
            type: String,
            default: null,
        },
        tip: {
            type: Number,
            default: 1,
        },
        image: {
            type: String,
            default: null,
        },
        id: {
            type: Number,
            default: null,
        },
        cui: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            isOpen: false,
            appsmis: false,
            financiar: false,
            situatie: [
                {
                    nume: "Finanțări eligibile",
                    notificari: 1,
                    icon: "star",
                    style: "color:#a0a0a0 !important; margin-right: 10px;",
                    link: "/eligibilitate-finantari",
                },
                {
                    nume: "Aplicații SMIS",
                    notificari: 2,
                    icon: "play-circle",
                    classname: "text-white text-bold",
                    style: "color:#fff !important; margin-right: 10px;",
                    child: [
                        {
                            title: "M2manager",
                            link: "#",
                        },
                        {
                            title: "HORECA Manager",
                            link: "#",
                        },
                    ],
                },
                {
                    nume: "Financiar",
                    notificari: 3,
                    icon: "briefcase",
                    classname: "text-bold",
                    style: "color:#797979 !important; margin-right: 10px;",
                    child: [
                        {
                            title: "Sold neachitat",
                            link: "/contul-meu/financiar",
                        },
                        {
                            title: "Facturi emise",
                            link: "/contul-meu/financiar",
                        },
                    ],
                },
                {
                    nume: "Editează informații",
                    icon: "justify-left",
                    style: "color:#a0a0a0 !important; margin-right: 10px;",
                    cuiFirma: this.cui,
                    idFirma: this.id,
                },
                {
                    nume: "Șterge compania",
                    stare: 1,
                    icon: "trash",
                    style: "color:#a0a0a0 !important; margin-right: 10px;",
                    link: "#",
                },
            ],
            isInfoPopupVisible: false,
            modalShow: false,
            errors: new Errors(),
        };
    },
    created() {
    },
    methods: {
        showInfoPopup() {
            this.isInfoPopupVisible = true;
        },
        closeInfoPopup() {
            this.isInfoPopupVisible = false;
        },
        bigSmall() {
            this.isOpen = !this.isOpen;
        },
        appsmisOpen() {
            if (this.appsmis === false) {
                this.appsmis = true;
            } else {
                this.appsmis = false;
            }
        },
        financiarOpen() {
            if (this.financiar === false) {
                this.financiar = true;
            } else {
                this.financiar = false;
            }
        },
        confirmAlert() {
            axios.delete(api.API_ENDPOINTS.companies + '/' + this.id)
                .then(response => {
                    this.$emit('idCompanie', this.id)
                    this.$notify(response.data.message);
                })
                .catch(xhr => {
                if (xhr.response.status === 422) {
                    this.errors.record(xhr.response.data.errors);
                }
                this.$notify(xhr.response.data.message);
                })
        }
    },
};
</script>

<style>
#blackModal .modal-content {
    background-color: transparent;
}
#blackModal___BV_modal_backdrop_ {
    background-color: transparent !important;
}
#blackModal .modal-dialog .bg-dark {
    background-color: rgba(21, 20, 20, 0.85) !important;
    border: rgba(21, 20, 20, 0.7) !important;
    backdrop-filter: blur(7px);
    position: relative;
    overflow: auto;
    padding: 30px;
}
#blackModal___BV_modal_footer_{
    background-color: rgba(21, 20, 20, 0.85) !important;
    border: rgba(21, 20, 20, 0.7) !important;
    backdrop-filter: blur(7px);
}
#blackModal .modal-dialog .opacityNone {
    background-color: rgba(21, 20, 20, 0.00) !important;
    border: none !important;
    position: relative;
}
#blackModal .btn-danger{
    border-color: var(--dangerColor);
    color: var(--dangerColor);
}
#blackModal .btn-success{
    border-color: #0cc2aa;
    background-color: #0cc2aa;
}
#blackModal___BV_modal_header_{
    display: none;
}
#blackModal .modal-dialog {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

}
.modal-content {
    border: 2px solid #fff;
}
</style>
<style scoped>
.d-flex{
    align-items: center;
}
.companie {
    display: flex;
    background-color: #fff;
    align-items: center;
    min-height: 55px;
    border-radius: 5px;
    cursor: pointer;
    overflow-wrap: anywhere;
    padding: 5px;
}

.companie-mare {
    display: flex;
    background-color: #fff;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    overflow-wrap: anywhere;
}

.companie-mare-margin {
    margin: 20px;
    width: -webkit-fill-available;
}

.nume-companie-mica {
    border-left: 1px solid #eeeff0;
    margin-left: 30px;
    padding: 5px 0 5px 10px;
    font-size: 14px;
    text-align: initial;
    color: #929292;
}

.nume-companie-mare {
    font-size: 14px;
    text-align: initial;
    color: #929292;
}

.companie-eligibilitate {
    display: flex;
    align-items: center;
}

.companie-imagine-mica {
    max-height: 40px;
}
.mare-header{
    min-height: 76px;
}
.companie-imagine-mare {
    max-height: 50px;
    margin-bottom: 5px;
}

p {
    margin: 0;
}

.text-meniu {
    font-size: 14px;
}
.text-bold {
    font-weight: bold;
}
.text-white {
    color: #fff;
    background-color: #0cc2aa;
}
.notificari,
.notificari-fara-dropdown {
    background-color: #0cc2aa;
    border-radius: 5px;
    color: #d9f7f4;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    width: 18px;
    text-align: center;
    align-self: center;
    margin-right: 20px;
}

.notificari-fara-dropdown {
    margin-right: 33px;
}

.aplicatii-dropdown {
    background-color: #0cc2aa;
    padding: 7px 10px 7px 10px;
    margin: 0 -10px;
    border-radius: 5px;
}

.text-subcat-appsmis {
    background-color: #eeeeee;
    padding: 10px 0px 10px 45px;
    margin: 0px -10px;
}

.financiar-dropdown {
    background-color: #dedede;
    padding: 7px 10px 7px 10px;
    margin: 0 -10px;
    border-radius: 5px;
}

.text-subcat-financiar {
    background-color: #dedede;
    padding: 10px 0px 10px 45px;
    margin: 0px -10px;
    color: #929292;
}

@media only screen and (max-width: 500px) {
    .text-meniu {
        font-size: 14px;
        margin-left: 10px;
        place-self: center;
    }
}
</style>
