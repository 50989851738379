<template>
    <div>
        <div class="background-container justify-content-between align-items-center">
            <div class="text-big">
                <span>Aplicații de implementare</span>
            </div>
            <div class="top-bar-right-side mr-4">
                <div class="d-felx text-right">
                    <b-img
                        :src="require('@/assets/images/logo-text-only.png')"
                    ></b-img>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {BImg} from 'bootstrap-vue'
    export default {
        components:{
            BImg
        },
        props: {
            userName: {
                type: String,
                default: "Nume",
            },
            userImage: {
                type: String,
                default: '',
            },
            role: {
                type: String,
                default: "Rol",
            },
            email: {
                type: String,
                default: "email",
            },
            usepackage: {
                type: String,
                default: "Basic",
            },
            phone: {
                type: String,
                default: "0707070707",
            },
            company: {
                type: String,
                default: "Compania Mea",
            },
        },
        data() {
            return {};
        },
        created() {},
        methods: {},
    };
</script>

<style scoped>
	.background-container {
		display: flex;
		background-image: linear-gradient(to right, #509290, #4ea7ad, #5cbd50);
        width: 100%;
        padding: 0px 35px;
	}
    .text-big {
        font-size: 22px;
        color: #fff;
        padding: 25px 0;
    }

    @media only screen and (max-width: 1550px) {
        .top-bar-right-side {
            margin-left: 10px;
        }
    }
    @media (max-width:500px){
        .background-container{
            flex-direction: column;
        }
    }
</style>
