<template>
    <div class="flex flex-column">
        <div class="white-box">
            <p class="info-text">Incarcă logo-ul firmei</p>
            <b-card-text class="text-tip-imagine">Acceptă JPG, GIF sau PNG. Maximum 800kB</b-card-text>
            <b-form-file
                name="image"
                accept=".jpg, .png, .gif"
                @change="logoCompanie($event)"
            ></b-form-file>
        </div>
        <div class="white-box mt-3">
            <p class="info-text">Informatiile sunt corecte?</p>
            <p class="nume-companie">Daca da, te rugam sa apesi butonul de mai jos.<br>
                In cazul in care informatiile nu sunt corecte te rugam<br>
                sa ne contactezi la 031.402.50.10 sau 031.402.50.09</p>
            <b-button v-if="!firmaExistenta" class="green mt-3" @click="addCompany()">Informatiile sunt corecte, adauga compania in contul meu</b-button>
            <b-button v-else class="green mt-3" @click="updateCompany()">Informatiile sunt corecte, actualizează compania</b-button>
        </div>
    </div>
</template>

<script>
    import {BButton, BCardText, BFormFile} from "bootstrap-vue";
    export default{
        components:{
            BButton,
            BCardText,
            BFormFile
        },
        props: {
            data: null,
        },
        data() {
            return{
                max: 10,
                form: {
                    cui: '',
                },
                firmaExistenta: false,
            }
        },
        mounted() {
            if(this.$route.params.cui){
                this.firmaExistenta = true
            }
        },
        methods: {
            addCompany() {
                this.$emit('adaugaCompania')
            },
            updateCompany() {
                this.$emit('actualizeazaCompania')
            },
            logoCompanie(event){
                this.$emit('logoCompanie',event.target.files[0]);
            }
        },
    }
</script>

<style scoped>
.info-text{
    color: #878588;
    text-align: left;
    font-size: 13px;
    font-weight: 600;
}
.nume-companie{
    color: #878588;
    font-size: 13px;
}
.white-box{
    background: #fff;
    border: #dddddd;
    padding: 13px;
}
.green {
    background: linear-gradient(to top, #49a5ce, #07c2a4);
    color: #fff;
    border: 0;
    width: 100%;
    min-width: 170px;
    display: block;
    border-radius: 3px;
    padding: 3px 10px;
    text-decoration: none;
    text-align: center;
    /*white-space: nowrap;*/
    font-size: 13px;
}
.green:hover {
    background: linear-gradient(to bottom, #07c2a4, #49a5ce);
}
.flex{
    display: flex;
}
.flex-column{
    flex-direction: column;
}
</style>