<template>
    <div class="main-content">
        <div>
            <UserTopBar :user-name="infoCont.name"
                        :role="infoCont.rol"
                        :email="infoCont.email"
                        :user-image="infoCont.avatar"
                        :usepackage="infoCont.account_type_name"
                        :phone="infoCont.phone"
                        :company="infoCont.company"
            ></UserTopBar>
            <CompaniileMeleTabs></CompaniileMeleTabs>
            <AdaugaCompanieTitleBar :data="title"></AdaugaCompanieTitleBar>
        </div>
        <div class="viewPagePadding">
            <div class="grid-adauga">
                <div class="cui">
                    <div class="green-box h-100">
                        <CuiAfacere :cuiError="cuiInputError" :cui="cui" @cuiCompletat="getByCui($event)" @faraCui="removeCui()"></CuiAfacere>
                    </div>
                </div>
                <div class="serviciu">
                    <div class="white-box">
                        <p class="info-text mb-3">Serviciu asigurat de</p>
                        <img src="@/assets/images/logo-termene.png" class="pb-3">
                    </div>
                </div>
                <div v-if="cui && !loading" class="compania-ta">
                    <div class="white-box">
                        <CompaniaTa :nume="companie.nume"></CompaniaTa>
                    </div>
                    <div class="white-box mt-3">
                        <AdresaCompanie :locatie="companie.locatie"></AdresaCompanie>
                    </div>
                </div>
                <div v-if="cui && !loading" class="caen">
                    <div v-if="companie.caen != null" class="yellow-box">
                        <CaenPrincipal :caen="companie.caen"></CaenPrincipal>
                    </div>
                </div>
                <div v-if="cui && !loading" class="informatii">
                    <VerificareInformatii @adaugaCompania="addCompany()" @actualizeazaCompania="updateCompany()" @logoCompanie="updateLogoCompanie"></VerificareInformatii>
                </div>
                <div v-if="cui && !loading" class="profit-ca">
                    <div v-if="companie.profit != ''" class="yellow-box">
                        <Profit :profit="companie.profit"></Profit>
                    </div>
                    <div v-if="companie.cifra_afaceri != ''" class="white-box mt-3">
                        <CifraAfaceri :cifra_afaceri="companie.cifra_afaceri"></CifraAfaceri>
                    </div>
                    <div class="white-box" :class="companie.cifra_afaceri != '' ? 'mt-3' : ''">
                        <AngajatiMediu :angajati="companie.angajati"></AngajatiMediu>
                    </div>
                    <div class="white-box mt-3">
                        <VechimeFirma :statut_vechime="companie.statut_vechime"></VechimeFirma>
                    </div>
                </div>
                <div v-if="cui && !loading" class="caen-secundare">
                    <div class="white-box">
                        <CaenSecundare></CaenSecundare>
                    </div>
                </div>
                <div v-if="cui && !loading" class="pachet-business">
                    <div class="white-box">
                        <PachetBusiness :data="smisBusiness" :count="eligibilityCount" @adaugareBusiness="adaugareBusiness($event)"></PachetBusiness>
                    </div>
                </div>
                <div v-if="cui && !loading" class="right-arrow">
                    <font-awesome-icon icon="chevron-right"
                                       @click.prevent="onSubmit()"
                    ></font-awesome-icon>
                </div>
                <ModalPrincipal :modalShow="modalShow" :invoiceType="selected_invoice_type" :planId="planId" :newCompanyAdded="newCompanyAdded" @openModal="openCheckout()" @closeModal="closeCheckout()" @invoiceTypeChanged="invoiceTypeChanged()"></ModalPrincipal>
                <LoadingSpinner
                    v-if="loading"
                    class="loadingSpinner"
                ></LoadingSpinner>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingSpinner from '@/common/components/LoadingSpinner'
    import {library} from "@fortawesome/fontawesome-svg-core";
    import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
    import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import CompaniileMeleTabs from "./components/CompaniileMeleTabs";
    import AdaugaCompanieTitleBar from "./components/AdaugaCompanieTitleBar";
    import UserTopBar from '../ContulMeu/components/UserTopBar'
    import CuiAfacere from './components/CuiAfacere'
    import CompaniaTa from './components/CompaniaTa'
    import AdresaCompanie from './components/AdresaCompanie'
    import CaenPrincipal from './components/CaenPrincipal'
    import VerificareInformatii from './components/VerificareInformatii'
    import CifraAfaceri from './components/CifraAfaceri'
    import Profit from './components/Profit'
    import AngajatiMediu from './components/AngajatiMediu'
    import VechimeFirma from './components/VechimeFirma'
    import PachetBusiness from './components/PachetBusiness'
    import CaenSecundare from './components/CaenSecundare'
    import axios from '@/helpers/axios';
    import api from '@/helpers/api'
    import ModalPrincipal from "@/common/components/Checkout/ModalPrincipal";

    library.add(faChevronRight);
    library.add(faChevronDown);
    export default {
        components: {
            LoadingSpinner,
            FontAwesomeIcon,
            CompaniileMeleTabs,
            UserTopBar,
            CuiAfacere,
            CompaniaTa,
            AdresaCompanie,
            CaenPrincipal,
            VerificareInformatii,
            Profit,
            AngajatiMediu,
            CifraAfaceri,
            VechimeFirma,
            PachetBusiness,
            CaenSecundare,
            AdaugaCompanieTitleBar,
            ModalPrincipal,
        },
        data() {
            return {
                loading: false,
                infoCont: {
                    name: '',
                    rol: '',
                    email: '',
                    phone: '',
                    avatar: '',
                    account_type_name: '',
                    account_type_logo: '',
                    company: '',
                },
                title: 'Adaugare companie nouă',
                cui: false,
                cuiInputError: false,
                mobile: false,
                
                companie: {
                    nume: '',
                    locatie: {
                        judet: '',
                        localitate: ''
                    },
                    profit: '',
                    cifra_afaceri: '',
                    angajati: {
                        an: '',
                        numar: ''
                    },
                    statut_vechime: {
                        statut: '',
                        vechime: ''
                    },
                    caen: '',
                    cui: '',
                    adresa: '',
                    cod_inmatriculare: '',
                    telefon: '',
                    infiintare: '',
                    logo:null,
                },
                modalShow: false,
                selected_invoice_type: 'year',
                planId: '',
                smisBusiness: [],
                company_eligibility: [],
                eligibilityCount: null,
                newCompanyAdded: null,
            };
        },
        mounted() {
            if(this.$route.params.cui){
                this.getByCui(this.$route.params.cui)
                this.cui = this.$route.params.cui
            }
            this.getInfoCont()
        },
        methods: {
            getInfoCont() {
                this.infoCont.name = this.$store.state.auth.user.name
                this.infoCont.rol = this.$store.state.auth.user.role
                this.infoCont.email = this.$store.state.auth.user.email
                this.infoCont.phone = this.$store.state.auth.user.phone
                this.infoCont.avatar = this.$store.state.auth.user.logo
                if(this.$store.state.auth.user.account_type){
                    this.infoCont.account_type_name = this.$store.state.auth.user.account_type.name
                    this.infoCont.account_type_logo = this.$store.state.auth.user.account_type.logo
                }
                this.infoCont.company = this.$store.state.auth.user.company
            },

            /* Modal Checkout */
            openCheckout() {
                this.modalShow = true;
            },

            closeCheckout() {
                this.modalShow = false;
            },

            invoiceTypeChanged() {
                this.switchInvoiceType()
            },

            switchInvoiceType() {
                if(this.selected_invoice_type === 'year') {
                    this.selected_invoice_type = 'month';
                } else {
                    this.selected_invoice_type = 'year';
                }
            },
            /* End Modal Checkout*/

            getByCui(cui)  {
                if (!cui) {
                    this.cuiInputError = true
                } else {
                    this.loading = true;
                    this.cuiInputError = false
                    axios.get('https://termene.ro/api/dateFirmaSumar.php?cui=' + cui, {
                        auth: {
                            username: 'smisro',
                            password: 'lfU&53s$!AU5'
                        }}).then(response => {
                        this.companie.nume = response.data.DateGenerale.nume
                        this.companie.locatie.judet = response.data.DateGenerale.judet
                        this.companie.locatie.localitate = ["1", "2", "3", "4", "5", "6"].includes(response.data.DateGenerale.localitate) ? 'Sectorul ' + response.data.DateGenerale.localitate : response.data.DateGenerale.localitate
                        if(response.data.Bilanturi.length > 0){
                            this.companie.profit = response.data.Bilanturi.map(function (item) {
                                return {
                                    'an': item.an,
                                    'suma': item.pierdere_net === "0" ? item.profit_net : item.pierdere_net,
                                    'hasProfit': item.pierdere_net == 0 ? true : false
                                }
                            })
                        } else{
                            this.companie.profit = '';
                        }
                        if(response.data.Bilanturi.length > 0){
                            this.companie.cifra_afaceri = response.data.Bilanturi.map(function (item) {
                                return {
                                    'an': item.an,
                                    'suma': item.cifra_de_afaceri_neta
                                }
                            })
                        } else{
                            this.companie.cifra_afaceri = '';
                        }
                        this.companie.angajati.an = response.data.Bilanturi.length > 0 ? response.data.Bilanturi[0].an : ''
                        this.companie.angajati.numar = response.data.Bilanturi.length > 0 ? response.data.Bilanturi[0].numar_mediu_angajati : ''
                        this.companie.statut_vechime.statut = response.data.DateGenerale.statut_TVA
                        this.companie.statut_vechime.vechime = response.data.DateGenerale.vechime_firma.vechime
                        this.companie.caen = response.data.DateGenerale.cod_caen
                        this.companie.cui = response.data.DateGenerale.cui
                        this.companie.adresa = response.data.DateGenerale.adresa
                        this.companie.cod_inmatriculare = response.data.DateGenerale.cod_inmatriculare
                        this.companie.telefon = response.data.DateGenerale.telefon
                        this.companie.infiintare = response.data.DateGenerale.vechime_firma.data

                        this.company_eligibility = {
                            name: this.companie.nume,
                            business_id: this.companie.cui,
                            state: this.companie.locatie.judet,
                            turnover: this.companie.cifra_afaceri != '' ? this.companie.cifra_afaceri.slice(-1)[0].suma : '',
                            employees_no: this.companie.angajati.numar == '-' ? 0 : this.companie.angajati.numar,
                            main_caen:  this.companie.caen,
                            secondary_caens: ["0113", "0114"],
                            founded: this.companie.infiintare,
                        }
                        axios.post(api.API_ENDPOINTS.new_company_eligibility, this.company_eligibility)
                            .then(response => {
                                this.eligibilityCount = response.data.count
                            })
                    })
                        .catch(() => {
                            this.cuiInputError = true;
                        })
                        .finally(() => {
                            this.cui = this.cuiInputError === false;
                            this.loading = false;
                        })
                }

            },
            removeCui() {
                this.cui = null
            },
            adaugareBusiness(event){
                if(event){
                    axios.get(api.API_ENDPOINTS.fetchApps + '/smis-business')
                        .then(response => {
                            this.smisBusiness = response.data
                            this.planId = response.data.id
                        })
                }
                return event
            },
            addCompany() {
                
                let formData = new FormData;
                formData.append('name', this.companie.nume);
                formData.append('business_id', this.companie.cui);
                formData.append('phone', this.companie.telefon);
                formData.append('city', this.companie.locatie.localitate);
                formData.append('state', this.companie.locatie.judet);
                formData.append('address', this.companie.adresa);
                formData.append('reg_no', this.companie.cod_inmatriculare);
                formData.append('turnover', this.companie.cifra_afaceri != '' ? this.companie.cifra_afaceri.slice(-1)[0].suma : '');
                formData.append('employees_no', this.companie.angajati.numar == '-' ? 0 : this.companie.angajati.numar);
                formData.append('founded', this.companie.infiintare,);
                formData.append('main_caen', this.companie.caen);
                formData.append(' is_active', 1);
                if(this.companie.logo){
                    formData.append('file',this.companie.logo);
                    formData.append('location','companies');
                }

                axios.post(api.API_ENDPOINTS.companies, formData)
                    .then(response => {

                        this.$notify(response.data.message);
                        if(!this.adaugareBusiness()){
                            this.$router.push({ path: '/companiile-mele' })
                        }
                    })
                    .catch((err) => {
                        this.$notify(err.response.data.message);
                    })

                if(this.adaugareBusiness()){
                    this.newCompanyAdded = true;
                    this.openCheckout()
                }
            },
            updateCompany() {
                let formData = new FormData;
                formData.append('name', this.companie.nume);
                formData.append('business_id', this.companie.cui);
                formData.append('phone', this.companie.telefon);
                formData.append('city', this.companie.locatie.localitate);
                formData.append('state', this.companie.locatie.judet);
                formData.append('address', this.companie.adresa);
                formData.append('reg_no', this.companie.cod_inmatriculare);
                formData.append('turnover', this.companie.cifra_afaceri != '' ? this.companie.cifra_afaceri.slice(-1)[0].suma : '');
                formData.append('employees_no', this.companie.angajati.numar == '-' ? 0 : this.companie.angajati.numar);
                formData.append('founded', this.companie.infiintare,);
                formData.append('main_caen', this.companie.caen);
                formData.append(' is_active', 1);
                if(this.companie.logo){
                    formData.append('file',this.companie.logo);
                    formData.append('location','companies');
                }
                formData.append('_method','PUT');
                axios.post(api.API_ENDPOINTS.companies + '/' + this.$route.params.id, formData)
                    .then(response => {
                        this.$notify(response.data.message);
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            },
            updateLogoCompanie(logo){
                this.companie.logo = logo;
            },
        },
    };
</script>

<style scoped>
    .viewPagePadding{
        position: relative;
    }
    .loadingSpinner{
        position: absolute;
        top: 20%;
        height: auto;
    }
    .info-text {
        font-size: 10px;
        color: #878588;
    }

    p {
        font-size: 14px;
        text-align: left;
        color: #bababa;
    }

    .grid-adauga {
        display: grid;
        grid-template-columns: 1.2fr 0fr 1fr 1fr 1.2fr 0.4fr 0.9fr 0.9fr;
        grid-template-rows: 0fr 0fr 0.6fr;
        gap: 20px;
        padding-bottom: 250px;
        grid-auto-flow: row;
        grid-template-areas: "cui compania-ta compania-ta compania-ta caen . informatii informatii" "serviciu profit-ca profit-ca profit-ca caen-secundare right-arrow pachet-business pachet-business" ". profit-ca profit-ca profit-ca caen-secundare right-arrow pachet-business pachet-business" ". profit-ca profit-ca profit-ca caen-secundare right-arrow pachet-business pachet-business";

    }

    .serviciu {
        grid-area: serviciu;
    }

    .cui {
        grid-area: cui;
    }

    .compania-ta {
        grid-area: compania-ta;
    }

    .caen {
        grid-area: caen;
    }

    .informatii {
        grid-area: informatii;
    }

    .right-arrow {
        align-self: center;
        grid-area: right-arrow;
        text-align: center;
    }

    .right-arrow svg {
        font-size: 80px;
        color: #d7d6d7;
    }

    .profit-ca {
        grid-area: profit-ca;
    }

    .caen-secundare {
        grid-area: caen-secundare;
    }

    .pachet-business {
        grid-area: pachet-business;
    }

    .white-box {
        background: #fff;
        border: #dddddd;
        padding: 13px;
    }

    .yellow-box {
        background: #fcc100;
        border: #dddddd;
        padding: 13px;
    }

    .green-box {
        background: var(--succesColor);
        border: #dddddd;
        padding: 13px;
    }

    @media only screen and (max-width: 768px) {
        .grid-adauga {
            grid-template-columns: 100%;
            grid-template-areas: "cui" "serviciu" "compania-ta" "profit-ca" "caen" "caen-secundare" "right-arrow" "informatii" "pachet-business";
            padding: 0;
        }

        .right-arrow svg {
            font-size: 40px;
            transform: rotate(0.25turn);
        }

    }
</style>
