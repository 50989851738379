<template>
    <div v-if="!loading">
        <GradientTopSection
            :resultCount="resultCount"
            :resultCountOpened="resultCountOpened"
            :fundingsSum="fundingsSum"
        ></GradientTopSection>
        <Filters
            :filterDisable="disableFilters"
            @sendDataToParent="getFilters($event)"
            @changeToTabListView="formatLista = $event"
            @changeToExpertMode="showProgramTree($event)"
            @noFilter="filterDefault"
        ></Filters>
        <div v-if="!loadingComponent">
            <ViewExpert
                v-if="modExpert && loadProgramTree"
                :data="programTree"
                :load="loadProgramTree"
                @filterByProgram="filterByProgram"
                @filterByMasura="filterByMasura"
            >   
            </ViewExpert >
            <div v-else-if="modExpert && !loadProgramTree" style="height:100px">
                <LoadingSpinner ></LoadingSpinner>
            </div>
            <ViewList
                v-if="!formatLista && (finantari.length > 0 || finantariFiltrate.length > 0)"
                :data="filtrari ? finantariFiltrate: finantari"
                :class="[modExpert ? 'marginBottom' : '']"
            ></ViewList>
            <ViewTab
                v-if="formatLista && (finantari.length > 0 || finantariFiltrate.length > 0)"
                :data="filtrari ? finantariFiltrate: finantari"
            ></ViewTab>
        </div>
        <LoadingSpinner v-else></LoadingSpinner>
        <div v-if="!filtrari && numberOfPages > 1" class="overflow-auto pagination">
            <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="numberOfPages"
                align="center"
                base-url="#"
                use-router
                @change="getCurrentPage"
            ></b-pagination-nav>
        </div>

        <div v-if="finantari.length <= 0 ||( finantariFiltrate.length<=0 && filtrari)" class="text-center noFunding">
            Nicio finanțare disponibilă.
        </div>
    </div>
    <LoadingSpinner v-else></LoadingSpinner>
</template>

<script>
    import LoadingSpinner from '@/common/components/LoadingSpinner'
    import GradientTopSection from './components/GradientTopSection'
    import Filters from './components/Filters'
    import ViewExpert from './components/ViewExpert'
    import ViewList from './components/ViewList'
    import ViewTab from './components/ViewTab'
    import api from '@/helpers/api'
    import axios from '@/helpers/axios'
    import _ from 'lodash'
    import {BPaginationNav} from 'bootstrap-vue'

    export default {       
        components: {
            GradientTopSection,
            Filters,
            ViewExpert,
            ViewList,
            ViewTab,
            LoadingSpinner,
            BPaginationNav
        },
        data() {
            return {
                loading: true,
                loadingComponent: true,
                finantari: [],
                programTree: {},
                finantariFiltrate: [],
                toateFinantarile: [],
                formatLista: false,
                modExpert: false,
                filtrari: false,
                filtrariActive:{},
                urlModExpert:{},
                resultCount: null,
                resultCountOpened: null,
                fundingsSum: null,
                filterMasuraIds: [],
                pageNum: 1,
                numberOfPages: null,
                currentPage: 1,
                programExpertSelect:null,
                masuraExpertSelect: null,
            };
        },
        computed:{
            disableFilters(){
                if(this.toateFinantarile.length > 1){
                    return false
                }
                return true
            },
            loadProgramTree(){
                if(this.programTree && Object.keys(this.programTree).length === 0 &&  this.programTree.constructor === Object){
                    return false
                }
                return true
            },
        },
        created (){
        },
        mounted () {
            let promises = [];
            if(!(this.$route.query && Object.keys(this.$route.query).length === 0 &&  this.$route.query.constructor === Object)){
                promises.push(this.getFundingWithNoPagination());
            }
            else{
                promises.push(this.getCurrentPage(1));
            }

            Promise.all(promises)
                .catch(err => console.log(err))
        },
        methods: {
            getCurrentPage(value) {
                this.loadingComponent = true
                this.currentPage = value
                this.getFunding(value)
            },
            getFunding(pageNum) {
                return axios.get(api.API_ENDPOINTS.finantari + "?page=" + pageNum)
                    .then(response => {
                        if(response.data.count > 0) {
                            this.finantari = response.data.items
                            this.numberOfPages = response.data.numberOfPages
                            this.resultCount = response.data.count;
                            this.resultCountOpened = response.data.items.filter((funding) => {
                                return funding.status == 'opened';
                            }).length
                            this.fundingsSum = _.sumBy(response.data.items, 'total_budget');
                            if (this.toateFinantarile.length < 1) {
                                this.getFundingWithNoPagination();
                            }
                        }
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingComponent = false
                    })
            },
            getProgramTree(){
                return  axios.get(api.API_ENDPOINTS.programTree)
                    .then(response => {
                        this.programTree = response.data;
                    })
            },
            getFundingWithNoPagination(){
                return axios.get(api.API_ENDPOINTS.finantari)
                    .then( response =>{
                        this.toateFinantarile = response.data.items
                        this.finantariFiltrate = response.data.items
                        if((this.finantari.length < 1)){
                            this.getCurrentPage(1);
                        }
                    })
            },
            filterDefault(){
                if(this.modExpert===false)
                {this.filtrari =false;
                 if(this.currentPage!=1)
                     this.getCurrentPage(1);
                }
                this.$router.push({query:{}}).catch(() => {})
            },
            showProgramTree(event){
                if(this.programTree && Object.keys(this.programTree).length === 0 &&  this.programTree.constructor === Object){
                    this.getProgramTree()
                }
                this.modExpert = event
                if(!this.modExpert){
                    this.masuraExpertSelect =null;
                    this.programExpertSelect = null;
                    if(this.filtrariActive && Object.keys(this.filtrariActive).length === 0 &&  this.filtrariActive.constructor === Object)
                    { this.filtrari = false}
                    else{
                        this.getFilters(this.filtrariActive)
                    }

                } 
            },
            getFilters(filters) {
                let urlQuery = {};
                this.finantariFiltrate = this.toateFinantarile
                this.filtrariActive = filters;
                this.filtrari = true
                if(this.modExpert ){

                    if(this.programExpertSelect){
                        this.finantariFiltrate = this.finantariFiltrate.filter(finantare=>{
                            return  finantare.program.acronym === this.programExpertSelect;
                        })
                    }
                    if(this.masuraExpertSelect){
                        this.filterMasuraIds = [this.masuraExpertSelect.id];
                        if(this.masuraExpertSelect.items.length>0){
                            this.getMasuraIds(this.masuraExpertSelect.items)
                        }
                        this.finantariFiltrate = this.finantariFiltrate.filter(finantare => {

                            return this.filterMasuraIds.includes(finantare.sub_program)
                        })
                    }
                  
                        
                }
                if(!(filters && Object.keys(filters).length===0 && filters.constructor===Object)){
    
                    this.finantariFiltrate = this.finantariFiltrate.filter((finantare)=>{
                        if(finantare.max_budget){
                            return finantare.min_budget/1000 >= filters.valoareaFinantarii[0] && finantare.max_budget/1000 <= filters.valoareaFinantarii[1];
                        } else {
                            return finantare.min_budget/1000 >= filters.valoareaFinantarii[0] && finantare.min_budget/1000 <= filters.valoareaFinantarii[1];
                        }
                    })
                    urlQuery['valoare-finantare']=filters.valoareaFinantarii;
                    if(filters.finantariActive){
                        this.finantariFiltrate = this.finantariFiltrate.filter( finantare =>{
                            return finantare.status === "opened"
                        })
                        urlQuery['finantari-active'] ='da';

                    }
                   
                    if(filters.filtreazaRegiuneImplementare !== 'all'){
                        this.finantariFiltrate = this.finantariFiltrate.filter((item) => {
                            return Object.keys(item.supported_regions).includes(filters.filtreazaRegiuneImplementare)
                        })
                        urlQuery['regiune-implementare']=filters.filtreazaRegiuneImplementare;
                    }
                    if(filters.sursaFinantare !=='all'){

                        this.finantariFiltrate  = this.finantariFiltrate.filter((finantare)=>{
                            return  finantare.program.type ? finantare.program.type.title === filters.sursaFinantare : '';
                        })
                        urlQuery['sursa-finantare'] = filters.sursaFinantare.replace(/\s+/g, '-');
                    }
                    if(filters.tipBeneficiar !=='all'){
                        this.finantariFiltrate = this.finantariFiltrate.filter((finantare) =>{
                            return finantare.beneficiaries.includes(filters.tipBeneficiar);
                        })
                        urlQuery['tip-beneficiar'] = filters.tipBeneficiar.replace(/\s+/g, '-');
                    }
                    if(filters.optiuneCofinantare !=='all'){
                        if(filters.optiuneCofinantare==='no_contribution'){
                            this.finantariFiltrate = this.finantariFiltrate.filter((finantare) =>{
                                return finantare.contribution_type === 'no_contribution';
                            })
                            urlQuery['optiune-cofinantare'] = 'Fara-cofinantare'
                        }else{
                            this.finantariFiltrate = this.finantariFiltrate.filter((finantare) =>{
                                return finantare.contribution_type !== 'no_contribution';
                            })
                            urlQuery['optiune-cofinantare'] = 'Cu-cofinantare'
                        }
                    }
                
                } else{ 
                    this.filtrariActive ={}  
                }
                this.$router.push({  query: {
                    ...urlQuery
                } }).catch(() => {})
               
            },
            filterByProgram(acronym){
                this.programExpertSelect = acronym;
                this.masuraExpertSelect = null;
                this.getFilters(this.filtrariActive);
         
            },
            filterByMasura(masura){
                this.masuraExpertSelect = masura;
                this.programExpertSelect = null;
                this.getFilters(this.filtrariActive);
           
            },
            getMasuraIds(submasuri){
                for(let i=0 ; i<submasuri.length ; i++){
                    this.filterMasuraIds.push(submasuri[i].id)
                    if(submasuri[i].items.length > 0){
                        this.getMasuraIds(submasuri[i].items)
                    }
                }
            },
   
        },
    };
</script>

<style scoped>
	* {
        width: 100%;
    }
    .pagination {
        padding-bottom: 80px;
    }
    .noFunding {
        margin: 2rem 0;
    }
    .marginBottom{
        margin-bottom: 100px;
    }
</style>
