<template>
    <div>
        <div id="nav" v-click-outside="closeTopMenu">
            <router-link
                    v-for="(tab, index) in primaryMenu"
                    :key="index"
                    :class="[smallLaptop ? 'menu-item-small-laptop' : '','menu-item', 'item-link']"
                    :to="(tab.type === 'page' || tab.type === 'url') ? (tab.type === 'page') ? `/p${tab.link}` : `${tab.link}` : '#'"
                    @click.native="getRootPrograms(tab.type, tab.link); closeTopMenu()"
            >
                <span class="menu-item-text">
                    {{ tab.name }}
                    <span v-if="tab.tooltip" class="tooltiptext">{{ tab.tooltip }}</span>
                </span>
                <svg v-if="tab.is_fluid" viewBox="0 0 1030 638" width="10">
                    <path
                            d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z"
                            fill="#GGG"
                    />
                </svg>
            </router-link>
            <FinantareDropdown v-if="sendChild" :title="sendChild" @closeDropdown="closeTopMenu()"></FinantareDropdown>
        </div>

    </div>
</template>

<script>
    import FinantareDropdown from "./FinantareDropdown";
    import axios from '@/helpers/axios'
    import api from '@/helpers/api'
    export default {
        components: {FinantareDropdown},
        directives: {
            'click-outside': {
                bind: function (el, binding, vNode) {
                    // Provided expression must evaluate to a function.
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) {
                            warn += `Found in component '${compName}'`
                        }

                        console.warn(warn)
                    }
                    // Define Handler and cache it on the element
                    const bubble = binding.modifiers.bubble

                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    }

                    el.__vueClickOutside__ = handler

                    // add Event Listeners
                    document.addEventListener('click', handler)
                },

                unbind: function (el) {
                    // Remove Event Listeners

                    document.removeEventListener('click', el.__vueClickOutside__)
                    el.__vueClickOutside__ = null

                }
            }
        },
        props: {
            leftMenuOpened: Boolean,
            shortcutsDropdownOpen: {
                type: Boolean,
                default: false
            },
            notificationsDropdownOpen: {
                type: Boolean,
                default: false
            },
            usersDropdownOpen: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                topNavClicked: false,
                sendChild: [],
                meniuFront: [
                    {
                        title: "Știri",
                        route: "stiri",
                    },
                    {
                        title: "Harta finanțărilor",
                        route: "harta-finantarilor",
                    },
                    {
                        title: "Toate Finanțările",
                        route: "/toate-finantarile",
                        child: [
                            {
                                title: "Alocare 2014-2020",
                                route: "#",
                                notification: 10,
                                child: [
                                    {
                                        title: "Prezentare program",
                                        route: "prezentare-program",
                                    },
                                    {
                                        title: "PO Capital Uman (POCU)",
                                        route: "pocu",
                                    },
                                    {
                                        title: "PO Competitivitate",
                                        route: "competitivitate",
                                    },
                                    {
                                        title: "Programul National Dezvoltare Rurala (PNDR)",
                                        route: "pndr",
                                    },
                                    {
                                        title: "PO Capacitate Administrativa (POCA)",
                                        route: "poca",
                                    },
                                    {
                                        title: "PO Infrastructură Mare (POIM)",
                                        route: "poim",
                                    },
                                    {
                                        title: "PO Regional (POR - Regio)",
                                        route: "porr",
                                    },
                                    {
                                        title: "Programul Operațional (POAD)",
                                        route: "poad",
                                    },
                                    {
                                        title: "Programul Operațional Asistență Tehnică (POAT)",
                                        route: "poat",
                                    },
                                ],
                            },
                            {
                                title: "ALOCARE 2021 - 2027",
                                route: "#",
                                child: [
                                    {
                                        title: "Programul Operaţional Tranziție Justă (POTJ)",
                                        route: "POTJ",
                                    },
                                    {
                                        title: "Programul Operațional Dezvoltare Durabilă (PODD)",
                                        route: "PODD",
                                    },
                                    {
                                        title: "Programul Operațional Transport (POT)",
                                        route: "POT",
                                    },
                                    {
                                        title: "Programului Operațional  (POCIDIF)",
                                        route: "POCIDIF",
                                    },
                                    {
                                        title: "Programul Operațional Sănătate (POS)",
                                        route: "#",
                                    },
                                    {
                                        title: "Programul Operațional Educație și Ocupare (POEO)",
                                        route: "#",
                                    },
                                    {
                                        title: "Programul Operaţional Incl. și Demnitate Socială (POIDS)",
                                        route: "#",
                                    },
                                    {
                                        title: "Programe Operaţionale Regionale",
                                        route: "#",
                                    },
                                ],
                            },
                            {
                                title: "PNRR  2021 - 2026",
                                route: "#",
                                notification: 16,
                                child: [
                                    {
                                        title: "Tranziția verde",
                                        route: "#",
                                    },
                                    {
                                        title: "Transformarea Digitală",
                                        route: "#",
                                    },
                                    {
                                        title: "Creștere inteligentă, durabilă ",
                                        route: "#",
                                    },
                                    {
                                        title: "Coeziune socială si și teritorială",
                                        route: "#",
                                    },
                                    {
                                        title: "Sanătare și reziliență economică, ",
                                        route: "#",
                                    },
                                    {
                                        title: "Politici pentru generația următoare,",
                                        route: "#",
                                    },
                                ],
                            },
                            {
                                title: "FINANȚĂRI NAȚIONALE",
                                route: "#",
                                child: [
                                    {
                                        title: "Granturi de sprijin pentru microîntreprinderi",
                                        route: "#",
                                    },
                                    {
                                        title: "Măsura 2 - Capital de lucru",
                                        route: "#",
                                    },
                                    {
                                        title: "INNOTECH",
                                        route: "#",
                                    },
                                    {
                                        title: "Digitalizare",
                                        route: "#",
                                    },
                                    {
                                        title: "HORECA 2021",
                                        route: "#",
                                    },
                                ],
                            },
                            {
                                title: "ALTE FINANȚĂRI",
                                route: "#",
                                child: [
                                    {
                                        title: "Granturile SEE și Norvegiene",
                                        route: "#",
                                    },
                                    {
                                        title: "Erasmus +",
                                        route: "#",
                                    },
                                    {
                                        title: "IMM INVEST",
                                        route: "#",
                                    },
                                    {
                                        title: "AgroINVEST",
                                        route: "#",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        title: "Harta consultanților",
                        route: "harta-consultantilor",
                    },
                    {
                        title: "Task manager",
                        route: "task-manager",
                    },
                    {
                        title: "Notificările mele",
                        route: "notificarile-mele",
                    },
                    {
                        title: "SMIS Flowchart",
                        route: "smis-flowchart",
                        tooltip: "Nou",
                    },

                ],
                smallLaptop: false,
                primaryMenu: [],
            };
        },
        watch: {
            leftMenuOpened: function () {
                if (this.leftMenuOpened) {
                    this.closeTopMenu()
                }
            },
            shortcutsDropdownOpen: function () {
                if (this.shortcutsDropdownOpen) {
                    this.closeTopMenu()
                }
            },
            notificationsDropdownOpen: function () {
                if (this.notificationsDropdownOpen) {
                    this.closeTopMenu()
                }
            },
            usersDropdownOpen: function () {
                if (this.usersDropdownOpen) {
                    this.closeTopMenu()
                }
            }
        },
        mounted() {
            this.getMenu()
        },
        methods: {
            getMenu() {
                return axios.get(api.API_ENDPOINTS.primaryMenu)
                    .then(response => {
                        let data = response.data.data
                        this.primaryMenu = Object.values(data).map(data => {
                            return data
                        })
                    }).finally(() => {
                        this.getUrlType()
                    })
            },
            getRootPrograms(type, url) {
                if (type === 'api') {
                    return axios.get(api.API_ROOT + '/' + url)
                        .then(response => {
                            this.rootPrograms = response.data
                        }).finally(() => {
                            this.sendChild = this.rootPrograms
                        })
                }
            },
            getChild(child) {
                if (this.sendChild === child) {
                    this.sendChild = null;
                } else {
                    this.sendChild = child;
                }
                this.isClickedTopNav();
            },
            isClickedTopNav() {
                this.topNavClicked = true
                this.$emit('isClickedTopNav', this.topNavClicked)
            },
            closeTopMenu(){
                this.sendChild = []
            },
            getUrlType() {
                return this.primaryMenu.map(data => {
                    return data.type
                })
            }
        },
    };
</script>

<style scoped>
    .menu-item svg {
        width: 10px;
        margin-left: 10px;
        position: relative;
    }

    #nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 5px;
        border-left: 1px solid #f1f1f1;
    }

    .menu-item {
        background: -webkit-linear-gradient(#6404cd, #4593d2);
        background-clip: text;
        text-decoration: none;
        padding: 23px 32px;
        font-size: 15px;
        transition: 0.3s;
        position: relative;
    }

    .router-link-active .menu-item-text,
    .menu-item:hover .menu-item-text {
        background: -webkit-linear-gradient(#6404cd, #4593d2);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .router-link-active .menu-item-text:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 9.5px 12px 9.5px;
        border-color: transparent transparent #f0f0f0 transparent;
    }

    .tooltiptext {
        position: absolute;
        top: -11px;
        right: -25px;
        width: 37px;
        height: 13px;
        background: linear-gradient(to right, #ff8a00, #ed3784);
        color: #fff;
        font-size: 10px;
        line-height: 13px;
        border-radius: 5px;
        -webkit-text-fill-color: #fff;
        text-align: center;
    }

    .menu-item-small-laptop {
        padding: 23px 10px !important;
        font-size: 13px !important;
    }

    @media only screen and (max-width: 1820px) {
        a.menu-item {
            padding: 23px 15px !important;
            font-size: 14px !important;
        }

        .tooltiptext {
            padding: 1px 4px;
            width: auto;
            right: -15px;
        }
    }

    @media only screen and (max-width: 1700px) {
        a.menu-item {
            padding: 23px 10px !important;
            font-size: 14px !important;
        }
    }

    @media only screen and (max-width: 1400px) {
        a.menu-item {
            padding: 23px 8px !important;
            font-size: 13px !important;
            line-height: 14px;
        }
    }

</style>
