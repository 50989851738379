import { render, staticRenderFns } from "./SidebarStiri.vue?vue&type=template&id=3b940bf4&scoped=true&"
import script from "./SidebarStiri.vue?vue&type=script&lang=js&"
export * from "./SidebarStiri.vue?vue&type=script&lang=js&"
import style0 from "./SidebarStiri.vue?vue&type=style&index=0&id=3b940bf4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b940bf4",
  null
  
)

export default component.exports