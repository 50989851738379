<template>
    <div class="padding-box">
        <div class="flex space-between">
            <div class="first-item white-box">
                <div class="flex flex-end">
                    <img src="@/assets/images/danger-sign.png">
                    <span class="section-title">Catalog Cheltuieli</span>
                </div>
                <p>Catalogul cheltuielilor este întocmit de experți SMIS.ro în colaborare cu experți și consultanți în
                    domeniul fiscal, în baza procedurii de implementare și a actualizărilor transmise de Ministerul
                    Economiei. Cheltuielile eligibile sunt în strânsă legatură cu codul CAEN pentru care s-a aplicat. Vă
                    rugăm să acordați o atenție sporită procedurii de implementare.</p>
            </div>
            <router-link to="#" class="second-item white-box centered">
                <img src="@/assets/images/download-cloud.png">
                <p>Descarcă procedura de</p>
                <span>implementare Măsura 2</span>
            </router-link>
        </div>
        <div class="gray-box">
            <ul class="sidebar-menu">
                <li class="s-menu-item item-active">
                    <span>Toate categoriile</span>
                </li>
                <li class="s-menu-item">
                    <span>Produse</span>
                </li>
                <li class="s-menu-item">
                    <span>Servicii</span>
                </li>
                <li class="s-menu-item">
                    <span>Taxe & impozite către stat</span>
                </li>
                <li class="s-menu-item orange-background">
                    <span>Cheltuieli neeligibile</span>
                </li>
            </ul>
            <div class="search">
                <b-form-input
                    v-model="searchQuery"
                    placeholder="Caută task"
                ></b-form-input>
                <b-button type="button" class="search-button">Șterge</b-button>
            </div>
            <div class="list">
                <ListaCheltuieli></ListaCheltuieli>
            </div>
        </div>
    </div>
</template>

<script>
import {BIconCloudDownload, BFormInput} from 'bootstrap-vue'
import ListaCheltuieli from './components/ListaCheltuieli';

export default {
    components: {
        BIconCloudDownload,
        BFormInput,
        ListaCheltuieli,
    },
    props: {},
    data() {
        return {
            searchQuery: null,
        };
    },
    mounted() {
    },
    methods: {},
};
</script>

<style scoped>
.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.centered p {
    margin-top: 15px;
}
.centered p, .centered span {
    font-size: 14px;
}
.centered span {
    background: -webkit-linear-gradient(#6404cd, #4593d2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 800;
}
.flex {
    display: flex;
}
.flex-end {
    align-items: flex-end;
}
.section-title {
    font-size: 14px;
    line-height: 17px;
    background: -webkit-linear-gradient(#6404cd, #4593d2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 800;
    margin-left: 10px;
}
.white-box {
    background: #fff;
    border: #dddddd;
    border-radius: 2px;
    padding: 15px;
}
.gray-box {
    background: #f8f8f8;
    border: #f8f8f8;
    border-radius: 2px;
    padding: 15px;
    margin-top: 15px;
}
.padding-box {
    padding: 20px;
}
.space-between {
    justify-content: space-between;
}
.first-item {
    width: 80%;
}
.first-item p {
    color: #8c8c8c;
    margin-top: 15px;
}
.second-item {
    width: 18%;
}
.sidebar-menu{
    display: flex;
    justify-content: space-between;
}
.s-menu-item{
    background: -webkit-linear-gradient(#6404cd, #4593d2);
    padding: 6px 31px;
    border-radius: 3px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
}
.orange-background{
    background: -webkit-linear-gradient(#ff5748, #ffad82);
}
.item-active{
    background: #fff;
    color: #000;
    border-width: 2px;
    border-style: solid;
    border-radius: 3px;
    border-image: linear-gradient(to right, #6404cd, #4593d2) 3;
}
.search{
    position: relative;
}
.search-button{
    position: absolute;
    top: 0;
    right: 0;
    background: #929498;
    padding: 8px 10px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
}
input{
    font-size: 14px;
}
</style>
